/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const loadDiv = (props) => {
  if (props) {
    console.log("props", props.divConfig.height);
    return (
      <div
        id={props.divData.id}
        style={{
          height: props.divConfig.height || "1px",
          width: props.divConfig.width || "100%",
        }}
      ></div>
    );
  } else {
    return "NO DVI DATA";
  }
};
export default loadDiv;