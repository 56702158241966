import axios from "axios";
import decode from "jwt-decode";
//require('dotenv').config()

// import store from 'common/redux/store';
// import { logout } from 'common/redux/user/actions';
// import Auth from "services/Auth";

//import AppConfig from "../../config";

//import config from 'config';

import {
  suscribe,
  getOptions,
  getAttributes,
  getFilterProductsElastic,
  getCartLocal,
  setCartLocal,
  getLayoutTemplateByName,
} from "../common/functions";

const api = axios.create({
  //baseURL: 'http://localhost:8080'
  baseURL: process.env.REACT_APP_API_CONFIG,
  //baseURL: 'https://back.casaideas.com.bo'
  // timeout: 1000,
});
const parse_res = (api) => api.then((data) => data.data);

const Api = {
  init: {
    init: () => console.log("init"),
  },
  menu: {
    userMenu: () => parse_res(api.get(`/api/getMenu`)),
    adminMenu: () => parse_res(api.get(`/api/getuserMenu`)),
  },
  customApi: (data) =>
    parse_res(
      api.post(
        `/externalapi/execute/` +
          data.apiName +
          `/key/3452356rtshfghsdfgy456fh`,
        data
      )
    ),

  customer: {
    /*miscellaneous*/

    getFilterProducts: (data) => getFilterProductsElastic(data),
    //getFilterProducts: (data) => parse_res(api.post(`/customerapi/getFilterProducts`, data)),
    getFilterProductsNew: (data) => getFilterProductsElastic(data),
    //getFilterProductsNew: (data) => parse_res(api.post(`/customerapi/getFilterProducts`, data)),

    getFilterProductsDB: (data) =>
      parse_res(api.post(`/customerapi/getFilterProducts`, data)),

    getHeaderMenu: (data) =>
      parse_res(api.post(`/customerapi/getHeaderMenu`, data)),
    getProductById: (data) =>
      parse_res(api.post(`/customerapi/getProductById`, data)),

    getProductByIdElastic: (data) => getFilterProductsElastic(data),

    //getOptions                  : (data) => parse_res(api.post(`/customerapi/getOptions`,  data )),

    getOptionsDB: (data) =>
      parse_res(api.post(`/customerapi/getOptions`, data)),
    getAttributesDB: (data) =>
      parse_res(api.post(`/customerapi/getAttributes`, data)),

    getOptions: (data) => getOptions(data),
    getFilterProductsElastic: (data) =>
      parse_res(api.post(`/customerapi/getFilterProductsElastic`, data)),

    prevalOrderDiscount: (data) =>
      parse_res(api.post(`/customerapi/prevalOrderDiscount`, data)),
    checkCartProducts: (data) =>
      parse_res(api.post(`/customerapi/checkCartProducts`, data)),

    prevalCoupon: (data) =>
      parse_res(api.post(`/customerapi/prevalCoupon`, data)),
    unlockCoupon: (data) =>
      parse_res(api.post(`/customerapi/unlockCoupon`, data)),
    getValCouponByUser: (data) =>
      parse_res(api.post(`/customerapi/getValCouponByUser`, data)),
    getAttributes: (data) => getAttributes(data),
    recoveryPass: (data) =>
      parse_res(
        api.post(
          `/externalapi/execute/recoveryPass/key/3452356rtshfghsdfgy456fh`,
          data
        )
      ),

    getCategories: (data) =>
      parse_res(api.post(`/customerapi/getCategories`, data)),
    getCategoriesByStore: (data) =>
      parse_res(api.post(`/customerapi/getCategoriesByStore`, data)),
    getProductQuantity: (data) =>
      parse_res(api.post(`/customerapi/getProductQuantity`, data)),
  },

  manager: {
    /*
	 
		*/
  },
  /*layaoutManager*/
  layaoutManager: {
    getLayoutTemplateByNameOld: (data) =>
      parse_res(api.post(`/api/layout/getLayoutTemplateByName`, data)),
    getLayoutTemplateByName: (data) =>
      parse_res(api.post(`/api/s3/getFile`, data)),
    getProducts: (data) => parse_res(api.post(`/api/layout/getProducts`, data)),

    getLayoutTemplateByNameNew: (data) => getLayoutTemplateByName(data),
  },

  /*frontend*/
  frontend: {
    //getCart: (data) => parse_res(api.post(`/api/frontend/getCart`, data)),
    getCart: (data) => getCartLocal(data),
    //setCart: (data) => parse_res(api.put(`/api/frontend/setCart`, data)),
    setCart: (data) => setCartLocal(data),

    setCustomers: (data) =>
      parse_res(api.put(`/api/frontend/setCustomers`, data)),
    updateCustomers: (data) =>
      parse_res(api.put(`/api/frontend/updateCustomers`, data)),
    changePassword: (data) =>
      parse_res(api.put(`/api/frontend/changePassword`, data)),
    changePasswordByToken: (data) =>
      parse_res(api.put(`/api/frontend/changePasswordByToken`, data)),

    getStores: (data) => parse_res(api.post(`/api/frontend/getStores`, data)),

    setCustomerAddress: (data) =>
      parse_res(api.put(`/api/frontend/setCustomerAddress`, data)),
    updateCustomerAddress: (data) =>
      parse_res(api.put(`/api/frontend/updateCustomerAddress`, data)),
    deleteCustomerAddress: (data) =>
      parse_res(api.put(`/api/frontend/deleteCustomerAddress`, data)),

    login: (data) => parse_res(api.post(`/api/frontend/login`, data)),
    loginSNKey: (data) => parse_res(api.post(`/api/frontend/loginSNKey`, data)),
    getTokenEmail: (data) =>
      parse_res(api.post(`/api/frontend/getTokenEmail`, data)),
    getOrders: (data) => parse_res(api.post(`/api/frontend/getOrders`, data)),
    getStatusOrders: (data) =>
      parse_res(api.post(`/api/frontend/getStatusOrders`, data)),
    getOrderById: (data) =>
      parse_res(api.post(`/api/frontend/getOrderById`, data)),
    checkOrderPaid: (data) =>
      parse_res(api.post(`/api/frontend/checkOrderPaid`, data)),
    setOrderNotify: (data) =>
      parse_res(api.put(`/api/frontend/setOrderNotify`, data)),
    getOrderByCustomerId: (data) =>
      parse_res(api.post(`/api/frontend/getOrderByCustomerId`, data)),
    setOrders: (data) => parse_res(api.put(`/api/frontend/setOrders`, data)),
    setOrdersCoupons: (data) =>
      parse_res(api.put(`/api/frontend/setOrdersCoupons`, data)),
    setExternalOrders: (data) =>
      parse_res(api.put(`/api/frontend/setExternalOrders`, data)),

    updateStatusPending: (data) =>
      parse_res(api.put(`/api/frontend/updateStatusPending`, data)),

    updateOrders: (data) =>
      parse_res(api.put(`/api/frontend/updateOrders`, data)),
    addWishlist: (data) =>
      parse_res(api.put(`/api/frontend/addWishlist`, data)),
    getWishListDB: (data) =>
      parse_res(api.post(`/api/frontend/getWishlist`, data)),
    getTokenWishlist: (data) =>
      parse_res(api.post(`/api/frontend/getTokenWishlist`, data)),
    getTokenEventlist: (data) =>
      parse_res(api.post(`/api/frontend/getTokenEventlist`, data)),
    decodeToken: (data) =>
      parse_res(api.post(`/api/frontend/decodeToken`, data)),
    decodePrivateToken: (data) =>
      parse_res(api.post(`/api/frontend/decodePrivateToken`, data)),
    getPaymentTypes: (data) =>
      parse_res(api.post(`/api/frontend/getPaymentTypes`, data)),
    getPaymentTypesByStoreId: (data) =>
      parse_res(api.post(`/api/frontend/getPaymentTypesByStoreId`, data)),
    getShippingTypes: (data) =>
      parse_res(api.post(`/api/frontend/getShippingTypes`, data)),
    getEventTypes: (data) =>
      parse_res(api.post(`/api/frontend/getEventTypes`, data)),

    searchEvents: (data) =>
      parse_res(api.post(`/api/frontend/searchEvents`, data)),

    setProductEvent: (data) =>
      parse_res(api.put(`/api/frontend/setProductEvent`, data)),
    updateProductEvent: (data) =>
      parse_res(api.put(`/api/frontend/updateProductEvent`, data)),
    deleteProductEvent: (data) =>
      parse_res(api.put(`/api/frontend/deleteProductEvent`, data)),

    getCustomerEventById: (data) =>
      parse_res(api.post(`/api/frontend/getCustomerEventById`, data)),
    getCustomerEventsByUserId: (data) =>
      parse_res(api.post(`/api/frontend/getCustomerEventsByUserId`, data)),
    getTokenManageEvent: (data) =>
      parse_res(api.post(`/api/frontend/getTokenManageEvent`, data)),
    getPolygonsByCity: (data) =>
      parse_res(api.post(`/api/frontend/getPolygonsByCity`, data)),
    checkCoords: (data) =>
      parse_res(api.post(`/api/frontend/checkCoords`, data)),
    /*cart*/
    updateCart: (data) => parse_res(api.put(`/api/frontend/updateCart`, data)),
    deleteCartByUserId: (data) =>
      parse_res(api.put(`/api/frontend/deleteCartByUserId`, data)),
    deleteCart: (data) => parse_res(api.put(`/api/frontend/deleteCart`, data)),

    getUserInfoByCartID: (data) =>
      parse_res(api.post(`/api/frontend/getUserInfoByCartID`, data)),

    getFormShippingTypes: (data) =>
      parse_res(api.post(`/api/frontend/getFormShippingTypes`, data)),
    getAcviteCartsByUserId: (data) =>
      parse_res(api.post(`/api/frontend/getAcviteCartsByUserId`, data)),
    shopStatus: (data) => parse_res(api.post(`/api/frontend/shopStatus`, data)),
  },

  /*admin just for logged user*/
  admin: {
    getCustomerById: (data) =>
      parse_res(api.post(`/api/admin/getCustomerById`, data)),
  },

  /*s3*/
  s3: {
    listFiles: (data) => parse_res(api.post(`/api/s3/listFiles`, data)),
    uploadImage: (data) => parse_res(api.post(`/api/s3/uploadImage`, data)),
  },

  /*predefined apis for forms*/
  predefined: {
    suscribe: (data) => suscribe(data),
  },
};

export default Api;

export const setApiToken = (token) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + token;
};
