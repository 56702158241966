/*****components***/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
//import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from "react-google-login";
/*custom components*/
import PageLoading from "../../components/PageLoading";

import Form from "../../components/Form";
import successIcon from "../../assets/images/ico_success.svg";
import errorIcon from "../../assets/images/ico_error.svg";

import { MDBAlert } from "mdbreact";
import decode from "jwt-decode";
import { toast } from "mdbreact";
import LoginG from "./loginG";
import {
  loginSN,
  login,
  getLoginInfo,
  changeHandler,
  getRenderObj,
  getLanguage,
  getDefValues,
  callApi,
} from "../../common/functions";

/*functions*/

const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginFormData: {},
      loginFormConfig: {},
      preRegisterFormData: {},
      preRegisterFormConfig: {},
      emialR: "",
      goHome: false,
      rememberme: true,
      showRecoveryMessage: false,
      showRecoveryErrorMessage: false,
      MetaTags: {
        title: getDefValues().loginLabel,
        names: [
          { name: "description", content: getDefValues().loginLabel },
          { name: "keywords", content: process.env.REACT_APP_ORG_NAME },
          { name: "robots", content: "INDEX,FOLLOW" },
        ],
      },
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      var token = this.props.match.params.token;
      if (token) {
        if (token === "successful") {
          this.setState({ showSuccessMessage: true });
        } else {
          const { exp } = decode(token);
          if (exp > (new Date().getTime() + 1) / 1000) {
            this.setState({ emialR: decode(token).email });
            var formData = { email: decode(token).email };
            var resp = await callApi("customer", "recoveryPass", formData);
            if (resp.success) {
              this.setState({ showRecoveryMessage: true });
            } else {
              this.setState({ showRecoveryErrorMessage: true });
            }
          }
        }
      }

      var login = getLoginInfo();
      if (login.userId != null) {
        this.setState({ isLoading: false, goHome: true });
      } else {
        this.props.hideCart();
        let loginForm = await callApi(
          "layaoutManager",
          "getLayoutTemplateByName",
          {
            layoutFile: "loginForm_" + getLanguage(),
            foldername: foldername + "private/",
          }
        );
        let preregisterForm = await callApi(
          "layaoutManager",
          "getLayoutTemplateByName",
          {
            layoutFile: "preregisterForm_" + getLanguage(),
            foldername: foldername + "private/",
          }
        );

        let rendegObj = await getRenderObj(
          loginForm.template[0],
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        let rendegObj2 = await getRenderObj(
          preregisterForm.template[0],
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );

        this.setState({
          loginFormData: rendegObj.curdata.data,
          loginFormConfig: rendegObj.curdata.config,
          preRegisterFormData: rendegObj2.curdata.data,
          preRegisterFormConfig: rendegObj2.curdata.config,
          isLoading: false,
        });
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
      this.setState({ isLoading: false });
    }
  };

  changeHandler = async (event) => {
    if (event.target.name === "rememberme") {
      this.setState({
        rememberme: !this.state.rememberme,
      });
    } else {
      //let resp = await validateForm(this.state.loginFormData, toast, event);
      //this.setState({ loginFormData: resp.formData });

      var formData = changeHandler(event, this.state.loginFormData);
      this.setState({ loginFormData: formData });
    }

    if (event.key === "Enter") {
      this.login(event);
    }
  };

  login = async (event) => {
    try {
      this.setState({ isLoading: true });

      var resp = await login(
        this.state.loginFormData,
        this.state.rememberme,
        toast
      );

      if (resp.success) {
        this.setState({ goHome: true });

        if (resp.email && resp.email !== "") {
          var objBloomreach_login = {
            email: resp.email,
            firstname: resp.firstname || "",
            lastname: resp.lastname || "",
            lastname2: resp.lastname2 || "",
            opt_in_ofertas: false,
            opt_in_tyc: true,
            origen: "web",
            phone: resp.phone || "",
            DNI: resp.code || "",

            //aqui pueden añadir los otros atributos que puedan obtener al login
          };
          var objBloomreach_setCustomer = {
            registered: resp.code || "", //Este atributo es OBLIGATORIO, Aqui va la identificacion si se tiene si no va vacia NO ES NULL DEBE IR VACIA si va vacio se debe incluir obligatorio el de email_id que es el soft_id
            email_id: resp.email, //Si se tiene el valor se debe añadir
          };

          if (resp.exponea_status !== "YES") {
            console.log("BR new");

            window.exponea.track("register", objBloomreach_login);
            window.exponea.update(objBloomreach_login);
            window.exponea.identify(objBloomreach_setCustomer);
            window.location.reload();
          } else {
            console.log("BR old");
            window.exponea.track("login", objBloomreach_login);
            window.exponea.update(objBloomreach_login);
            window.exponea.identify(objBloomreach_setCustomer);
            window.location.reload();
          }
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "exponea_registered",
            "YES"
          );
        } else {
          console.log("ALERT!! no email");
        }
      }

      this.setState({ isLoading: false });
      this.setState({ successForm: resp.success });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  responseFacebook = (response) => {
    console.log(response);
  };

  callBackSN = async (res) => {
    console.log("google");
    //	if( this.state.tryOut){
    try {
      this.setState({ isLoading: true });
      var status = await loginSN(res, toast, this.state.rememberme);
      if (status) {
        this.setState({ goHome: true });

        var login = getLoginInfo();

        let data = await callApi("admin", "getCustomerById", {
          customerid: login.userId,
        });
        let resp = data.USER;
        if (resp.EMAIL && resp.EMAIL !== "") {
          var objBloomreach_login = {
            email: resp.EMAIL,
            firstname: resp.FIRSTNAME || "",
            lastname: resp.LASTNAME || "",
            lastname2: resp.LASTNAME2 || "",
            opt_in_ofertas: false,
            opt_in_tyc: true,
            origen: "web",
            phone: resp.PHONE || "",
            DNI: resp.CODE || "",

            //aqui pueden añadir los otros atributos que puedan obtener al login
          };
          var objBloomreach_setCustomer = {
            registered: resp.CODE || "", //Este atributo es OBLIGATORIO, Aqui va la identificacion si se tiene si no va vacia NO ES NULL DEBE IR VACIA si va vacio se debe incluir obligatorio el de email_id que es el soft_id
            email_id: resp.EMAIL, //Si se tiene el valor se debe añadir
          };

          if (resp.EXPONEA_STATUS !== "YES") {
            console.log("BR_new");

            window.exponea.track("register", objBloomreach_login);
            window.exponea.update(objBloomreach_login);
            window.exponea.identify(objBloomreach_setCustomer);

            setTimeout(
              async function () {
                window.location.reload();
              }.bind(this),
              500
            );
          } else {
            console.log("BR_old");
            window.exponea.track("login", objBloomreach_login);
            window.exponea.update(objBloomreach_login);
            window.exponea.identify(objBloomreach_setCustomer);

            setTimeout(
              async function () {
                window.location.reload();
              }.bind(this),
              500
            );
          }
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "exponea_registered",
            "YES"
          );
        } else {
          console.log("ALERT!! no email");
        }
        window.location.reload();
      }
      this.setState({ isLoading: false });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
    //	}
    this.setState({ tryOut: true });
  };

  callBackSNError = (res) => {
    console.log("Login failed: res:", res);
  };
  /*
	handleLogin = async googleData => {
		const res = await fetch("http://localhost:8080/api/v1/auth/google", {
			method: "POST",
			body: JSON.stringify({
				token: googleData.tokenId
			}),
			headers: {
				"Content-Type": "application/json"
			}
		});
		const data = await res.json()
		// store returned user somehow
	}*/
  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.goHome ? <Redirect to={{ pathname: "/" }} /> : ""}
        <PageLoading
          isLoading={this.state.isLoading}
          MetaTags={this.state.MetaTags}
        />

        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content container-page custom-container container-fluid">
            {this.state.showRecoveryMessage ? (
              <MDBAlert color="success" className="mt-50 mb-30">
                <img
                  className="formIcon"
                  src={successIcon}
                  alt="success-icon"
                />
                <span className="formIcon-span">
                  {" "}
                  {getDefValues().recoveryMessageLabel.replace(
                    "<email>",
                    this.state.emialR
                  )}{" "}
                </span>
              </MDBAlert>
            ) : (
              ""
            )}

            {this.state.showSuccessMessage ? (
              <MDBAlert color="success" className="mt-50 mb-30">
                <img
                  className="formIcon"
                  src={successIcon}
                  alt="success-icon"
                />
                <span className="formIcon-span">
                  {" "}
                  {getDefValues().recoverySuccessMessageLabel}{" "}
                </span>
              </MDBAlert>
            ) : (
              ""
            )}

            {this.state.showRecoveryErrorMessage ? (
              <MDBAlert color="danger" className="mt-50 mb-30">
                <img className="formIcon" src={errorIcon} alt="error-icon" />
                <span className="formIcon-span">
                  {" "}
                  {getDefValues().recoveryErrorMessageLabel.replace(
                    "<email>",
                    this.state.emialR
                  )}{" "}
                </span>
              </MDBAlert>
            ) : (
              ""
            )}

            <div
              className={
                "card  mt-30 " + (!this.state.isLoading ? "card-user-info" : "")
              }
            >
              <div className="row mt-30 mb-30">
                <div
                  className={
                    "mobile-col col-12 col-sm-12 col-md-5 col-lg-5" +
                    (this.props.windowWidth > 992
                      ? " ml-5 border-right"
                      : " ml-1")
                  }
                >
                  <Form
                    customClass={"userForm"}
                    formData={this.state.loginFormData}
                    changeHandler={this.changeHandler}
                    formConfig={this.state.loginFormConfig}
                  />
                  {!this.state.isLoading ? (
                    <div className="col-12 col-lg-9 col-md-9 col-sm-12">
                      <button
                        onClick={this.login}
                        className="accout-btn btn btn-primary text-bold btn-block"
                      >
                        {" "}
                        {getDefValues().loginLabel}{" "}
                      </button>
                      <div className="row" style={{ margin: "10px 0 0 0" }}>
                        <div className="col-6 flex text-left">
                          <label>
                            <input
                              name="rememberme"
                              className="myinput"
                              checked={this.state.rememberme}
                              value="YES"
                              onChange={this.changeHandler}
                              type="checkbox"
                            />{" "}
                            {getDefValues().remembermeLabel}{" "}
                          </label>
                        </div>
                        <div
                          style={{ paddingRight: "0px" }}
                          className="col-6 flex text-right items-right"
                        >
                          <Link to="/customer/account/forgotpassword">
                            {" "}
                            <span className="text-underline">
                              {" "}
                              {getDefValues().forgotPasswordLabel}
                            </span>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.props.windowWidth < 992 && !this.state.isLoading ? (
                    <div className="or-line mt-40 mb-2 ml-2 mr-2"></div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={
                    "mobile-col col-12 col-sm-12 col-md-5 col-lg-5" +
                    (this.props.windowWidth < 992 ? " mt-3 " : " ml-3 ")
                  }
                >
                  <Form
                    formData={this.state.preRegisterFormData}
                    changeHandler={this.changeHandler}
                    formConfig={this.state.preRegisterFormConfig}
                  />
                  {!this.state.isLoading ? (
                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 mt-30">
                      <Link to="/customer/account/create">
                        <button className="accout-btn btn btn-outline-primary text-bold btn-block">
                          {" "}
                          {getDefValues().createAccountLabel}{" "}
                        </button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mb-20">
                {!this.state.isLoading ? (
                  <p className="mobile-col col-12 col-sm-12 col-md-5 col-lg-5 social-login flex items-center">
                    <LoginG
                      setExecuting={() => {
                        this.setState({ isLoading: !this.state.isLoading });
                      }}
                    />
                    {/*
                    <GoogleLogin
                      clientId={process.env.REACT_APP_API_KEY_GOOGLE}
                      buttonText="Google"
                      className="google"
                      onSuccess={this.callBackSN}
                      onFailure={this.callBackSNError}
                      cookiePolicy={"single_host_origin"}
                    />
                    */}

                    {/*
										<FacebookLogin
											cssClass="ml-2 facebook"
											appId={process.env.REACT_APP_API_KEY_LOGIN_FB}
											autoLoad={false}
											fields="name,email,picture,last_name,first_name"
											scope="public_profile, email"
											callback={this.callBackSN}
											onFailure={this.callBackSNError}
											textButton="Facebook"
											icon="fa-facebook" />
										 */}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
