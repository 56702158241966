import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";
import React, { Component } from "react";


class DatePicker extends Component {
	 constructor(props) {
    super(props);
    this.state = {
			 date: this.props.date,
			 enableTime:false,
			 show:true
		    };
  	}
  	
  	updateDatePicker = () => {
  		this.setState({ 
			show:false
		});
		this.setState({ 
			show:true
		});
	};
	
  render() {
  	var { date } = this.state;
  	 
	  return ( 
	  <>
	  	{this.props.isUpdatable?
		  <>
	   	<Flatpickr
	   	 
			value={this.props.date}
			options={ this.props.options }
			name={this.props.name}
			onChange={([date]) => {
				this.updateDatePicker();
				this.props.updateDate({ target:{value: date, name:this.props.name}})
        	}}
      	/>

	  </>
    :""}
    </>
	  );
	}
};
export default DatePicker;
				 