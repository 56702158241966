/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";

import { Link } from "react-router-dom";

/*custom components*/
import PageLoading from "../../components/PageLoading";

import { MDBAlert } from "mdbreact";
import {
  getLoginInfo,
  getImagePath,
  getCurrency,
  getDefValues,
  toFixed,
  formatPathName, 
  getLanguage,
  callApi,
  find,
} from "../../common/functions";
import defaultImage from "../../assets/images/default.jpg";

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoading: true,
      orderNotFound: false,
      listOrders: [],
      goLogin: false,
      orgName: process.env.REACT_APP_ORG_NAME,
      order: {},
      orderDetails: [],
      orderHistory: [],
      orderStatusArr: [],
      totalOrderDetails: 0,
      statusDesc: {},
      orderCanceled: false,
      MetaTags: {
        title: getDefValues().orderDetailLabel,
        names: [
          { name: "description", content: getDefValues().orderDetailLabel },
          { name: "keywords", content: process.env.REACT_APP_ORG_NAME },
          { name: "robots", content: "INDEX,FOLLOW" },
        ],
      },
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      this.props.hideCart();
      var orderid = this.props.match.params.orderid;
      var email = this.props.match.params.email;
      var login = getLoginInfo();
      var userId = 0;
      if (login.userId != null) {
        userId = login.userId;
      }
      let resp1 = await callApi("frontend", "getOrderById", {
        orderid: orderid,
        email: email,
        userId: userId,
        languageid: getLanguage(),
      });
      let resp2 = await callApi("frontend", "getStatusOrders", {
        languageid: getLanguage(),
      });
      if (resp1.success) {
        var activeStatusGroup = "";
        resp2.rows.map(function (key) {
          if (key.STATUSID == resp1.order.STATUSID) {
            activeStatusGroup = key.STATUSGROUP;
          }
        });

        var statusDesc = {};
        var orderStatusArr = [];
        resp2.rows.map(function (key) {
          let pos = 0;
          if (key.TYPE === "ADDRESS" || key.TYPE === "PICKUP") {
            if (resp1.order.SHIPPINGTYPE !== key.TYPE) {
              pos = 1;
            } else {
              pos = find(orderStatusArr, key.STATUSGROUP, "STATUSGROUP");
            }
          } else {
            pos = find(orderStatusArr, key.STATUSGROUP, "STATUSGROUP");
          }

          if (pos < 0 && key.STATUSGROUP != null) {
            var isActive = key.STATUSGROUP === activeStatusGroup ? true : false;

            orderStatusArr.push({
              STATUSGROUP: key.STATUSGROUP,
              IMAGEPATH: key.IMAGEPATH,
              IMAGEPATHACTIVE: key.IMAGEPATHACTIVE,
              STATUSDESCRIPTION: key.STATUSDESCRIPTION,
              isActive: isActive,
              STATUSID: key.STATUSID,
              MASTERICON: key.TYPE === "SUCCESS" ? key.COLOR : null,
            });
          }
          if (key.STATUSID === resp1.order.STATUSID) {
            console.log("emncontrado");
            statusDesc = {
              name: key.STATUSGROUP,
            };
          }
        });

        var orderCanceled = false;
        if (!statusDesc.name) {
          console.log("null");
          resp1.orderHistory.map(function (key) {
            let pos = find(resp2.rows, key.STATUSID, "STATUSID");
            if (!statusDesc.name) {
              if (pos >= 0) {
                statusDesc = {
                  name: resp2.rows[pos]["STATUSGROUP"],
                };
              }
            }
            if (pos >= 0) {
              console.log("vali");
              if (resp2.rows[pos]["TYPE"] === "CANCEL") {
                orderCanceled = true;
                console.log("can", resp2.rows[pos]["STATUSNAME"]);
                statusDesc = {
                  name: resp2.rows[pos]["STATUSNAME"],
                };

                orderStatusArr.push({
                  STATUSGROUP: resp2.rows[pos].STATUSNAME,
                  STATUSDESCRIPTION: resp2.rows[pos].STATUSDESCRIPTION,
                  isActive: true,
                  STATUSID: resp2.rows[pos].STATUSID,
                  MASTERICON: resp2.rows[pos].COLOR,
                });
              }
            }
          });
        }

        let pos = find(orderStatusArr, statusDesc.name, "STATUSGROUP");

        statusDesc["description"] = orderStatusArr[pos].STATUSDESCRIPTION;
        statusDesc["mastericon"] = orderStatusArr[pos].MASTERICON;

        var orderHistory = resp1.orderHistory;

        orderHistory.map(function (key) {
          let pos = find(resp2.rows, key.STATUSID, "STATUSID");
          key.OBSERVATION = resp2.rows[pos].STATUSDESCRIPTION;
        });

        var orderDetails = [];
        resp1.orderDetails.map(function (key) {
          if (key.PACKNAME) {
            var filled = false;
            resp1.orderDetails.map(function (key2) {
              if (!filled && key.PACKNAME === key2.PACKNAME) {
                let pos = find(orderDetails, key.PACKNAME, "PACKNAME");
                if (pos < 0) {
                  filled = true;
                  orderDetails.push(key2);
                }
              }
            });
          } else {
            orderDetails.push(key);
          }
        });
        var totalOrderDetails = 0;
        orderDetails.map(function (key) {
          totalOrderDetails = totalOrderDetails + key.QUANTITY;
        });

        this.setState({
          order: resp1.order,
          orderDetails: orderDetails,
          orderHistory: orderHistory,
          orderStatusArr: orderStatusArr,
          isLoading: false,
          totalOrderDetails: totalOrderDetails,
          statusDesc: statusDesc,
          orderCanceled: orderCanceled,
        });
      } else {
        this.setState({
          isLoading: false,
          orderNotFound: true,
        });
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
      this.setState({ isLoading: false });
    } finally {
    }
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        <PageLoading
          isLoading={this.state.isLoading}
          MetaTags={this.state.MetaTags}
        />

        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content custom-container container-fluid ">
            <div className="card">
              <div className="row">
                <div className="mobile-col col-12">
                  <div
                    className="categoryRoot mt-4 mb-2"
                    style={{ "margin-left": "-15px" }}
                  >
                    {this.state.orgName + " > "}{" "}
                    <span>{getDefValues().orderInfoLabel}</span>
                  </div>
                  <div className="mobile-col col-12 mt-30">
                    <div className="row order-status-header">
                      <div className="mobile-col col-12 col-md-8 col-lg-9">
                        {this.state.order.SHIPPINGDATE ? (
                          <span className="h1-responsive">
                            {getDefValues().shippingDateMessageLabel + " "}
                            <span className="text-primary">
                              {this.state.order.SHIPPINGDATE}{" "}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mobile-col col-12 col-md-4 col-lg-3 resume-info">
                        {this.state.order.ORDERID ? (
                          <>
                            <p>
                              {" "}
                              {getDefValues().orderLabel + ": "}{" "}
                              <span className="h6-responsive text-primary">
                                {" "}
                                {this.state.order.ORDERID}{" "}
                              </span>{" "}
                            </p>
                            <p>
                              {" "}
                              {getDefValues().paymentDateLabel + ": "}{" "}
                              <span className="text-bold">
                                {this.state.order.DATEADDED}
                              </span>{" "}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="mobile-col col-12">
                        <p className="flex items-center mt-30 mb-30">
                          <span className="h1-responsive">
                            {getDefValues().orderDetailLabel}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-col col-12">
                    <div
                      className={
                        "flex items-center" +
                        (this.state.orderNotFound ? "" : " order-status")
                      }
                    >
                      <div className=" direction-column flex items-center ">
                        {this.state.orderNotFound ? (
                          <div className="row">
                            <div className="col-12 flex items-center">
                              <MDBAlert color="danger" className="mt-2 mb-2">
                                <p className="mt-3">
                                  <span
                                    className="h2-responsive"
                                    style={{ width: "100%" }}
                                  >
                                    <i className="fas fa-exclamation-circle mr-1"></i>
                                    {getDefValues().orderNotFoundLabel}
                                  </span>
                                </p>
                              </MDBAlert>
                            </div>
                            <div className="col-12 flex items-left">
                              <p>
                                <Link to="/customer/tracking">
                                  <span className="h4-responsive dark-text">
                                    {" "}
                                    <i className="fas fa-arrow-circle-left"></i>{" "}
                                    {getDefValues().goBackLabel}{" "}
                                  </span>
                                </Link>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="row mt-50">
                            <div className="col-12 flex items-center">
                              {this.state.statusDesc.mastericon ? (
                                <img
                                  width="60px"
                                  height="60px"
                                  src={getImagePath(
                                    this.state.statusDesc.mastericon
                                  )}
                                />
                              ) : (
                                ""
                              )}
                              <span className="ml-2 title">
                                {this.state.statusDesc.name}
                              </span>
                            </div>
                          </div>
                        )}

                        {this.state.orderNotFound ? (
                          ""
                        ) : (
                          <div className="row mt-3 mb-50">
                            <div className="col-12">
                              <span>{this.state.statusDesc.description}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {!this.state.orderCanceled ? (
                    <div className="mobile-col col-12">
                      <div className="flex order-status-container">
                        {this.state.orderStatusArr.map((item) => (
                          <div className=" flex  step-order-status">
                            <div className={"flex text-center items-center "}>
                              {item.IMAGEPATH ? (
                                <img
                                  src={getImagePath(
                                    item.isActive
                                      ? item.IMAGEPATHACTIVE
                                      : item.IMAGEPATH
                                  )}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row order-status order-status-white">
                    <div className="mobile-col col-1 col-lg-3 mt-50 mb-50"></div>
                    <div className="mobile-col col-11 col-lg-9 mt-50 mb-50">
                      <ul>
                        {this.state.orderHistory.map((item, i) => (
                          <li className="history-label">
                            {item.DATE_ADDED} {item.OBSERVATION}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {this.state.orderDetails.length > 0 ? (
                    <>
                      <div className="mobile-col col-12">
                        <div className="flex items-left order-status">
                          <div className=" direction-column flex items-left ">
                            <div className="row mt-3 ml-2">
                              <div className="col-12">
                                <span className="title">
                                  {getDefValues().productsLabel.toUpperCase()}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-3 ml-2">
                              <div className="col-12">
                                <span>
                                  {getDefValues().itemsOrderLabel + ": "}{" "}
                                  <span className="text-primary">
                                    {this.state.totalOrderDetails}{" "}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mobile-col col-12">
                        <div className="order-status">
                          <div className="row">
                            {this.state.orderDetails.map((item) => (
                              <div className="product-detail">
                                <div
                                  key={"k_img" + item.PRODUCTID}
                                  className="image"
                                >
                                  <a
                                    href={
                                      "/" +
                                      formatPathName(item.CATEGORY) +
                                      "/" +
                                      formatPathName(
                                        item.PRODUCTNAME + "-" + item.PRODUCTID
                                      )
                                    }
                                    tabIndex="-1"
                                  >
                                    <img
                                      key={"img" + item.PRODUCTID}
                                      name={"manageImage_" + item.PRODUCTNAME}
                                      src={
                                        item.DEFAULTIMAGE !== ""
                                          ? getImagePath(item.DEFAULTIMAGE)
                                          : defaultImage
                                      }
                                    />
                                  </a>
                                </div>
                                <div
                                  key={"info" + item.PRODUCTID}
                                  className="detail"
                                >
                                  <p key={"p1" + item.PRODUCTID}>
                                    {item.PRODUCTNAME}
                                  </p>
                                  <p key={"p2" + item.PRODUCTID}>
                                    <span key={"spaninfo" + item.PRODUCTID}>
                                      {" "}
                                      {item.PACKNAME
                                        ? getDefValues().packRefLabel +
                                          ": " +
                                          item.PRODUCTID
                                        : "SKU: " + item.SKU}
                                    </span>
                                  </p>
                                  <p key={"p3" + item.PRODUCTID}>
                                    <span key={"spaninfo" + item.PRODUCTID}>
                                      {" "}
                                      {getDefValues().shopCartQuantityLabel +
                                        ": " +
                                        item.QUANTITY}
                                    </span>
                                  </p>
                                  <p key={"p4" + item.PRODUCTID}>
                                    <span key={"spaninfo" + item.PRODUCTID}>
                                      {" "}
                                      {getCurrency()}
                                      {item.PACKNAME
                                        ? item.PRODUCTPRICE
                                        : item.PRICE}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            ))}
                            <div className="product-detail ">
                              <div className="image"></div>
                              <div className="detail">
                                <div className="row">
                                  <div className="col-12">
                                    <span className="h6-responsive">
                                      {getDefValues().orderResumeLabel}
                                    </span>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-6">
                                    <span>
                                      {getDefValues().shopCartSubtotalLabel}
                                    </span>
                                  </div>
                                  <div className="col-6 text-right">
                                    <span className="text-bold">
                                      {getCurrency()}
                                      {toFixed(this.state.order.SUBTOTAL)}
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <span>
                                      {getDefValues().shippingCostLabel}
                                    </span>
                                  </div>
                                  <div className="col-6 text-right">
                                    <span className="text-bold">
                                      {getCurrency()}
                                      {toFixed(this.state.order.SHIPPINGCOST)}
                                    </span>
                                  </div>
                                </div>{" "}
                                <span className="or-line mt-2 mr-2"></span>
                                <div className="row">
                                  <div className="col-6">
                                    <span className="text-bold">
                                      {getDefValues().totalOrderLabel}
                                    </span>
                                  </div>
                                  <div className="col-6 text-right">
                                    <span className="text-bold">
                                      {getCurrency()}
                                      {toFixed(this.state.order.TOTAL)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
