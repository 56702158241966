/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from 'react';

import { Link,Redirect } from "react-router-dom";

/*custom components*/ 
import PageLoading from '../../components/PageLoading';
 
import MenuInfo from '../../components/MenuInfo';
 

import {toast } from 'mdbreact';
import {getLoginInfo, getDefValues, validateForm, getLanguage, callApi, find} from '../../common/functions';

/*functions*/

class page extends Component{


	constructor(props) {
		
		super(props);
		this.state = {
			
			 formResumeData : {
			 		contactinfo          : {label: "Informacion de contacto"    , type: "LABEL" , class:"h6-responsive", size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6" },
			 		newsletterinfo       : {label: "Newsletter"                 , type: "LABEL" , class:"h6-responsive", size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6" }
			 },
			 formResumeConfig :{ colsize: "col-12", formtitle: "informacion de mi Cuenta"} ,
			 products:[],
			 isLoading:true,
			 ordersCount:0,
			 listOrders: [],
			 goLogin:false,
			  MetaTags : {title: getDefValues().myOrdersLabel,
									names:[
										{  name: "description" , content: getDefValues().myOrdersLabel},
										{  name: "keywords" , content: process.env.REACT_APP_ORG_NAME },
										{  name: "robots"   , content: "INDEX,FOLLOW"}
									]},
		}
	}
	/*loading data*/
	componentDidMount = async() => {
		try{			
			var  login = getLoginInfo()
			if (login.userId ==null) {
				this.setState({ isLoading:false, goLogin:true })
			}else{				
				this.props.hideCart()
				let resp1 = await callApi ( "frontend","getOrderByCustomerId", { customerid:login.userId } )
				let resp2 = await callApi ( "frontend","getStatusOrders" , { languageid : getLanguage() } )
				var orders =  resp1.rows
				var orderStatus = []
				var orderObj =[]
				
				resp2.rows.map(function (key) {
			 		let  pos =0
			 		 
			 		pos = find ( orderStatus, key.STATUSGROUP, "STATUSGROUP" )

			 		if (pos<0 && key.STATUSGROUP!=null){  
			 			
			 			var statusList =[]
			 			
			 			resp2.rows.map(function (key2) {
			 				if (key.STATUSGROUP === key2.STATUSGROUP){
			 					statusList.push( key2.STATUSID )
			 				}
			 			})
			 			orderStatus.push({
			 				STATUSGROUP       :  key.STATUSGROUP,
			 				IMAGEPATH         :  key.IMAGEPATH,
			 				IMAGEPATHACTIVE   :  key.IMAGEPATHACTIVE,
			 				STATUSDESCRIPTION :  key.STATUSDESCRIPTION,
			 				STATUSLIST        :  statusList
			 				})
			 		}  
			 	})
			 	var ordersCount=0
			  	
				for (let s in orderStatus){
					var ordersSt=[]
					for (let o in orders){
						if (orderStatus[s].STATUSLIST.indexOf(orders[o].STATUSID) >=0 ){
							ordersCount++
							ordersSt.push ( {orderid:orders[o].ORDERID , email:orders[o].EMAIL  } )
						}
					}
					let pos = find( orderObj, orderStatus[s].STATUSGROUP, "STATUSGROUP")
					if (pos<0){ 
						orderObj.push ( { orderStatusLabel: orderStatus[s].STATUSGROUP, orders: ordersSt } )
					}
				}  
		 
				this.setState({listOrders: orderObj,ordersCount:ordersCount }) 
				
			}
	 	}catch(Excep){
			console.log(">>>>>>>",Excep )
		}finally{
			this.setState({ isLoading:false })
		}
	}	

	
	
	changeHandler = async (event) => {
	  let resp = await validateForm(this.state.formDataPD, toast,event);
	  this.setState({formDataPD:resp.formData});
		 
  }
 
	render(){
		return(this.props.showSearcher?"":
		<>
			{this.state.goLogin?
				<Redirect
						  to={{  pathname:  "/customer/account/login" }}
				/>
				:""
			}
			<PageLoading isLoading={this.state.isLoading} MetaTags={this.state.MetaTags}/>
		
			<div className="page-wrapper" onClick={this.props.hideCart}>
				<div className="content custom-container container-fluid ">
					<div className="card card-user-info">
						<div className="row">
							<div className="mobile-col menu-info col-12  col-sm-12 col-md-3 col-lg-3"> 
								<MenuInfo activePanel="panel2"/>
							</div> 	 
							<div className="mobile-col col-12  col-sm-12 col-md-1 col-lg-1">							 
							</div> 
							<div className="mobile-col menu-content col-12 col-sm-12 col-md-8 col-lg-8 ">
								
								<div id="panel2">
									<div className="col-12 col-sm-12 col-md-12 col-lg-9 mt-3 mb-3">	
									 	<p>
											<span className="h1-responsive">
												{getDefValues().myOrdersLabel.toUpperCase()}
											</span>
											<span className="or-line mb-3" style={{ "margin-right": "0px !important" }}></span>
										</p>										
										<ul className="order-list">
											{this.state.listOrders.map(st => (
												<li>
													{st.orders.length>0?
														<>
															<p>
																<span className="h3-responsive"> {st.orderStatusLabel}</span> 
																	<ul>
																	{st.orders.map(or => (
																		<li>
																			<Link  to={{  pathname:  "/getOrderDetails/"+or.orderid+"/"+or.email }}>
																				<span className="h5-responsive">
																					{	getDefValues().orderLabel +" #"+or.orderid}
																				</span>
																			</Link>
																		</li>
																	))
																	}
																	</ul>
															</p>
														</>
													:""}
												</li>
			              	))
			                } 
										</ul> 
										{!this.state.isLoading?
											<p>
												<span className="h1-responsive">
													{getDefValues().resumeTotalOrdersLabel.toUpperCase()+":"} <span className="text-primary"> {this.state.ordersCount}</span>
												</span>
											</p>
										:""
										} 
									</div>
								</div> 		
							</div> 						
						</div> 		
					</div> 
				</div>
			</div>
		</>				 
		)
	}
}
export default page; 