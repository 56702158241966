/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import {
	withGoogleMap,
	//withScriptjs,
	GoogleMap,
	Marker,
	Polygon,
	InfoWindow
} from 'react-google-maps';
import Geocode from "react-geocode";
import PageLoading from '../../components/PageLoading';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from 'react-places-autocomplete';

import { getDefValues, callApi, getPolygonsByCity, showError } from '../../common/functions';
import { toast } from 'mdbreact';
//const GoogleMapsAPI = process.env.REACT_APP_API_KEY_GMAP


Geocode.setApiKey("AIzaSyD9QlvrLNSxDZWXffh5kSBDR7OjY1FSjr8");
Geocode.enableDebug();
Geocode.setRegion("es");
Geocode.setLanguage("es");

var mapRef;
class Map extends Component {


	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			coords: {},
			markerPosition: {},
			address: '',
			coordValidation: [],
			width: 0
		};
	}

	componentDidMount() {
		this.setState({ width: "100%" });
		this.setState({ address: this.props.city + " " + this.props.address });
		Geocode.fromAddress(this.props.city + ", Bolivia " + this.props.address).then(
			response => {
				if (response.results.length > 0) {

					var lat = response.results[0].geometry.location.lat
					var lng = response.results[0].geometry.location.lng

					this.setState({
						coords: { lat: lat, lng: lng },
						markerPosition: { lat: lat, lng: lng },
						address: response.results[0].formatted_address
					});
					//this.props.setLocation( {lat:lat,lng:lng },response.results[0].formatted_address) 
				} else {
					this.setState({
						coords: this.props.coords,
						markerPosition: this.props.coords
					});
				}
			},
			error => {
				this.setState({
					coords: this.props.coords,
					markerPosition: this.props.coords
				});
			}
		);

	}
	handleChange = address => {
		this.setState({ address });
	};

	handleSelect = address => {
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.setState({
					coords: latLng,
					markerPosition: latLng,
					address: address
				});
				//this.props.setLocation( latLng,address)
			})
			.catch(error => console.error('Error', error));

	};

	handleToggleOpen = () => {
		this.setState({
			isOpen: true
		});
	};

	handleToggleClose = () => {
		this.setState({
			isOpen: false
		});
	};

	setMyLocation = async (event) => {
		await navigator.geolocation.getCurrentPosition(async function (position) {
			let latitude = position.coords.latitude
			let longitude = position.coords.longitude

			Geocode.fromLatLng(latitude, longitude).then(
				response => {
					const address = response.results[0].formatted_address/*,
						addressArray = response.results[0].address_components;*/

					this.setState({
						address: (address) ? address : '',
						coords: {
							lat: latitude,
							lng: longitude
						},
						markerPosition: {
							lat: latitude,
							lng: longitude
						},
					})
					//this.props.setLocation( {lat:latitude,lng:longitude },address) 
				},
				error => {
					console.error(error);
				}
			);
		}.bind(this))

	}

	onMarkerDragEnd = (event) => {
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address/*,
				addressArray = response.results[0].address_components;*/

				this.setState({
					address: (address) ? address : '',
					coords: {
						lat: newLat,
						lng: newLng
					},
					markerPosition: {
						lat: newLat,
						lng: newLng
					},
				})
				//this.props.setLocation( {lat:newLat,lng:newLng },address)
			},
			error => {
				console.error(error);
			}
		);
	};

	confirmLocation = async (event) => {



		this.setState({ isLoading: true })
		try {

			let respC = await callApi("frontend", "checkCoords",
				{
					latitude: (this.state.coords.lat).toFixed(5),
					longitude: (this.state.coords.lng).toFixed(5),
					city: this.props.city
				})

			if (respC.success) {
				//			 	console.log("OK")
				var data = {
					lat: this.state.coords.lat,
					lng: this.state.coords.lng,
					shippingCost: respC.result.price,
					zonecode: respC.result.code
				}
				//console.log("OK",data)
				this.props.confirmLocation(data)
			} else {
				showError(toast, getDefValues().outOfCoverageLabel)
				var coordsV = await getPolygonsByCity(this.props.city)
				var coordValidation = []
				coordsV.map(function (key) {
					if (key.TYPE === "LIMIT") {
						coordValidation.push({ lat: key.LATITUDE, lng: key.LONGITUDE })
					}
				});
				//console.log(coordValidation)
				this.setState({ coordValidation: coordValidation })
			}
			this.setState({ isLoading: false })
		} catch (Excep) {
			this.setState({ isLoading: false })
			console.log(">>>>>>>", Excep)
		}
	}

	onMapDragEnd = async (event) => {
		//console.log(event)
		//let mapRef;
		const newCenter = mapRef.getCenter().toJSON();
		//console.log(newCenter);


		let newLat = newCenter.lat
		let newLng = newCenter.lng

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address/*,
				addressArray = response.results[0].address_components;*/

				this.setState({
					address: (address) ? address : '',
					coords: {
						lat: newLat,
						lng: newLng
					},
					markerPosition: {
						lat: newLat,
						lng: newLng
					},
				})
				//this.props.setLocation( {lat:newLat,lng:newLng },address)
			},
			error => {
				console.error(error);
			}
		);


	};

	render() {
		const GoogleMapContainer = withGoogleMap(props => (
			<GoogleMap

				ref={(ref) => (mapRef = ref)}

				defaultCenter={this.state.coords}
				defaultZoom={16}

				language={"es-es"}
			>
				<Marker
					key={this.props.index}
					position={this.state.markerPosition}


					draggable={true}
					onDragEnd={this.onMarkerDragEnd}

					onClick={() => this.handleToggleOpen()}
				>

					{this.state.coordValidation.length > 0 ?
						<Polygon
							path={this.state.coordValidation}

							options={{
								fillColor: "#ff3939",
								fillOpacity: 0.4,
								strokeColor: "#ff3939",
								strokeOpacity: 0.8,
								strokeWeight: 1,
								zIndex: 1
							}}
						/>
						: ""}


					{this.state.isOpen && (
						<InfoWindow
							onCloseClick={this.props.handleCloseCall}
							options={{ maxWidth: 100 }}
						>
							<span>This is InfoWindow message!</span>
						</InfoWindow>
					)}
				</Marker>
			</GoogleMap>
		));

		return (
			<>
				<PageLoading isLoading={this.state.isLoading} />
				<div>
					<PlacesAutocomplete
						value={this.state.address}
						onChange={this.handleChange}
						onSelect={this.handleSelect}
					>
						{({
							getInputProps,
							suggestions,
							getSuggestionItemProps,
							loading
						}) => (
							<div>
								<input
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input'
									})}
								/>
								<div className="autocomplete-dropdown-container">

									{loading && <div className="loading">{getDefValues().loadingLabel}</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
											? 'suggestion-item--active'
											: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
											? { backgroundColor: '#fafafa', cursor: 'pointer' }
											: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
											<div
												{...getSuggestionItemProps(suggestion, {
													className,
													style
												})}
												key={suggestion.placeId}
											>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</PlacesAutocomplete>

					<button className={"btn btn-md btn-" + (this.props.btncolor ? this.props.btncolor : "primary")}

						style={{
							width: '10%',
							height: '40px',

							marginBottom: '6px',
							position: 'absolute',
							top: 7,
							right: 27
						}}
						type="button" onClick={this.setMyLocation}>
						<i style={{ "font-size": "15px" }} className="fas fa-map-marker-alt" />

					</button>
					<div className="mb-2 mt-2" style={{ "font-size": "15px", "margin-top": "5px", "margin-bottom": "2px" }}> <b>*Mueva el pin hasta la ubicaci&oacute;n deseada</b>
					</div>

					<div>
						<GoogleMapContainer
							containerElement={<div style={{ height: this.props.height, "margin-top": "3px", "width": this.state.width }} />}
							mapElement={<div style={{ height: this.props.height, "width": this.state.width }} />}
						/>
					</div>
				</div>

				<div className="card" >

					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-center mt-1">

						<button className={"btn mr-1 btn-outline-" + (this.props.btncolor ? this.props.btncolor : "primary")}
							type="button" onClick={this.props.backLocation}
						>
							<i style={{ "font-size": "15px" }} className="fas fa-chevron-left" />
						</button>
						<button className={"btn btn-md mr-1 btn-" + (this.props.btncolor ? this.props.btncolor : "primary")}
							type="button" onClick={this.confirmLocation}>{getDefValues().confirmLocationLabel} </button>
						<button className={"btn btn-md btn-outline-" + (this.props.btncolor ? this.props.btncolor : "primary")}
							type="button" onClick={this.props.cancelLocation}  >{getDefValues().cancelLabel}</button>
					</div>
				</div>
			</>
		);
	}
}

export default Map;
