/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/***components***/
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "mdbreact";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import Card from "../../components/Card";
import NotFounded from "../../components/NotFounded";
import AutoComplete from "../../components/AutoComplete";

/*treeview component*/
import TreeMenu from "react-simple-tree-menu";
import "../../assets/css/main-treeview.css";
import InfiniteScroll from "react-infinite-scroll-component";
import eventBanner from "../../assets/images/eventBanner.jpg";
import EventMessage from "../../components/EventMessage";

import Text from "../../components/Text";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Video from "../../components/Video";
import Slider from "../../components/Slider";
import SliderImage from "../../components/SliderImage";

/*functions*/
import {
  getStoresInfo,
  manageCart,
  find,
  getCookie,
  loadPageV2,
  getSyncStoresInfo,
  setGTMEvent,
  getCurrentStore,
  getImagePath,
  getLoginInfo,
  findValueById,
  getDefValues,
  buildProductsCard,
  getSortMethods,
  managesort,
  managepagination,
  getLanguage,
  callApi,
} from "../../common/functions";

var windowWidth = window.innerWidth;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

class page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal2: false,
      categories: [],
      categoryid: "",
      newcategoryid: "",
      showMenu: false,
      categoryLabel: "",
      categoryImage: "",
      productNotFounded: false,
      showCategoryMenu: false,
      defaultEvents: [],
      isLoading: true,
      activeNodes: [],
      initialActiveKey: "",
      categoryHomeLabel: "",
      selectedEvent: false,
      treeDataMenu: [],
      redirect: false,
      redirectProductDetails: false,
      settingsSlider: {
        dots: false,
        adaptiveHeight: true,
        arrows: true,
        autoplay: false,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
        specialArrows: "2",
        outside: "YES",
        spaceitems: "YES",
      },
      cardConfig: {},
      cardData: [],
      messages: [],
      selectedProductID: 0,
      selectedPath: "",
      newPath: "",
      selectedCategory: "",
      categoryObj: {
        orgName: process.env.REACT_APP_ORG_NAME,
        categoryRouteObj: [],
      },
      mainMenu: [],
      sortmethod: "new",
      prodDefConfig: {
        pagewidth: "col-md-12 col-lg-12 col-xl-12",
        colsize: "col-6",
        colsizelg: "col-lg-2_5",
        colsizemd: "col-md-4",
        colsizesm: "col-sm-6",

        limit: 10,
        /*overtop           : "YES"*/
      },

      items: Array.from({ length: 10 }),
      hasMore: true,
      start: 0,
      end: 0,
      noEvent: false,
      treeMenu: [],
      aditionalInfo: "",
    };
  }

  fetchMoreData = () => {
    let cardConfig = this.state.cardConfig;
    if (this.state.items.length >= this.state.cardData.length) {
      cardConfig["limit"] = this.state.cardData.length;
      this.setState({ hasMore: false, cardConfig: cardConfig });

      return;
    } else {
      cardConfig["limit"] = this.state.items.length;
      this.setState({
        cardConfig: cardConfig,
      });
    }

    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 10 })),
      });
    }, 500);
  };

  renderData = (_pageData) => {
    try {
      for (let r in _pageData) {
        this.setState({
          ["RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  /*loading data*/
  componentDidMount = async () => {
    const prodDefConfig = this.state.prodDefConfig;
    var layoutFile = "event_giftcard";
    var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: layoutFile,
      foldername: foldername + "private/",
    });

    this.setState({ ...this.state, isLoading: true });

    if (resp.success) {
      let _pageData = await loadPageV2(
        resp.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        windowWidth
      );

      this.setState({ template: resp.template });
      this.renderData(_pageData);
    }

    if (!this.props.match.params.token) {
      let resp = []; // await callApi ( "frontend","searchEvents" )
      this.setState({
        ...this.state,
        noEvent: true,
        defaultEvents: resp,
        /*isLoading:false,*/ isLoading2: false,
      });
      let eventId = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
      //let eventName  = getCookie(process.env.REACT_APP_ORG_NAME+'_eventName')
      let action = getCookie(process.env.REACT_APP_ORG_NAME + "_action");

      //console.log(eventId, action, this.props.shopCart)

      if (action === "addEventCart") {
        let respE = await callApi("frontend", "getCustomerEventById", {
          languageid: getLanguage(),
          eventid: eventId,
        });
        if (respE.success) {
          prodDefConfig["action"] = "addEventCart";
          prodDefConfig["id"] = respE.EVENT.EVENTID;

          this.setState({
            ...this.state,
            modal2: false,
            //isLoading    : true,
            eventid: respE.EVENT.EVENTID,
            eventdate: respE.EVENT.EVENTDATE,
            eventmessage: respE.EVENT.EVENTINFO.message,
            eventname: respE.EVENT.EVENTINFO.eventname,

            eventimage: respE.EVENT.IMAGEPATH,
            storeid: respE.EVENT.STOREID,
            eventType: respE.EVENT.EVENTTYPE,
          });

          this.showList();
        }
      } else {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      }
    } else {
      let token = this.props.match.params.token.split("-");
      let eventIdT = token[token.length - 1];
      let eventId = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
      //let eventName  = getCookie(process.env.REACT_APP_ORG_NAME+'_eventName')
      let action = getCookie(process.env.REACT_APP_ORG_NAME + "_action");

      //console.log(eventIdT, eventId, this.props.shopCart)

      if (action === "addEventCart" && eventIdT == eventId) {
        //this.setState({...this.state, isLoading:true });
        let respE = await callApi("frontend", "getCustomerEventById", {
          languageid: getLanguage(),
          eventid: eventId,
        });
        if (respE.success) {
          prodDefConfig["action"] = "addEventCart";
          prodDefConfig["id"] = respE.EVENT.EVENTID;

          this.setState({
            ...this.state,
            modal2: false,
            //isLoading    : true,
            eventid: respE.EVENT.EVENTID,
            eventdate: respE.EVENT.EVENTDATE,
            eventmessage: respE.EVENT.EVENTINFO.message,
            eventname: respE.EVENT.EVENTINFO.eventname,
            eventimage: respE.EVENT.IMAGEPATH,
            storeid: respE.EVENT.STOREID,
            eventType: respE.EVENT.EVENTTYPE,
          });

          this.showList();
        }
      } else {
        let token = this.props.match.params.token.split("-");
        let eventId = token[token.length - 1];
        let respE = await callApi("frontend", "getCustomerEventById", {
          languageid: getLanguage(),
          eventid: eventId,
        });
        prodDefConfig["action"] = "addEventCart";
        prodDefConfig["id"] = respE.EVENT.EVENTID;
        this.setState({
          ...this.state,
          modal2: true,
          isLoading: false,
          eventid: respE.EVENT.EVENTID,
          eventdate: respE.EVENT.EVENTDATE,
          eventmessage: respE.EVENT.EVENTINFO.message,
          eventname: respE.EVENT.EVENTINFO.eventname,
          eventimage: respE.EVENT.IMAGEPATH,
          storeid: respE.EVENT.STOREID,
          eventType: respE.EVENT.EVENTTYPE,
        });
      }
    }
    //this.setState({...this.state, isLoading:false });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  showList = async () => {
    const prodDefConfig = this.state.prodDefConfig;
    prodDefConfig["action"] = "addEventCart";
    prodDefConfig["id"] = this.state.eventid;

    let _eventid = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
    //var data =  this.props.shopCart
    var tempCart = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_activeCartV2"
    );
    var data = JSON.parse(tempCart);

    let clearCart = true;
    for (let d in data.products) {
      if (data.products[d].eventid) {
        if (
          data.products[d].eventid == this.state.eventid &&
          this.state.eventid == _eventid
        ) {
          clearCart = false;
          //console.log("no limpia")
        }
      }
    }
    if (clearCart) {
      var storeid = getCurrentStore();

      await this.props.setEventMode(
        this.state.eventid,
        this.state.eventname,
        "addEventCart"
      );

      //console.log("tioendas", storeid,  this.state.storeid)
      if (data.products.length > 0) {
        //console.log("limpiamos carrito")
        let tempCart = localStorage.getItem(
          process.env.REACT_APP_ORG_NAME + "_activeCartV2"
        );
        let cart = JSON.parse(tempCart);
        //console.log(cart.id)
        let _data = {
          id: cart.id,
          updatedate: new Date().getTime(),
          status: "SHOPEVENTMODE",
          data: {
            id: cart.id,
            userID: getLoginInfo().userId,
            discount: 0,
            needCommit: false,
            products: [],
            subtotal: 0,
            total: 0,
          },
        };
        if (storeid == this.state.storeid) {
          //	console.log("cartdata",_data)
          await callApi("frontend", "updateCart", _data);
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_activeCartV2",
            JSON.stringify(_data.data)
          );
        } else {
          //	console.log ("es otra tienda")
        }
      }
      if (storeid != this.state.storeid) {
        //				console.log("es otra tiendaaaaaaaaaaaaaaaaaa")
        await this.props.setStore({ target: { name: this.state.storeid } });
      }
    }

    this.setState({
      noEvent: false,
      modal2: false,
      //isLoading:true
    });

    await this.getProducts();

    this.setState({
      noEvent: false,
      modal2: false,
    });
  };

  getProducts = async () => {
    try {
      this.setState({ ...this.state, isLoading: true });
      var storeInfo = getSyncStoresInfo();
      let fixedCatalogId = findValueById(
        storeInfo.stores,
        this.state.storeid,
        "storeId",
        "catalogId"
      );
      let respE = await callApi("frontend", "getCustomerEventById", {
        languageid: getLanguage(),
        eventid: this.state.eventid,
      });
      const prodDefConfig = this.state.prodDefConfig;
      prodDefConfig["eventskus"] = respE.PRODUCTS;

      var data = {
        languageid: getLanguage(),
        eventid: this.state.eventid,
        storeid: fixedCatalogId,
      };

      var resp = await callApi("customer", "getFilterProductsDB", data);

      if (resp.rows.length <= 0) {
        this.setState({
          isLoading: false,
          productNotFounded: true,
          messages: [getDefValues().alreadyproductsPurchasedLabel],
          selectedEvent: true,
        });
        let layoutFile = "suggestedCategories_" + getLanguage();
        try {
          let evenInfo = JSON.parse(respE.EVENT.EVENT_FORMAT);
          layoutFile = evenInfo.pathlayout;
        } catch (EX) {
          console.log("no layout founded");
        }

        resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
          layoutFile: layoutFile,
          foldername: foldername,
        });
        /*
        var template = resp.template;
        for (let t in template) {
          //let config = template[t].config;
          //let data = template[t].data;
        }
        */
        let _pageData2 = await loadPageV2(
          resp.template,
          this.managepagination,
          this.manageCart,
          this.changeHandler,
          this.manageButton,
          this.toggleModal,
          windowWidth
        );
        this.setState({ template2: resp.template });
        this.renderData2(_pageData2);
      } else {
        this.setState({
          /*isLoading: false,*/ productNotFounded: false,
          messages: [],
          selectedEvent: true,
        });
        let respCat = await buildProductsCard(
          resp.rows,
          this.managepagination,
          this.manageCart,
          this.state.prodDefConfig,
          "categories",
          this.state.categoryObj.categoryRouteObj,
          this.props.match.params.categoryid
        );
        let cardConfig = respCat.cardConfig;
        if (this.props.windowWidth < 767) {
          delete cardConfig["managepagination"];
          delete cardConfig["pagesettings"];
          cardConfig["mobile"] = true;
        }
        //  			console.log("cardConfig",cardConfig)
        var listS = [];
        var listI = [];
        var listP = [];
        resp.rows.map(function (key, index) {
          listS.push(key.productid + "");
          listI.push(key.productid);
          listP.push({
            id: key.productid + "",
            name: key.productname,
            price: key.price,
            brand: "CasaIdeas",
            category: key.category,
            list: key.categories.toString(),
            quantity: "1",
            position: index + 1,
          });
        });

        var login = getLoginInfo();
        let storesInfo = await getStoresInfo();
        let _pos = find(
          storesInfo.stores,
          storesInfo.selectedStoreId,
          "storeId"
        );
        var storeName = storesInfo.stores[_pos].storeName;
        var objCategory = {
          current_view: "category",
          current_list: "category: " + this.state.categoryLabel,
          current_currency: "BOB",
          userId: login.userId ? login.userId : "0",
          string_searched: "",
          store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
          //gtm: {uniqueEventId: 1407, start: 1649342569890},
          googleDynamicRemarketing: {
            ecomm_pcat: [this.state.categoryLabel],
            ecomm_pagetype: "category",
            ecomm_prodid: listS,
          },
          criteoParams: {
            PageType: "ListingPage",
            ProductIDList: listI,
            email: "",
          },
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          ecommerce: {
            currencyCode: "BOB",
            impressions: listP,
          },
        };
        setGTMEvent("category", objCategory);

        this.setState({
          ...this.state,
          isLoading: false,
          cardConfig: respCat.cardConfig,
          cardData: respCat.cardProducts,
        });
        var _event = {
          target: {
            name: "sort",
            value: this.state.sortmethod,
          },
        };
        this.managesort(_event);
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ redirect: false, isLoading: false });
    }
  };

  manageCart = async (event) => {
    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state.cardData,
        this.manageCart,
        toast
      );
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
          window.location.reload();
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  managepagination = (event) => {
    this.setState({ isLoading: true });
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state.cardConfig.pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-2_5",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6",
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        //console.log(newcardConfig)
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };
  managesort = (event) => {
    this.setState({ isLoading: true });
    setTimeout(
      function () {
        var newcardData = managesort(this.state.cardData, event);
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };

  handleResize = () => {
    /*this.setState({
			windowWidth: window.innerWidth
		})*/
    var slidesToShow = 0;
    if (window.innerWidth > 1250) {
      slidesToShow = 6;
    } else if (window.innerWidth <= 1250 && window.innerWidth > 950) {
      slidesToShow = 5;
    } else if (window.innerWidth <= 950 && window.innerWidth > 768) {
      slidesToShow = 4;
    } else {
      slidesToShow = 3;
    }
    var settingsSlider = {
      dots: false,
      adaptiveHeight: true,
      arrows: true,
      autoplay: false,
      cssEase: "linear",
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      speed: 500,
      divWidth: "card col-md-12 col-lg-12 col-xl-12",
      specialArrows: "2",
      outside: "YES",
      spaceitems: "YES",
    };

    this.setState({
      //windowWidth: window.innerWidth,
      settingsSlider: settingsSlider,
    });
  };

  handleChange = (event) => {
    if (event.eventid) {
      this.setState({
        eventid: event.eventid,
        eventdate: event.eventdate,
        eventmessage: event.message,
        eventname: event.eventname,
        eventimage: event.eventimage,
        storeid: event.storeid,
        eventperson: event.eventperson,
      });
    }
  };

  selectEvent = () => {
    this.setState({ modal2: true });
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  renderData2 = (_pageData) => {
    try {
      for (let r in _pageData) {
        this.setState({
          ["2RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  render() {
    if (
      this.state.noEvent ||
      (!this.state.modal2 && !this.state.isLoading && !this.state.selectedEvent)
    ) {
      return !this.state.isLoading ? (
        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content custom-container container-page container-fluid container-page">
            <PageLoading
              isLoading={this.state.isLoading}
              MetaTags={this.state.MetaTags}
            />
            <PageLoading isLoading={this.state.isLoading2} />
            <EventMessage
              modal={this.state.modal2}
              toggle={this.toggle(2)}
              eventimage={this.state.eventimage}
              eventdate={this.state.eventdate}
              eventname={this.state.eventname}
              eventuser={this.state.eventuser}
              eventperson={this.state.eventperson}
              eventmessage={this.state.eventmessage}
              showList={this.showList}
            />

            <div className="row mt-10">
              <div className="col-12 flex items-center">
                <a href="/customer/event/home">
                  <img style={{ width: "100%" }} src={eventBanner} />
                </a>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-12 text-center">
                <h3> {getDefValues().findyourEvent}</h3>
              </div>
            </div>
            <div className="row mt-10 mb-30 flex items-center">
              <div className=" search1 col-12 col-md-7 col-lg-4 col-xl-4">
                <AutoComplete
                  defaultOptions={this.state.defaultEvents}
                  onChange={this.handleChange}
                />
              </div>
              <div className=" search2 col-12 col-md-5 col-lg-2 col-xl-2 ">
                <button
                  disabled={this.state.eventid ? false : true}
                  className="btn btn-block event-button"
                  type="button"
                  onClick={this.selectEvent}
                >
                  {getDefValues().showEvent}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoading
          isLoading={this.state.isLoading}
          MetaTags={this.state.MetaTags}
        />
      );
    } else {
      return this.props.showSearcher ? (
        ""
      ) : (
        <>
          <PageLoading
            isLoading={this.state.isLoading}
            MetaTags={this.state.MetaTags}
          />
          <PageLoading isLoading={this.state.isLoading2} />
          <EventMessage
            modal={this.state.modal2}
            noClosable={true}
            toggle={this.toggle(2)}
            eventimage={this.state.eventimage}
            eventdate={this.state.eventdate}
            eventname={this.state.eventname}
            eventmessage={this.state.eventmessage}
            showList={this.showList}
          />

          {this.state.redirectProductDetails ? (
            <Redirect
              to={{
                pathname:
                  (this.state.selectedCategory
                    ? "/" + this.state.selectedCategory + "/"
                    : "/productDetails/") +
                  (this.state.selectedPath ? this.state.selectedPath : "show"),
              }}
            />
          ) : (
            ""
          )}
          {this.state.redirect === true ? (
            <Redirect to={this.state.newPath} />
          ) : (
            ""
          )}
          {this.state.productNotFounded ? (
            <div className="page-wrapper" onClick={this.props.hideCart}>
              <div className="content custom-container container-page container-fluid container-page">
                <NotFounded messages={this.state.messages} />
                {Object.keys(this.state).map((obj) => (
                  <>
                    {obj.startsWith("2RENDEROBJ") &&
                    !this.state.modal2 &&
                    !this.state.isLoading ? (
                      <>
                        {this.state[obj].type === "TEXT" ? (
                          <Text
                            name={this.state[obj].ObjectName}
                            textData={this.state[obj].data}
                            textConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                        {this.state[obj].type === "IMAGE" ? (
                          <Image
                            name={this.state[obj].ObjectName}
                            imageData={this.state[obj].data}
                            imageConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                        {this.state[obj].type === "VIDEO" ? (
                          <Video
                            name={this.state[obj].ObjectName}
                            videoData={this.state[obj].data}
                            videoConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                        {this.state[obj].type === "CARDCONTAINER" ? (
                          <Card
                            name={this.state[obj].ObjectName}
                            cardData={this.state[obj].data}
                            cardConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                        {this.state[obj].type === "SLIDERIMAGE" ? (
                          <SliderImage
                            sliderData={this.state[obj].data}
                            sliderConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                        {this.state[obj].type === "SLIDER" ||
                        this.state[obj].type === "SLIDERCONTAINER" ? (
                          <Slider
                            name={this.state[obj].ObjectName}
                            sliderData={this.state[obj].data}
                            sliderConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                        {this.state[obj].type === "BUTTON" ? (
                          <Button
                            name={this.state[obj].ObjectName}
                            buttonData={this.state[obj].data}
                            buttonConfig={this.state[obj].config}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="page-wrapper" onClick={this.props.hideCart}>
                <div className="content custom-container container-page container-fluid container-page">
                  <h1 className="categoryRoot mt-4 mb-30">
                    <>
                      {this.state.categoryObj.orgName +
                        " > " +
                        getDefValues().eventShopTitle}{" "}
                    </>
                  </h1>

                  <div className="row mb-4">
                    {!this.state.showMenu ? (
                      <div className="col-md-6 col-lg-6 col-sm-12 flex middle"></div>
                    ) : (
                      <div className="col-md-6 col-lg-6 col-sm-12 flex middle">
                        <>
                          {!this.state.showCategoryMenu ? (
                            <>
                              <i
                                onClick={this.toggleCategoryMenu}
                                className="h3-responsive fa fa-bars"
                              />
                              {this.state.categoryImage !== "" ? (
                                <img
                                  src={getImagePath(this.state.categoryImage)}
                                  className="category-img"
                                  alt=""
                                />
                              ) : (
                                <span className="ml-3" />
                              )}
                              <span className="h3-responsive">
                                {" "}
                                {this.state.categoryLabel}
                              </span>
                            </>
                          ) : (
                            <>
                              <span onClick={this.toggleCategoryMenu}>
                                <i className="h3-responsive fas fa-times mr-1" />
                              </span>
                              <div className="subcategory-menu-list mt-2">
                                <span className="h3-responsive">
                                  {this.state.categoryHomeLabel}
                                </span>

                                <TreeMenu
                                  data={this.state.treeDataMenu}
                                  search={false}
                                  hasSearch={false}
                                  onClickItem={async ({
                                    key,
                                    label,
                                    ...props
                                  }) => {
                                    await this.navigate(key, label, props); // user defined prop
                                  }}
                                  initialOpenNodes={this.state.activeNodes}
                                  initialActiveKey={this.state.initialActiveKey}
                                />
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    )}
                    <div className="col-md-6 col-lg-6	col-sm-12">
                      <div className="card text-right">
                        <div className="row mt-3">
                          <div className="col-12">
                            {getDefValues().orderByLabel + ": "}
                            <select
                              id="sort"
                              name="sort"
                              className="pagination-select"
                              defaultValue={this.state.sortmethod}
                              onChange={this.managesort}
                            >
                              {getSortMethods().map((item) => (
                                <option
                                  key={"sort_i" + item.value}
                                  value={item.value}
                                >
                                  {item.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mobile-col col-md-12 col-lg-12	">
                      {/*
									<Category categories={this.state.categories} setCategory={this.setCategory} categoryid={this.state.categoryid} settingsSlider={this.state.settingsSlider} />
									*/}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mobile-col col-md-12 col-lg-12	">
                      {this.state.isLoading ? (
                        ""
                      ) : (
                        <>
                          {this.props.windowWidth < 767 ? (
                            <InfiniteScroll
                              dataLength={this.state.items.length}
                              next={this.fetchMoreData}
                              hasMore={this.state.hasMore}
                              loader={
                                <h4>{getDefValues().loadingProductsLabel}</h4>
                              }
                              endMessage={
                                <p style={{ textAlign: "center" }}>
                                  <b>{getDefValues().endOfResultsLabel}</b>
                                </p>
                              }
                            >
                              <Card
                                cardData={this.state.cardData}
                                cardConfig={this.state.cardConfig}
                              />
                            </InfiniteScroll>
                          ) : (
                            <Card
                              cardData={this.state.cardData}
                              cardConfig={this.state.cardConfig}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {Object.keys(this.state).map((obj) => (
            <>
              {obj.startsWith("RENDEROBJ") && !this.state.isLoading ? (
                <div className="content container-page container-fluid custom-container mt-10 mb-20">
                  {this.state[obj].type === "TEXT" ? (
                    <Text
                      name={this.state[obj].ObjectName}
                      textData={this.state[obj].data}
                      textConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                  {this.state[obj].type === "IMAGE" ? (
                    <Image
                      name={this.state[obj].ObjectName}
                      imageData={this.state[obj].data}
                      imageConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                  {this.state[obj].type === "VIDEO" ? (
                    <Video
                      name={this.state[obj].ObjectName}
                      videoData={this.state[obj].data}
                      videoConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                  {this.state[obj].type === "CARDCONTAINER" ? (
                    <Card
                      name={this.state[obj].ObjectName}
                      cardData={this.state[obj].data}
                      cardConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                  {this.state[obj].type === "SLIDERIMAGE" ? (
                    <SliderImage
                      sliderData={this.state[obj].data}
                      sliderConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                  {this.state[obj].type === "SLIDER" ||
                  this.state[obj].type === "SLIDERCONTAINER" ? (
                    <Slider
                      name={this.state[obj].ObjectName}
                      sliderData={this.state[obj].data}
                      sliderConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                  {this.state[obj].type === "BUTTON" ? (
                    <Button
                      name={this.state[obj].ObjectName}
                      buttonData={this.state[obj].data}
                      buttonConfig={this.state[obj].config}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </>
      );
    }
  }
}
export default page;
