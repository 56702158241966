import React from "react";

import { getImagePath, getDefValues  } from '../../common/functions';
import defaultImage from "../../assets/images/default.jpg";



const foldername= process.env.REACT_APP_FOLDER_LAYOUT

const loadBlogContainer =  props => {
	if (props.blogContainerContent){
		return (
		<>
			<div className="row mt-30 mb-30"> 
				<div className="col-12 text-left"> 
					<span className="h3-responsive">{props.blogContainerData.title} </span>
				</div>
			</div>			
			<div className="row"> 
				{props.blogContainerContent.map(item => (
		    	
	  			<div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center"> 
	  				<div className="blog-preview-container">
		  				<a target={item.target?item.target:"_self" } 
				  			   href={ process.env.REACT_APP_URL_FRONT_CONFIG+"/pages/"+ ( item.value.replace(foldername, "") )   } 
				  		>
						  	<img src={item.image==""?defaultImage: getImagePath(item.image)} style={{width:"100%"}} />		 
						  </a> 
						  
						  {item.tags!=""?
							  <div className="row mt-3 mb-3">
							  	<div className="col-3 flex items-right"> 
							 		 	<div className="tag-support-left">  </div>
		  						</div>	
		  						<div className="col-6"> 
		  							<span className="text-primary"> {item.tags} </span>
		  						</div>
		  						<div className="col-3 flex items-left"> 
		  							<span className="tag-support-right"> </span>
		  						</div>
		  					</div>
	  					:""}
	  					
	  					<div className="h3-responsive mt-3 mb-2">
	  						<a
				  			   target={item.target?item.target:"_self" } 
				  			   href={ process.env.REACT_APP_URL_FRONT_CONFIG+"/pages/"+ ( item.value.replace(foldername, "") )   } 
				  			>
	  							{item.title==""?item.value.replace(foldername, "").toUpperCase(): item.title.toUpperCase()}
	  						</a>
	  					</div>
	  					{item.about!=""?
	  						<a
				  			   target={item.target?item.target:"_self" } 
				  			   href={ process.env.REACT_APP_URL_FRONT_CONFIG+"/pages/"+ ( item.value.replace(foldername, "") )   } 
				  			>
		  						<p style={{textAlign: 'justify'}}>
		  							{item.about} <span className="text-underline"> {getDefValues().showMoreoptionsLabel} </span>
		  						</p>
		  					</a>
	  					:""}
	  				</div>
				 	</div>
		  	))
		    }    
			</div>
		</>
		);
			  
	}else{
		return "NO VALUES"
	}
};

export default loadBlogContainer;
				 