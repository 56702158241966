/* eslint-disable jsx-a11y/alt-text */
import React from 'react'; 
import orderSuccess from '../../assets/images/orderSuccess.png';

import {getImagePath,formatPathName, getDefValues,getCurrency, toFixed } from '../../common/functions';
import {MDBModal, MDBModalBody } from 'mdbreact';
import defaultImage from "../../assets/images/default.jpg";
const OrderSucc = (props) => {
	 
	if (props.orderid){
	 //console.log(props.addressForm)
  	return(
			<div className="row">
				<MDBModal isOpen={true} toggle={props.toggle} className="zoom-modal" centered>
	        <MDBModalBody>
	        <div className="card flex items-center" style={{ width:"100%"}}>
	      	  
			        	<div className="row flex items-center">
			        		<div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
			        	
						        <div className="row mt-30 mb-30">				        
							        <div className="col-12 flex items-center">
							          <img	style={{"width":"350px"}}src={orderSuccess} alt="order-success" /> 	
							        </div> 
							      </div> 
							      
							      <div className="row mt-30 mb-30">				        
							        <div className="col-12 flex items-center">
							          <span className="h1-responsive"> {getDefValues().orderNumberLabel.toUpperCase()}: {props.orderid} </span> 	
							        </div> 
							      </div> 
							      
							      <div className="row mb-3">
							      	<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							        	
					        			<div className="row order-status-customer mt-1">
					         				<div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
					         					<b> {getDefValues().cityLabel}:</b>
					         				</div> 
									        <div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									      		 {props.city} 
									        </div> 
									      </div> 
									      <div className="row order-status-customer mt-1">
					         				<div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
					         					<b>{getDefValues().paymentMethodLabel}:</b>
					         				</div> 
									        <div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									        	{props.paymentMethod} 
									        </div> 
									      </div> 
										      
							    			<div className="row order-status-customer mt-1">
					         				<div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
					         					<b>{getDefValues().shippingMethodLabel}:</b>
					         				</div> 
									        <div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									        	{props.shippingLabel}
									        </div> 
									      </div> 
										       
							    		</div> 
							      	<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							        	{/* pickup (Tienda Megacenter)*/}
							        	{props.shippingType==="PICKUP"?
							        		
							        		<div className="row order-status-customer mt-1">
						         				<div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
						         					<b>{ getDefValues().pickUpInfoLabel}:</b>
						         				</div> 
										        <div className="mobile-col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
										        	 {props.addressForm.shippingday} {props.addressForm.shippinghour}  
										        </div> 
										      </div> 
									      
							        	:""}
							        	 
							    		</div> 
							    		
							     <div className="row order-status-customer mt-1 mt-3 mb-3">				        
							        <div className="col-12 flex items-left">
							          <span > { getDefValues().pickUpMessageLabel} </span> 	
							        </div> 
							      </div> 
							      
							      </div> 
							      
							      <div className="row">  
							     		  <>
													<div className="mobile-col col-12">
														<div className="flex items-left order-status-resume">
															<div className=" direction-column flex items-left ">
																<div className="row mt-3 ml-2">
																	<div className="col-12">
																		<span className="title">
																			{ getDefValues().productsLabel.toUpperCase()}
																		</span>
																	</div>
																</div>
																<div className="row mt-3 ml-2">
																	<div className="col-12">
																		<span>
																			{ getDefValues().itemsOrderLabel +": "} <span className="text-primary">{props.quantity?props.quantity:props.orderDetails.length} </span>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="mobile-col col-12">
														<div className="order-status-resume">
															<div className="row" >
																{props.orderDetails.map(item=> (
																	<div className="product-detail"> 
																 		<div key= {"k_img"+item.productid}  className="image"> 	
																	 		<a href={ ( item.category? "/"+formatPathName(item.category)+"/"  :"/productDetails/") + formatPathName(item.productname+"-"+item.productid)  } tabIndex="-1">
																				<img 
																					key={"img"+item.PRODUCTID} 
																					name={"manageImage_"+item.productname}  
																					src={item.image!==""?getImagePath(item.image):defaultImage}
																				/>
																			</a>
																		</div>
																		<div key={"info"+item.productid} className= "detail"  >
																			<p key={"p1"+item.productid} >
																				{item.productname} 
																			</p>
																			<p key={"p2"+item.productid} > 
																				<span key={"spaninfo"+item.productid} > { (item.skus? "PACK REF:":"SKU: ")+ item.productsku}</span>
																		 	</p>
																		 	<p key={"p3"+item.productid} > 
																				<span key={"spaninfo"+item.productid} > { getDefValues().shopCartQuantityLabel+": "+item.quantity}</span>
																		 	</p>
																		 	<p key={"p4"+item.productid} > 
																				<span key={"spaninfo"+item.productid} > { getCurrency()}{ item.NEWPRICED? toFixed(item.NEWPRICED) :  toFixed(item.price)}</span>
																		 	</p>
																		</div>
																 	</div>
														 	  ))
						    								} 
																<div className="product-detail ">  
						    									<div className="image"> 	 
																	</div> 
						    								 <div className= "detail"  > 
						    								 		<div className="row" >
							    								 		<div className="col-12">
							    								 			<span className="h6-responsive">{ getDefValues().orderResumeLabel}</span>
							    								 		</div>
							    								 	</div>
							    								 	<div className="row mt-2" >
							    								 		<div className="col-6">
							    								 			<span>{getDefValues().shopCartSubtotalLabel}</span>
							    								 		</div>
							    								 		<div className="col-6 text-right">
							    								 			<span className="text-bold">{ getCurrency()}{ toFixed(props.subtotal)}</span>
							    								 		</div>
							    								 	</div> 
							    								 	{props.discount>0?
								    								 	<div className="row mt-2" >
								    								 		<div className="col-6">
								    								 			<span>{getDefValues().discountLabel}</span>
								    								 		</div>
								    								 		<div className="col-6 text-right">
								    								 			<span className="text-bold">{ "-"+getCurrency()}{ toFixed(props.discount)}</span>
								    								 		</div>
								    								 	</div> 
								    								:""}
							    								 	
							    								 	<div className="row" >
							    								 		<div className="col-6">
							    								 			<span>{getDefValues().shippingCostLabel}</span>
							    								 		</div>
							    								 		<div className="col-6 text-right">
							    								 			<span className="text-bold">{ getCurrency()}{toFixed(props.shippingCost)}</span>
							    								 		</div>
							    								 	</div> 	<span className="or-line mt-2 mr-2"></span>
							    								 	
							    								 	
							    								 	<div className="row" >
							    								 		<div className="col-6">
							    								 			<span className="text-bold">{getDefValues().totalOrderLabel}</span>
							    								 		</div>
							    								 		<div className="col-6 text-right">
							    								 			<span className="text-bold"><b>{getCurrency()}{ 
							    								 				toFixed(  ( parseFloat(props.subtotal) -parseFloat ( props.discount) <0? 0: parseFloat(props.subtotal) -parseFloat ( props.discount) )   + parseFloat(props.shippingCost)) }</b></span>
							    								 		</div>
						    									 	</div> 
					    								 		</div>
					    									</div>
								 							</div>
														</div>
													</div>
												</> 
						        </div>
								  
								{props.shippingLabel==="Evento" &&
									<div className="row">   
									<div className="col mt-3 col-12 flex items-center middle"  >
										<span className="short-label">{getDefValues().disclaimerEventMessage2}</span>
									</div> 
									</div> 
								}
						        <div className="row">   
						        	<div className="col mt-3 mb-3 col-12 flex items-center middle"  >
										<a  href="/">
											<span className="short-label">{getDefValues().continueLabel}</span>
										</a>
						       		</div> 
						      	</div> 
				      		</div> 
				      	 	 
				      			
				   	</div> 
		      	</div> 
	        </MDBModalBody> 
	      </MDBModal>
			</div>
	  );
	}else{
		return("" )
	}
}
export default OrderSucc;
				 