/*****components***/
import React, { Component } from "react";

import { Redirect } from "react-router-dom";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import Form from "../../components/Form";

import { toast } from "mdbreact";
import {
  getLoginInfo,
  parseFormData,
  getLanguage,
  validateForm,
  getDefValues,
  callApi,
} from "../../common/functions";

/*functions*/

const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassFormData: {},
      forgotPassFormConfig: {},
      goHome: false,
      successForm: false,
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });

      var login = getLoginInfo();
      if (login.userId != null) {
        this.setState({ isLoading: false, goHome: true });
      } else {
        this.props.hideCart();
        let loginForgotPass = await callApi(
          "layaoutManager",
          "getLayoutTemplateByName",
          {
            layoutFile: "forgotPasswordForm_" + getLanguage(),
            foldername: foldername + "private/",
          }
        );

        var forgotPassFormData = {};
        for (let f in loginForgotPass.template[0].content) {
          forgotPassFormData[loginForgotPass.template[0].content[f].name] =
            loginForgotPass.template[0].content[f];
        }
        this.setState({
          forgotPassFormData: forgotPassFormData,
          forgotPassFormConfig: loginForgotPass.template[0].config,
          isLoading: false,
        });
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
      this.setState({ isLoading: false });
    }
  };

  changeHandler = async (event) => {
    if (event.target.name === "rememberme") {
      this.setState({
        rememberme: !this.state.rememberme,
      });
    } else {
      let resp = await validateForm(
        this.state.forgotPassFormData,
        toast,
        event
      );
      this.setState({ forgotPassFormData: resp.formData });
    }
  };

  recovery = async (event) => {
    try {
      this.setState({ isLoading: true });
      var formData = parseFormData(this.state.forgotPassFormData);
      var resp = await callApi("frontend", "getTokenEmail", formData);
      this.setState({ token: resp.token });

      this.setState({ isLoading: false });
      this.setState({ successForm: resp.success });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.goHome ? <Redirect to={{ pathname: "/" }} /> : ""}
        {this.state.successForm ? (
          <Redirect
            to={{ pathname: "/customer/account/login/" + this.state.token }}
          />
        ) : (
          ""
        )}

        <PageLoading isLoading={this.state.isLoading} />

        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content container-page custom-container container-fluid">
            <div
              className={
                "card  mt-30 " + (!this.state.isLoading ? "card-user-info" : "")
              }
            >
              <div className="row mt-50 mb-30">
                <div
                  className={
                    "mobile-col col-12 col-sm-12 col-md-5 col-lg-5" +
                    (this.props.windowWidth > 992
                      ? " ml-5 border-right"
                      : " ml-1")
                  }
                >
                  <Form
                    customClass={"userForm"}
                    formData={this.state.forgotPassFormData}
                    changeHandler={this.changeHandler}
                    formConfig={this.state.forgotPassFormConfig}
                  />
                  {!this.state.isLoading ? (
                    <div className="col-12 col-lg-9 col-md-9 col-sm-12">
                      <button
                        onClick={this.recovery}
                        className="accout-btn btn btn-primary text-bold btn-block"
                      >
                        {" "}
                        {getDefValues().recoveyPasswordLabel}{" "}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.props.windowWidth < 992 && !this.state.isLoading ? (
                    <div className="or-line mt-40 mb-2 ml-2 mr-2"></div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={
                    "mobile-col col-12 col-sm-12 col-md-5 col-lg-5 " +
                    (this.props.windowWidth < 992 ? " mt-3 " : " ml-3 ")
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
