import React from "react";
 
/*
<meta id="og-title" property="og:title" content="MyApp" />
<meta id="og-image" property="og:image" content="path/to/image.jpg" />
*/
import loader from '../../assets/images/loader.gif';
 
const loadMessage = props => {
 
	  return ( 
	   	<>
	   	 	{/*props.MetaTags?
					<MetaTags>
						<title>{props.MetaTags.title+(process.env.REACT_APP_ORG_NAME?" - "+" "+getCurrentStoreName():"") }</title>
						<meta data-react-helmet="true" name="title" content={props.MetaTags.title} />
						{props.MetaTags.names.map((i) => (
							<meta data-react-helmet="true"  name={i.name} content={i.content} />
						))}
						
						{props.MetaTags.properties?props.MetaTags.properties.map((i) => (
							<meta data-react-helmet="true" property={i.property} content={i.content} />
						)):""}
					</MetaTags>
					:""
						*/}
				{props.isLoading?
					<>
			   		<div  className="loadingBG">
					 	</div>
						<div  className="loadingBG2">
							<div  className="loading" > 
								<img	src={loader} alt="loader" style={{ width: "100px", height: "100px"}}  /> 	
							</div>
						</div>
					</>
				:""}
			</> 
	  );
	 
};
export default loadMessage;
				 