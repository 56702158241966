import React from "react";

import {  getDefValues } from "../../common/functions";


const loadNotFounded = props => { 
	 
	  return ( 
    	<div className="col-12 text-center mt-50 mb-50">
      	<p className="mt-50 mb-30" ><span className="h1-responsive"> { props.title?props.title:getDefValues().notFoundTitleLabel} </span> </p>
				{props.messages.map(item => (	
					<p className="mt-2" ><span className="h3-responsive"> {item} </span> </p>
				))
		    }   	
      </div>
  	  
	  );
	 
};
export default loadNotFounded;
				 