import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "./firebaseConfig";
import { toast } from "mdbreact";
import { loginSNV2, getCurrentStore } from "../../common/functions";
function App(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [goHome, setGoHome] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (result) => {
      //console.log("result", result);
      if (result) {
        const { uid, email } = result;
        setUserData({ uid, email });

        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const SignUpUsingGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        props.setExecuting(true);
        //console.log("OK", result);

        const { uid, email, displayName, phoneNumber } = result.user;
        setUserData({ uid, email });

        setIsLoggedIn(true);
        let displayNameA = displayName.split(" ");

        let firstname = displayNameA[0];
        let lastname = "";
        if (displayNameA.length >= 1) {
          displayNameA.shift();
          lastname = displayNameA.toString();
        }

        let res = {
          googleId: uid,
          profileObj: {
            email: email,
            givenName: firstname,
            familyName: lastname,
            keytype: "google",
            phone: phoneNumber || "",
            phonecode: "",
            defstoreid: getCurrentStore(),
          },
        };
        //console.log(res);

        var respL = await loginSNV2(res, toast, true);
        if (respL.status) {
          //console.log(respL);

          let resp = respL.USER;
          if (resp.email && resp.email !== "") {
            var objBloomreach_login = {
              email: resp.email,
              firstname: resp.firstname || "",
              lastname: resp.lastname || "",
              lastname2: resp.lastname2 || "",
              opt_in_ofertas: false,
              opt_in_tyc: true,
              origen: "web",
              phone: resp.phone || "",
              DNI: resp.code || "",

              //aqui pueden añadir los otros atributos que puedan obtener al login
            };
            var objBloomreach_setCustomer = {
              registered: resp.code || "", //Este atributo es OBLIGATORIO, Aqui va la identificacion si se tiene si no va vacia NO ES NULL DEBE IR VACIA si va vacio se debe incluir obligatorio el de email_id que es el soft_id
              email_id: resp.email, //Si se tiene el valor se debe añadir
            };

            if (resp.EXPONEA_STATUS !== "YES") {
              console.log("BR_new");

              window.exponea.track("register", objBloomreach_login);
              window.exponea.update(objBloomreach_login);
              window.exponea.identify(objBloomreach_setCustomer);
              setGoHome(true);
              setTimeout(async function () {
                window.location.href = "/";
                window.location.reload();
              }, 500);
            } else {
              console.log("BR_old");
              window.exponea.track("login", objBloomreach_login);
              window.exponea.update(objBloomreach_login);
              window.exponea.identify(objBloomreach_setCustomer);
              if (props.stay) {
                setTimeout(async function () {
                  window.location.reload();
                }, 250);
              } else {
                setGoHome(true);
                setTimeout(async function () {
                  window.location.href = "/";
                  window.location.reload();
                }, 500);
              }
            }
            localStorage.setItem(
              process.env.REACT_APP_ORG_NAME + "exponea_registered",
              "YES"
            );
          } else {
            console.log("ALERT!! no email");
          }
          props.setExecuting(false);
        } else {
          props.setExecuting(false);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const Logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUserData({});
        setIsLoggedIn(false);
      })
      .catch((error) => {
        // An error happened.
        console.log({ error });
      });
  };

  return (
    <div>
      {!isLoggedIn && (
        <button
          onClick={SignUpUsingGoogle}
          type="button"
          className="login-with-google-btn"
        >
          {props.customLabel || "Ingresa con Google"}
        </button>
      )}

      {isLoggedIn && (
        <div className="wrapper logged" style={{ display: "none" }}>
          <button
            className="profile-card__button button--orange"
            onClick={Logout}
          >
            Log out
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
