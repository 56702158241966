/*****components***/
import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../login/firebaseConfig";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import { logout, getLoginInfo, getShopCart } from "../../common/functions";
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goHome: false,
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      await getShopCart("LOGOUT");
      this.setState({ isLoading: true });
      this.props.hideCart();
      var resp = await logout();
      getLoginInfo();

      //			console.log("resp  salir", resp, login)
      //if (login.userId) {
      if (resp.success) {
        onAuthStateChanged(auth, (result) => {
          console.log("result", result);
          if (result) {
            console.log("tenemos q cerrar");

            signOut(auth)
              .then(() => {
                // Sign-out successful.
                console.log("sesion cerrada");

                this.setState({ goHome: resp.success });
                window.location.reload();
              })
              .catch((error) => {
                // An error happened.
                console.log("ERR>>>>", error);
              });
          } else {
            console.log("salir2");
            this.setState({ goHome: resp.success });
            window.location.reload();
          }
        });

        //
      }
      /*	
			}else{
				this.props.setLoginInfo	()
				this.setState({ goHome:true })
			}
		 	*/

      this.setState({ isLoading: false });
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
      this.setState({ isLoading: false });
    }
  };
  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.goHome ? <Redirect to={{ pathname: "/" }} /> : ""}

        <PageLoading isLoading={this.state.isLoading} />
        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content container-page custom-container container-fluid"></div>
        </div>
      </>
    );
  }
}
export default page;
