import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAhBebCjAko8KWYAOMdfDg7ZEDZe7JVl6w",
  authDomain: "casaideas-bolivia.firebaseapp.com",
  projectId: "casaideas-bolivia",
  storageBucket: "casaideas-bolivia.appspot.com",
  messagingSenderId: "372293372506",
  appId: "1:372293372506:web:21aef286b244d411eb2663",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
