/*****components***/
import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

/*custom components*/
import PageLoading from '../../components/PageLoading';

import Form from '../../components/Form';
import MenuInfo from '../../components/MenuInfo';


import {toast,} from 'mdbreact';
import {getLoginInfo, updateCustomers, changeHandler, changePassword,showMessage, showError, mergeFormData, getStoresInfo, getLanguage,getDefValues,  callApi, callCustomApi} from '../../common/functions';

/*functions*/

const foldername = process.env.REACT_APP_FOLDER_LAYOUT
class page extends Component{


	constructor(props) {
		
		super(props);
		this.state = {
			
			formDataPWD : {
				oldpassword     : {label: getDefValues().oldPasswordLabel , value:"" , type: "PASSWORD", validationtype : "TEXT"},
				password        : {label: getDefValues().passwordLabel    , value:"" , type: "PASSWORD", validationtype : "TEXT" },
				password2       : {label: getDefValues().repeatPasswordLabel    , value:"" , type: "PASSWORD", validationtype : "TEXT" }
			},
			formDataPWDConfig :{ colsize: "col-12 col-lg-4 col-md-9 col-sm-6", formtitle: getDefValues().changePasswordLabel} ,
			isLoading:true,
			isLoadingMaster:true,
			cartLabel: getDefValues().cartLabel.toUpperCase(),
			cartLabelDesc: getDefValues().cartLabelDesc,
			formDataPD:{},
			formConfigPD:{},
			formDataSD:{},
			lockformPD:true,
			activePanel:"",
			userId:"",
			goLogin:false,
			showChangePass:false,
			MetaTags : {title: getDefValues().myAccountLabel,
									names:[
										{  name: "description" , content: getDefValues().myAccountLabel},
										{  name: "keywords" , content: process.env.REACT_APP_ORG_NAME },
										{  name: "robots"   , content: "INDEX,FOLLOW"}
									]},
			newsletterId:null
		}
	}
	/*loading data*/
	componentDidMount = async() => {
		try{
			var  login = getLoginInfo()
			if (login.userId ==null) {
				this.setState({ isLoading:false, goLogin:true })
			}else{
				this.props.hideCart()
				let registerForm= await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "registerForm_"+getLanguage(), foldername: foldername+"private/" } )
				var formDataPD={}
				for (let f in registerForm.template[0].content){
					formDataPD[registerForm.template[0].content[f].name ] = registerForm.template[0].content[f]
				} 
				
				var formConfigPD = registerForm.template[0].config
				formConfigPD.formtitle = getDefValues().accountInfoLabel
				formConfigPD.formtittleicon =""
						
				var newformDataPD ={}
				for (let i in Object.keys(formDataPD)){
					var field = Object.keys(formDataPD)[i] 
					if (formDataPD[field]){
						if (formDataPD[field].type==="INPUT" ||  formDataPD[field].type==="DATEPICKER"){
								newformDataPD[field] = formDataPD[field]
						} 
					} 
				}
		  	
				delete newformDataPD.password
				delete newformDataPD.password2
				
				let addressInfo= await callApi ( "admin","getCustomerById", {   customerid: login.userId } )
		  	
		  		var data2={
					 "emails": [ addressInfo.USER.EMAIL],
					 "apiName":"getSubscriptionStatus"
				}
				var resp = await callCustomApi("customApi", data2 )
			
				if (resp.success){
					this.setState({newsletterId: resp.response })
				}
				
	  			this.setState({ formDataPD: newformDataPD, 
											formConfigPD:registerForm.template[0].config,
											userId: login.userId,
											isLoading:false ,
											isLoadingMaster: false})
											
				await mergeFormData (this.state.formDataPD,addressInfo.USER)
				
			}
		}catch(Excep){
		console.log(">>>>>>>",Excep )
		}finally{
			this.setState({redirect: false, isLoading:false })
			
			//console.log(this.state.formDataPD)
		}
	}	
	
	subscribe= async (event) => {
		
		try{
			this.setState({isLoading:true })
			
			
			var data2={
				 "email": this.state.formDataPD.email.value,
				 "apiName":"suscribe"
			}
			var resp = await callCustomApi("customApi", data2 )
			
			if (resp.success){
				 window.location.reload();
			}else{
					showError(toast,resp.message )
			}		
			
			
			this.setState({isLoading:false })
			
		}catch(Excep){
			this.setState({isLoading:false })
			console.log(">>>>>>>",Excep )
		}
	}
	
	cancelSubscription= async (event) => {
		
		try{
			this.setState({isLoading:true })
			
			
			var data2={
				 "emails": this.state.formDataPD.email.value,
				 "apiName":"cancelSubscription"
			}
			var resp = await callCustomApi("customApi", data2 )
			
			if (resp.success){
				this.setState({newsletterId: null})
				showMessage(toast,resp.message );	
			}else{
					showError(toast,resp.message )
			}			
			
			
			this.setState({isLoading:false })
			
		}catch(Excep){
			this.setState({isLoading:false })
			console.log(">>>>>>>",Excep )
		}
	}
	
	updateCustomers= async (event) => {
		try{
			this.setState({isLoading:true })
			
			let storeid = await getStoresInfo().selectedStoreId
			const formDataPD = this.state.formDataPD
			
			formDataPD["storeid"] = { value: storeid, name: "storeid" } 
			formDataPD["customerid"] = { value: this.state.userId, name: "customerid" } 
			
			await updateCustomers (this.state.formDataPD, toast )
			//this.setState({successForm:resp.success })
			this.setState({isLoading:false })
			
		}catch(Excep){
			this.setState({isLoading:false })
			console.log(">>>>>>>",Excep )
		}
	}
	
	changePassword= async (event) => {
		try{
			this.setState({isLoading:true })
			const formDataPWD = this.state.formDataPWD
			formDataPWD["customerid"] = { value: this.state.userId, name: "customerid" } 
			await changePassword (this.state.formDataPWD, toast )
			//this.setState({successForm:resp.success })
			this.setState({isLoading:false })
			
		}catch(Excep){
			this.setState({isLoading:false })
			console.log(">>>>>>>",Excep )
		}
	}
	
	editPersonalData= (event) => {
		this.setState({ lockformPD:false })
	}
	showChangePass= (event) => {
		this.setState({ showChangePass:true })
	}
	
	editPersonalData2= (event) => {
		this.setState({ lockformPWD:false })
	}
	changeHandler = async (event) => {
		
	  //let resp = await validateForm(this.state.formDataPD, toast,event);
	  //this.setState({formDataPD:resp.formData});
	  
	  var formData =  changeHandler ( event, this.state.formDataPD )
	  this.setState({formDataPD:formData});
	  if (event.key === 'Enter') {
      this.updateCustomers(event)
    }
  }
  
  changeHandler2 = async (event) => {
	  //let resp = await validateForm(this.state.formDataPWD, toast,event);
	  //this.setState({formDataPWD:resp.formData});
	  
	  var formData =  changeHandler ( event, this.state.formDataPWD )
	  this.setState({formDataPWD:formData});
	  
	  if (event.key === 'Enter') {
      this.changePassword(event)
    }
  }
 
	render(){
		return(this.props.showSearcher?"":
		<>
			{this.state.goLogin?
				<Redirect
						  to={{  pathname:  "/customer/account/login" }}
				/>
				:""
			}
			<PageLoading isLoading={this.state.isLoading} MetaTags={this.state.MetaTags} />
			<PageLoading isLoading={this.state.isLoadingMaster}/>
			<div className="page-wrapper" onClick={this.props.hideCart}>
				<div className="content custom-container container-fluid ">
					<div className="card card-user-info">
						<div className="row">
							<div className="mobile-col menu-info col-12  col-sm-12 col-md-3 col-lg-3">
								<MenuInfo activePanel="panel1" />
							</div> 	
							<div className="mobile-col col-12  col-sm-12 col-md-1 col-lg-1">							 
							</div> 
							<div className="mobile-col menu-content col-12 col-sm-12 col-md-8 col-lg-8">								
								<div id="panel1">
									<div className="col-12 col-sm-12 col-md-12 col-lg-9 mt-3 mb-3">	
										<p >
											<span className="h1-responsive">
												{getDefValues().myAccountLabel.toUpperCase()}
											</span>
										</p>
										<p >
											<span className="h4-responsive">
												{getDefValues().infoAccountLabel.toUpperCase()}
											</span> 
											<span className="or-line mb-3" style={{ "margin-right": "0px !important" }}></span>
										</p>
									</div>
							 	</div>
							 	{!this.state.isLoadingMaster?
								 	<>
								 		<Form  
											formData={this.state.formDataPD}
											formConfig={this.state.formConfigPD}  
										 	changeHandler={this.changeHandler}  
										 	lockform={this.state.lockformPD}
											editForm={this.editPersonalData}
									 	/>		
									 	
										{!this.state.lockformPD?
											<div className="col-12 col-sm-12 col-md-12 col-lg-9 flex items-right mt-3 mb-3">	
												<button className="btn btn-md btn-primary shipping-form-btn" type="button" onClick={this.updateCustomers}>{getDefValues().saveChangesLabel }</button>
											</div>	
										:""}
										{this.state.newsletterId?
										<div className="row ml-1">
											<div className="col-12 col-sm-12 col-md-12 col-lg-9 text-left">	
												<span > {getDefValues().suscribeNewsletterMessageLabel}
													<button className="no-button" style={{textDecoration:"underline"}} type="button" onClick={this.cancelSubscription}>{getDefValues().cancelSubscriptionLabel }</button>
													</span>
												</div>	
										</div>	
									:
									<div className="row ml-1">
											<div className="col-12 col-sm-12 col-md-12 col-lg-9 text-left">	
												<span > {getDefValues().nosuscribeNewsletterMessageLabel}
													<button className="no-button" style={{textDecoration:"underline"}} type="button" onClick={this.subscribe}>{getDefValues().subscriptionLabel }</button>
													</span>
												</div>	
										</div>	
									}
										{this.state.showChangePass?
											<>
												<Form 
													formData={this.state.formDataPWD}
													formConfig={this.state.formDataPWDConfig}  
												 	changeHandler={this.changeHandler2}  
												 	lockform={this.state.lockformPWD}
													editForm={this.editPersonalData2}
											 	/>		
											 	<div className="col-12 col-lg-4 col-md-9 col-sm-6 mt-3 mb-3">	
													<button className="btn btn-md btn-primary btn-block shipping-form-btn" type="button" onClick={this.changePassword}>{getDefValues().changePasswordLabel }</button>
												</div>	
											</>
										: <button className="btn-text" type="button" onClick={this.showChangePass}>{getDefValues().changePasswordLabel }</button>
										}
									</>
								:""}
							</div>
						</div>
					</div> 
				</div>
			</div>
		</>				 
		)
	}
}
export default page; 