/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */

import { useEffect, useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../pages/login/firebaseConfig";

import React from "react";
import md5 from "react-native-md5";

import Api from "../common/api";

import decode from "jwt-decode";
import { v4 as uuid } from "uuid";
//import Table from '../components/Table';
import Modal from "../components/Modal";
import Form from "../components/Form";
import Card from "../components/Card";
import List from "../components/List";
import BlogContainer from "../components/BlogContainer";
import Slider from "../components/Slider";
import SliderImage from "../components/SliderImage";
import Product from "../components/Product";
import Text from "../components/Text";
import Image from "../components/Image";
import Video from "../components/Video";
import Div from "../components/Div";

import CategoryBlog from "../components/CategoryBlog";
import Button from "../components/Button";

const folderImages = process.env.REACT_APP_PATH_IMAGES;
const folderFiles = process.env.REACT_APP_PATH_FILES;
const privateFolder = process.env.REACT_APP_FOLDER_LAYOUT;
const discountTemplate = [];
export function getDefValues() {
  var values = {};

  values = {
    precisionSearch: 6,
    newLabel: "Nuevo",
    filesNames: "files,archivos",
    downloadHereLabel: "DESCARGA AQU\u00CD",
    downloadLabel: "Descargar",
    couponLabel: "Cup\u00F3n",
    thankslabel: "\u00A1Gracias!",
    checktermsLabel: (
      <div className="h5-responsive">
        Estoy de acuerdo con los{" "}
        <a
          className="text-primary"
          target="top"
          href="/page/pages/terminos_y_condiciones"
        >
          T&eacute;rminos y condiciones{" "}
        </a>{" "}
      </div>
    ),
    alertTitleLabel: "Espera un momento...",
    shareEventMessageLabel: "Hola deseo compartir esta lista contigo",
    oneUseOnlyCouponTitle: "Recuerda que tu cup\u00F3n es de un solo uso!",
    oneUseOnlyCouponLabel:
      "A\u00FAn te quedan Bs.<positiveBalance> por gastar, aprovecha y agrega m\u00E1s productos a tu carrito",
    needLoginMessage: "Necesita registrarse para poder usar esta opci\u00F3n",
    positiveBalanceLabel: "Saldo a Favor",
    eventUpdatedLabel: "Evento Actualizado",
    eventTopMessage: "Est\u00E1s armando tu lista - ",
    createNewEventLabel: "Crear nuevo evento",
    showOtherEvents: "Editar otro Evento",
    goManageEventLabel: "Ver otros Eventos Creados",
    eventListHeader: "INVITACI\u00D3N A LA LISTA",
    thanksForCreateEventMessage: "Tu evento ha sido registrado",
    eventShopTopMessage: "Est\u00E1s en la lista de regalos - ",
    eventShopTopMessage2: ">> Volver al Evento <<",
    discountMessageLabel: "Descuento",
    editEventButton: "Edita tu evento",
    nodedicationslabel: "A\u00FAn no tienes dedicatorias",
    editEventListButton: "Edita tu lista",
    editEventInfoButton: "Edita tu invitaci\u00F3n",
    duplicatedEventError: "Ya existe otro evento con el carrito existente",
    errorGettingCartProducts: "Error obteniendo lista de productos del carrito",
    tryoutsCouponExceededTitle: "Cupones Bloqueados",
    storeChangedByEventMessage: "Se actualiz\u00F3 la tienda a la del evento",
    limiteCouponExceededTitle:
      "Solo puede agregar <totalcustomersupported> cup\u00F3n este convenio",
    couponMessageLabel: "Usted ha recicibo un cup\u00F3n",
    comboSkusMessageLabel:
      "Combo aplicable \u00FAnicamente a los siguientes productos:",
    discountSkusMessageLabel:
      "Descuento aplicable \u00FAnicamente a los siguientes productos:",
    btnaddbutton: "Agregar al carro",
    notEventProductsMessage:
      "A\u00FAn no tienes productos en tu lista de regalos, empieza ahora!",
    addEventProductsMessage: "Agrega m\u00E1s productos",
    cantdeletedProduct: "No puedes eliminar el artículo porque ya fue comprado",
    sendListLabel: "Comparte tu evento",
    showDedicationsLabel: "Ver Dedicatorias",
    addSkusLabel: "Agregar SKUS",
    addSKUtoEventLabel: "Agregar SKU al evento",
    selectEventLabel: "Seleccione el evento que desea revisar",
    addSKUstoEventLabel: "Agregar SKUs al Pedido",
    registerOrderLabel: "Registrar Pedido en tienda",
    successfullyOrderRegisteredLabel: "Orden externa registrada correctamente",
    sendToAnotherStoreMessage: "Envio desde otra tienda",
    errorOrderRegisteredLabel: "Error registrando orden externa",
    showDedicationLabel: "Dejar Dedicatoria",
    dedicationLabel: "Dedicatoria",
    showCommentLabel: "Ver Comentario",
    quantityRequestedLabel: "Cantidad sugerida",
    shortbtnaddbutton: "Agregar",
    addEventListButton: "A\u00F1adir a la lista",
    proddecriptionlabel: "Descripci\u00F3n del producto",
    prodaditionalinfo: "Informaci\u00F3n adicional",
    prodShare: "Compartir",
    setAddressLabel: "Elegir Direcci\u00F3n",
    outOfCoverageLabel:
      "La ubicaci\u00F3n esta fuera de cobertura, seleccione otro punto por favor",
    PriceLabel: "Precio",
    selectCodesLabel: "*Productos seleccionados",
    relatedProductsLabel: "Nuestras sugerencias para ti",
    errorSettingAddress: "Error registrando direcci\u00F3n, intente nuevamente",
    errorUpdatingAddress:
      "Error acgtualizando direcci\u00F3n, intente nuevamente",
    succUpdatingAddress: "Direcci\u00F3n actualizada correctamente",
    cartLabel: "Carro de compras",
    yeslabel: "SI",
    nodedicationLabel: "No hay dedicatorias a\u00FAn",
    nolabel: "NO",
    shareEventListLabel: "Comparte tu lista con tus amigos",
    boughtByLabel: "Comprado por",
    externaldeliverylabel: "(Entregar\u00E1 el regalo en persona)",
    eventListTitleLabel: "Lista de Regalos",
    cartLabelDesc:
      "Ac\u00E1 est\u00E1n los productos agregados a tu carro de compra",
    productEventListLabel:
      "Ac\u00E1 est\u00E1n los productos agregados a tu lista de regalos",
    shopCartProductLabel: "Producto",
    shopCartPriceLabel: "Precio",
    copyLinkLabel: "Copiar",
    copiedLinkLabel: "Copiado!",
    eventShopTitle: "Mi Lista de Regalos",
    shopCartQuantityLabel: "Cantidad",
    shopCartQuantityRequestedLabel: "Cantidad Requerida",
    shopCartQuantityAssignedLabel: "Compras De Invitados",
    invalidOrderLabel:
      "Tiempo m\u00E1ximo de espera excedido, refresque esta ventana e inicie el procedimiento nuevamente",
    cancelLabel: "Cancelar",
    editAddressLabel: "Editar Direcci\u00F3n",
    showMoreoptionsLabel: "Ver mas",
    formErrorLabel: "En la validaci\u00F3n de",
    orderPaymentErrorLabel:
      "Lo sentimos, no se pudo verificar el estado del pedido: <orderid>, puede revisarlo en el siguiente link:",
    goCheckOrdersLabel: "Ver mis pedidos",
    shopCartSubtotalLabel: "Subtotal",
    showCart: "Ver y Editar Carro",
    saveLabel: "Guardar",
    closeLabel: "Cerrar",
    closeWindowLabel: "Cerrar Ventana",
    filterLabel: "Filtrar",
    completeOrder: "Completar la compra",
    completeFormMessage: "Por favor complete todos los datos",
    //orderResumeLabel : "Resumen del pedido",
    orderLabel: "Pedido",
    customerLabel: "Cliente",
    allLabel: "Todo",
    packRefLabel: "REF",
    paymentDateLabel: "Fecha de compra",
    updateSuccessLabel: "Informaci\u00F3n actualizada Correctamente",
    updateSuccessChangePassLabel: "Contrase\u00F1a cambiada correctamente",
    minimunLengthLabel:
      "La contrase\u00F1a debe contener al menos 8 caracteres",
    cancelSubscriptionLabel: "Cancelar suscripci\u00F3n",
    subscriptionLabel: "Suscribirse",
    nosuscribeNewsletterMessageLabel: "No suscrito aun al newsletter",
    novalidPasswordLabel: "La debe contener al menos una letra y un numero",
    suscribeNewsletterMessageLabel: "Suscrito al Newsletter",
    updateErrorLabel: "Error procesando informacio\u00F3n, intente nuevamente",
    expiredKeyLabel:
      "Token de recuperacion intente nuevamente recuperar su contrase\u00F1a",
    recoveryPasswordLabel: "Recuperar contrase\u00F1a",
    offLabel: "de Descuento",
    showPostLabel: "VER+",
    recoverySuccessMessageLabel: "Intente ingresar con sus nuevas credenciales",
    emailNotExistsLabel: "El correo electr\u00F3nico no existe",
    shippingDateMessageLaeventResumeLabelzbel:
      "Hola, tu pedido fue entregado el",
    orderDetailLabel: "Seguimiento de tu Compra",
    orderNumberLabel: "N\u00FAmero De Pedido",
    codeEventLabel: "Tu c\u00F3digo de registro es",
    creataEventProductListLabel: "Crea tu lista",
    messageCompleteOption: "Escoge la opci\u00F3n",
    confirmLocationLabel: "Confirmar", // Ubicaci\u00F3n",
    selectLocationLabel: "Siguiente", //"Seleccionar Ubicaci\u00F3n en Mapa",
    subtotalOrderLabel: "Subtotal del pedido",
    invalidSKU: "Producto inv\u00E1lido, por favor seleccione otro producto",
    resumeTotalOrdersLabel: "Cantidad de Pedidos realizados",
    totalOrderLabel: "Total del pedido",
    noproductsCartError:
      "Hubo un error leyendo la lista de productos del carrito, refresque esta ventana e intente nuevamente por favor",
    buyLabel: "Completar la compra",
    chectCartLabel: "Revise el carrito para continuar",
    continueToPaymentLabel: "Continuar con el Pago",
    initPaymentLabel: "Iniciar compra",
    continueBuyingLabel: "Agregar m\u00E1s productos al carro",
    continueEventLabel: "Agregar m\u00E1s productos a tu lista",
    shortBuyLabel: "Ir a Pagar",
    discountLabel: "Descuento",
    startTrackingLabel: "Ir Al Seguimiento",
    endOfResultsLabel: ">>Fin de Resultados<<",
    loadingProductsLabel: "Cargando mas productos...",
    loadingLabel: "Cargando...",
    orLabel: "\u00F3",
    loginLabel: "Iniciar sesi\u00F3n",
    remembermeLabel: "Recordar sesi\u00F3n",
    loginWithSocialNetwork: "Iniciar sesi\u00F3n usando una red social",
    registerLabel: "Registarse",
    errorNumberLabel: "Por favor introduzca un n\u00FAmero v\u00E1lido",
    chooseMapPoint: "Seleccione un punto en el mapa",
    emailLabel: "Email",
    goBackLabel: "intente nuevamente",
    orderNotFoundLabel:
      "No hemos encontrado ninguna orden bajo este n\u00FAmero de Orden y correo electr\u00F3nico",
    oldPasswordLabel: "Contrase\u00F1a actual",
    passwordLabel: "Contrase\u00F1a",
    repeatPasswordLabel: "Repita Contrase\u00F1a",
    forgotPasswordLabel: "Olvid\u00E9 mi contrase\u00F1a",
    createAccountLabel: "Crear cuenta   >",
    continueAsGuest: "Continuar como invitado",
    successfullyAddedLabel: "Agregado Correctamente al carro",
    showEvent: "Ver Evento",
    findyourEvent: "Busca tu evento:",
    showEventList: "Ver lista de regalos",
    disclaimerEventMessage:
      "*Te recordamos que todos los productos ser\u00E1n entregados al organizador del evento",
    disclaimerEventMessage2:
      " Enviaremos la factura a tu correo en un m\u00E1ximo de 72 horas h\u00E1biles",
    successfullyAddedToEventListLabel:
      "Agregado Correctamente a la lista de Regalos",
    skuExceededMessage: "Cantidad l\u00EDmite para este producto excedida",
    skuExceededMessage2:
      "La cantidad requerida de este producto ya fue comprada",
    errorAddedToEventListLabel: (
      <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
        {" "}
        <p>Error agregando a la lista</p> <p>Intente nuevamente</p>{" "}
      </div>
    ),
    errorAddedToNewSKULabel: "SKU no encontrado",
    successfullyEventItemUpdatedLabel: "Datos Actualizados correctamente",
    successfullyRemoveEventItemLabel:
      "Producto removido correctamente de la Lista",
    successfullyRemoveItemLabel: "Producto removido correctamente del carro",
    successfullyChangedItemLabel: "Cantidad cambiada correctamente",
    emtpyCartLabel: "El Carro est\u00E1 vac\u00EDo",
    uploadMessage: (
      <>
        Arrastre una imagen o{" "}
        <span
          style={{
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {" "}
          haga click
        </span>{" "}
        para subirla (Recuerda que no debe superar los 5MB){" "}
      </>
    ),
    uploadUpdateMessage: (
      <>
        Arrastre una imagen o{" "}
        <span
          style={{
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {" "}
          haga click
        </span>{" "}
        para subirla (Recuerda que no debe superar los 5MB){" "}
      </>
    ),
    changeImageButton: "Cambiar Imagen",
    changeImageButton2: "Sube tu Imagen",
    deleteImageButton: "Eliminar Imagen",
    noProductsOnCart: "No tienes ning\u00FAn art\u00EDculo en el carro.",
    startBuyingLabel: "Continue comprando",
    confirmOrderLabel: "Confirmar Pedido",
    confirmInvoceDataLabel: "Confirmar",
    invoicetitleLabel: "Confirmar Datos de Facturaci\u00F3n",
    closeOrderLabel: "Finalizar Pedido",
    setOrderLabel: "Registrar Pedido",
    invalidNumberLabel: "No es un n\u00FAmero v\u00E1lido",
    invalidMaxLengthLabel: "Longitud m\u00E1xima excedida",
    invalidMinLengthLabel: "Longitud m\u00EDnima no completada",
    invalidPhoneNumberLabel: "No es un n\u00FAmero telef\u00F3nico v\u00E1lido",
    myCartLabel: "Mi carro",
    myEventFormLabel: "Info. de Evento",
    searchEventMessage:
      "Ahora hacer el regalo ideal es m\u00E1s f\u00E1cil en Casaideas.com",
    alreadyproductsPurchasedLabel:
      "Ya se compraron todos los regalos que eligfieron los organizadores, pero puedes seleccionar el producto que te guste, te damos algunas sugerencias",
    searchEventLabel: "\u00BFA qui\u00E9n regalas?",
    ShippingLabel: "Entrega",
    closeSessionLabel: "Cerrar Sesi\u00F3n",
    sendLabel: "ENVIAR",
    paymentLabel: "Pago",
    personalDataLabel: "Datos personales",
    shippingInfoLabel: "Entrega / Retiro",
    nextLabel: "Siguiente >",
    firstNameLabel: "Nombres",
    nameLabel: "Nombre",
    messageLabel: "Mensaje",
    lastNameLabel: "Apellidos",
    nitLabel: "Nit",
    phoneLabel: "Celular",
    codeLabel: "Cod.",
    streetLabel: "Calle",
    streetnumberLabel: "N\u00FAmero",
    referenceAddressLabel: "Referencias de direcci\u00F3n",
    fastShippingLabel: "Envio el mismo dia",
    apartmentLabel: "Piso o Departamento",
    recipientLabel: "Nombre de quien recibe",
    stateLabel: "Provincia",
    cityLabel: "Ciudad",
    additionalShippingInstructionsLabel: "Indicaciones para la entrega",
    isEmptyLabel: "no puede estar vac\u00EDo",
    theFieldLabel: "El campo",
    productsLabel: "producto(s)",
    previousStepIncompleteLabel:
      "A\u00FAn falta completar los datos del cuadro anterior",
    shippingMethodAddLabel: "Entrega en domicilio",
    terminalBusMethodLabel: "Entrega en terminal de buses",
    searchTitleLabel: "Buscar",
    pickupFromStoreMethodLabel: "Retiro en tienda",
    terminalNameLabel: "Nombre de la Terminal",
    storeNameLabel: "Tienda",
    continueLabel: "Continue navegando",
    resultsLabel: "Resultados",
    searchLabel: "Escribe tu b\u00FAsqueda",
    categoryLabel: "Categoria",
    showMoreLabel: "Mostrar mas...",
    hideLabel: "Ocultar",
    myAccountLabel: "Mi Cuenta",
    myOrdersLabel: "Mis pedidos",
    myWishListLabel: "Lista de deseos",
    gooutOfEventModeMessage:
      "Est\u00E1 seguro de salir de la lista de regalos?",
    deleteProductEventMessage: "Est\u00E1 seguro de eliminar este regalo?",
    gooutLabel: "Salir",
    clickLabel: "Haz Click Aqui",
    myWishListMessage:
      "\u00C9sta es tu lista de deseos <CLICK> para obtener el link y poderlo compartir con tus amigos y familia",
    myWishListSharedMessage:
      "Hola! \u00C9sta es la lista de deseos de tu amigo(a) <firstname> puedes darle una mirada",
    errorGenerationWishListsMessage:
      "Hubo un problema generando el link de tu lista de deseos, intente mas tarde",
    errorGenerationEventListsMessage:
      "Hubo un problema generando el link de tu lista de regalos, intente mas tarde",
    myWishListTokenMessage:
      "Gracias, aqui tienes el link para compartirlo con tus amigos!",
    myAddressLabel: "Mis direcciones",
    addressLabel: "Direcci\u00F3n",
    searchProductLabel: "Busca tu producto",
    mySuscriptionsLabel: "Suscripciones al Newsletter",
    infoAccountLabel: "Informaci\u00F3n de la cuenta",
    orderByLabel: "Ordenar Por",
    publishFacebookLabel: "Publicar en Facebook",
    writeEvent: "Por favor, ingresa el nombre del evento",
    noRecordsFoundLabel: "Lo sentimos, no encontramos resultados.",
    selecteOptionsLabel:
      "Por favor selecciona las opciones del producto para agregarlo al carrito",
    selecteOptionsEventLabel:
      "Por favor selecciona las opciones del producto para agregarlo a tu tienda de regalos",
    selectQuantityLabel: "Por favor selecciona la cantidad",
    userExistsLabel: "El usuario ya existente intente con otro correo",
    gotoStart: "Volver al inicio",
    gobackMyEvent: "Volver a mi Evento",
    addCommentLabel: "A\u00F1adir Comentario",
    userOrPassErrorLabel: "Usuario o Password Incorrecto",
    unknownErrorLabel: "Error desconocido",
    shippingCostLabel: "Env\u00EDo",
    itemsOrderLabel: "Art\u00EDculos comprados",
    ordermessageLabel: "El ID de su orden es:",
    successfullAddedToWishListLabel: "Agregado a tu lista de deseos",
    successfullRemovedToWishListLabel: "Removido de tu lista de deseos",
    errorAddedToWishListLabel: "Error agregando producto, intente nuevamente",
    firstLoginLabel: "Primero debe estar registrado",
    changePasswordLabel: "Cambiar Password",
    //relatedProductsLabel: "TE ENCANTAR\u00C1",
    limitedExceeded:
      "Cantidad l\u00CDmite del inventario del producto excedida u opcion de producto no existente",
    minimumNotSelected: "Debe elegir al menos 1 producto",
    orderInfoLabel: "Informaci\u00F3n del pedido",
    orderResumeLabel: "Detalle de la compra",
    eventResumeLabel: "Monto Acumulado de regalos",
    extragiftLabel: "Regalo Extra",
    outInventoryLabel: "Sin Stock",
    outInventoryLabelCart: "Sin Stock, eliminar del carrito",
    minusInventoryLabel:
      "S\u00F3lo existen <qty> unidad(es), disminuir cantidad",
    outInventoryLabel1: "Inventario superado ",
    outInventoryLabel2: "m\u00E1ximo:",
    ofLabel: "de",
    giftLabel: "Regalo(s)",
    defCommentEventProdut: "Deseamos este regalo porque...",
    eventResumeQuantityLabel: "Cantidad Acumulada de regalos",
    notFoundTitleLabel: "LO SENTIMOS",
    pickUpInfoLabel: "Datos de Recojo",
    pickUpMessageLabel:
      "*Revisa tu Correo para hacer el seguimiento de tu pedido",
    notFoundsubTitleLabel1:
      "No hemos encontrado productos para tu b\u00FAsqueda.",
    notFoundsubTitleLabel2:
      "El producto solicitado se encuentra fuera de Stock",
    notFoundsubTitleLabel3:
      "Realiza tu b\u00FAsqueda nuevamente o revisa nuestras sugerencias:",

    notEventFoundsubTitleLabel:
      "Lo sentimos, el evento es inv\u00E1lido o est\u00E1 vencido",
    notEventFoundsubTitleLabel1: "A\u00FAn no tienes ning\u00FAn evento creado",
    notEventFoundsubTitleLabel2: (
      <div>
        {" "}
        Puedes crear uno nuevo haciendo{" "}
        <a className="text-primary" href="/customer/event/create-event">
          {" "}
          Click aqu&iacute;{" "}
        </a>{" "}
      </div>
    ),

    eventFoundsubTitleLabel1: "Ya tienes un evento creado",
    eventFoundsubTitleLabel2: (
      <div>
        {" "}
        Puedes ver los detalles del mismo haciendo{" "}
        <a className="text-primary" href="/customer/event/manage">
          {" "}
          Click aqu&iacute;{" "}
        </a>{" "}
      </div>
    ),

    notFoundsubTitleLabel4:
      "A\u00FAn no tienes productos agregados a tu lista de deseos, aqu\u00ED algunas sugerencias",
    paymentMethodsLabel: "Opciones de Pago",
    choosePaymentMethodLabel: "Seleccione su m\u00E9todo de pago",
    paymentMethodsSelectecteLabel: "M\u00E9todo de Pago Seleccionado:",
    paymentMethodLabel: "M\u00E9todo de Pago",
    shippingMethodLabel: "M\u00E9todo de Env\u00CDo",
    selectCatalogLabel: "SELECCIONA TU CIUDAD:",
    saveChangesLabel: "Guardar Cambios",
    passwordsNotEqualLabel: "Las contrase\u00F1as no coindicen",
    accountInfoLabel: "Informaci\u00F3n de contacto",
    noNameLabel: "SIN NOMBRE",
    recoveyPasswordLabel: "Restablecer mi contrase\u00F1a",
    wrongPasswordLabel: "Contrase\u00F1a erronea",
    recoveryMessageLabel:
      "Si hay una cuenta asociada al correo <email> recibir\u00E1s un link para actualizar tu contrase\u00F1a.",
    recoveryErrorMessageLabel:
      "Error tratando de enviar correo de recuperaci\u00F3n de cuenta <email> revise si el correo es el correcto",
  };
  return values;
}

export function setCookie(name, value, hours) {
  try {
    var expires;
    if (hours) {
      var date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    // console.log(value)
    document.cookie = name + "=" + value + expires + "; path=/";
  } catch (err) {
    console.log(">>>>>>> ERR SETTING COOKIE", err);
  }
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  var resp;
  var status = false;
  //console.log("ca0",ca[0])
  for (let i = 0; i < ca.length; i++) {
    var c = ca[i];
    //console.log(c)
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        //console.log("ENC"+name)
        status = true;
        return c.substring(nameEQ.length, c.length);
      }
    }
  }

  if (!status) {
    //console.log("segunda busqueda"+name)
    for (let i = 0; i < ca.length; i++) {
      //let data = ca[i].split("=");
      if (ca[i].indexOf(name) >= 0) {
        let c = ca[i];
        //console.log("F:", ca[i])
        //console.log(c.substring(nameEQ.length, c.length).trim())
        return c.substring(nameEQ.length, c.length).trim();
      }
    }
  }

  return resp;
}
/*
function round2(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(3));
  //  console.log(m)
  return (Math.round(m) / 100) * Math.sign(num);
}
*/
export function toFixed(_value) {
  var resp;
  try {
    resp = _value.toFixed(2);
    //let r =round2(_value)
    // resp = r.toFixed(2)
    //console.log (resp, r)
  } catch (Excep) {
    //console.log("Excep",Excep)
    try {
      resp = _value.toFixed(1);
    } catch (Excep2) {
      resp = _value;
    }
  }
  return resp;
}

export function convertNumber(_string) {
  if (isNaN(parseFloat(_string))) {
    return null;
  } else {
    return parseFloat(_string);
  }
}
export function find(_json, _value, _key) {
  var pos = -1;

  _json.map(function (key, item) {
    if (key[_key] == _value) {
      pos = item;
    }
  });
  return pos;
}

export function formatCategory(desc) {
  var resp = desc;

  if (desc.indexOf("<a") >= 0) {
    let respFinal = [];

    let totalRows = desc.split("</a>");

    for (let i in totalRows) {
      let row = totalRows[i];
      let text = row.split("<a");
      let element = { text: text[0] };
      if (text[1]) {
        let link = text[1].split('"');
        element["link"] = link[1];
        element["linktext"] = link[2].substr(1, link[2].length);
      }

      respFinal.push(element);
    }
    /*
		let pos1 = desc.indexOf("<a")
		let pos2 = desc.indexOf("</a>")
		let newresp = desc.substr(0, pos1)
		let newresp2 = desc.substr(pos2+4, desc.length)
		
		
		let pos3 = desc.indexOf('href="' )
		let step1 = desc.substr( pos3+6, desc.length )
		let url  =  step1.substr(0, step1.indexOf('">') )

		let step2 = step1.substr( step1.indexOf('">')+2, desc.length )
		let linkLabel = step2.substr( 0, step2.indexOf('</a>') )


		let respA = [
			newresp, url, linkLabel, newresp2
		]*/

    return respFinal;
  } else {
    return resp;
  }
}

export function findObjName(_event) {
  var continueSerching = true;
  var objName = "";

  var event = _event.target;

  while (continueSerching) {
    if (event.name || event.attributes.name) {
      continueSerching = false;
      objName = event.name ? event.name : event.attributes.name.nodeValue;
    } else {
      event = event.parentNode;
    }
  }

  return objName;
}

export function spliceByKey(_json, _value, _key) {
  var pos = -1;
  _json.map(function (key, item) {
    if (key[_key] == _value) {
      pos = item;
    }
  });

  if (pos >= 0) {
    _json.splice(pos, 1);
  }
  return _json;
}

export function findValueById(_json, _value, _key, _param) {
  var pos = -1;
  var resp;
  _json.map(function (key, item) {
    if (key[_key] == _value) {
      pos = item;
    }
  });
  if (pos >= 0) {
    resp = _json[pos][_param];
  } else {
    resp = "NOT FOUND";
  }

  return resp;
}

export async function loginSN(res, _toast, _keepLooged) {
  var formData = {};
  console.log("callback");
  //login with google
  if (res.googleId) {
    formData = {
      email: res.profileObj.email,
      customerkey: res.googleId,
      keytype: "google",
      firstname: res.profileObj.givenName,
      lastname: res.profileObj.familyName,
      phonecode: res.profileObj.phonecode ? res.profileObj.phonecode : "",
      phone: res.profileObj.phone ? res.profileObj.phone : "",
      defstoreid: getCurrentStore(),
    };
  } else if (res.graphDomain === "facebook") {
    formData = {
      email: res.email,
      customerkey: res.id,
      keytype: "facebook",
      firstname: res.first_name,
      lastname: res.last_name,
      phonecode: res.phonecode ? res.phonecode : "",
      phone: res.phone ? res.phone : "",
      defstoreid: getCurrentStore(),
    };
  }
  var resp = await callApi("frontend", "loginSNKey", formData);

  if (!resp.success) {
    if (resp.errorcode === "AUTE_10") {
      showError(_toast, getDefValues().userOrPassErrorLabel);
    } else if (resp.errorcode === "AUTE_90") {
      //all error codes are added here
      showError(_toast, getDefValues().unknownErrorLabel);
    }
    return false;
  } else {
    setCookie(process.env.REACT_APP_ORG_NAME + "_eventToken", null, 0.00001);
    var data = {
      userId: resp.userId,
      userToken: resp.token,
    };

    var tData = decode(resp.token);
    var haveEvent = tData.haveenvent;
    if (haveEvent > 0) {
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_haveEvent",
        haveEvent
      );
    }

    if (_keepLooged) {
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_userloginV2",
        JSON.stringify(data)
      );
    } else {
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_userloginV2",
        JSON.stringify(data)
      );
    }

    //var curCart = await getCartProductsEx()
    var tempCart = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_activeCartV2"
    );
    var curCart = JSON.parse(tempCart);

    if (curCart.products.length > 0) {
      data = {
        id: curCart.id,
        userID: resp.userId ? resp.userId : -1,
        discount: curCart.discount,
        products: curCart.products,
        subtotal: curCart.subtotal,
        total: curCart.total,
      };
      var _data = {
        id: curCart.id,
        userid: resp.userId ? resp.userId : -1,
        storeid: getCurrentStore(),
        data: data,
        status: "LOGIN",
      };
      await callApi("frontend", "updateCart", _data);
    } else {
      var id = await getAcviteCartsByUserId(resp.userId);
      if (id) {
        var dataC = [
          { id: id, storeid: getCurrentStore(), userid: resp.userId },
        ];
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
          JSON.stringify(dataC),
          24 * 7
        );
      }
    }
    await getWishList();
    return true;
  }
}

export async function loginSNV2(res, _toast, _keepLooged) {
  var formData = {};
  console.log("callback");
  //login with google
  if (res.googleId) {
    formData = {
      email: res.profileObj.email,
      customerkey: res.googleId,
      keytype: "google",
      firstname: res.profileObj.givenName,
      lastname: res.profileObj.familyName,
      phonecode: res.profileObj.phonecode ? res.profileObj.phonecode : "",
      phone: res.profileObj.phone ? res.profileObj.phone : "",
      defstoreid: getCurrentStore(),
    };
  } else if (res.graphDomain === "facebook") {
    formData = {
      email: res.email,
      customerkey: res.id,
      keytype: "facebook",
      firstname: res.first_name,
      lastname: res.last_name,
      phonecode: res.phonecode ? res.phonecode : "",
      phone: res.phone ? res.phone : "",
      defstoreid: getCurrentStore(),
    };
  }
  var resp = await callApi("frontend", "loginSNKey", formData);

  if (!resp.success) {
    if (resp.errorcode === "AUTE_10") {
      showError(_toast, getDefValues().userOrPassErrorLabel);
    } else if (resp.errorcode === "AUTE_90") {
      //all error codes are added here
      showError(_toast, getDefValues().unknownErrorLabel);
    }
    return { status: false, message: "Error, vuelva a intentar" };
  } else {
    setCookie(process.env.REACT_APP_ORG_NAME + "_eventToken", null, 0.00001);
    var data = {
      userId: resp.userId,
      userToken: resp.token,
    };

    var tData = decode(resp.token);
    var haveEvent = tData.haveenvent;
    if (haveEvent > 0) {
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_haveEvent",
        haveEvent
      );
    }

    if (_keepLooged) {
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_userloginV2",
        JSON.stringify(data)
      );
    } else {
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_userloginV2",
        JSON.stringify(data)
      );
    }

    //var curCart = await getCartProductsEx()
    var tempCart = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_activeCartV2"
    );
    var curCart = JSON.parse(tempCart);

    if (curCart.products.length > 0) {
      data = {
        id: curCart.id,
        userID: resp.userId ? resp.userId : -1,
        discount: curCart.discount,
        products: curCart.products,
        subtotal: curCart.subtotal,
        total: curCart.total,
      };
      var _data = {
        id: curCart.id,
        userid: resp.userId ? resp.userId : -1,
        storeid: getCurrentStore(),
        data: data,
        status: "LOGIN",
      };
      await callApi("frontend", "updateCart", _data);
    } else {
      var id = await getAcviteCartsByUserId(resp.userId);
      if (id) {
        var dataC = [
          { id: id, storeid: getCurrentStore(), userid: resp.userId },
        ];
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
          JSON.stringify(dataC),
          24 * 7
        );
      }
    }
    await getWishList();
    return { status: true, USER: resp };
  }
}

export async function login(_formData, _keepLooged, _toast) {
  var resp = {
    success: false,
    message: "",
  };

  try {
    let validation = await validateForm(_formData, _toast);
    _formData = parseFormData(_formData);

    if (validation.isValid) {
      resp = await callApi("frontend", "login", _formData);

      if (!resp.success) {
        if (resp.errorcode === "AUTE_10") {
          showError(_toast, getDefValues().userOrPassErrorLabel);
        } else if (resp.errorcode === "AUTE_90") {
          //all error codes are added here
          showError(_toast, getDefValues().unknownErrorLabel);
        }
      } else {
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_eventToken",
          null,
          0.00001
        );
        var data = {
          userId: resp.userId,
          userToken: resp.token,
        };

        var tData = decode(resp.token);

        var haveEvent = tData.haveenvent;
        if (haveEvent > 0) {
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_haveEvent",
            haveEvent
          );
        }

        if (_keepLooged) {
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_userloginV2",
            JSON.stringify(data)
          );
        } else {
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_userloginV2",
            JSON.stringify(data)
          );
        }

        //var curCart = await getCartProductsEx()

        var tempCart = localStorage.getItem(
          process.env.REACT_APP_ORG_NAME + "_activeCartV2"
        );
        var curCart = JSON.parse(tempCart);

        if (curCart.products.length > 0) {
          data = {
            id: curCart.id,
            userID: resp.userId ? resp.userId : -1,
            discount: curCart.discount,
            products: curCart.products,
            subtotal: curCart.subtotal,
            total: curCart.total,
          };
          let _data = {
            id: curCart.id,
            userid: resp.userId ? resp.userId : -1,
            storeid: getCurrentStore(),
            data: data,
            status: "LOGIN",
          };

          callApi("frontend", "deleteCartByUserId", _data);

          await callApi("frontend", "updateCart", _data);
        } else {
          var id = await getAcviteCartsByUserId(resp.userId);
          //console.log("Id", id)
          if (id) {
            let dataC = JSON.parse(
              getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
            );
            //console.log("tenia carrito", dataC)
            let pos = find(dataC, getCurrentStore(), "storeid");
            if (pos < 0) {
              dataC.push({
                id: id,
                storeid: getCurrentStore(),
                userid: resp.userId,
              });
              setCookie(
                process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
                JSON.stringify(dataC),
                24 * 7
              );
            } else {
              dataC[pos] = {
                id: id,
                storeid: getCurrentStore(),
                userid: resp.userId,
              };
              setCookie(
                process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
                JSON.stringify(dataC),
                24 * 7
              );
            }

            setCookie(
              process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
              JSON.stringify(dataC),
              24 * 7
            );

            callApi("frontend", "deleteCart", { id: curCart.id });

            let _data = {
              id: id,
              userid: resp.userId,
              status: "LOGIN",
            };
            callApi("frontend", "updateCart", _data);
          } else {
            data = {
              id: curCart.id,
              userID: resp.userId,
              discount: curCart.discount,
              products: curCart.products,
              subtotal: curCart.subtotal,
              total: curCart.total,
            };
            let _data = {
              id: curCart.id,
              userid: resp.userId,
              storeid: getCurrentStore(),
              data: data,
              status: "LOGIN",
            };

            //await callApi ( "frontend","deleteCartByUserId", _data )
            await callApi("frontend", "updateCart", _data);
          }
        }

        let dataC = JSON.parse(
          getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
        );
        for (let c in dataC) {
          if (dataC[c].storeid != getCurrentStore()) {
            let _data = {
              id: dataC[c].id,
              userid: resp.userId,
              status: "LOGIN",
            };

            callApi("frontend", "updateCart", _data);
          }
        }

        let force = true;
        await getWishList(force);
      }
    }
    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = {
      success: false,
      message: err,
    };
    return resp;
  }
}

export function getLoginInfo() {
  try {
    var tempLogin = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_userloginV2"
    );
    var _login = { userId: null, userToken: null };

    if (tempLogin) {
      _login = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_userloginV2")
      );
    } else {
      tempLogin = getCookie(process.env.REACT_APP_ORG_NAME + "_userloginV2");
      if (tempLogin) {
        _login = JSON.parse(tempLogin);
      } else {
        _login = { userId: null, userToken: null };
      }
    }

    var loginInfo = {
      userId: _login.userId ? _login.userId : null,
      token: _login.userToken ? decode(_login.userToken) : null,
      keyToken: _login.userToken,
    };
    return loginInfo;
  } catch (Excep) {
    //console.log(">>>>>>>",Excep)
    loginInfo = {
      userId: null,
      token: null,
    };
    return loginInfo;
  }
}

export async function addWishListCokie(_productid) {
  try {
    var data = [_productid];
    var tempW = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_wishSession"
    );

    if (tempW) {
      data = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_wishSession")
      );
      if (data.indexOf(parseInt(_productid)) < 0) {
        data.push(parseInt(_productid));
        localStorage.setItem(
          process.env.REACT_APP_ORG_NAME + "_wishSession",
          JSON.stringify(data)
        );
      }
    } else {
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_wishSession",
        JSON.stringify(data)
      );
    }
    return data;
  } catch (Excep) {
    console.log(">>>>>>>", Excep);
  }
}

export async function removeWishListCokie(_productid) {
  try {
    var data = [_productid];
    var tempW = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_wishSession"
    );
    if (tempW) {
      data = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_wishSession")
      );
      if (data.indexOf(parseInt(_productid)) >= 0) {
        data.splice(data.indexOf(parseInt(_productid)), 1);
        localStorage.setItem(
          process.env.REACT_APP_ORG_NAME + "_wishSession",
          JSON.stringify(data)
        );
      }
    }
    return data;
  } catch (Excep) {
    console.log(">>>>>>>", Excep);
  }
}

export async function clearWishList() {
  localStorage.removeItem(process.env.REACT_APP_ORG_NAME + "_wishSession");
  var data = [];
  return data;
}

export function getsyncWishList() {
  var tempW = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_wishSession"
  );
  var data = [];
  try {
    if (tempW) {
      data = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_wishSession")
      );
    } else {
      data = [];
    }
    return data;
  } catch (Exc) {
    data = [];
    return data;
  }
}

export function getCurrentStoreName() {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );
  var storesInfo = {};
  if (tempStores) {
    storesInfo = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew")
    );
    var storeName = "";

    storeName = findValueById(
      storesInfo.stores,
      storesInfo.selectedStoreId,
      "storeId",
      "storeName"
    );
    return storeName;
  } else return null;
}
export async function getStoresInfo() {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );

  //console.log("tempStores",JSON.parse(tempStores))

  var storesInfo = {};
  var updateDate = new Date();
  if (tempStores) {
    storesInfo = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew")
    );
    var lastDate = getCookie(
      process.env.REACT_APP_ORG_NAME + "_storesInfoCookie"
    );
    var diffDays = 0;

    //console.log("lastDate",lastDate)

    //setCookie(process.env.REACT_APP_ORG_NAME+'_storesInfoCookie', updateDate, 48 )

    if (lastDate) {
      var currDate = new Date();
      var lastUpdateDate = new Date(lastDate);

      //var diff = lastUpdateDate - currDate;
      //console.log("ahora", currDate, currDate.getTime())
      //console.log("fecha max",new Date(lastDate),lastUpdateDate.getTime())
      //console.log("diff", diff)
      //var dayMills= 8640 000;
      diffDays = (lastUpdateDate - currDate) / (1000 * 60 * 60 * 24);
    } else {
      diffDays = -1;
    }
    //console.log("D", diffDays , diffDays < 0 )
    if (diffDays < 0) {
      //console.log("rec")

      let respStores = await callApi("frontend", "getStores");
      let stores = [];
      respStores.rows.map(function (key) {
        let coords = JSON.parse(key.CLOB_VALUE);
        let store = {
          storeId: key.DETAILDOMAINID,
          storeName: key.STRING_VALUE,
          catalogId: key.NUMBER_VALUE,
          lat: coords.lat,
          lng: coords.lng,
          add: coords.address,
        };
        stores.push(store);
      });
      let newStoresInfo = {
        stores: stores,
        selectedStoreId: null,
        selectedCatalogId: null,
      };
      //console.log("stores updated", newStoresInfo)
      storesInfo = newStoresInfo;
      updateDate = new Date();

      updateDate.setDate(updateDate.getDate() + 10);
      //			console.log("updateDatee", updateDate)
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_storesInfoCookie",
        updateDate,
        240
      );
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_storesInfoNew",
        JSON.stringify(newStoresInfo)
      );
    }
  } else {
    //		console.log("empty")
    let respStores = await callApi("frontend", "getStores");
    let stores = [];
    respStores.rows.map(function (key) {
      let coords = JSON.parse(key.CLOB_VALUE);
      let store = {
        storeId: key.DETAILDOMAINID,
        storeName: key.STRING_VALUE,
        catalogId: key.NUMBER_VALUE,
        lat: coords.lat,
        lng: coords.lng,
        add: coords.address,
      };
      stores.push(store);
    });
    storesInfo = {
      stores: stores,
      selectedStoreId: null,
      selectedCatalogId: null,
    };
    /*
			storesInfo ={
						stores:[
										{storeId: 59, storeName:"La Paz"     , catalogId: 59, lat:'-16.5345619', lng:'-68.0900024' },
										{storeId: 61, storeName:"Cochabamba" , catalogId: 61, lat:'-16.5345619', lng:'-68.0900024' },
										{storeId: 60, storeName:"Santa Cruz" , catalogId: 60, lat:'-16.5345619', lng:'-68.0900024' },
										{storeId: 62, storeName:"Otras Ciudades" , catalogId: 59, lat:'-16.5345619', lng:'-68.0900024' }

									],
						selectedStoreId:null,
						selectedCatalogId:null }
			*/
    updateDate = new Date();
    updateDate.setDate(updateDate.getDate() + 10);
    //console.log("updateDate", updateDate)
    setCookie(
      process.env.REACT_APP_ORG_NAME + "_storesInfoCookie",
      updateDate,
      240
    );
    localStorage.setItem(
      process.env.REACT_APP_ORG_NAME + "_storesInfoNew",
      JSON.stringify(storesInfo)
    );
  }
  if (storesInfo.stores.length === 0) {
    //		console.log("fixed")
    var respStores = await callApi("frontend", "getStores");
    var stores = [];
    respStores.rows.map(function (key) {
      let coords = JSON.parse(key.CLOB_VALUE);
      let store = {
        storeId: key.DETAILDOMAINID,
        storeName: key.STRING_VALUE,
        catalogId: key.NUMBER_VALUE,
        lat: coords.lat,
        lng: coords.lng,
        add: coords.address,
      };
      stores.push(store);
    });
    let newStoresInfo = {
      stores: stores,
      selectedStoreId: null,
      selectedCatalogId: null,
    };

    storesInfo = newStoresInfo;
    updateDate = new Date();
    updateDate.setDate(updateDate.getDate() + 10);
    //		console.log("updateDatef", updateDate)
    setCookie(
      process.env.REACT_APP_ORG_NAME + "_storesInfoCookie",
      updateDate,
      240
    );
    localStorage.setItem(
      process.env.REACT_APP_ORG_NAME + "_storesInfoNew",
      JSON.stringify(newStoresInfo)
    );
  }
  //console.log("return", storesInfo)
  return storesInfo;
}

export function getSyncStoresInfo() {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );
  var storesInfo = {};
  if (tempStores) {
    storesInfo = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew")
    );
    let pos = find(
      storesInfo.stores,
      parseInt(storesInfo.selectedStoreId),
      "storeId"
    );
    let storeInfo = storesInfo.stores[pos];
    storesInfo["selectedStoreInfo"] = storeInfo;
  }
  return storesInfo;
}

export function getCurrentStore() {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );
  var storesInfo = {};
  if (tempStores) {
    storesInfo = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew")
    );
    return storesInfo.selectedStoreId;
  } else return null;
}

export async function getCartProductsEx() {
  var curCarts = getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2");
  //var store = await getStoresInfo ()
  var dataC = [];
  var shopCart = {};
  var Cart = true;
  var data = {};
  if (curCarts) {
    dataC = JSON.parse(
      getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
    );

    var id, storeid;
    for (let i in dataC) {
      if (dataC[i].storeid.toString() === getCurrentStore().toString()) {
        id = dataC[i].id;
        storeid = dataC[i].storeid;
      }
    }
    if (id) {
      var _data = {
        id: id,
        storeid: storeid,
      };
      var respCart = await callApi("frontend", "getCart", _data);
      //			console.log(respCart)
      if (!respCart.success) {
        //	console.log("no existe en elast")
        Cart = false;
      } else {
        //		console.log(" existe en elast leemos")
        shopCart = respCart.shopCart;
      }
    } else {
      //	console.log("no existe en cache/tienda")
      Cart = false;
    }
  } else {
    //	console.log("no existe en cache")
    Cart = false;
  }
  if (Cart) {
    //console.log("SI")

    data = {
      id: id,
      userID: getLoginInfo().userId ? getLoginInfo().userId : -1,
      products: shopCart.products ? shopCart.products : null,
      discount: shopCart.discount ? shopCart.discount : null,
      subtotal: shopCart.subtotal ? shopCart.subtotal : null,
      total: shopCart.total ? shopCart.total : null,
    };
  } else {
    //console.log("NO")
    id = uuid();
    data = {
      id: id,
      userID: getLoginInfo().userId ? getLoginInfo().userId : -1,
      products: [],
      discount: null,
      subtotal: null,
      total: null,
    };
  }
  return data;
}

export async function getCartLocal(_data) {
  try {
    let data = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_activeCartV2"
    );
    var cart = JSON.parse(data);
    var resp = { success: true, shopCart: cart };
  } catch (Ex) {
    let id = uuid();
    let shopCart = {
      id: id,
      storeid: getCurrentStore(),
      userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
      createdate: new Date().getTime(),
      updatedate: new Date().getTime(),
      status: "INITIAL",
      data: {
        id: id,
        userID: getLoginInfo().userId ? getLoginInfo().userId : -1,
        needCommit: false,
        products: [],
        discount: null,
        subtotal: null,
        total: null,
      },
    };

    resp = { success: true, shopCart: shopCart };
  }
  return resp;
}

export async function setCartLocal(_data) {
  var resp = { success: true, shopCart: _data };
  return resp;
}

export async function getShopCart(status) {
  var curCarts = getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2");
  //var store =  getSyncStoresInfo ()
  var dataC = [];
  var shopCart = {};
  var createCart = false;
  if (curCarts) {
    dataC = JSON.parse(
      getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
    );
    let tmpDataC = getCookie(
      process.env.REACT_APP_ORG_NAME + "_cartSessionsV2"
    );
    setCookie(
      process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
      tmpDataC,
      24 * 7
    );
    var id, storeid;
    for (let i in dataC) {
      if (dataC[i].storeid == getCurrentStore()) {
        id = dataC[i].id;
        storeid = dataC[i].storeid;
      }
    }
    if (id) {
      var _data = {
        id: id,
        storeid: storeid,
        status: status,
        userid: getLoginInfo().userId,
      };
      var respCart = await callApi("frontend", "getCart", _data);
      if (!respCart.success) {
        createCart = true;
      } else {
        shopCart = respCart.shopCart;
        let pos = find(dataC, id, "id");

        if (pos < 0) {
          //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
          dataC.push({
            id: id,
            storeid: getCurrentStore(),
            userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
          });
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
            JSON.stringify(dataC),
            24 * 7
          );
        } else {
          if (respCart.newCartId) {
            dataC[pos] = {
              id: respCart.newCartId,
              storeid: getCurrentStore(),
              userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
            };
            setCookie(
              process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
              JSON.stringify(dataC),
              24 * 7
            );
          }
        }

        localStorage.setItem(
          process.env.REACT_APP_ORG_NAME + "_activeCartV2",
          JSON.stringify(shopCart)
        );
      }
    } else {
      createCart = true;
    }
  } else {
    createCart = true;
  }

  if (createCart && !status) {
    id = uuid();
    shopCart = {
      id: id,
      storeid: getCurrentStore(),
      userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
      createdate: new Date().getTime(),
      updatedate: new Date().getTime(),
      status: "INITIAL",
      data: {
        id: id,
        userID: getLoginInfo().userId ? getLoginInfo().userId : -1,
        needCommit: false,
        products: [],
        discount: null,
        subtotal: null,
        total: null,
      },
    };
    var newCart = await callApi("frontend", "setCart", shopCart);

    if (newCart.success) {
      //dataC=[ { id:id, storeid:getCurrentStore(), userid: getLoginInfo().userId?getLoginInfo().userId:-1 }]

      let pos = find(dataC, getCurrentStore(), "storeid");
      if (pos < 0) {
        dataC.push({
          id: id,
          storeid: getCurrentStore(),
          userid: getLoginInfo().userId,
        });
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
          JSON.stringify(dataC),
          24 * 7
        );
      } else {
        dataC[pos] = {
          id: id,
          storeid: getCurrentStore(),
          userid: getLoginInfo().userId,
        };
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
          JSON.stringify(dataC),
          24 * 7
        );
      }

      setCookie(
        process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
        JSON.stringify(dataC),
        24 * 7
      );
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_activeCartV2",
        JSON.stringify(shopCart.data)
      );
    } else {
      console.log(">>>Error", newCart);
    }
  }
  return { success: true, id: id, shopCart: shopCart };
}

export async function setStore(storeId) {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );
  var storesInfo = {};
  var cartinfo;
  if (tempStores) {
    storesInfo = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew")
    );
    storesInfo.selectedStoreId = storeId;

    var catalogId = findValueById(
      storesInfo.stores,
      storesInfo.selectedStoreId,
      "storeId",
      "catalogId"
    );
    storesInfo.selectedCatalogId = catalogId;
    localStorage.setItem(
      process.env.REACT_APP_ORG_NAME + "_storesInfoNew",
      JSON.stringify(storesInfo)
    );
    //cartinfo =await getShopCart()

    if (getLoginInfo().userId != null) {
      var id = await getAcviteCartsByUserId(getLoginInfo().userId);

      if (id) {
        var dataC = [];
        try {
          dataC = JSON.parse(
            getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
          );
        } catch (Ex) {
          console.log("NO PREVIOUS CART");
        }
        //dataC = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSessionsV2'))

        let pos = find(dataC, storeId, "storeid");
        if (pos < 0) {
          dataC.push({
            id: id,
            storeid: getCurrentStore(),
            userid: getLoginInfo().userId,
          });
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
            JSON.stringify(dataC),
            24 * 7
          );
        } else {
          dataC[pos] = {
            id: id,
            storeid: getCurrentStore(),
            userid: getLoginInfo().userId,
          };
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
            JSON.stringify(dataC),
            24 * 7
          );
        }
      }
    }

    await getShopCart("CHANGESTORE");
  }

  var resp = {
    storesInfo: storesInfo,
    cartinfo: cartinfo,
  };

  return resp;
}

export async function getWishList(force) {
  var tempW;
  if (!force) {
    tempW = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "_wishSession"
    );
  }
  var data = [];
  try {
    if (tempW) {
      data = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_wishSession")
      );
    } else {
      if (getLoginInfo().userId != null) {
        let resp = await callApi("frontend", "getWishListDB", {
          customerid: getLoginInfo().userId,
        });

        resp.rows.map(function (key) {
          if (key.PREFERREDSKU) {
            data.push(parseInt(key.PRODUCTID + "" + key.PREFERREDSKU));
          } else {
            data.push(parseInt(key.PRODUCTID));
          }
        });
        localStorage.setItem(
          process.env.REACT_APP_ORG_NAME + "_wishSession",
          JSON.stringify(data)
        );
      }
    }
    return data;
  } catch (Exc) {
    data = [];
    return data;
  }
}

export async function getShippingRules(_formData, formData, userInfo) {
  try {
    let resp = await callApi("frontend", "getFormShippingTypes", {
      domainid: 27,
      _formData,
      formData,
      userInfo,
    });

    return resp;
  } catch (Exc) {
    return null;
  }
}

export function calculateDiscount(products) {
  var discount = 0;
  var skuChecked = [];
  var subtotal = 0;
  for (let k in products) {
    let key = products[k];
    subtotal = subtotal + key.price * key.quantity;
    if (key.NEWPRICED) {
      discount =
        discount +
        (toFixed(key.price) * key.quantity -
          toFixed(key.NEWPRICED) * key.quantity);
    }
    if (key.checkcombo) {
      if (skuChecked.indexOf(key.checkcombo) < 0) {
        var totalSKUs = 0;
        for (let k3 in products) {
          if (products[k].checkcombo == products[k3].checkcombo) {
            totalSKUs = totalSKUs + parseFloat(products[k3].quantity);
          }
        }
        let subtotal = 0;
        let qtyC = -1;
        for (let i = 0; i <= totalSKUs; i = i + parseFloat(key.NEWPRICEC1)) {
          qtyC++;
        }
        let rest = totalSKUs % parseFloat(key.NEWPRICEC1);
        let priceDesc =
          qtyC * parseFloat(key.NEWPRICEC1) * parseFloat(key.NEWPRICEC2);
        let priceRest = rest * parseFloat(key.price);
        //let finalPrice = priceDesc + priceRest;

        let originalPrice = parseFloat(totalSKUs) * parseFloat(key.price);

        subtotal = originalPrice - (priceDesc + priceRest);
        discount = discount + subtotal;
        skuChecked.push(key.checkcombo);
      }
    }
  }
  return discount;
}

export function calculateSkusDiscount(products) {
  var skuChecked = [];
  var skusCombo = [];
  var subtotal = 0;
  for (let k in products) {
    let key = products[k];
    subtotal = subtotal + key.price * key.quantity;
    let discount = 0; //check!
    if (key.NEWPRICED) {
      discount =
        discount +
        (toFixed(key.price) * key.quantity -
          toFixed(key.NEWPRICED) * key.quantity);
    }
    if (key.checkcombo) {
      if (skuChecked.indexOf(key.checkcombo) < 0) {
        var totalSKUs = 0;

        for (let k3 in products) {
          if (products[k].checkcombo === products[k3].checkcombo) {
            totalSKUs = totalSKUs + parseFloat(products[k3].quantity);
          }
        }
        let subtotal = 0;
        let qtyC = -1;
        for (let i = 0; i <= totalSKUs; i = i + parseFloat(key.NEWPRICEC1)) {
          qtyC++;
        }
        let rest = totalSKUs % parseFloat(key.NEWPRICEC1);
        let priceDesc =
          qtyC * parseFloat(key.NEWPRICEC1) * parseFloat(key.NEWPRICEC2);
        let priceRest = rest * parseFloat(key.price);
        //let finalPrice = priceDesc + priceRest;

        let originalPrice = parseFloat(totalSKUs) * parseFloat(key.price);

        subtotal = originalPrice - (priceDesc + priceRest);
        discount = discount + subtotal;
        skuChecked.push(key.checkcombo);
        skusCombo.push({
          productname: key.productname,
          checkcombo: key.checkcombo,
          discount: discount,
        });
      }
    }
  }
  return skusCombo;
}

export async function addShopCart(_product, _quantity, _toast) {
  try {
    //var tempCart = localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_cartSession')
    //var tempCart = getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession')

    if (!_product.productsku) {
      showError(_toast, getDefValues().invalidSKU);
      return null;
    } else {
      var tempCart = localStorage.getItem(
        process.env.REACT_APP_ORG_NAME + "_activeCartV2"
      );

      var data = JSON.parse(tempCart);

      if (!data) {
        let dataTC = JSON.parse(
          getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
        );
        let currStr = getCurrentStore();

        let pos = find(dataTC, currStr, "storeid");

        if (pos >= 0) {
          console.log("fixing cart");
          let tid = dataTC[pos].id;
          data = {
            id: tid,
            userID: getLoginInfo().userId ? getLoginInfo().userId : -1,
            needCommit: false,
            products: [],
            discount: null,
            subtotal: null,
            total: null,
          };
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_activeCartV2",
            JSON.stringify(data)
          );
        }
      }

      var limitExceeded = false;
      var isValid = true;

      if (_product.eventid) {
        try {
          for (let a in data.products) {
            if (data.products[a].eventid) {
              if (data.products[a].eventid !== _product.eventid) {
                //console.log("error event dup")
                isValid = false;
                showError(_toast, getDefValues().duplicatedEventError);
              }
            }
          }
        } catch (exc) {
          isValid = false;
          console.log("exc", exc);
          showError(_toast, getDefValues().errorGettingCartProducts);
        }
      }

      if (isValid) {
        var _productid = (_product.productid + "").split("_C_");
        var productid = _productid[0];
        console.log(".", _product);

        var objProduct = {
          id: productid,
          price: _product.priceold,
          name: _product.label,
          category: _product.category ? _product.category : "-",
          brand: "",
          quantity: 1,
          store: getCurrentStore(),
          variant: _product.sku,
          skus: _product.skus,
        };
        setGTMEvent("addToCart", objProduct);
        var _optionsselected = _product.optionsselected;

        var optionsselected = [];
        let res = await getOptions();
        let options = res.rows;
        var skus;
        if (_product.skus.length > 0) {
          skus = _product.skus;
        }
        for (let o in _optionsselected) {
          let label = _optionsselected[o].optionLabel;
          let showOption = false;
          if (!_optionsselected[o].optionLabel) {
            label = findValueById(
              options,
              _optionsselected[o].optionid,
              "optionid",
              "optionname"
            );
          }

          let _optionvalues = findValueById(
            _product.options,
            _optionsselected[o].optionid,
            "optionid",
            "optionvalues"
          );
          if (_optionvalues.length > 1) {
            showOption = true;
          }
          if (showOption) {
            var val = findValueById(
              _product.quantities,
              _optionsselected[o].optionvalue,
              "optionvalue",
              "optioncustomer"
            );
            optionsselected.push({
              optionLabel: label,
              optionid: _optionsselected[o].optionid,
              optionvalue: val !== "NOT FOUND" ? val : null,
            });
          }
        }
        var price = parseFloat(_product.priceold);
        var priceOld = false;

        var NEWPRICED = null;
        var NEWPRICEC1 = null;
        var NEWPRICEC2 = null;

        if (tempCart) {
          var curProducts = data.products;
          var id = data.id;

          var position = find(curProducts, _product.productsku, "productsku");

          var master = null;
          var combos = [];
          var combosTotal = [];
          var subtotal = 0;

          if (position < 0) {
            let quantity = parseFloat(_quantity);

            let Cproduct = validDiscount(_product, quantity);
            if (Cproduct.discount) {
              subtotal = Cproduct.subtotal;
              priceOld = Cproduct.discountM;
              NEWPRICED = Cproduct.discount;
            }

            let respC = await callApi(
              "layaoutManager",
              "getLayoutTemplateByName",
              {
                layoutFile: "discounts",
                foldername: privateFolder + "private/MAINDISCOUNTS/",
              }
            );

            for (let k in respC.template) {
              let key = respC.template[k];
              discountTemplate.push(key);
              if (
                key.sku.toString() === _product.productsku.toString() ||
                master === key.productid + "__" + key.name
              ) {
                if (key.newpricec1) {
                  master = key.productid + "__" + key.name;
                  combos.push(key);
                }
              }
              if (key.newpricec1) {
                combosTotal.push(key);
              }
            }

            curProducts.push({
              productsku: _product.productsku,
              prequantity: null,
              optionsselected: optionsselected,
              productid: productid,
              quantity: quantity,
              price: price,
              priceOld: priceOld,
              subtotal: subtotal,
              NEWPRICED: NEWPRICED,
              NEWPRICEC1: NEWPRICEC1,
              NEWPRICEC2: NEWPRICEC2,
              category: _product.category,
              productpath: _product.productpath,
              maxQuantity: _product.maxQuantity,
              image: _product.path,
              productname: _product.label,
              skus: skus,
              eventid: _product.eventid,
            });

            position = curProducts.length - 1;
          } else {
            let curQuantity = curProducts[position].quantity;
            let quantity = parseFloat(_quantity) + parseFloat(curQuantity);
            let subtotal = quantity * price;

            if (
              _product.maxQuantity >=
              parseFloat(_quantity) + parseFloat(curQuantity)
            ) {
              let Cproduct = validDiscount(_product, quantity);
              if (Cproduct.discount) {
                subtotal = Cproduct.subtotal;
                priceOld = Cproduct.discountM;
                NEWPRICED = Cproduct.discount;
              }

              let respC = await callApi(
                "layaoutManager",
                "getLayoutTemplateByName",
                {
                  layoutFile: "discounts",
                  foldername: privateFolder + "private/MAINDISCOUNTS/",
                }
              );

              for (let k in respC.template) {
                let key = respC.template[k];
                discountTemplate.push(key);
                if (
                  key.sku.toString() ===
                    curProducts[position].productsku.toString() ||
                  master === key.productid + "__" + key.name
                ) {
                  if (key.newpricec1) {
                    master = key.productid + "__" + key.name;
                    combos.push(key);
                  }
                }
                if (key.newpricec1) {
                  combosTotal.push(key);
                }
              }

              curProducts[position] = {
                productsku: _product.productsku,
                prequantity: null,
                optionsselected: optionsselected,
                productid: productid,
                quantity: parseFloat(_quantity) + parseFloat(curQuantity),
                subtotal: subtotal,
                NEWPRICED: NEWPRICED,
                NEWPRICEC1: NEWPRICEC1,
                NEWPRICEC2: NEWPRICEC2,
                category: _product.category,
                productpath: _product.productpath,
                maxQuantity: _product.maxQuantity,
                price: price,
                priceOld: priceOld,
                image: _product.path,
                productname: _product.label,
                skus: skus,
                eventid: _product.eventid,
              };
            } else {
              limitExceeded = true;
              showError(_toast, getDefValues().limitedExceeded);
            }
          }

          var Fsubtotal = 0;
          var total = 0;
          var skuT = 0;
          //let key = curProducts[position];
          if (combos.length > 0) {
            for (let k2 in curProducts) {
              let pos = find(combosTotal, curProducts[k2].productsku, "sku");
              if (pos >= 0) {
                skuT = skuT + curProducts[k2].quantity;
                curProducts[k2]["checkcombo"] =
                  combosTotal[pos].productid + "__" + combosTotal[pos].name;
                curProducts[k2]["NEWPRICEC1"] = combosTotal[pos].newpricec1;
                curProducts[k2]["NEWPRICEC2"] = combosTotal[pos].newpricec2;
                curProducts[k2]["priceOld"] =
                  parseInt(combosTotal[pos].newpricec1) +
                  "x" +
                  getCurrency() +
                  toFixed(
                    combosTotal[pos].newpricec2 * combosTotal[pos].newpricec1
                  );
              }
            }
          }
          for (let k in curProducts) {
            let key = curProducts[k];
            var totalSKUs = 0;
            if (key.checkcombo) {
              var checkcombo = key.checkcombo;
              for (let k2 in curProducts) {
                var key2 = curProducts[k2];
                if (checkcombo === key2.checkcombo) {
                  totalSKUs = totalSKUs + key2.quantity;
                }
              }
              if (totalSKUs < curProducts[k]["NEWPRICEC1"]) {
                delete key.checkcombo;
                delete key.NEWPRICEC1;
                delete key.NEWPRICEC2;
                delete key.priceOld;
              } else {
                key["totalSKUs"] = totalSKUs;
              }
            }
          }
          for (let k in curProducts) {
            let key = curProducts[k];
            Fsubtotal =
              Fsubtotal + parseFloat(key.price) * parseFloat(key.quantity);
          }
          var discount = calculateDiscount(curProducts);
          total = Fsubtotal - discount;
          data = {
            id: id,
            userID: getLoginInfo().userId,
            discount: toFixed(discount),
            needCommit: false,
            products: curProducts,
            subtotal: toFixed(Fsubtotal),
            total: toFixed(total),
          };
        } else {
          console.log("error!!!");
        }

        var _data = {
          id: data.id,
          userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
          updatedate: new Date().getTime(),
          status: "NEWPRODCUCTCART",
          bstatus: "INIT",
          data: data,
        };

        //console.log("data", data)

        var respCart = validateCart(data);

        if (respCart.success) {
          if (!limitExceeded) {
            //callApi("frontend", "updateCart", _data);
            localStorage.setItem(
              process.env.REACT_APP_ORG_NAME + "_activeCartV2",
              JSON.stringify(data)
            );
            showMessage(_toast, getDefValues().successfullyAddedLabel);
          } else {
            console.log("limitExceeded");
          }
        } else {
          data = null;
          showError(_toast, respCart.message);
        }
      }

      let storesInfo = getSyncStoresInfo();
      let loginInfo = getLoginInfo();
      if (loginInfo.userId) {
        try {
          if (curProducts.length > 0) {
            await callApi("frontend", "shopStatus", {
              customerid: loginInfo.userId,
              cartsessionid: data.id,
              storeid: storesInfo.selectedStoreId,
              data: JSON.stringify(data),
              page: "ADDPRODUCT",
            });
          }
        } catch (e) {
          console.log("no products" + e);
        }
      }

      /*********BLOOMREACH*********/
      let url = window.location.href;
      let urlObj = new URL(url);
      let pathParts = urlObj.pathname.split("/");
      pathParts.splice(pathParts.length - 1, 1);

      console.log(pathParts);
      let fixedItems = [];
      let total_quantity = 0;
      for (let i in data.products) {
        let item = {
          item_id: data.products[i].productsku,
          quantity: data.products[i].quantity,
        };
        total_quantity += data.products[i].quantity;
        fixedItems.push(item);
      }
      var objBloomreach_CartUpdate = {
        action: "add",
        origen: "web",
        id_cart: data.id,
        item_id: _product.productsku,
        item_name: _product.label,
        item_price: _product.priceold,
        item_quantity: _quantity,
        items: fixedItems,
        total_quantity: total_quantity,
        total_price: data.subtotal,
      };
      if (pathParts[1]) {
        objBloomreach_CartUpdate["cat_espacios_level_1"] = pathParts[1];
      }
      if (pathParts[2]) {
        objBloomreach_CartUpdate["cat_espacios_level_2"] = pathParts[2];
      }
      if (pathParts[3]) {
        objBloomreach_CartUpdate["cat_espacios_level_3"] = pathParts[3];
      }
      //console.log("objBloomreach_CartUpdate", objBloomreach_CartUpdate);
      window.exponea.track("cart_update", objBloomreach_CartUpdate);

      /*********BLOOMREACH*********/

      return data;
      /*
			setTimeout( function () {
				console.log("data TO",data)	
				return data
			}, 2500);
			*/
    }
  } catch (Excep) {
    console.log("Ex", Excep);
    showError(_toast, "SHOPCART Excep:" + Excep);
    return null;
  }
}

export async function updateCart(
  _productsku,
  _action,
  _toast,
  _quantity,
  _curData
) {
  try {
    //var tempCart = localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_cartSession')
    //var tempCart = getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession')

    console.log("updateCart", _action)
    var tempCart;
    var data;
    var isValid = true;
    var cartStatus = "";

    if (_curData) {
      tempCart = { shopCart: _curData, success: true, id: _curData.id };
      data = _curData;
    } else {
      tempCart = localStorage.getItem(
        process.env.REACT_APP_ORG_NAME + "_activeCartV2"
      );
      data = JSON.parse(tempCart);
    }

    var master = null;
    var combos = [];
    var combosTotal = [];
    //var data = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession'))

    //console.log("tempCartdata", data)
    var curProducts = data.products;
    var id = data.id;
    //var userID = data.userID
    var position = find(curProducts, _productsku, "productsku");

    var message = getDefValues().successfullyChangedItemLabel;
    var needCommit = data.needCommit ? data.needCommit : false;
    var deleted = false;
    //var skuToDelete;
    //var checkcomboToDelete;
    var nameD = "";
    var priceD = "";
    if (position >= 0) {
      if (!curProducts[position].quantity) {
        isValid = false;
        message = getDefValues().minimumNotSelected;
      } else {
        if (parseInt(curProducts[position].quantity) === 0) {
          isValid = false;
          message = getDefValues().minimumNotSelected;
        }
      }
      if (_action === "del") {
        console.log("del", curProducts[position]);

        nameD = curProducts[position].productname;
        priceD = curProducts[position].price;

        needCommit = false;
        var objProduct = {
          id: curProducts[position].productid,
          price: curProducts[position].price,
          name: curProducts[position].productname,
          category: curProducts[position].category
            ? curProducts[position].category
            : "-",
          brand: "",
          quantity: 1,
          store: getCurrentStore(),
          variant: curProducts[position].productsku,
        };

        setGTMEvent("removeFromCart", objProduct);
        cartStatus = "DELPRODCUCTCART";
        //curProducts.splice(position, 1)
        message = getDefValues().successfullyRemoveItemLabel;
        deleted = true;
        //skuToDelete = curProducts[position].productsku;
        //checkcomboToDelete = curProducts[position].checkcombo;
      } else if (_action === "increase") {
        if (
          curProducts[position].quantity + 1 <=
          curProducts[position].maxQuantity
        ) {
          curProducts[position].quantity++;
          curProducts[position]["prequantity"] = null;

          needCommit = false;
          curProducts[position]["prequantity"] = null;
          curProducts[position]["showUpdate"] = false;
        } else {
          isValid = false;
          message = getDefValues().limitedExceeded;
        }

        cartStatus = "ADDPRODCUCTCART";
      } else if (_action === "decrease") {
        //console.log(curProducts[position])
        if (curProducts[position].quantity > 1) {
          curProducts[position].quantity--;
          curProducts[position]["prequantity"] = null;
          needCommit = false;
          curProducts[position]["prequantity"] = null;
          curProducts[position]["showUpdate"] = false;
        } else {
          isValid = false;
          message = getDefValues().minimumNotSelected;
        }
        cartStatus = "RESPRODCUCTCART";
      } else if (_action === "quantity") {
        //let val = validateNumber(_quantity);
        //if ((val = _quantity && parseInt(_quantity) > 0)) { //check!
        if (parseInt(_quantity) > 0) {
          if (
            parseInt(curProducts[position]["quantity"]) === parseInt(_quantity)
          ) {
            curProducts[position]["prequantity"] = null;
            curProducts[position]["showUpdate"] = false;
            needCommit = true;
          } else {
            curProducts[position]["prequantity"] = parseInt(_quantity);
            curProducts[position]["showUpdate"] = true;
            needCommit = true;
          }
        } else {
          curProducts[position]["prequantity"] = "";
          curProducts[position]["showUpdate"] = false;
          needCommit = true;
        }
      } else if (_action === "update") {
        if (
          curProducts[position].prequantity <= curProducts[position].maxQuantity
        ) {
          curProducts[position].quantity = curProducts[position]["prequantity"];
        } else {
          isValid = false;
          message = getDefValues().limitedExceeded;
        }
        //curProducts[position].quantity = curProducts[position]["prequantity"]

        curProducts[position]["prequantity"] = null;
        curProducts[position]["showUpdate"] = false;
        needCommit = false;
        cartStatus = "QTYPRODCUCTCART";
      }
      //if (!deleted && isValid){
      if (isValid) {
        if (!needCommit) {
          var price = parseFloat(curProducts[position].price);
          var priceOld = false;
          var quantity = parseFloat(curProducts[position].quantity);
          var subtotal = quantity * price;

          if (curProducts[position]["NEWPRICED"] != null) {
            priceOld = curProducts[position]["priceOld"];
            subtotal = quantity * curProducts[position]["NEWPRICED"];
            curProducts[position]["priceOld"] = priceOld;
            curProducts[position]["subtotal"] = subtotal;
          }

          var respC = await callApi(
            "layaoutManager",
            "getLayoutTemplateByName",
            {
              layoutFile: "discounts",
              foldername: privateFolder + "private/MAINDISCOUNTS/",
            }
          );

          for (let k in respC.template) {
            let key = respC.template[k];
            if (
              key.sku.toString() ===
                curProducts[position].productsku.toString() ||
              master === key.productid + "__" + key.name
            ) {
              if (key.newpricec1) {
                master = key.productid + "__" + key.name;
                combos.push(key);
              }
            }

            if (key.newpricec1) {
              combosTotal.push(key);
            }
          }
        }
      }
    }
    var skuT = 0;
    if (deleted) {
      curProducts.splice(position, 1);
    }

    if (position >= 0 && isValid && !needCommit) {
      for (let prod in curProducts) {
        var key = curProducts[prod];

        /*Fsubtotal =
          Fsubtotal + parseFloat(key.price) * parseFloat(key.quantity);
        */ //check!
        if (combosTotal.length > 0) {
          for (let k2 in curProducts) {
            let pos = find(combosTotal, curProducts[k2].productsku, "sku");
            if (pos >= 0) {
              skuT = skuT + curProducts[k2].quantity;
              curProducts[k2]["checkcombo"] =
                combosTotal[pos].productid + "__" + combosTotal[pos].name;
              curProducts[k2]["NEWPRICEC1"] = combosTotal[pos].newpricec1;
              curProducts[k2]["NEWPRICEC2"] = combosTotal[pos].newpricec2;
              curProducts[k2]["priceOld"] =
                parseInt(combosTotal[pos].newpricec1) +
                "x" +
                getCurrency() +
                toFixed(
                  combosTotal[pos].newpricec2 * combosTotal[pos].newpricec1
                );
            }
          }
        }

        var totalSKUs = key.quantity;

        if (key.checkcombo) {
          for (let k2 in curProducts) {
            var check = true;
            let key2 = curProducts[k2];
            if (key2.productsku.toString() === key.productsku.toString()) {
              check = false;
            }
            if (check) {
              if (key.checkcombo === key2.checkcombo) {
                totalSKUs = totalSKUs + key2.quantity;
              }
            }
          }
          if (totalSKUs < parseFloat(key["NEWPRICEC1"])) {
            var comboToDelete = key.checkcombo;
            for (let k2 in curProducts) {
              let key2 = curProducts[k2];
              if (comboToDelete == key2.checkcombo) {
                curProducts[k2]["comboToDelete"] = comboToDelete;
              }
            }
          } else {
            delete key.comboToDelete;
            key["totalSKUs"] = totalSKUs;
          }
        }
      }
    }

    var Fsubtotal = 0;
    var total = 0;
    for (let k2 in curProducts) {
      let key = curProducts[k2];
      Fsubtotal = Fsubtotal + parseFloat(key.price) * parseFloat(key.quantity);

      if (!needCommit && key.comboToDelete) {
        delete key.checkcombo;
        delete key.NEWPRICEC1;
        delete key.NEWPRICEC2;
        delete key.priceOld;
        delete key.checkcombo;
      }
    }

    var discount = calculateDiscount(curProducts);
    total = Fsubtotal - discount;
    data = {
      id: id,
      userID: getLoginInfo().userId,
      needCommit: needCommit,
      products: curProducts,
      discount: toFixed(discount),
      subtotal: toFixed(Fsubtotal),
      total: toFixed(total),
    };

    if (!needCommit) {
      if (isValid) {
        var _data = {
          id: data.id,
          userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
          updatedate: new Date().getTime(),
          status: cartStatus,
          bstatus: "INIT",
          data: data,
        };
        var respCart = validateCart(data);
        if (respCart.success) {
          //callApi("frontend", "updateCart", _data);
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_activeCartV2",
            JSON.stringify(data)
          );
          showMessage(_toast, message);
        } else {
          data = null;
          showError(_toast, respCart.message);
        }

        /*********BLOOMREACH*********/
        let url = window.location.href;
        let urlObj = new URL(url);
        let pathParts = urlObj.pathname.split("/");
        pathParts.splice(pathParts.length - 1, 1);

        console.log(pathParts);
        let fixedItems = [];
        let total_quantity = 0;
        for (let i in data.products) {
          let item = {
            item_id: data.products[i].productsku,
            quantity: data.products[i].quantity,
          };
          total_quantity += data.products[i].quantity;
          fixedItems.push(item);
        }

        let pos = data.products.findIndex(
          (item) => item.productsku === _productsku
        );
        let item_name, item_price, item_quantity;
        if (pos === -1) {
          item_name = nameD;
          item_price = priceD;
          item_quantity = 0;
        } else {
          item_name = data.products[pos].productname;
          item_price = data.products[pos].price;
          item_quantity = data.products[pos].quantity;
        }
    //    console.log(pos, data.products[pos]);
        let act = ""
        
        if (_action ==="increase"){
          act= "add"
        }

        if (_action ==="decrease"){
          act= "remove"
        } 
        if (_action ==="del"){
          act= "empty"
        }
 
        var objBloomreach_CartUpdate = {
          action: act,
          origen: "web",
          id_cart: data.id,
          item_id: _productsku,
          item_name: item_name,
          item_price: item_price,
          item_quantity: item_quantity,
          items: fixedItems,
          total_quantity: total_quantity,
          total_price: data.subtotal,
        };
        if (pathParts[1]) {
          objBloomreach_CartUpdate["cat_espacios_level_1"] = pathParts[1];
        }
        if (pathParts[2]) {
          objBloomreach_CartUpdate["cat_espacios_level_2"] = pathParts[2];
        }
        if (pathParts[3]) {
          objBloomreach_CartUpdate["cat_espacios_level_3"] = pathParts[3];
        }
        //console.log("objBloomreach_CartUpdate", objBloomreach_CartUpdate);
        window.exponea.track("cart_update", objBloomreach_CartUpdate);
        /*********BLOOMREACH*********/
      } else {
        showWarning(_toast, message);
        return false;
      }
    }

    let storesInfo = getSyncStoresInfo();
    let loginInfo = getLoginInfo();
    if (loginInfo.userId) {
      try {
        if (curProducts.length > 0) {
          await callApi("frontend", "shopStatus", {
            customerid: loginInfo.userId,
            cartsessionid: data.id,
            storeid: storesInfo.selectedStoreId,
            data: JSON.stringify(data),
            page: "ADDPRODUCT",
          });
        }
      } catch (e) {
        console.log("no products" + e);
      }
    }

    return data;
  } catch (Excep) {
    console.log(">>>>>>", Excep);
    showError(_toast, "SHOPCART Excep:", Excep);
    return null;
  }
}

export function validateCart(carData) {
  let isValid = true;
  let message = "";
  for (let p in carData.products) {
    var key = carData.products[p];
    if (!key.quantity) {
      isValid = false;
      message =
        "INVALID QUANTITY SKU:" + key.productsku + "(qty " + key.quantity + ")";
    } else {
      if (parseInt(key.quantity) === 0) {
        isValid = false;
        message =
          "INVALID QUANTITY SKU:" +
          key.productsku +
          "(qty " +
          key.quantity +
          ")";
      }
    }
    if (key.productsku === "") {
      isValid = false;
      message = "INVALID SKU:" + key.productsku;
    }
    if (key.quantity > key.maxQuantity) {
      isValid = false;
      message =
        "MAX QUANTITY SKU:" +
        key.productsku +
        " EXCEEDED " +
        "(max " +
        key.maxQuantity +
        ")";
    }
    if ((key.price + "").length === 0) {
      isValid = false;
      message =
        "INVALID PRICE SKU:" + key.productsku + "(price " + key.price + ")";
    }
  }
  var resp = { success: isValid, message: message };
  return resp;
}

export async function manageCart(_event, _cardData, _manageCart, _toast) {
  try {
    //var data;
    //var container;

    var data = findObjName(_event).split("__");

    var action = data[0];
    var productId = data[1];
    //var newCardProducts = [];
    var dataCart = null;
    var redirect = false;
    var category = "";
    var productpath = "";
    var isEvent = false;
    var eventQty = 0;
    let pos = find(_cardData, productId, "productid");

    if (pos >= 0) {
      var key = _cardData[pos];

      key["objdata"] = key.body.props.productData;
      key["objconfig"] = key.body.props.productConfig;
      key["name"] = key.body.props.name;

      if (key.objdata.productid.toString() === productId.toString()) {
        var newQuantity = key.body.props.productConfig.quantitySelected;
        var newObjconfig = key.body.props.productConfig;

        category = key.objdata.category;
        productpath = key.objdata.productpath;

        if (action === "selectOption") {
          var options = key.objdata.options;
          var optionsselected = newObjconfig["optionsselected"];
          var optionsArr = await getOptions();

          if (optionsselected) {
            let pos = find(optionsselected, data[2], "optionid");
            if (pos >= 0) {
              optionsselected[pos] = {
                optionid: data[2],
                optionvalue: data[3],
              };
            } else {
              optionsselected.push({ optionid: data[2], optionvalue: data[3] });
            }
          } else {
            optionsselected = [];
            optionsselected.push({ optionid: data[2], optionvalue: data[3] });
          }
          newObjconfig["optionsselected"] = optionsselected;
          if (key.objdata.options.length === optionsselected.length) {
            var arrSKUs = [];
            var arrPrices = [];
            var arrPaths = [];
            var arrPaths2 = [];
            var arrNames = [];
            var optionsSelected = [];
            var arrOps = [];
            var arrPricesD = [];
            var arrPricesD2 = [];
            var arrPricesC1 = [];
            var arrPricesC2 = [];
            var arrQtys = [];
            var currentOptionSelected = "";
            optionsselected.map(function (o) {
              optionsSelected.push(o.optionvalue);
              if (o.optionvalue == data[3]) {
                currentOptionSelected = o.optionvalue;
              }
            });

            for (let o in optionsSelected) {
              var arrSKUs = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "sku",
                optionsSelected[o],
                arrSKUs
              );
              var arrPrices = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "price",
                optionsSelected[o],
                arrPrices
              );

              var arrPaths = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "imagepath",
                optionsSelected[o],
                arrPaths
              );
              var arrPaths2 = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "imagespath",
                optionsSelected[o],
                arrPaths2
              );
              var arrNames = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "specificname",
                optionsSelected[o],
                arrNames
              );

              var arrPrices = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "price",
                optionsSelected[o],
                arrPrices
              );
              var arrPricesD = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "newpriced",
                optionsSelected[o],
                arrPricesD
              );
              var arrPricesD2 = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "newpriced2",
                optionsSelected[o],
                arrPricesD2
              );
              var arrPricesC1 = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "newpricec1",
                optionsSelected[o],
                arrPricesC1
              );
              var arrPricesC2 = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "newpricec2",
                optionsSelected[o],
                arrPricesC2
              );
              arrQtys = searchArray(
                key.objdata.quantities,
                "optionvalue",
                "quantity",
                optionsSelected[o],
                arrQtys
              );
            }
            /*
							console.log("quantities",key.objdata.quantities)
							console.log("optionsSelected",optionsSelected)
							console.log("arrSKUs",arrSKUs)
							console.log("arrPaths",arrPaths)
							console.log("arrPaths2",arrPaths2)

							console.log("arrNames",arrNames)
							console.log("arrPrices",arrPrices)
							console.log("arrPricesD",arrPricesD)
							console.log("arrPricesD2",arrPricesD2)
							console.log("arrPricesC1",arrPricesC1)
							console.log("arrPricesC2",arrPricesC2)
						*/
            var sku = arrSKUs[0];
            var price = arrPrices[0];
            var path = arrPaths[0];
            var name = arrNames[0];
            var qty = arrQtys[0];
            var pricenew = arrPricesD[0];
            var discount = arrPricesD2[0];

            key.objdata["priceold"] = price;
            key.objdata["pricenew"] = pricenew;
            key.objdata["productsku"] = sku;
            key.objdata["discount"] = discount;
            key.objdata["label"] = name;

            key.objdata["maxQuantity"] = qty;

            if (path == null) {
              path = arrPaths2[0] + sku + "_1" + getImgagesExtension();
            }

            key.objdata["path"] = path;

            arrSKUs = [];
            arrPaths = [];
            arrPaths2 = [];

            arrOps = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "optionid",
              currentOptionSelected,
              arrOps
            );
            arrSKUs = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "sku",
              currentOptionSelected,
              arrSKUs
            );
            arrPaths = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "imagepath",
              currentOptionSelected,
              arrPaths
            );
            arrPaths2 = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "imagespath",
              currentOptionSelected,
              arrPaths2
            );

            var opId = find(optionsArr.rows, arrOps[0], "optionid");
            var otherSKUs = [];
            var isInvalid = false;

            for (let k in key.objdata.quantities) {
              let _key = key.objdata.quantities[k];

              if (
                arrSKUs.indexOf(_key.sku) >= 0 &&
                arrOps.indexOf(_key.optionid) < 0
              ) {
                if (_key.quantity > 0) {
                  otherSKUs.push(_key);
                }
              }
            }

            for (let k in options) {
              var opName = options[k];
              for (let l in opName.optionvalues) {
                if (opName.optionid != arrOps[0]) {
                  var opValue = opName.optionvalues[l];
                  let pos = find(otherSKUs, opValue.value, "optionvalue");
                  opValue["banned"] = pos < 0;
                  if (pos < 0) {
                    isInvalid = true;
                  }
                }
              }
            }
            //		console.log("com")
          } else {
            //	console.log("uno")
            var optionsSelected = [];
            var arrSKUs = [];
            var arrPaths = [];
            var arrPaths2 = [];
            var arrOps = [];
            optionsselected.map(function (o) {
              optionsSelected.push(o.optionvalue);
            });

            var arrSKUs = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "sku",
              optionsSelected[0],
              arrSKUs
            );
            var arrOps = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "optionid",
              optionsSelected[0],
              arrOps
            );
            var arrPaths = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "imagepath",
              optionsSelected[0],
              arrPaths
            );
            var arrPaths2 = searchArray(
              key.objdata.quantities,
              "optionvalue",
              "imagespath",
              optionsSelected[0],
              arrPaths2
            );

            var opId = find(optionsArr.rows, arrOps[0], "optionid");
            var otherSKUs = [];

            for (let k in key.objdata.quantities) {
              let _key = key.objdata.quantities[k];

              if (
                arrSKUs.indexOf(_key.sku) >= 0 &&
                arrOps.indexOf(_key.optionid) < 0
              ) {
                if (_key.quantity > 0) {
                  otherSKUs.push(_key);
                }
              }
            }
            for (let k in options) {
              var opName = options[k];
              for (let l in opName.optionvalues) {
                if (opName.optionid != arrOps[0]) {
                  var opValue = opName.optionvalues[l];
                  let pos = find(otherSKUs, opValue.value, "optionvalue");
                  opValue["banned"] = pos < 0;
                }
              }
            }

            if (opId == 0) {
              var sku = arrSKUs[0];
              var path = arrPaths[0];
              if (path == null) {
                path = arrPaths2[0] + sku + "_1" + getImgagesExtension();
              }
              key.objdata["path"] = path;
            }
          }
        } else if (action === "favorite") {
          var fav = await addWishlist(data[1], _toast);
          newObjconfig["favorite"] = fav.errorcode === "ADD" ? true : false;
        } else if (action === "increase") {
          newQuantity++;
          newObjconfig["quantitySelected"] = newQuantity;
        } else if (action === "quantity") {
          var quantitySelected = _event.target.value;
          let val = validateNumber(quantitySelected);
          if (val) {
            newObjconfig["quantitySelected"] = parseInt(quantitySelected);
          } else {
            newObjconfig["quantitySelected"] = "";
          }
        } else if (action === "decrease") {
          if (newQuantity > 0) {
            newQuantity--;
          }
          newObjconfig["quantitySelected"] = newQuantity < 1 ? 1 : newQuantity;
        } else if (action === "add") {
          if (key.objdata.options && key.objdata.options.length > 0) {
            if (key.objconfig.optionsselected) {
              if (
                key.objdata.options.length ==
                key.objconfig.optionsselected.length
              ) {
                var arrSKUs = [];
                var arrQuantities = [];
                var opSelected = [];
                var arrNames = [];

                var arrPrices = [];
                var arrPricesD = [];
                var arrPricesC1 = [];
                var arrPricesC2 = [];

                key.objconfig.optionsselected.map(function (o) {
                  opSelected.push(o.optionvalue);
                });
                for (let o in opSelected) {
                  //	console.log("CHECK", opSelected[o])
                  var arrSKUs = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "sku",
                    opSelected[o],
                    arrSKUs
                  );
                  var arrQuantities = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "quantity",
                    opSelected[o],
                    arrQuantities
                  );
                  var arrNames = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "specificname",
                    opSelected[o],
                    arrNames
                  );

                  var arrPrices = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "price",
                    opSelected[o],
                    arrPrices
                  );
                  var arrPricesD = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "newpriced",
                    opSelected[o],
                    arrPricesD
                  );
                  var arrPricesC1 = searchArray(
                    key.objdata.quantitiess,
                    "optionvalue",
                    "newpricec1",
                    opSelected[o],
                    arrPricesC1
                  );
                  var arrPricesC2 = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "newpricec2",
                    opSelected[o],
                    arrPricesC2
                  );
                }

                //	console.log("optionsSelected",optionsSelected)
                /*	console.log("arrSKUs",arrSKUs)

								console.log("arrNames",arrNames)
								console.log("arrPrices",arrPrices)
								console.log("arrPricesD",arrPricesD)

								console.log("arrPricesC1",arrPricesC1)
								console.log("arrPricesC2",arrPricesC2)
							*/
                var sku = arrSKUs[0];
                var quantity = arrQuantities[0];
                var price = arrPrices[0];
                var name = arrNames[0];

                for (let n in arrNames) {
                  if (arrNames[n] != null && name == null) {
                    name = arrNames[n];
                  }
                }

                if (
                  parseFloat(quantity) <
                  parseFloat(key.objconfig.quantitySelected)
                ) {
                  showWarning(_toast, getDefValues().limitedExceeded);
                } else {
                  var skus = [];
                  let pos = find(key.objdata.quantities, sku, "sku");
                  if (key.objdata.quantities[pos].skus) {
                    skus = key.objdata.quantities[pos].skus;
                  }

                  var product = {
                    productsku: sku,
                    productid: key.objdata.productid,
                    label: name,
                    categoryid: key.objdata.categoryid,
                    category: key.objdata.category,
                    productpath: key.objdata.productpath,
                    path: key.objdata.path,
                    priceold: price,
                    maxQuantity: key.objdata.maxQuantity,
                    options: key.objdata.options,
                    quantities: key.objdata.quantities,
                    features: key.objdata.quantities,
                    sku: sku,
                    optionsselected: key.objconfig.optionsselected,
                    skus: skus,
                  };
                  if (data[2]) {
                    if (data[2] != "") {
                      product["eventid"] = data[2];
                    }
                  }
                  dataCart = await addShopCart(product, newQuantity, _toast);
                  //console.log("dataCart", dataCart);
                }
              } else {
                redirect = true;
                showWarning(_toast, getDefValues().selecteOptionsLabel);
              }
            } else {
              redirect = true;
              showWarning(_toast, getDefValues().selecteOptionsLabel);
            }
          }
        } else if (action === "addEventList") {
          //console.log(key.objdata.options , key.objdata.options.length )
          if (key.objdata.options && key.objdata.options.length > 0) {
            isEvent = true;
            if (key.objconfig.optionsselected) {
              if (
                key.objdata.options.length ==
                key.objconfig.optionsselected.length
              ) {
                var arrSKUs = [];
                var arrQuantities = [];
                var opSelected = [];
                var arrNames = [];

                var arrPrices = [];
                var arrPricesD = [];
                var arrPricesC1 = [];
                var arrPricesC2 = [];

                key.objconfig.optionsselected.map(function (o) {
                  opSelected.push(o.optionvalue);
                });
                for (let o in opSelected) {
                  //	console.log("CHECK", opSelected[o])
                  var arrSKUs = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "sku",
                    opSelected[o],
                    arrSKUs
                  );
                  var arrQuantities = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "quantity",
                    opSelected[o],
                    arrQuantities
                  );
                  var arrNames = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "specificname",
                    opSelected[o],
                    arrNames
                  );
                  var arrPrices = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "price",
                    opSelected[o],
                    arrPrices
                  );
                  var arrPricesD = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "newpriced",
                    opSelected[o],
                    arrPricesD
                  );
                  var arrPricesC1 = searchArray(
                    key.objdata.quantitiess,
                    "optionvalue",
                    "newpricec1",
                    opSelected[o],
                    arrPricesC1
                  );
                  var arrPricesC2 = searchArray(
                    key.objdata.quantities,
                    "optionvalue",
                    "newpricec2",
                    opSelected[o],
                    arrPricesC2
                  );
                }

                var sku = arrSKUs[0];
                var quantity = arrQuantities[0];
                var price = arrPrices[0];
                var name = arrNames[0];
                for (let n in arrNames) {
                  if (arrNames[n] != null && name == null) {
                    name = arrNames[n];
                  }
                }

                if (
                  parseFloat(quantity) <
                  parseFloat(key.objconfig.quantitySelected)
                ) {
                  showWarning(_toast, getDefValues().limitedExceeded);
                } else {
                  var skus = [];
                  let pos = find(key.objdata.quantities, sku, "sku");
                  if (key.objdata.quantities[pos].skus) {
                    skus = key.objdata.quantities[pos].skus;
                  }
                  var formData = {
                    sku: sku,
                    productid: key.objdata.productid,
                    eventid: data[2],
                    quantity: newQuantity,
                  };
                  var resp = await callApi(
                    "frontend",
                    "setProductEvent",
                    formData
                  );
                  eventQty = newQuantity;
                  if (resp.success) {
                    showMessage(
                      _toast,
                      getDefValues().successfullyAddedToEventListLabel
                    );
                  } else {
                    showError(
                      _toast,
                      getDefValues().errorAddedToEventListLabel
                    );
                  }
                }
              } else {
                showWarning(_toast, getDefValues().selecteOptionsEventLabel);
              }
            } else {
              showWarning(_toast, getDefValues().selecteOptionsEventLabel);
            }
          } else {
            //		console.log("falta")
          }
        } else {
          console.log(data);
        }
        //console.log("newObjconfig",newObjconfig)
        //console.log("productData",key.objdata)
        key["objconfig"] = newObjconfig;
        key["body"] = (
          <Product
            name={key.name}
            productData={key.objdata}
            productConfig={newObjconfig}
          />
        );
      }
    }

    var rsp = {
      success: true,
      redirect,
      dataCart,
      _cardData,
      message: "OK",
      selectedProductID: productId,
      selectedPath: productpath,
      selectedCategory: category,
      isEvent: isEvent,
      eventQty: eventQty,
    };
    return rsp;
  } catch (Excep) {
    console.log(">>>>>>ERR", Excep);
    return {
      success: false,
      redirect: false,
      dataCart: null,
      _cardData,
      message: Excep,
      selectedProductID: productId,
      selectedPath: productpath,
      selectedCategory: category,
    };
  }
}

export async function updateEventList(
  _data,
  _productsku,
  _action,
  _toast,
  _value
) {
  try {
    console.log("updateEventList", _data, _action, _productsku);

    var data;
    var isValid = true;

    if (_data.products.length > 0) {
      var master = null;
      var combos = [];
      var combosTotal = [];
      //var data = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession'))
      var data = _data;
      //console.log("tempCartdata", data)
      var curProducts = data.products;

      //console.log(curProducts)
      var id = data.id;
      var userID = data.userID;
      var position = find(curProducts, _productsku, "productsku");

      var message = getDefValues().successfullyChangedItemLabel;
      var needCommit = data.needCommit ? data.needCommit : false;
      var deleted = false;
      var skuToDelete;

      //console.log(position)

      if (position >= 0) {
        if (!curProducts[position].quantity) {
          isValid = false;
          message = getDefValues().minimumNotSelected;
        } else {
          if (curProducts[position].quantity == 0) {
            isValid = false;
            message = getDefValues().minimumNotSelected;
          }
        }
        if (_action === "del") {
          if (curProducts[position].quantity_assigned > 0) {
            message = getDefValues().cantdeletedProduct;
            isValid = false;
          } else {
            needCommit = false;
            message = getDefValues().successfullyRemoveItemLabel;
            deleted = true;
            skuToDelete = curProducts[position].productsku;
          }
        } else if (_action === "increase") {
          if (
            curProducts[position].quantity + 1 <=
            curProducts[position].maxQuantity
          ) {
            curProducts[position].quantity++;
            curProducts[position]["prequantity"] = null;
            needCommit = false;
            curProducts[position]["prequantity"] = null;
            curProducts[position]["showUpdate"] = false;
          } else {
            isValid = false;
            message = getDefValues().limitedExceeded;
          }
        } else if (_action === "decrease") {
          //console.log(curProducts[position])
          if (curProducts[position].quantity > 1) {
            console.log(
              curProducts[position].quantity - 1,
              curProducts[position].quantity_assigned
            );

            if (
              curProducts[position].quantity - 1 <
              curProducts[position].quantity_assigned
            ) {
              message = getDefValues().cantdeletedProduct;
              isValid = false;
            } else {
              curProducts[position].quantity--;
              curProducts[position]["prequantity"] = null;
              needCommit = false;
              curProducts[position]["prequantity"] = null;
              curProducts[position]["showUpdate"] = false;
            }
          } else {
            isValid = false;
            message = getDefValues().minimumNotSelected;
          }
        } else if (_action === "quantity") {
          let val = validateNumber(_value);
          if ((val = _value && parseInt(_value) > 0)) {
            if (curProducts[position]["quantity"] == _value) {
              curProducts[position]["prequantity"] = null;
              curProducts[position]["showUpdate"] = false;
              needCommit = true;
            } else {
              curProducts[position]["prequantity"] = parseInt(_value);
              curProducts[position]["showUpdate"] = true;
              needCommit = true;
            }
          } else {
            curProducts[position]["prequantity"] = "";
            curProducts[position]["showUpdate"] = false;
            needCommit = true;
          }
        } else if (_action === "update") {
          if (
            curProducts[position].prequantity <=
            curProducts[position].maxQuantity
          ) {
            curProducts[position].quantity =
              curProducts[position]["prequantity"];
          } else {
            isValid = false;
            message = getDefValues().limitedExceeded;
          }
          //curProducts[position].quantity = curProducts[position]["prequantity"]

          curProducts[position]["prequantity"] = null;
          curProducts[position]["showUpdate"] = false;
          needCommit = false;
        } else if (_action === "showComment") {
          //curProducts[position]["commentsku"]=""
          curProducts[position]["showComment"] = curProducts[position][
            "showComment"
          ]
            ? false
            : true;
          needCommit = true;
        } else if (_action === "commentsku") {
          curProducts[position]["commentsku"] = _value + "";
          curProducts[position]["showComment"] = true;
          needCommit = true;
        } else if (_action === "saveComment") {
          needCommit = false;
        }

        if (!isValid) {
          showWarning(_toast, message);
          data = null;
        } else {
          if (deleted) {
            let formData = {
              productsku: curProducts[position]["productsku"],
              eventid: curProducts[position]["eventid"],
            };
            let resp = await callApi(
              "frontend",
              "deleteProductEvent",
              formData
            );

            if (resp.success) {
              curProducts.splice(position, 1);
              showMessage(
                _toast,
                getDefValues().successfullyRemoveEventItemLabel
              );
            } else {
              data = null;
              showError(_toast, resp.message);
            }
          } else {
            if (!needCommit) {
              let formData = {
                productsku: curProducts[position]["productsku"],
                commentsku: curProducts[position]["commentsku"],
                eventid: curProducts[position]["eventid"],
                quantity: curProducts[position]["quantity"],
              };
              let resp = await callApi(
                "frontend",
                "updateProductEvent",
                formData
              );

              if (resp.success) {
                showMessage(
                  _toast,
                  getDefValues().successfullyEventItemUpdatedLabel
                );
              } else {
                data = null;
                showError(_toast, resp.message);
              }
            }
          }

          data = {
            needCommit: needCommit,
            products: curProducts,
          };
        }
      }
      return data;
    } else {
      return null;
    }
  } catch (Excep) {
    console.log(">>>>>>", Excep);
    showError(_toast, "SHOPCART Excep:" + Excep);
    return null;
  }
}

export async function clearCart() {
  localStorage.removeItem(process.env.REACT_APP_ORG_NAME + "_activeCartV2");
  setCookie(
    process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
    JSON.stringify([]),
    0.0001
  );
  var data = [];
  return data;
}

export async function finishCart() {
  //localStorage.removeItem(process.env.REACT_APP_ORG_NAME+'_activeCartV2');
  //setCookie(process.env.REACT_APP_ORG_NAME+'_cartSessionsV2',  JSON.stringify([]) , 0.0001 )
  var tempCart = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_activeCartV2"
  );
  var curCart = JSON.parse(tempCart);
  let dataC = JSON.parse(
    getCookie(process.env.REACT_APP_ORG_NAME + "_cartSessionsV2")
  );
  let pos = find(dataC, curCart.id, "id");
  console.log(curCart.id, pos);

  dataC.splice(pos, 1);
  //let resp = await callApi ( "frontend","deleteCart", {id  : curCart.id} )

  console.log("final remove", dataC);

  var _data = {
    id: curCart.id,
    userid: getLoginInfo().userId ? getLoginInfo().userId : -1,
    status: "PAID",
    //bstatus      : "FINISHED"
  };
  await callApi("frontend", "updateCart", _data);

  setCookie(
    process.env.REACT_APP_ORG_NAME + "_cartSessionsV2",
    JSON.stringify(dataC),
    24 * 7
  );
  localStorage.removeItem(process.env.REACT_APP_ORG_NAME + "_activeCartV2");

  return true;
}

export async function getAcviteCartsByUserId(_userid) {
  var id;
  var data = { storeid: getCurrentStore(), userid: _userid };
  var respCart = await callApi("frontend", "getAcviteCartsByUserId", data);
  //console.log("!!!!getAcviteCartsByUserId", respCart)
  if (respCart.success) {
    id = respCart.id;
  }

  return id;
}
/*
export async function getShopCart (justLoad){

	var resp = {success:false,
							shopCart:null}
	var rows=[];
	var finalRows=[]
	var meta={};
	try{
		var store = await getStoresInfo ()
		var data= { page: { current:1, size:1000 },
							   query: getLoginInfo().userId?getLoginInfo().userId+"":"xxxxxxxx" ,
							   filters : {
							            all: [
							               { userid  : getLoginInfo().userId?getLoginInfo().userId+"":"xxxxxxxx" },
							               { storeid : getCurrentStore()+"" },
							             
							            ]
							    }, 
								precision: 10
							  };
		var shopCart;
		
		var isCart = false
		var curCart = getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession')
		if (curCart){
			var dataC = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession'))
			if (dataC){
				isCart = true
			}	
		}
		if (isCart && !justLoad){
		 	
			data= { page: { current:1, size:1000 },
						   query: getLoginInfo().userId?getLoginInfo().userId+"":"xxxxxxxx" ,
						   filters : {
						            all: [
						               { userid  : getLoginInfo().userId?getLoginInfo().userId+"":"xxxxxxxx" },
						               { storeid : getCurrentStore()+"" },
						               { status  : "ENABLED" }
						            ]
						    }, 
							precision: 10
						  };
			const axios = require('axios');
			await axios
	    .post(
	       	process.env.REACT_APP_ENDPOINTBASE+"/api/as/v1/engines/shopcarts-engine/search",
	        data,
	        {headers: {
					'Access-Control-Allow-Origin': '*',
					'origin':'x-requested-with',
            'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+process.env.REACT_APP_SEARCHKEY

				}}
	    )
	    .then(response => response.data)
	    .then(async(data) => { 
				if (data.results.length>0){
					for (let s in data.results){
	    			let key = data.results[s]						
	    			console.log("removing", key.id.raw)
	    			var _data = {
							 id : key.id.raw,
							 userid       : key.userid.raw,
							 storeid      : key.storeid.raw,
							 data         : key.data.raw,
							 status       : "REPLACED",
								}
						var respCart = await callApi ( "frontend","setCart", _data )
						console.log("_data",_data)
		    	}
		    }
			})
	    .catch(function(error) {
	      console.log(">>>>>>>> Error api",error);
	      resp = {success:false, shopCart:null}
	    });
	    
	    var dataC = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession'))
			var _data = {
				 id : dataC.id,
				 userid       : getLoginInfo().userId,
				 storeid      : getCurrentStore(),
				 data         : dataC, 
			}
			var respCart = await callApi ( "frontend","setCart", _data )
			resp = {success: respCart.success,
						  shopCart:dataC}
			console.log(" set elastic new cart")
	    
		}else{
			const axios = require('axios');
			await axios
		    .post(
		       	process.env.REACT_APP_ENDPOINTBASE+"/api/as/v1/engines/shopcarts-engine/search",
		        data,
		        {headers: {
						'Access-Control-Allow-Origin': '*',
						'origin':'x-requested-with',
              'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+process.env.REACT_APP_SEARCHKEY

					}}
		    )
		    .then(response => response.data)
		    .then(async(data) => {
		    	if (data.results.length>0){
		    		
					 
						for (let s in data.results){
							let key = data.results[s]							
							let storeid  =key.storeid?key.storeid.raw:null
							//console.log("check",storeid, "w", getCurrentStore())
							if ( storeid == getCurrentStore()){
								//console.log("CHECK cart to delete", key.id.raw )
								try{
									var dataC = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSession'))
									if (dataC){
										
										if (key.id.raw == dataC.id && key.status.raw !="ENABLE" ){
											//console.log("clear by disabled" , key.id.raw , dataC.id, key.status.raw )
											var cart = await clearCart()
											resp = {success:true,
													  shopCart:cart}
										}
									}
								}catch(Ex){
									
								}
							}
						}
						
						for (let s in data.results){
							let key = data.results[s]							
							let storeid  =key.storeid?key.storeid.raw:null
							//console.log("check",storeid, "w", getCurrentStore())
							if ( storeid == getCurrentStore()){
								
								var _date = key.updatedate.raw?key.updatedate.raw:1
								var _curdate = new Date() 	
								var curdate= 	_curdate.getTime() 			
								_date = parseInt(_date) + 86400000
								//console.log("CHECK cart", key.status.raw, key.id.raw, _date, curdate )
								
								if (_date>=curdate ){ 
								//	 console.log("dale ok")
									if ( key.status.raw =="ENABLED" ){
								//		console.log("founded", key)
										var cart = JSON.parse(key.data.raw)
										setCookie(process.env.REACT_APP_ORG_NAME+'_cartSession',  JSON.stringify(cart), 24 )
										resp = {success:true,
													  shopCart:cart}
									}
										
								}
							} 
						}
					}else{
						resp = {success:false,
									  shopCart:null}
					}
		    })
		    .catch(function(error) {
		      console.log(">>>>>>>> Error api",error);
		      resp = {success:false, shopCart:null}
		    });
		}
		return resp
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
			resp={
			success:false, 
			shopCart:null
		}
 		return resp
	}
}
*/

export function getToken() {
  var token = "";
  try {
    token = getLoginInfo().token;
  } catch (Excep) {
    token = "";
  } finally {
    return token;
  }
}

export function getCurrency() {
  if (
    !localStorage.getItem("def_currency") ||
    localStorage.getItem("def_currency") === "undefined" ||
    localStorage.getItem("def_currency") == null
  ) {
    localStorage.setItem("def_currency", process.env.REACT_APP_DEF_CURRENCY);
  }
  return localStorage.getItem("def_currency");
}

export function getLanguage() {
  if (
    !localStorage.getItem("def_language") ||
    localStorage.getItem("def_language") === "undefined" ||
    localStorage.getItem("def_language") == null
  ) {
    localStorage.setItem("def_language", process.env.REACT_APP_DEF_LANGUAGE);
  }
  return localStorage.getItem("def_language");
}

export function getImgagesExtension() {
  return process.env.REACT_APP_DEF_IMG_EXTENSION;
}

export function getHomeLayout() {
  if (
    !localStorage.getItem(process.env.REACT_APP_ORG_NAME + "homeLayout") ||
    localStorage.getItem(process.env.REACT_APP_ORG_NAME + "homeLayout") ==
      "undefined" ||
    localStorage.getItem(process.env.REACT_APP_ORG_NAME + "homeLayout") == null
  ) {
    localStorage.setItem(
      process.env.REACT_APP_ORG_NAME + "homeLayout",
      process.env.REACT_APP_DEF_HOME_LAYOUT
    );
  }
  return localStorage.getItem(process.env.REACT_APP_ORG_NAME + "homeLayout");
}

export function getCurrentCatalog() {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );
  var storesInfo = {};
  if (tempStores) {
    storesInfo = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew")
    );
    return storesInfo.selectedCatalogId ? storesInfo.selectedCatalogId : 0;
  } else return null;
}

export function getImagePath(ImagePath, isSku) {
  try {
    if (isSku) {
      let l = ImagePath.lastIndexOf(".");
      const ext = "." + ImagePath.substring(l + 1);
      ImagePath = ImagePath.replace(ext, "_mini" + ext);
    }

    if (!ImagePath.endsWith(getImgagesExtension())) {
      if (ImagePath.indexOf(".") < 0) {
        ImagePath = ImagePath + getImgagesExtension();
      }
    }

    if (ImagePath.indexOf("http") >= 0) {
      return ImagePath;
    } else if (ImagePath.indexOf("/static") >= 0) {
      return ImagePath;
    } else {
      return folderImages + "" + ImagePath;
    }
  } catch (Excep) {
    return "";
  }
}

export function getFilePath(ImagePath) {
  try {
    if (ImagePath.indexOf("http") >= 0) {
      return ImagePath;
    } else if (ImagePath.indexOf("/static") >= 0) {
      return ImagePath;
    } else {
      return folderFiles + "" + ImagePath;
    }
  } catch (Excep) {
    return "";
  }
}

export async function getCoordinatesStore() {
  var tempStores = localStorage.getItem(
    process.env.REACT_APP_ORG_NAME + "_storesInfoNew"
  );
  var storesInfo = await getStoresInfo();
  var pos = find(storesInfo.stores, storesInfo.selectedStoreId, "storeId");

  return storesInfo.stores[pos];
}

export function getRowsPaginationValues() {
  var rows = [];
  if (localStorage.getItem("def_language") == 3) {
    rows = [
      { value: 15, text: "Ver 15 Elementos por p\u00E1gina" },
      { value: 30, text: "Ver 30 Elementos por p\u00E1gina" },
      { value: 45, text: "Ver 45 Elementos por p\u00E1gina" },
      { value: 60, text: "Ver 60 Elementos por p\u00E1gina" },
    ];
  }

  return rows;
}
export function getSortMethods() {
  var rows = [];

  if (localStorage.getItem("def_language") == 3) {
    rows = [
      { value: "new", text: "M\u00E1s reciente" },
      { value: "minorprice", text: "Menor Precio" },
      { value: "majorprice", text: "Mayor Precio" },
      { value: "ranking", text: "M\u00E1s vendidos" },
      { value: "AZ", text: "A - Z" },
      { value: "ZA", text: "Z - A" },
      { value: "discount", text: "Mejor Descuento" },
    ];
  }

  return rows;
}

export function getParamValues() {
  var values = {};

  values = {
    maxItemsFilter: 5,
    loginMenuoptions: [
      { link: "/customer/account/login", label: "Iniciar Sesi\u00F3n" },
      { link: "/customer/account/create", label: "Registrarme" },
    ],
    eventMenuoptions: [
      { link: "/customer/event/create-event", label: "Armar lista" },
      {
        link: "/customer/event/manage?step=info",
        action: "checkEventStatus",
        label: "Editar info. de evento",
        validationValue: "haveEvent",
      },
      {
        link: "/customer/event/manage?step=list",
        action: "checkEventStatus",
        label: "Editar lista de regalos",
        validationValue: "haveEvent",
      },
      {
        link: "/customer/event/event-details",
        action: "checkEventStatus",
        label: "Comprar Regalo",
      },
    ],
    loggedMenuoptions: [
      { link: "#", label: "Hola" },
      { link: "/sales/order/history", label: "Pedidos" },
      { link: "/customer/account", label: "Mis Datos" },
      //{	link:"/customer/event/manage", label:"Mi Lista de Regalos"},
      { link: "/wishlist/index/index", label: "Mi lista de deseos" },
      { link: "/customer/account/logout", label: "Cerrar Sesi\u00F3n" },
    ],

    shippingMenuoptions: [
      {
        type: "TEXT",
        size: "col-12",
        height: "mt-4",
        label: "Conoce el detalle de tu compra aqu\u00ED!",
        link: "/sales/order/history",
      },
      {
        type: "IMAGE",
        size: "col-6",
        height: "mt-4",
        label: "Rastrea tu pedido",
        align: "right",
        width: "75px",
        image:
          "https://cidev-media.s3.amazonaws.com/iconos/header_menu/envio.png",
        link: "/customer/tracking",
      },
      {
        type: "IMAGE",
        size: "col-6",
        height: "mt-4",
        label: "Necesitas ayuda?",
        align: "left",
        width: "75px",
        image:
          "https://cidev-media.s3.amazonaws.com/iconos/header_menu/preguntas.png",
        link: "/pages/preguntas_frecuentes",
      },
      {
        type: "BUTTON",
        size: "col-12",
        height: "mt-4",
        label: "IR A MIS COMPRAS",
        align: "center",
        link: "/sales/order/history",
      },
    ],

    mobileMenuoptions: [
      {
        label: "Revisa tu compra aqu\u00ED",
        link: "/customer/tracking",
        image: "/prod/icons/ico_seguimiento_menu.svg",
        type: "TOP",
        needlogin: "NO",
      },
      /*
			{ label: "Arma tu lista" , link:"/customer/event/create-event" , image: "/prod/icons/event.png", type:"TOP", needlogin:"NO"},
			{ label: "Comprar regalo" , link:"/customer/event/event-details" , image: "/prod/icons/event.png", type:"TOP", needlogin:"NO"},
*/
      {
        label: "Nuestras Tiendas",
        link: "/pages/tiendas",
        image: "/prod/icons/ico_tienda_menu.svg",
        type: "TOP",
        needlogin: "NO",
      },
      //{ label: "Mi lista de Regalos", link:"/customer/event/manage", type:"TOP", needlogin:"YES" },
      //	{ label: "Comprar Regalo", link:"/customer/event/event-details", type:"TOP", needlogin:"NO" },
      {
        label: "Cerrar Sesi\u00F3n",
        link: "/customer/account/logout",
        type: "BOTTOM",
        needlogin: "YES",
      },
    ],
  };

  if (getLoginInfo().userId != null) {
    values.mobileMenuoptions.push();
  }

  return values;
}

export function validateNumber(_fieldValue) {
  try {
    if (isNaN(parseInt(_fieldValue))) {
      return false;
    } else {
      return true;
    }
  } catch (Excep) {
    //console.log(">>>>>>>>",Excep)
    return false;
  }
}
function validateField(_fieldObj, _fieldName) {
  let isInvalid = false;
  let resp = { isInvalid: isInvalid, message: null };
  let message = "";
  if (_fieldObj) {
    if (_fieldObj.value === "" && _fieldObj.notRequired === undefined) {
      isInvalid = true;
      message = _fieldObj.validationlabel
        ? getDefValues().theFieldLabel +
          " " +
          _fieldObj.label +
          " " +
          _fieldObj.validationlabel
        : getDefValues().theFieldLabel +
          " " +
          _fieldObj.label +
          " " +
          getDefValues().isEmptyLabel;
    } else {
      if (_fieldObj.type === "PASSWORD") {
        if (_fieldObj.value.length < 8) {
          message += getDefValues().minimunLengthLabel;
          isInvalid = true;
        }
      } else if (_fieldObj.type === "CHECKBOX") {
        if (_fieldObj.validationtype !== "") {
          if (_fieldObj.value.length <= 0) {
            message += _fieldObj.validationlabel
              ? _fieldObj.validationlabel
              : "Debe aprobar los terminos y condiciones.";
            isInvalid = true;
          }
        }
      } else {
        if (_fieldObj.validationtype) {
          if (
            _fieldObj.validationtype.toLowerCase() === "email" &&
            (_fieldObj.value + "").length > 0
          ) {
            var emailRegex =
              /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
            if (!emailRegex.test(_fieldObj.value)) {
              message += _fieldObj.validationlabel
                ? getDefValues().theFieldLabel +
                  " " +
                  _fieldObj.label +
                  " " +
                  _fieldObj.validationlabel
                : _fieldObj.label + " Formato de Correo Incorrecto";
              isInvalid = true;
            }
          } else if (_fieldObj.validationtype.toLowerCase() === "date") {
            if (_fieldObj.dateFormat === "yyyymmdd") {
              if (isNaN(_fieldObj.value) || _fieldObj.value.length !== 8) {
                isInvalid = true;
                message += _fieldObj.validationlabel
                  ? getDefValues().theFieldLabel +
                    " " +
                    _fieldObj.label +
                    " " +
                    _fieldObj.validationlabel
                  : _fieldObj.label +
                    " Incorrect format(" +
                    _fieldObj.dateFormat +
                    ").(Correct Format yyyymmdd).";
              } else {
                //let hoy = new Date();
                let anio = _fieldObj.value.substr(0, 4);
                let mes = _fieldObj.value.substr(4, 2);
                let dia = _fieldObj.value.substr(6, 2);
                let fechaNaci = new Date(
                  anio + "/" + mes + "/" + dia + " 00:00:00"
                );
                if (fechaNaci.toString() === "Invalid Date") {
                  message += _fieldObj.validationlabel
                    ? getDefValues().theFieldLabel +
                      " " +
                      _fieldObj.label +
                      " " +
                      _fieldObj.validationlabel
                    : _fieldObj.label +
                      " Incorrect format(" +
                      _fieldObj.dateFormat +
                      ").(Correct Format yyyymmdd).";
                  isInvalid = true;
                } else {
                  _fieldObj["returnedValue"] = fechaNaci;
                }
              }
            } else if (_fieldObj.dateFormat === "yyyymmddhh24miss") {
              if (isNaN(_fieldObj.value) || _fieldObj.value.length !== 14) {
                isInvalid = true;
                message += _fieldObj.validationlabel
                  ? getDefValues().theFieldLabel +
                    " " +
                    _fieldObj.label +
                    " " +
                    _fieldObj.validationlabel
                  : _fieldObj.label +
                    " Incorrect format(" +
                    _fieldObj.dateFormat +
                    ").(Correct Format yyyymmddhh24miss).";
              } else {
                //let hoy = new Date();
                let anio = _fieldObj.value.substr(0, 4);
                let mes = _fieldObj.value.substr(4, 2);
                let dia = _fieldObj.value.substr(6, 2);

                let hora = _fieldObj.value.substr(8, 2);
                let minuto = _fieldObj.value.substr(10, 2);
                let segundo = _fieldObj.value.substr(12, 2);
                let Fecha = new Date(
                  anio +
                    "/" +
                    mes +
                    "/" +
                    dia +
                    " " +
                    hora +
                    ":" +
                    minuto +
                    ":" +
                    segundo
                );
                if (Fecha.toString() === "Invalid Date") {
                  message += _fieldObj.validationlabel
                    ? getDefValues().theFieldLabel +
                      " " +
                      _fieldObj.label +
                      " " +
                      _fieldObj.validationlabel
                    : _fieldObj.label +
                      " Incorrect format(" +
                      _fieldObj.dateFormat +
                      ").(Correct Format yyyymmddhh24miss).";
                  isInvalid = true;
                } else {
                  _fieldObj["returnedValue"] = Fecha;
                }
              }
            } else if (_fieldObj.dateFormat === "yyyymmdd hh24:mi:ss") {
              if (_fieldObj.value.length !== 17) {
                isInvalid = true;
                message += _fieldObj.validationlabel
                  ? getDefValues().theFieldLabel +
                    " " +
                    _fieldObj.label +
                    " " +
                    _fieldObj.validationlabel
                  : _fieldObj.label +
                    " Incorrect format(" +
                    _fieldObj.dateFormat +
                    ").(Correct Format yyyymmdd hh24:mi:ss).";
              } else {
                //let hoy = new Date();
                let anio = _fieldObj.value.substr(0, 4);
                let mes = _fieldObj.value.substr(4, 2);
                let dia = _fieldObj.value.substr(6, 2);

                let hora = _fieldObj.value.substr(9, 2);
                let minuto = _fieldObj.value.substr(12, 2);
                let segundo = _fieldObj.value.substr(15, 2);

                let Fecha = new Date(
                  anio +
                    "/" +
                    mes +
                    "/" +
                    dia +
                    " " +
                    hora +
                    ":" +
                    minuto +
                    ":" +
                    segundo
                );
                if (Fecha.toString() === "Invalid Date") {
                  message += _fieldObj.validationlabel
                    ? getDefValues().theFieldLabel +
                      " " +
                      _fieldObj.label +
                      " " +
                      _fieldObj.validationlabel
                    : _fieldObj.label +
                      " Incorrect format(" +
                      _fieldObj.dateFormat +
                      ").(Correct Format yyyymmdd hh24:mi:ss).";
                  isInvalid = true;
                } else {
                  _fieldObj["returnedValue"] = Fecha;
                }
              }
            }
          } else if (
            _fieldObj.validationtype.toLowerCase() === "number" &&
            (_fieldObj.value + "").length > 0
          ) {
            if (isNaN(parseInt(_fieldObj.value))) {
              message += _fieldObj.validationlabel
                ? getDefValues().theFieldLabel +
                  " " +
                  _fieldObj.label +
                  " " +
                  _fieldObj.validationlabel
                : _fieldObj.label + getDefValues().invalidNumberLabel;
              isInvalid = true;
            } else {
              if (_fieldObj.maxvalue && _fieldObj.value != "") {
                if (parseInt(_fieldObj.value) > _fieldObj.maxvalue) {
                  message += _fieldObj.validationlabel
                    ? getDefValues().theFieldLabel +
                      " " +
                      _fieldObj.label +
                      " " +
                      _fieldObj.validationlabel
                    : _fieldObj.label +
                      " exceeds the maximum value." +
                      "(Current:" +
                      _fieldObj.value +
                      ",Max:" +
                      _fieldObj.maxvalue +
                      ").";
                  isInvalid = true;
                }
              }

              if (_fieldObj.minvalue && _fieldObj.value != "") {
                if (parseInt(_fieldObj.value) < _fieldObj.minvalue) {
                  message += _fieldObj.validationlabel
                    ? getDefValues().theFieldLabel +
                      " " +
                      _fieldObj.label +
                      " " +
                      _fieldObj.validationlabel
                    : _fieldObj.label +
                      " does not satisfy the minimum value." +
                      "(Current:" +
                      _fieldObj.value +
                      ",Min:" +
                      _fieldObj.minvalue +
                      ").";
                  isInvalid = true;
                }
              }
            }
          } else if (_fieldObj.validationtype.toLowerCase() === "text") {
          } else if (
            _fieldObj.validationtype.toLowerCase() === "array" &&
            _fieldObj.notRequired === undefined
          ) {
            if (!Array.isArray(_fieldObj.value)) {
              isInvalid = true;
              message += _fieldObj.label + " does not an array.";
            } else {
              if (_fieldObj.value.length < 1) {
                isInvalid = true;
                message += _fieldObj.label + " must have at least one record.";
              }
            }
          } else if (
            _fieldObj.validationtype.toLowerCase() === "phone" &&
            (_fieldObj.value + "").length > 0
          ) {
            if (isNaN(_fieldObj.value)) {
              message += _fieldObj.validationlabel
                ? getDefValues().theFieldLabel +
                  " " +
                  _fieldObj.label +
                  " " +
                  _fieldObj.validationlabel
                : _fieldObj.label + getDefValues().invalidPhoneNumberLabel;
              isInvalid = true;
            } else {
              /*
			      	if (_fieldObj.value < 1000000 || _fieldObj.value > 80000000){
			      		message += _fieldObj.label+" is not a valid phone number";
			          isInvalid=true;
			      	}*/
            }
          }
          if (_fieldObj.maxlength && _fieldObj.value != "") {
            if (_fieldObj.value.length > _fieldObj.maxlength) {
              message += _fieldObj.validationlabel
                ? getDefValues().theFieldLabel +
                  " " +
                  _fieldObj.label +
                  " " +
                  _fieldObj.validationlabel
                : getDefValues().invalidMaxLengthLabel;
              isInvalid = true;
            }
          }
          if (_fieldObj.minlength && _fieldObj.value != "") {
            if (_fieldObj.value.length < _fieldObj.minlength) {
              message += _fieldObj.validationlabel
                ? getDefValues().theFieldLabel +
                  " " +
                  _fieldObj.label +
                  " " +
                  _fieldObj.validationlabel
                : getDefValues().invalidMinLengthLabel;
              isInvalid = true;
            }
          }

          if (_fieldObj.allowedValues) {
            let contiene = false;
            for (let i in _fieldObj.allowedValues) {
              if (_fieldObj.allowedValues[i] == _fieldObj.value) {
                contiene = true;
              }
            }
            if (!contiene) {
              message +=
                _fieldObj.label +
                " value not allowed , it is only allowed : " +
                _fieldObj.allowedValues +
                ".";
              isInvalid = true;
            }
          }
        }
      }
    }
  }
  resp = { isInvalid: isInvalid, message: message };
  return resp;
}

export async function validateForm(formData, _toast, _event, _formName) {
  var isValid = true;
  var error = "";
  //var message=""
  var message = [];
  var resp = { formData: formData, isValid: isValid };
  try {
    var field_value = _event ? _event.target.value : undefined;
    var field_name = _event ? _event.target.name : undefined;
    if (_event) {
      if (_event.target.name) {
        field_value = _event.target.value;
        field_name = _event.target.name;
      } else {
        field_value = _event.target.parentNode.value;
        field_name = _event.target.parentNode.name;
      }
    }
    if (field_name) {
      if (field_name.startsWith("togglePass")) {
        formData[field_name.replace("togglePass", "")].showPass = formData[
          field_name.replace("togglePass", "")
        ].showPass
          ? false
          : true;
        //console.log("tgg",formData)
      } else {
        for (let i in Object.keys(formData)) {
          let field = Object.keys(formData)[i];
          if (formData[field].type === "CHECKBOX" && field === field_name) {
            let checkedvalues = formData[field].value;
            if (validateNumber(field_value)) {
              field_value = parseInt(field_value);
            }

            if (checkedvalues.indexOf(field_value) < 0) {
              checkedvalues.push(field_value);
            } else {
              let i = checkedvalues.indexOf(field_value);
              checkedvalues.splice(i, 1);
            }

            formData[field].value = checkedvalues;

            //console.log(checkedvalues,field_value)
          } else {
            if (field === field_name) {
              formData[field].value = field_value;
            }
          }
          if (field_name == field && formData[field].script) {
            var currentField = formData[field];
            try {
              await eval(formData[field].script);
            } catch (Excep) {
              console.log(">>>>>>>>ERROR EXECUTING CUSTOM SCRIPT", Excep);
              console.log(formData[field].script);
            }
          }
        }
      }
    }

    for (let i in Object.keys(formData)) {
      let field = Object.keys(formData)[i];
      if (
        formData[field].type != "LABEL" &&
        formData[field].type != "LINEBREAK"
      ) {
        if (!field_name || field_name === field) {
          if (formData[field].type === "PASSWORD") {
            formData[field].isInvalid = false;
            if (formData[field + "2"] && !field_name) {
              //	console.log("valudando", field,"2")
              resp = validateField(formData[field], field);
              formData[field]["isInvalid"] = resp.isInvalid;
              formData[field]["validationmessage"] = resp.message;
              //message +=" "+resp.message
              message.push(resp.message);

              if (resp.isInvalid) {
                isValid = false;
              }
              if (formData[field + "2"].value != formData[field].value) {
                //message +=" "+ getDefValues().passwordsNotEqualLabel
                message.push(getDefValues().passwordsNotEqualLabel);
                formData[field]["isInvalid"] = true;
                formData[field]["validationmessage"] =
                  getDefValues().passwordsNotEqualLabel;
                isValid = false;
              } else {
                var regex = /^(?=.*\d)(?=.*[a-z???????])/;
                if (regex.test(formData[field].value)) {
                } else {
                  //message +=  getDefValues().novalidPasswordLabel;
                  message.push(getDefValues().novalidPasswordLabel);
                  formData[field]["isInvalid"] = true;
                  formData[field]["validationmessage"] =
                    getDefValues().novalidPasswordLabel;
                  isValid = false;
                }
              }
            } else {
              if (!field_name) {
                resp = validateField(formData[field], field);
                formData[field]["isInvalid"] = resp.isInvalid;
                formData[field]["validationmessage"] = resp.message;
                //message +=" "+resp.message
                message.push(resp.message);
                if (resp.isInvalid) {
                  isValid = false;
                }
              }
            }
          } else {
            resp = validateField(formData[field], field);
            formData[field]["isInvalid"] = resp.isInvalid;
            formData[field]["validationmessage"] = resp.message;
            //message +=" "+resp.message

            if (resp.isInvalid) {
              message.push(resp.message);
              isValid = false;
            }
          }
        }
      }
    }
    if (!field_name && !isValid) {
      let error = (
        <div className="validation-message">
          {_formName ? (
            <p>
              {" "}
              <b> {getDefValues().formErrorLabel + " " + _formName} </b>{" "}
            </p>
          ) : (
            ""
          )}
          {message.map((m) => (
            <p>{"* " + m}</p>
          ))}
        </div>
      );
      //"Error:"+ ( _formName? getDefValues().formErrorLabel+ " "+_formName :""  ) +message
      showError(_toast, error);
    }

    resp = { formData: formData, isValid: isValid };
  } catch (error) {
    var resp = { formData: formData, isValid: false, error: error };
    console.log(">>>>>>>>", error);
    if (_toast) {
      showError(_toast, "PAGE ERROR!");
    }
  } finally {
    return resp;
  }
}

export async function manageButton(
  _event,
  _state,
  _toast,
  _formData,
  _customMessage
) {
  var resp = {
    response: false,
    message: "",
  };
  try {
    var isValid = true;

    var finalForm = {};

    if (_formData) {
      finalForm = _formData;
    }

    for (let k in Object.keys(_state)) {
      var key = Object.keys(_state)[k];
      if (key.startsWith("RENDEROBJ")) {
        if (_state[key].type === "FORM") {
          if (_state[key].config.formclonable) {
            if (_state[key].config.formclonable === "YES") {
              let arrayParams = [];
              if (Array.isArray(_state[key].data)) {
                _state[key].data.map(async function (key, item) {
                  console.log("key", key);
                  let validation = await validateForm(
                    key.formData,
                    _toast,
                    null,
                    key.formName
                  );

                  if (!validation.isValid) {
                    isValid = false;
                  }
                  let tempData = parseFormData(key.formData);
                  arrayParams.push(tempData);
                });
                finalForm[key.replace("RENDEROBJ", "formdata__")] = arrayParams;
              } else {
                let validation = await validateForm(_state[key].data, _toast);

                if (!validation.isValid) {
                  isValid = false;
                }
                let tempData = parseFormData(_state[key].data);
                arrayParams.push(tempData);
                finalForm[key.replace("RENDEROBJ", "formdata__")] = arrayParams;
              }
            } else {
              let validation = await validateForm(_state[key].data, _toast);

              if (!validation.isValid) {
                isValid = false;
              }
              let data = parseFormData(_state[key].data);
              Object.assign(finalForm, data);
            }
          } else {
            let validation = await validateForm(_state[key].data, _toast);

            if (!validation.isValid) {
              isValid = false;
            }

            let data = parseFormData(_state[key].data);
            Object.assign(finalForm, data);
          }
        }
      }
    }

    if (isValid) {
      finalForm["apiName"] = _event;
      try {
        resp = await Api["customApi"](finalForm);
        if (resp.success) {
          if (_customMessage) {
            showMessage(_toast, _customMessage);
          } else {
            showMessage(_toast, resp.message ? resp.message : resp.response);
          }
        } else {
          showError(_toast, resp.message ? resp.message : resp.response);
        }
      } catch (error) {
        console.log(">>>>>>>", error);
      }
    }
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = {
      response: false,
      message: err,
    };
  } finally {
    return resp;
  }
}

export async function getDataDB(_formData, field, _toast) {
  var formData = _formData;
  var Data = {};
  var apiGroup = "custom";
  if (formData[field].getDataDB.apiValues) {
    Object.keys(formData).map(function (key) {
      if (formData[field].getDataDB.apiValues.indexOf(key) >= 0) {
        Data[key] = formData[key].value;
      }
    });
  }
  if (formData[field].getDataDB.aditionalValues) {
    for (let key in formData[field].getDataDB.aditionalValues) {
      Data[Object.keys(formData[field].getDataDB.aditionalValues[key])[0]] =
        Object.values(formData[field].getDataDB.aditionalValues[key])[0];
    }
  }
  if (formData[field].getDataDB.apiGroup) {
    apiGroup = formData[field].getDataDB.apiGroup;
  }

  try {
    var values = [];
    var customScript =
      " (async () => {  values = await callFormApi ('" +
      apiGroup +
      "', '" +
      formData[field].getDataDB.apiMethod +
      "', " +
      `Data` +
      " ) })();";
    await eval(customScript);
    if (formData[field].addDefault) {
      let defRow = [{ value: "", text: formData[field].addDefault }];
      let rows = [...defRow, ...values.rows];
      formData[field]["values"] = rows;
    } else {
      formData[field]["values"] = values.rows;
    }
  } catch (error) {
    //console.log(">>>>>>>>", error)
    if (_toast) {
      showError(_toast, "Error SQL TABLE");
    }
  } finally {
    return formData;
  }
}

export async function getDataRestAPI(_url, _data, _header, _toast) {
  var response = { success: false, message: "" };
  var data;
  var errorMessage;

  try {
    const axios = require("axios");
    await axios
      .post(_url, _data, { _header })
      .then((response) => response.data)
      .then((resp) => {
        response = resp;
      })
      .catch(function (error) {
        console.log(">>>>>>>> Error api", error);
        response = { success: false, message: "ERR:" + error };
      });

    return response;
  } catch (error) {
    response = { success: false, message: "ERR:" + error };
    return response;
  }
}

export async function getDataRestAPIget(_url, _header, _toast) {
  var response = { success: false, message: "" };
  var data;
  var errorMessage;

  try {
    const axios = require("axios");
    await axios
      .get(_url, { _header })
      .then((response) => response.data)
      .then((resp) => {
        response = resp;
      })
      .catch(function (error) {
        console.log(">>>>>>>> Error api", error);
        response = { success: false, message: "ERR:" + error };
      });

    return response;
  } catch (error) {
    response = { success: false, message: "ERR:" + error };
    return response;
  }
}

export async function recoveryPass(apiGroup, apiName, formData, _toast) {
  var resp = { rows: [] };
  try {
    formData["templateid"] = "d-8fb32d1a442c4ca09afc15c9a0182949";

    let resp = await Api["customer"]["sendMail"](formData);

    //console.log(resp)
  } catch (error) {
    //console.log(">>>>>>>>", error)
    if (_toast) {
      showError(_toast, "Error consultando api");
    }
  } finally {
    return resp;
  }
}

export async function callFormApi(apiGroup, apiName, formData, _toast) {
  var resp = { rows: [] };
  try {
    let _resp = await Api[apiGroup][apiName](formData);
    for (let k in _resp.rows) {
      resp.rows.push({
        value: _resp.rows[k][Object.keys(_resp.rows[k])[0]],
        text: _resp.rows[k][Object.keys(_resp.rows[k])[1]],
      });
    }
  } catch (error) {
    //console.log(">>>>>>>>", error)
    if (_toast) {
      showError(_toast, "Error consultando api");
    }
  } finally {
    return resp;
  }
}

export async function callCustomApi(apiName, formData, _toast) {
  var resp = { rows: [] };
  try {
    formData["apiName"] = apiName;
    resp = await Api["customApi"](formData);
  } catch (error) {
    //console.log(">>>>>>>>", error)
    if (_toast) {
      showError(_toast, "Error consultando api");
    }
  } finally {
    return resp;
  }
}

export async function callApi(apiGroup, apiName, formData, _toast) {
  var resp = { rows: [] };
  try {
    resp = await Api[apiGroup][apiName](formData);
  } catch (error) {
    //console.log(">>>>>>>>", error)
    if (_toast) {
      showError(_toast, "Error consultando api");
    }
  } finally {
    return resp;
  }
}
export async function preloadForm(_formData, _allData) {
  var formData = _formData;

  try {
    for (let key in Object.keys(_formData)) {
      let field = Object.keys(_formData)[key];
      if (_formData[field].getDataDB) {
        let tmpformData = await getDataDB(_formData, field);
        formData = tmpformData;
      }
      if (_formData[field].getDataRestAPI) {
        //getDataRestAPI

        var apiInfo = _formData[field].getDataRestAPI;
        var url = apiInfo.url;
        var header = apiInfo.header ? JSON.parse(apiInfo.header) : {};
        var params = apiInfo.params;

        if (apiInfo.prescript) {
          //console.log("tiene un script", apiInfo.prescript)
          eval(apiInfo.prescript);
        }
        //  console.log(params)
        var responseApi = await getDataRestAPI(url, params, header);

        if (apiInfo.postscript) {
          eval(apiInfo.postscript);
        }
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    return formData;
  }
}

export async function clearformData(_formData) {
  var formData = _formData;

  try {
    for (let key in Object.keys(_formData)) {
      var field = Object.keys(_formData)[key];
      let fieldValue = "";
      if (
        _formData[field].type === "SELECT" ||
        _formData[field].type === "RADIO" ||
        _formData[field].type === "MULTIPLE"
      ) {
        fieldValue = _formData[field].values[0]
          ? _formData[field].values[0].value
          : "";
      } else if (_formData[field].type === "CHECKBOX") {
        fieldValue = [];
      } else if (_formData[field].type === "DATEPICKER") {
        fieldValue = new Date();
      }
      _formData[field].value = _formData[field].defultvalue
        ? _formData[field].defultvalue
        : fieldValue;
      _formData[field].isInvalid = false;
    }
    formData = _formData;
  } catch (err) {
    //console.log(">>>>>>>>>>"+err)
  } finally {
    return formData;
  }
}

export function mergeFormData(_formData, _userData) {
  var formData = _formData;
  try {
    for (let key in Object.keys(_formData)) {
      var field = Object.keys(_formData)[key];
      for (let key2 in Object.keys(_userData)) {
        let field2 = Object.keys(_userData)[key2];
        if (field.toLowerCase() == field2.toLowerCase()) {
          let fieldValue = _userData[field2] ? _userData[field2] : "";

          if (
            Array.isArray(fieldValue) &&
            _formData[field].type.startsWith("Comp")
          ) {
            _formData[field].values = fieldValue === null ? "" : fieldValue;
          } else {
            _formData[field].value = fieldValue === null ? "" : fieldValue;
          }
        }
      }
    }
    formData = _formData;
  } catch (err) {
    //console.log(">>>>>>>>>>"+err)
  } finally {
    return formData;
  }
}

export function parseFormData(_formData) {
  var parsedFormData = {};
  try {
    for (let key in Object.keys(_formData)) {
      var field = Object.keys(_formData)[key];

      if (_formData[field].value !== undefined) {
        let fieldValue;
        if (_formData[field].type === "PASSWORD") {
          fieldValue = md5.hex_md5(_formData[field].value + "");
        } else if (_formData[field].type === "CHECKBOX") {
          fieldValue = _formData[field].value.toString();
        } else {
          fieldValue = _formData[field].value;
        }

        parsedFormData[field.toLowerCase()] = fieldValue;
      } else {
        parsedFormData[field.toLowerCase()] = _formData[field].values;
      }
    }
  } catch (err) {
    //console.log(">>>>>>>>>>"+err)
  } finally {
    return parsedFormData;
  }
}

export function showMessage(toast, message) {
  toast.success(message, { closeButton: true, position: "bottom-right" });
}
export function showWarning(toast, message) {
  toast.warning(message, { closeButton: true, position: "bottom-right" });
}
export function showError(toast, message) {
  toast.error(message, { closeButton: true, position: "bottom-right" });
}
export function encryptText(text) {
  return md5.hex_md5(text);
}

export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a href="#0">Previous</a>;
  }
  if (type === "next") {
    return <a href="#0">Next</a>;
  }
  return originalElement;
}

export function onShowSizeChange(obj) {}

export function getChildren(_jsonObjet, _children, _parent) {
  let respChildObj;
  for (let key in _jsonObjet) {
    if (
      _jsonObjet[key].isChildren &&
      _jsonObjet[key].name === _children &&
      _jsonObjet[key].parent === _parent
    ) {
      respChildObj = _jsonObjet[key];
    }
  }
  return respChildObj;
}
export function setObjectToFather(_jsonObjet, _obj, _renderObj) {
  let objFather;
  let objChildren;
  for (let key in _jsonObjet) {
    if (_jsonObjet[key].name === _obj.parent) {
      objFather = _jsonObjet[key];
    }
  }
  if (
    objFather.componentType === "SLIDERCONTAINER" ||
    objFather.componentType === "CARDCONTAINER" ||
    objFather.componentType === "CARD" ||
    objFather.componentType === "SLIDER"
  ) {
    for (let c in objFather.content) {
      if (objFather.content[c].children === _obj.name) {
        objChildren = {
          header: objFather.content[c].header,
          cardWidth: objFather.content[c].cardWidth,
          body: _renderObj,
          temporalyName: _obj.parent,
        };
      }
    }
  }

  if (objFather.componentType === "MODAL") {
    objChildren = _renderObj;
  }

  return objChildren;
}

export function getFormInfo(_template, _formName) {
  var formData = {};
  try {
    for (let c in _template) {
      if (_template[c].name == _formName) {
        for (let f in _template[c].content) {
          formData[_template[c].content[f].name] = _template[c].content[f];
        }
      }
    }
  } catch (Exc) {
    formData = {};
    console.log(">>>>>>>>", Exc);
  } finally {
    return formData;
  }
}
export function setObject(
  windowWidth,
  changeHandler,
  toggleModal,
  manageButton,
  manageCart,
  _jsonObjet,
  _obj,
  _container,
  _containerMaster,
  elementSubType
) {
  var respObj = "NO DATA";
  var renderObj;

  try {
    if (
      _obj.componentType === "CARD" ||
      _obj.componentType === "CARDCONTAINER"
    ) {
      for (let c in _obj.content) {
        let newObj = _obj.content[c];
        newObj["temporalyName"] = _obj.name;

        if (!_obj.content[c].children) {
          _container.push(newObj);
        } else {
          var objChildren = getChildren(_jsonObjet, newObj.children, _obj.name);
          setObject(
            windowWidth,
            changeHandler,
            toggleModal,
            manageButton,
            manageCart,
            _jsonObjet,
            objChildren,
            _container,
            _containerMaster,
            true
          );
        }
      }

      var finalContainer = [];

      for (let c in _container) {
        if (_container[c].temporalyName === _obj.name) {
          finalContainer.push(_container[c]);

          if (_container[c].body.props.imageConfig) {
            //	_container[c]["style"] = {"margin-left":"-5px", "margin-right": "5px" }
          }
        }
      }

      renderObj = <Card cardData={finalContainer} cardConfig={_obj.config} />;
    } else if (
      _obj.componentType === "SLIDER" ||
      _obj.componentType === "SLIDERCONTAINER"
    ) {
      for (let c in _obj.content) {
        let newObj = _obj.content[c];
        newObj["temporalyName"] = _obj.name;
        if (!_obj.content[c].children) {
          _container.push(_obj.content[c]);
        } else {
          var objChildren = getChildren(
            _jsonObjet,
            _obj.content[c].children,
            _obj.name
          );
          setObject(
            windowWidth,
            changeHandler,
            toggleModal,
            manageButton,
            manageCart,
            _jsonObjet,
            objChildren,
            _container,
            _containerMaster,
            true
          );
        }
      }
      finalContainer = [];
      for (let c in _container) {
        if (_container[c].temporalyName === _obj.name) {
          finalContainer.push(_container[c]);
        }
      }

      var config = {
        dots: _obj.config.dots + "" === "false" ? false : true,
        arrows: _obj.config.arrows + "" === "false" ? false : true,
        slidesToShow:
          windowWidth <= 799
            ? 2
            : _obj.config.slidestoshow
            ? parseInt(_obj.config.slidestoshow)
            : 3,
        slidesToScroll: _obj.config.slidestoscroll
          ? parseInt(_obj.config.slidestoscroll)
          : 1,
        autoplay: true,
        cssEase: "linear",
        speed: _obj.config.speed ? parseInt(_obj.config.speed) : 500,
        autoplaySpeed: 30000, // _obj.config.autoplayspeed ? parseInt(_obj.config.autoplayspeed):3000,
        pauseOnHover: true,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
      };
      renderObj = <Slider sliderData={finalContainer} sliderConfig={config} />;
    } else if (_obj.componentType === "MODAL") {
      _obj["temporalyName"] = _obj.name;

      var objChildren = getChildren(_jsonObjet, _obj.data.children, _obj.name);

      setObject(
        windowWidth,
        changeHandler,
        toggleModal,
        manageButton,
        manageCart,
        _jsonObjet,
        objChildren,
        _container,
        _containerMaster,
        true
      );
      var finalContainer = [];

      for (let c in _container) {
        if (_container[c].temporalyName === _obj.name) {
          finalContainer.push(_container[c]);
        }
      }
      var config = _obj.config;
      config["startopen"] =
        _obj.config.startopen + "" === "true" ? true : false;

      var _renderModal = (
        <Modal
          name={_obj.name}
          toggle={toggleModal}
          modalData={_container[0]}
          modalConfig={config}
        />
      );

      if (config["store"]) {
        if (config["store"] != "") {
          let selectedStoreId = getCurrentStore();
          if (config["store"] == selectedStoreId) {
          } else {
            _renderModal = null;
          }
        }
      }
      renderObj = _renderModal;
    } else if (_obj.componentType === "SLIDERIMAGE") {
      for (let c in _obj.content) {
        let newObj = _obj.content[c];
        newObj["temporalyName"] = _obj.name;

        if (!_obj.content[c].children) {
          _container.push(_obj.content[c]);
        } else {
          var objChildren = getChildren(
            _jsonObjet,
            _obj.content[c].children,
            _obj.name
          );
          setObject(
            windowWidth,
            changeHandler,
            toggleModal,
            manageButton,
            manageCart,
            _jsonObjet,
            objChildren,
            _container,
            _containerMaster,
            true
          );
        }
      }

      var finalContainer = [];
      for (let c in _container) {
        if (_container[c].temporalyName === _obj.name) {
          finalContainer.push(_container[c]);
        }
      }
      var config = {
        dots: _obj.config.dots + "" === "false" ? false : true,
        arrows: _obj.config.arrows + "" === "false" ? false : true,
        autoplay: true,
        cssEase: "linear",
        slidesToShow:
          windowWidth <= 799
            ? 1
            : _obj.config.slidestoshow
            ? parseInt(_obj.config.slidestoshow)
            : 1,
        slidesToScroll: _obj.config.slidestoscroll
          ? parseInt(_obj.config.slidestoscroll)
          : 1,

        speed: _obj.config.speed ? parseInt(_obj.config.speed) : 500,
        autoplaySpeed: _obj.config.autoplayspeed
          ? parseInt(_obj.config.autoplayspeed)
          : 3000,

        pauseOnHover: true,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
      };
      renderObj = (
        <SliderImage sliderData={finalContainer} sliderConfig={config} />
      );
    } else if (_obj.componentType === "LIST") {
      var cardData = [];
      for (let c in _obj.content) {
        let newObj = _obj.content[c];
        newObj["temporalyName"] = _obj.name;
        if (!_obj.content[c].children) {
          _container.push(_obj.content[c]);
        } else {
        }
      }
      var finalContainer = [];

      for (let c in _container) {
        if (_container[c].temporalyName === _obj.name) {
          finalContainer.push(_container[c]);
        }
      }
      renderObj = <List listValues={finalContainer} />;
    } else if (_obj.BlogContainer === "BLOGCONTAINER") {
      var cardData = [];
      for (let c in _obj.content) {
        let newObj = _obj.content[c];
        newObj["temporalyName"] = _obj.name;
        if (!_obj.content[c].children) {
          _container.push(_obj.content[c]);
        } else {
        }
      }
      var finalContainer = [];

      for (let c in _container) {
        if (_container[c].temporalyName === _obj.name) {
          finalContainer.push(_container[c]);
        }
      }

      renderObj = <BlogContainer listBlogs={finalContainer} />;
    } else if (_obj.componentType === "FORM") {
      var formData = {};
      for (let f in _obj.content) {
        formData[_obj.content[f].name] = _obj.content[f];
      }

      renderObj = (
        <Form
          container={_obj.name}
          formData={formData}
          changeHandler={changeHandler}
          manageButton={manageButton}
          formConfig={_obj.config}
        />
      );
    } else if (_obj.componentType === "PRODUCT") {
      var productConfig = _obj.config;

      productConfig["quantitySelected"] = productConfig["quantitySelected"]
        ? parseFloat(productConfig["quantitySelected"]) + 1
        : 1;
      productConfig["manageCart"] = manageCart;

      renderObj = (
        <Product
          name={_obj.name}
          productData={_obj.data}
          productConfig={productConfig}
        />
      );
    } else if (_obj.componentType === "TEXT") {
      renderObj = (
        <Text name={_obj.name} textData={_obj.data} textConfig={_obj.config} />
      );
    } else if (_obj.componentType === "IMAGE") {
      if (!_obj.parent) {
        _obj.config["adjust"] = true;
      }

      renderObj = (
        <Image
          name={_obj.name}
          imageData={_obj.data}
          imageConfig={_obj.config}
        />
      );
    } else if (_obj.componentType === "CATEGORYBLOG") {
      renderObj = (
        <CategoryBlog
          name={_obj.name}
          categoryBlogData={_obj.data}
          categoryBlogContent={_obj.content}
          categoryBlogConfig={_obj.config}
        />
      );
    } else if (_obj.componentType === "SLIDERPRODUCTS") {
    } else if (_obj.componentType === "BUTTON") {
      renderObj = (
        <Button
          name={_obj.name}
          buttonData={_obj.data}
          buttonConfig={_obj.config}
        />
      );
    } else {
      console.log("NO DEF", _obj.componentType, _obj);
    }

    if (!elementSubType) {
      _containerMaster.push(renderObj);
    } else {
      let objChildren = setObjectToFather(_jsonObjet, _obj, renderObj);
      _container.push(objChildren);
    }
  } catch (Exc) {
    console.log(">>>>>>>>ERROR SETTING OBJECT", Exc);
  }
}

export function loadPage(
  jsonObjet,
  changeHandler,
  toggleModal,
  manageButton,
  manageCart,
  windowWidth
) {
  var finalObject = [];
  let containerMaster = [];
  for (let key in jsonObjet) {
    let obj = jsonObjet[key];
    let container = [];

    if (!obj.isChildren) {
      let respObj = setObject(
        windowWidth,
        changeHandler,
        toggleModal,
        manageButton,
        manageCart,
        jsonObjet,
        obj,
        container,
        containerMaster
      );
    }
  }
  return containerMaster;
}
export async function getRenderObj(
  _obj,
  _managepagination,
  _manageCart,
  _changeHandler,
  _manageButton,
  _toggleModal,
  windowWidth,
  _curdata
) {
  try {
    var renderObj;
    if (_obj.componentType === "ABOUT") {
      renderObj = {
        curdata: {
          type: _obj.componentType,
          data: _obj.data,
          config: _obj.config,
        },
        render: "",
      };
    } else if (
      _obj.componentType === "CARD" ||
      _obj.componentType === "CARDCONTAINER"
    ) {
      var _config = _obj.config;
      if (!_config.content) {
        _config["content"] = _obj.content;
      } /*
			var finalContainer= []
			for(let c in _obj.content){

				let content = _obj.content[c]

			 	console.log("content", content)



			 	//var renderChild = await getRenderObj ( key, null, null,null, null, null, null)

				finalContainer.push(content)
			}
			*/
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _curdata,
          config: _obj.config,
        },
        render: (
          <Card
            ObjectName={_obj.name}
            cardData={_curdata}
            cardConfig={_obj.config}
          />
        ),
      };
    } else if (
      _obj.componentType === "SLIDER" ||
      _obj.componentType === "SLIDERCONTAINER"
    ) {
      let config = {
        dots: _obj.config.dots + "" === "false" ? false : true,
        arrows: _obj.config.arrows + "" === "false" ? false : true,
        slidesToShow:
          windowWidth <= 799
            ? 2
            : _obj.config.slidestoshow
            ? parseInt(_obj.config.slidestoshow)
            : 3,
        slidesToScroll: _obj.config.slidestoscroll
          ? parseInt(_obj.config.slidestoscroll)
          : 1,
        autoplay: true,
        cssEase: "linear",
        speed: _obj.config.speed ? parseInt(_obj.config.speed) : 500,
        autoplaySpeed: 30000, // _obj.config.autoplayspeed ? parseInt(_obj.config.autoplayspeed):3000,
        pauseOnHover: true,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
      };

      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _curdata,
          config: config,
        },
        render: (
          <Slider
            ObjectName={_obj.name}
            sliderData={_curdata}
            sliderConfig={config}
          />
        ),
      };
    } else if (_obj.componentType === "MODAL") {
      let config = _obj.config;
      config["startopen"] =
        _obj.config.startopen + "" === "true" ? true : false;
      var _renderModal = (
        <Modal
          name={_obj.name}
          ObjectName={_obj.name}
          toggle={_toggleModal}
          modalData={_curdata}
          modalConfig={config}
        />
      );

      if (config["store"]) {
        if (config["store"] != "") {
          let selectedStoreId = getCurrentStore();
          if (config["store"] == selectedStoreId) {
          } else {
            _renderModal = null;
            _curdata = null;
          }
        }
      }
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _curdata,
          config: config,
        },
        render: _renderModal,
      };
    } else if (_obj.componentType === "SLIDERIMAGE") {
      let config = {
        dots: _obj.config.dots + "" === "false" ? false : true,
        arrows: _obj.config.arrows + "" === "false" ? false : true,
        autoplay: true,
        cssEase: "linear",
        slidesToShow:
          windowWidth <= 799
            ? 1
            : _obj.config.slidestoshow
            ? parseInt(_obj.config.slidestoshow)
            : 1,
        slidesToScroll: _obj.config.slidestoscroll
          ? parseInt(_obj.config.slidestoscroll)
          : 1,
        speed: _obj.config.speed ? parseInt(_obj.config.speed) : 500,
        autoplaySpeed: _obj.config.autoplayspeed
          ? parseInt(_obj.config.autoplayspeed)
          : 3000,
        pauseOnHover: true,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
      };
      var finalContainer = [];

      for (let c in _obj.content) {
        let content = _obj.content[c];

        if (content.height && content.height != "") {
          content["height"] =
            content.height.indexOf("px") >= 0
              ? content.height
              : content.height + "px";
        }
        if (content.mheight && content.mheight != "") {
          content["mheight"] =
            content.mheight.indexOf("px") >= 0
              ? content.mheight
              : content.mheight + "px";
        }
        if (content.width && content.width != "") {
          content["width"] =
            content.width.indexOf("px") >= 0
              ? content.width
              : content.width + "px";
        }
        if (content.mwidth && content.mwidth != "") {
          content["mwidth"] =
            content.mwidth.indexOf("px") >= 0
              ? content.mwidth
              : content.mwidth + "px";
        }

        finalContainer.push(content);
      }
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: finalContainer,
          config: config,
        },
        render: (
          <SliderImage
            ObjectName={_obj.name}
            sliderData={finalContainer}
            sliderConfig={config}
          />
        ),
      };
    } else if (_obj.componentType === "LIST") {
      var finalContainer = [];
      for (let c in _obj.content) {
        let content = _obj.content[c];
        finalContainer.push(content);
      }

      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: finalContainer,
        },
        render: <List ObjectName={_obj.name} listValues={finalContainer} />,
      };
    } else if (_obj.componentType === "BLOGCONTAINER") {
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          content: _obj.content,
        },
        render: (
          <BlogContainer
            ObjectName={_obj.name}
            blogContainerData={_obj.data}
            blogContainerContent={_obj.content}
          />
        ),
      };
    } else if (_obj.componentType === "FORM") {
      var content = _obj.content;

      content = _obj.content.sort(function (a, b) {
        return convertNumber(a.sortorder) - convertNumber(b.sortorder);
      });

      var formData = {};
      for (let f in content) {
        formData[_obj.content[f].name] = _obj.content[f];

        if (_obj.content[f].type === "SELECT" && _obj.content[f].items) {
          formData[_obj.content[f].name].values = _obj.content[f].items
            ? _obj.content[f].items
            : [{ text: "", value: "" }];
          formData[_obj.content[f].name].value =
            formData[_obj.content[f].name].values[0].value;
        }
        if (_obj.content[f].type === "GMAP") {
          formData[_obj.content[f].name].config = {
            lat: -16.5345619,
            lng: -68.0900024,
            zoom: 13,
          };
        }

        if (_obj.content[f].type === "CHECKBOX" && _obj.content[f].items) {
          formData[_obj.content[f].name].values = _obj.content[f].items
            ? _obj.content[f].items
            : [{ text: "", value: "" }];
          formData[_obj.content[f].name].value = [];
        }

        if (_obj.content[f].type === "CHECKBOX" && !_obj.content[f].items) {
          //	console.log("no tienes items")
          formData[_obj.content[f].name].values = [
            { text: _obj.content[f].label, value: "YES" },
          ];
          formData[_obj.content[f].name].value = [];
        }

        if (_obj.content[f].type === "ITEMS" && _obj.content[f].items) {
          formData[_obj.content[f].name].values = _obj.content[f].items
            ? _obj.content[f].items
            : [{ text: "", value: "" }];
          formData[_obj.content[f].name].value = [];
        }

        if (formData[_obj.content[f].name].validationtype === "NOTREQUIRED") {
          formData[_obj.content[f].name].notRequired = true;
          if (formData[_obj.content[f].name].maxlength) {
            formData[_obj.content[f].name].validationtype = "text";
          } else {
            formData[_obj.content[f].name].validationtype = "";
          }
        }
        if (!formData[_obj.content[f].name].validationtype) {
          formData[_obj.content[f].name].notRequired = true;
          if (formData[_obj.content[f].name].maxlength) {
            formData[_obj.content[f].name].validationtype = "text";
          } else {
            formData[_obj.content[f].name].validationtype = "";
          }
        }
      }
      try {
        await eval(_obj.config.postprocessing);
      } catch (e) {
        console.log(">>>>>>> ERROR EXECUTING FORM SCRIPT", e);
        console.log(_obj.config.postprocessing);
      }
      //console.log(formData)
      let finalData = {
        formName: _obj.name,
        formLabel: _obj.config.formtitle,
        formOptionID: _obj.config.formid,
        isValid: false,
        formData: formData,
        formConfig: _obj.config,
      };
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          finalData: finalData,
          data: formData,
          config: _obj.config,
        },
        render: (
          <Form
            ObjectName={_obj.name}
            container={_obj.name}
            formData={formData}
            changeHandler={_changeHandler}
            manageButton={_manageButton}
            formConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "FORMCONTAINER") {
    } else if (_obj.componentType === "PRODUCT") {
      var productConfig = _obj.config;
      productConfig["quantitySelected"] = productConfig["quantitySelected"]
        ? parseFloat(productConfig["quantitySelected"]) + 1
        : 1;
      productConfig["manageCart"] = _manageCart;

      var data = {
        languageid: getLanguage(),
        categoryid: 28,
        storeid: 59,
      };

      renderObj = {
        curdata: {
          type: _obj.componentType,
          name: "product" + _obj.data.productid,
          ObjectName: _obj.name,
          data: _obj.data,
          config: productConfig,
        },
        render: (
          <Product
            ObjectName={_obj.name}
            name={"product" + _obj.data.productid}
            productData={_obj.data}
            productConfig={productConfig}
          />
        ),
      };
    } else if (_obj.componentType === "TEXT") {
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          config: _obj.config,
        },
        render: (
          <Text
            ObjectName={_obj.name}
            textData={_obj.data}
            textConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "BUTTON") {
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          config: _obj.config,
        },
        render: (
          <Button
            ObjectName={_obj.name}
            buttonData={_obj.data}
            buttonConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "IMAGE") {
      if (!_obj.parent) {
        _obj.config["adjust"] = true;
      }
      //console.log(_obj.config)
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          config: _obj.config,
        },
        render: (
          <Image
            ObjectName={_obj.name}
            imageData={_obj.data}
            imageConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "VIDEO") {
      if (!_obj.parent) {
        _obj.config["adjust"] = true;
      }
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          config: _obj.config,
        },
        render: (
          <Video
            ObjectName={_obj.name}
            videoData={_obj.data}
            videoConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "DIV") {
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          config: _obj.config,
        },
        render: (
          <Div
            ObjectName={_obj.name}
            divData={_obj.data}
            divConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "IFRAME") {
      if (!_obj.parent) {
        _obj.config["adjust"] = true;
      }
      let formData = {
        iframe: {
          type: "IFRAME",
          value: _obj.data.link,
          height: _obj.data.height,
          width: _obj.data.width,
          size: "col-12",
        },
      };
      let formConfig = { colsize: "col-12" };
      let finalData = {
        formName: _obj.name,
        formLabel: null,
        formOptionID: 0,
        isValid: false,
        formData: formData,
        formConfig: formConfig,
      };

      renderObj = {
        curdata: {
          type: "FORM",
          ObjectName: _obj.name,
          finalData: finalData,
          data: formData,
          config: _obj.config,
        },
        render: (
          <Form
            ObjectName={_obj.name}
            container={_obj.name}
            formData={formData}
            changeHandler={_changeHandler}
            manageButton={_manageButton}
            formConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (_obj.componentType === "CATEGORYBLOG") {
      renderObj = {
        curdata: {
          type: _obj.componentType,
          ObjectName: _obj.name,
          data: _obj.data,
          content: _obj.content,
          config: _obj.config,
        },
        render: (
          <CategoryBlog
            ObjectName={_obj.name}
            categoryBlogData={_obj.data}
            categoryBlogContent={_obj.content}
            categoryBlogConfig={_obj.config}
            config={_obj.config}
          />
        ),
      };
    } else if (
      _obj.componentType === "SLIDERPRODUCTS" ||
      _obj.componentType === "CARDPRODUCTS"
    ) {
      /*****START GETTING DATA******/
      var data;
      var respProd;
      var products = [];

      var tempSlider = null; //getCookie(process.env.REACT_APP_ORG_NAME+'SLIDERPRODUCTS'+_obj.name)

      var exists = false;

      if (tempSlider) {
        var productsLength = getCookie(
          process.env.REACT_APP_ORG_NAME + "SLIDERPRODUCTS" + _obj.name
        );
        for (let k = 0; k < parseInt(productsLength); k++) {
          products.push(
            JSON.parse(
              getCookie(
                process.env.REACT_APP_ORG_NAME +
                  "SLIDERPRODUCTS" +
                  _obj.name +
                  "_" +
                  k
              )
            )
          );
        }
      } else {
        //console.log("GETTING DATA...")
        if (_obj.config.criteria === "CATEGORY") {
          data = {
            languageid: getLanguage(),
            categoryid: _obj.data.category,
            storeid: getCurrentCatalog(),
            limit: 20,
          };
        } else if (_obj.config.criteria === "PRODUCT") {
          data = {
            languageid: getLanguage(),
            productids: _obj.data.products.toString(),
            storeid: getCurrentCatalog(),
          };
        } else if (_obj.config.criteria === "NEWS") {
          data = {
            languageid: getLanguage(),
            name: _obj.config.criteria,
            storeid: getCurrentCatalog(),
          };
        } else if (_obj.config.criteria === "NEWCATEGORY") {
          data = {
            languageid: getLanguage(),
            name: _obj.config.criteria,
            categoryid: _obj.data.category,
            storeid: getCurrentCatalog(),
          };
        }

        respProd = await callApi("customer", "getFilterProducts", data);

        for (let k in respProd.rows) {
          if (
            parseInt(k) <
            parseInt(
              _obj.config.maxproducts == 0
                ? respProd.rows.length
                : _obj.config.maxproducts
            )
          ) {
            products.push(respProd.rows[k]);

            //	setCookie(process.env.REACT_APP_ORG_NAME+'SLIDERPRODUCTS'+_obj.name+"_"+k,  JSON.stringify(respProd.rows[k]), 1 )
          }
        }
        //	setCookie(process.env.REACT_APP_ORG_NAME+'SLIDERPRODUCTS'+_obj.name,  products.length, 24 )
      }
      /*****END GETTING DATA******/
      if (_obj.componentType === "SLIDERPRODUCTS") {
        var prodDefConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-3",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6",
        };

        var eventId = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
        var eventName = getCookie(
          process.env.REACT_APP_ORG_NAME + "_eventName"
        );
        var action = getCookie(process.env.REACT_APP_ORG_NAME + "_action");
        var eventQty = getCookie(process.env.REACT_APP_ORG_NAME + "_eventQty");
        if (action !== "") {
          prodDefConfig["action"] = action;
          prodDefConfig["id"] = eventId;
        }

        let respCat = await buildProductsSlider(
          products,
          null,
          _manageCart,
          prodDefConfig,
          _obj.name
        );
        let config = {
          dots: _obj.config.dots + "" === "false" ? false : true,
          arrows: _obj.config.arrows + "" === "false" ? false : true,
          slidesToShow:
            windowWidth <= 799
              ? 2
              : _obj.config.slidestoshow
              ? parseInt(_obj.config.slidestoshow)
              : 3,
          slidesToScroll: _obj.config.slidestoscroll
            ? parseInt(_obj.config.slidestoscroll)
            : 1,
          autoplay: true,
          cssEase: "linear",
          speed: _obj.config.speed ? parseInt(_obj.config.speed) : 500,
          autoplaySpeed: _obj.config.autoplayspeed
            ? parseInt(_obj.config.autoplayspeed)
            : 3000,
          pauseOnHover: true,
          divWidth: "card col-md-12 col-lg-12 col-xl-12",
        };
        //console.log("config",config)
        renderObj = {
          curdata: {
            type: _obj.componentType,
            ObjectName: _obj.name,
            data: respCat.cardProducts,
            config: config,
          },
          render: (
            <Slider
              ObjectName={_obj.name}
              sliderData={respCat.cardProducts}
              sliderConfig={config}
              config={_obj.config}
            />
          ),
        };
      } else {
        let config = _obj.config;

        let respCat = await buildProductsCard(
          products,
          _managepagination,
          _manageCart,
          config,
          _obj.name
        );
        config["container"] = _obj.name;
        renderObj = {
          curdata: {
            type: _obj.componentType,
            ObjectName: _obj.name,
            data: respCat.cardProducts,
            config: config,
          },
          render: (
            <Card
              ObjectName={_obj.name}
              cardData={respCat.cardProducts}
              cardConfig={respCat.cardConfig}
              config={_obj.config}
            />
          ),
        };
      }
    } else {
      renderObj = "NO OBJ DEF";
    }

    return renderObj;
  } catch (err) {
    console.log(">>>>>>>", err);
    return null;
  }
}

export async function loadPageV2(
  jsonObjet,
  _managepagination,
  _manageCart,
  _changeHandler,
  _manageButton,
  _toggleModal,
  windowWidth
) {
  var finalObject = [];
  let containerMaster = [];
  for (let key in jsonObjet) {
    let obj = jsonObjet[key];
    let container = [];

    if (!obj.isChildren) {
      let renderObj = await getRenderObj(
        obj,
        _managepagination,
        _manageCart,
        _changeHandler,
        _manageButton,
        _toggleModal,
        windowWidth,
        obj.curdata
      );

      if (renderObj.data == null || !renderObj.data) {
        var data = [];
        if (obj.data.children && !obj.content.length) {
          obj["content"] = [{ children: obj.data.children }];
        }
        var objectsLoaded = [];
        for (let i in obj.content) {
          var cfg2 = obj.content[i];
          for (let i1 in jsonObjet) {
            let c1 = jsonObjet[i1];
            if (
              c1.name ==
              obj.content[i]
                .children /*&&  objectsLoaded.indexOf( c1.name||i1 )<0*/
            ) {
              objectsLoaded.push(c1.name || i1);
              var cfg1 = c1["config"];
              for (let k in Object.keys(cfg2)) {
                let field = Object.keys(cfg2)[k];
                cfg1[field] = cfg2[field];
              }
              cfg1["fatherconfig"] = obj.config ? obj.config : null;
              let renderObjC1 = await getRenderObj(
                c1,
                _managepagination,
                _manageCart,
                _changeHandler,
                _manageButton,
                _toggleModal,
                windowWidth,
                obj.curdata
              );
              data.push(renderObjC1);
            }
          }
        }
        /*
  			for (let i1 in jsonObjet){
  				let c1 = jsonObjet[i1]

  				if (c1.parent == obj.name ){

  					let renderObjC1 = await getRenderObj ( c1, _manageCart, _changeHandler, _manageButton, _toggleModal , windowWidth,obj.curdata );
  					data.push(renderObjC1)

  				}
				}*/

        var renderData = [];
        data.map(function (child) {
          //
          renderData.push({ body: child.render });
        });

        renderObj = await getRenderObj(
          obj,
          _managepagination,
          _manageCart,
          _changeHandler,
          _manageButton,
          _toggleModal,
          windowWidth,
          renderData
        );
        containerMaster.push(renderObj.curdata);
      } else {
        containerMaster.push(renderObj.curdata);
        //	console.log("render",renderObj.render)
      }
    } else {
      //console.log("es hijo"+obj.name+"_"+obj.parent)
    }
  }
  //console.log(containerMaster)
  return containerMaster;
}

export function toggleModalManager(jsonObjet) {
  jsonObjet.config.startopen = false;
  return jsonObjet;
}

export function changeHandlerManager(_jsonObjet, _fieldName, _value, _action) {
  let newForms = [];
  let activeForm = 0;
  if (_fieldName === "CLONFORM") {
    let newData = [];
    let newDataNames = [];
    let isValid = true;
    let curFields = _jsonObjet.originaldata
      ? _jsonObjet.originaldata
      : _jsonObjet.data;
    for (let i in Object.keys(curFields)) {
      let field = Object.keys(curFields)[i];

      newDataNames.push(field);

      let newValues = [];
      let initVal;
      if (curFields[field].values) {
        for (let i in curFields[field].values) {
          if (i == 0) {
            initVal = curFields[field].values[i].value;
          }
          let row = {
            text: "1",
            value: "value",
          };
          newValues.push(row);
        }
      }

      let objField = {};

      if (curFields[field].values) {
        objField["values"] = newValues;
      }

      for (let f in Object.keys(curFields[field])) {
        let fieldName = Object.keys(curFields[field])[f];
        if (fieldName === "value") {
          if (Array.isArray(curFields[field].values)) {
            if (curFields[field].values.length > 0) {
              objField["value"] = 1;
            } else {
              objField["value"] = "";
            }
          } else {
            objField["value"] = "";
          }
        } else {
          objField[fieldName] = curFields[field][fieldName];
        }
      }
      newData.push(objField);
    }

    let finalData = {};
    for (let i in newDataNames) {
      finalData[newDataNames[i]] = newData[i];
    }

    if (Array.isArray(_jsonObjet.data)) {
      if (_jsonObjet.data.length < 10) {
        activeForm = _jsonObjet.data.length;
        newForms = _jsonObjet.data;
        newForms.push({
          formName:
            (_jsonObjet.config.formsubtitle
              ? _jsonObjet.config.formsubtitle
              : "ITEM") +
            " " +
            (_jsonObjet.data.length + 1),
          formLabel:
            (_jsonObjet.config.formsubtitle
              ? _jsonObjet.config.formsubtitle
              : "ITEM") +
            " " +
            (_jsonObjet.data.length + 1),
          formConfig: _jsonObjet.config,
          formData: finalData,
          formOptionID: _jsonObjet.data.length,
        });
      } else {
        isValid = false;
      }
    } else {
      _jsonObjet["originaldata"] = _jsonObjet.data;
      activeForm = 1;
      newForms.push({
        formName:
          (_jsonObjet.config.formsubtitle
            ? _jsonObjet.config.formsubtitle
            : "ITEM") + " 1",
        formLabel:
          (_jsonObjet.config.formsubtitle
            ? _jsonObjet.config.formsubtitle
            : "ITEM") + " 1",
        formConfig: _jsonObjet.config,
        formData: _jsonObjet.data,
        formOptionID: 0,
      });
      newForms.push({
        formName:
          (_jsonObjet.config.formsubtitle
            ? _jsonObjet.config.formsubtitle
            : "ITEM") + " 2",
        formLabel:
          (_jsonObjet.config.formsubtitle
            ? _jsonObjet.config.formsubtitle
            : "ITEM") + " 2",
        formConfig: _jsonObjet.config,
        formData: finalData,
        formOptionID: 1,
      });
    }
    if (isValid) {
      _jsonObjet["data"] = newForms;
      _jsonObjet["activeForm"] = activeForm;
    }
  } else if (_fieldName === "DELCLONFORM") {
    newForms = _jsonObjet["data"];
    newForms.splice(_value, 1);

    if (newForms.length === 1) {
      _jsonObjet["data"] = newForms[0].formData;
      _jsonObjet["activeForm"] = 1;
    } else {
      for (let f in newForms) {
        newForms[f]["formName"] =
          (_jsonObjet.config.formsubtitle
            ? _jsonObjet.config.formsubtitle
            : "ITEM") +
          " " +
          (parseInt(f) + 1);
        newForms[f]["formLabel"] =
          (_jsonObjet.config.formsubtitle
            ? _jsonObjet.config.formsubtitle
            : "ITEM") +
          " " +
          (parseInt(f) + 1);
      }
      _jsonObjet["activeForm"] = newForms.length - 1;
    }
  } else {
    if (Array.isArray(_jsonObjet.data)) {
      _jsonObjet.data[_jsonObjet.activeForm]["formData"][_fieldName]["value"] =
        _value;
    } else {
      if (_jsonObjet.data[_fieldName].type === "CHECKBOX") {
        let checkedvalues = _jsonObjet.data[_fieldName].value;
        if (validateNumber(_value)) {
          _value = parseInt(_value);
        }
        if (checkedvalues.indexOf(_value) < 0) {
          checkedvalues.push(_value);
        } else {
          let i = checkedvalues.indexOf(_value);
          checkedvalues.splice(i, 1);
        }
        _jsonObjet.data[_fieldName].value = checkedvalues;
      } else {
        _jsonObjet.data[_fieldName].value = _value;
      }
    }
  }
  //console.log(_jsonObjet)

  return _jsonObjet;
}

export function changeHandler(_event, _formData) {
  var field_value = "";
  var field_name = "";
  var formData = _formData;
  if (_event.target.name) {
    field_value = _event.target.value;
    field_name = _event.target.name;
  } else {
    field_value = _event.target.parentNode.value;
    field_name = _event.target.parentNode.name;
  }
  //console.log("field_name",field_name, field_value)
  if (field_name) {
    var field_nameObj = formData[field_name];

    if (field_name.startsWith("togglePass")) {
      formData[field_name.replace("togglePass", "")].showPass = formData[
        field_name.replace("togglePass", "")
      ].showPass
        ? false
        : true;
    } else {
      if (_formData[field_name].type === "CHECKBOX") {
        let checkedvalues = _formData[field_name].value;
        if (validateNumber(field_value)) {
          field_value = parseInt(field_value);
        }
        if (checkedvalues.indexOf(field_value) < 0) {
          checkedvalues.push(field_value);
        } else {
          let i = checkedvalues.indexOf(field_value);
          checkedvalues.splice(i, 1);
        }
        field_nameObj["value"] = checkedvalues;
      } else {
        field_nameObj["value"] = field_value;
      }

      var resp = validateField(field_nameObj, field_name);
      field_nameObj["isInvalid"] = resp.isInvalid;
      field_nameObj["validationmessage"] = resp.message;

      if (
        field_nameObj.type === "INPUT" ||
        field_nameObj.type === "PASSWORD" ||
        field_nameObj.type === "TEXTAREA"
      ) {
        if (field_value === "") {
          field_nameObj["isInvalid"] = false;
          field_nameObj["validationmessage"] = "";
        }
      }
    }
  }

  return formData;
}

export function managepagination(_pagesettings, event) {
  var pagesettings = _pagesettings;

  var data;
  if (event.target.name) {
    data = event.target.name;
  } else {
    data = event.target.parentNode.name;
  }
  if (data != undefined) {
    if (data === "next") {
      if (
        _pagesettings.currentPage > 0 &&
        _pagesettings.currentPage < _pagesettings.pages
      ) {
        var currentPage = _pagesettings.currentPage + 1;

        if (currentPage > _pagesettings.pages) {
          currentPage = _pagesettings.pages;
        }
        pagesettings["currentPage"] = currentPage;
      }
    } else if (data === "prev") {
      if (
        _pagesettings.currentPage > 0 &&
        _pagesettings.currentPage <= _pagesettings.pages
      ) {
        var currentPage = _pagesettings.currentPage - 1;
        pagesettings["currentPage"] = currentPage;
      }
    } else if (data === "doublenext") {
      if (
        _pagesettings.currentPage > 0 &&
        _pagesettings.currentPage < _pagesettings.pages
      ) {
        var currentPage = _pagesettings.currentPage + 3;

        if (currentPage > _pagesettings.pages) {
          currentPage = _pagesettings.pages;
        }
        pagesettings["currentPage"] = currentPage;
      }
    } else if (data === "doubleprev") {
      if (
        _pagesettings.currentPage > 0 &&
        _pagesettings.currentPage <= _pagesettings.pages
      ) {
        var currentPage = _pagesettings.currentPage - 3;
        if (currentPage < 0) {
          currentPage = 0;
        }
        pagesettings["currentPage"] = currentPage;
      }
    } else if (data === "itemsperpage") {
      var itemsperpage = parseInt(event.target.value);
      var pages = Math.ceil(_pagesettings.cardProducts / itemsperpage);
      var paginationGroup = [];
      for (let i = 1; i <= pages; i++) {
        paginationGroup.push(i);
      }
      pagesettings["currentPage"] = 1;
      pagesettings["itemsperpage"] = itemsperpage;
      pagesettings["pages"] = pages;
      pagesettings["paginationGroup"] = paginationGroup;
    } else {
      var currentPage = parseInt(data.replace("select", ""));
      pagesettings["currentPage"] = currentPage;
    }
  }
  return pagesettings;
}

export function managesort(_rows, event) {
  var data;
  if (event.target.name) {
    data = event.target.name;
  } else {
    data = event.target.parentNode.name;
  }
  var rows = [];
  if (data === "sort") {
    var sortTye = event.target.value;

    rows = _rows.sort(function (a, b) {
      if (sortTye === "minorprice") {
        return (
          a.body.props.productData.minprice - b.body.props.productData.minprice
        );
      } else if (sortTye === "majorprice") {
        return (
          b.body.props.productData.minprice - a.body.props.productData.minprice
        );
      } else if (sortTye === "ZA") {
        return b.body.props.productData.label < a.body.props.productData.label
          ? -1
          : b.body.props.productData.label > a.body.props.productData.label
          ? 1
          : 0;
      } else if (sortTye === "AZ") {
        return a.body.props.productData.label < b.body.props.productData.label
          ? -1
          : a.body.props.productData.label > b.body.props.productData.label
          ? 1
          : 0;
      } else if (sortTye === "discount") {
        return (
          b.body.props.productData.discount - a.body.props.productData.discount
        );
      } else if (sortTye === "position") {
        return a.body.props.productData.label < b.body.props.productData.label
          ? -1
          : a.body.props.productData.label > b.body.props.productData.label
          ? 1
          : 0;
      } else if (sortTye === "new") {
        return (
          (a.body.props.productData.tag === "new" ? 0 : 1) -
          (b.body.props.productData.tag === "new" ? 0 : 1)
        );

        //	return ((b.body.props.productData.tag < a.body.props.productData.tag) ? -1 : ((b.body.props.productData.tag > a.body.props.productData.tag) ? 1 : 0));
      } else if (sortTye === "ranking") {
        return b.body.props.productData.productname <
          a.body.props.productData.productname
          ? -1
          : b.body.props.productData.productname >
            a.body.props.productData.productname
          ? 1
          : 0;
      }
    });
  }

  return rows;
}

export function searchParent(_jsonObjet, _menuid, _PARENTMENUID) {
  var resp = 0;
  _jsonObjet.map(function (key) {
    if (key[_PARENTMENUID] == _menuid) {
      resp = resp + 1;
    }
  });
  return resp;
}

export function setSpanLabel(treeDataMenu) {
  for (let key in treeDataMenu) {
    treeDataMenu[key]["label"] = <span> {treeDataMenu[key]["label"]} </span>;
  }
}

export function searchBrothers(_jsonObjet, _menuid) {
  var resp = false;
  _jsonObjet.map(function (key) {
    if (key.PARENTMENUID == _menuid) {
      resp = true;
    }
  });
  return resp;
}

export function setMenu(_parent, _menu, _response) {
  var arr = [];
  for (let k in _menu) {
    if (_menu[k].PARENTMENUID == _parent) {
      arr.push(_menu[k]);
    }
  }
  if (arr.length > 0) {
    for (let k in arr) {
      var className;
      var iconLink = "";

      if (arr[k].MENULEVEL == 0) {
        className = "";
      } else if (arr[k].MENULEVEL == 1) {
        className = "";
        iconLink = '<i className="fi-rs-angle-down"></i>';
      } else if (arr[k].MENULEVEL == 2) {
        className = "sub-menu";
        iconLink = '<i className="fi-rs-angle-right"></i>';
      } else if (arr[k].MENULEVEL == 3) {
        className = "level-menu1 level-menu-modify";
      } else if (arr[k].MENULEVEL == 4) {
        className = "level-menu2 level-menu-modify";
      }
      if (arr[k].MENULEVEL == 0) {
      } else {
        if (arr[k].ISPARENT) {
          _response.push('<ul className="' + className + '">\n');
        }

        _response.push(
          ' <li> \n<Link href="' +
            arr[k].MENUURL +
            '">' +
            "	\n<a>\n" +
            arr[k].MENULABEL +
            " " +
            iconLink +
            "	\n</a>" +
            "\n</Link>\n"
        );
      }

      setMenu(arr[k].MENUID, _menu, _response);
      if (arr[k].MENULEVEL == 0) {
      } else {
        _response.push("</li>\n");
        if (arr[k].ISPARENT) {
          _response.push("</ul>\n");
        }
      }
    }
  }
  return;
}

export function validCombo(_product, quantitySelected) {
  var product = _product;
  var features = product["features"];
  var options = product["quantities"];
  var sku = product["sku"];

  //console.log(features,options)
  var isValid = true;
  var combo = null;
  /*
	for (let f  in features) {
		if (!features[f].selectedOption || features[f].selectedOption==null  ){
			isValid=false;
		}
	}*/
  if (isValid) {
    for (let o in options) {
      if (options[o].SKU == sku) {
        if (options[o].NEWPRICEC1 != null) {
          //console.log(quantitySelected,  options[o].NEWPRICEC1, sku)
          if (quantitySelected >= options[o].NEWPRICEC1) {
            combo =
              options[o].NEWPRICEC1 +
              "x" +
              getCurrency() +
              toFixed(options[o].NEWPRICEC2 * options[o].NEWPRICEC1);
            product["combo"] = combo;

            let subtotal = 0;
            let qtyC = -1;
            for (
              let i = 0;
              i <= quantitySelected;
              i = i + options[o].NEWPRICEC1
            ) {
              qtyC++;
            }
            let rest = quantitySelected % options[o].NEWPRICEC1;

            subtotal =
              options[o].NEWPRICEC2 * options[o].NEWPRICEC1 * qtyC +
              rest * options[o].PRICE;
            product["newPrice"] = options[o].NEWPRICEC2;
            product["quantity"] = options[o].NEWPRICEC1;
            product["subtotal"] = subtotal;
          } else {
            product["combo"] = null;
          }
        } else {
          product["combo"] = null;
        }
      }
    }
  } else {
    //console.log("chao  por vali")
    product["combo"] = null;
  }
  //console.log(product)
  return product;
}

export function validDiscount(_product, quantitySelected) {
  var product = _product;
  var features = product["features"];
  var options = product["quantities"];
  var sku = product["sku"];
  var isValid = true;
  var discount = null;
  /*
	for (let f  in features) {
		if (!features[f].selectedOption || features[f].selectedOption==null  ){
			isValid=false;
		}
	}*/
  if (isValid) {
    for (let o in options) {
      if (options[o].sku == sku) {
        if (options[o].newpriced != null) {
          var discountM =
            parseInt(options[o].newpriced2 * 100) +
            "% " +
            getDefValues().offLabel;
          //toFixed(	(100-  (parseInt( options[o].NEWPRICED) *100/parseFloat( options[o].PRICE )) ))
          //	+"% "
          var discount = options[o].newpriced;

          let subtotal = quantitySelected * parseFloat(options[o].newpriced);

          product["discountM"] = discountM;
          product["discount"] = discount;
          product["subtotal"] = subtotal;
        } else {
          product["discount"] = null;
        }
      }
    }
  } else {
    //console.log("chao  por vali")
    product["combo"] = null;
  }
  //console.log(product)
  return product;
}
export function selectOption(
    _optionId,
    _optionValue,
    _product,
    _price,
    _sku,
    _quantities,
    _selectedImage,
    optionsArr
  ) {
    try {
      var resp = {
        success: true,
        product: _product,
        isValid: false,
        changeImage: false,
      };
  
      var product = _product;
      var features = product.features;
      var quantities = _quantities;
      var selectedImage = _selectedImage;
      var isValid = true;
      var isValid1 = true;
      var price = _price;
      var sku = _sku;
      var title = _product.title;
      var maxQuantity = _product.maxQuantity ? _product.maxQuantity : 1;
      var changeImage = false;
      var changePrice= false;
      var sliderConfig = {
        imageCarrousel: true,
        arrows: true,
        dots: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
      };
  
      ///aqui
  
      var options = _product.features;
      var optionsselected = _product.optionsselected;
      if (optionsselected) {
        let pos = find(optionsselected, _optionId, "optionid");
        if (pos >= 0) {
          optionsselected[pos] = {
            optionid: _optionId,
            optionvalue: _optionValue,
          };
        } else {
          optionsselected.push({
            optionid: _optionId,
            optionvalue: _optionValue,
          });
        }
      } else {
        optionsselected = [];
        optionsselected.push({ optionid: _optionId, optionvalue: _optionValue });
      }
      _product["optionsselected"] = optionsselected;
  
      var newImages = _product.originalimages;
  
      var arrSKUs = [];
      var arrPrices = [];
      var arrPaths = [];
      var arrPaths2 = [];
      var arrNames = [];
      var optionsSelected = [];
      var arrOps = [];
      var arrPricesD = [];
      var arrPricesD2 = [];
      var arrPricesC1 = [];
      var arrPricesC2 = [];
      var arrImagesQtys = [];
      var otherSKUs = [];
  
      var currentOptionSelected = "";
      optionsselected.map(function (o) {
        optionsSelected.push(o.optionvalue);
        if (o.optionvalue == _optionValue) {
          currentOptionSelected = o.optionvalue;
        }
      });
  
      if (_product.features.length === optionsselected.length) {
        for (let o in optionsSelected) {
          var arrSKUs = searchArray(
            _product.quantities,
            "optionvalue",
            "sku",
            optionsSelected[o],
            arrSKUs
          );
  
          var arrPaths = searchArray(
            _product.quantities,
            "optionvalue",
            "imagepath",
            optionsSelected[o],
            arrPaths
          );
          var arrPaths2 = searchArray(
            _product.quantities,
            "optionvalue",
            "imagespath",
            optionsSelected[o],
            arrPaths2
          );
          var arrImagesQtys = searchArray(
            _product.quantities,
            "optionvalue",
            "imagesquantity",
            optionsSelected[o],
            arrImagesQtys
          );
          var arrNames = searchArray(
            _product.quantities,
            "optionvalue",
            "specificname",
            optionsSelected[o],
            arrNames
          );
  
          var arrPrices = searchArray(
            _product.quantities,
            "optionvalue",
            "price",
            optionsSelected[o],
            arrPrices
          );
          var arrPricesD = searchArray(
            _product.quantities,
            "optionvalue",
            "newpriced",
            optionsSelected[o],
            arrPricesD
          );
          var arrPricesD2 = searchArray(
            _product.quantities,
            "optionvalue",
            "newpriced2",
            optionsSelected[o],
            arrPricesD2
          );
          var arrPricesC1 = searchArray(
            _product.quantities,
            "optionvalue",
            "newpricec1",
            optionsSelected[o],
            arrPricesC1
          );
          var arrPricesC2 = searchArray(
            _product.quantities,
            "optionvalue",
            "newpricec2",
            optionsSelected[o],
            arrPricesC2
          );
        }
      } else {
        var arrSKUs = searchArray(
          _product.quantities,
          "optionvalue",
          "sku",
          currentOptionSelected,
          arrSKUs
        );
  
        var arrPaths = searchArray(
          _product.quantities,
          "optionvalue",
          "imagepath",
          currentOptionSelected,
          arrPaths
        );
        var arrPaths2 = searchArray(
          _product.quantities,
          "optionvalue",
          "imagespath",
          currentOptionSelected,
          arrPaths2
        );
        var arrImagesQtys = searchArray(
          _product.quantities,
          "optionvalue",
          "imagesquantity",
          currentOptionSelected,
          arrImagesQtys
        );
        var arrNames = searchArray(
          _product.quantities,
          "optionvalue",
          "specificname",
          currentOptionSelected,
          arrNames
        );
  
        var arrPrices = searchArray(
          _product.quantities,
          "optionvalue",
          "price",
          currentOptionSelected,
          arrPrices
        );
        var arrPricesD = searchArray(
          _product.quantities,
          "optionvalue",
          "newpriced",
          currentOptionSelected,
          arrPricesD
        );
        var arrPricesD2 = searchArray(
          _product.quantities,
          "optionvalue",
          "newpriced2",
          currentOptionSelected,
          arrPricesD2
        );
        var arrPricesC1 = searchArray(
          _product.quantities,
          "optionvalue",
          "newpricec1",
          currentOptionSelected,
          arrPricesC1
        );
        var arrPricesC2 = searchArray(
          _product.quantities,
          "optionvalue",
          "newpricec2",
          currentOptionSelected,
          arrPricesC2
        );
      }
      /*
          console.log("optionsSelected",optionsSelected)
          console.log("arrSKUs",arrSKUs)
          console.log("arrPaths",arrPaths)
          console.log("arrPaths2",arrPaths2)
          console.log("arrImagesQtys",arrImagesQtys)
          console.log("arrNames",arrNames)
          console.log("arrPrices",arrPrices)
          console.log("arrPricesD",arrPricesD)
          console.log("arrPricesD2",arrPricesD2)
          console.log("arrPricesC1",arrPricesC1)
          console.log("arrPricesC2",arrPricesC2)
  */
        sku = arrSKUs[0];
        price = arrPrices[0];
      var path = arrPaths[0];
      var name = arrNames[0];
      var discount = arrPricesD2[0];
      var imagesQtys = arrImagesQtys[0];
      var newprice = arrPricesD[0];
      var path2 = arrPaths2[0];
      if (path == null) {
        path = path2 + sku + "_1" + getImgagesExtension();
      }
      var image = "";
      var newImages = [];
      for (let i = 1; i <= imagesQtys; i++) {
        if (i === 1) {
          image = getImagePath(path2 + sku + "_" + i + getImgagesExtension());
        }
        newImages.push({
          image: getImagePath(path2 + sku + "_" + i + getImgagesExtension()),
          imagealt: _product.title,
        });
      }
  
      arrSKUs = [];
      arrOps = [];
      arrOps = searchArray(
        _product.quantities,
        "optionvalue",
        "optionid",
        currentOptionSelected,
        arrOps
      );
      arrSKUs = searchArray(
        _product.quantities,
        "optionvalue",
        "sku",
        currentOptionSelected,
        arrSKUs
      );
  
      for (let k in _product.quantities) {
        let _key = _product.quantities[k];
  
        if (arrSKUs.indexOf(_key.sku) >= 0 && arrOps.indexOf(_key.optionid) < 0) {
          if (_key.quantity > 0) {
            otherSKUs.push(_key);
          }
        }
      }
      var opId = find(optionsArr.rows, arrOps[0], "optionid");
   
      var isInvalid = false;
      //console.log("options",options)
      for (let k in options) {
        var opName = options[k];
        for (let l in opName.optionvalues) {
          if (opName.optionid != arrOps[0]) {
            var opValue = opName.optionvalues[l];
            let pos = find(otherSKUs, opValue.value, "optionvalue");
  
            opValue["banned"] = pos < 0;
            if (pos < 0) {
              //console.log("ban", opValue)
              isInvalid = true;
            }
          }
        }
      }
  
      sliderConfig = {
        imageCarrousel: true,
        arrows: true,
        dots: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
      };
  
      _product["sku"] = sku;
      _product["path"] = path;
      _product["image"] = image;
  
      if (_product.features.length === optionsselected.length) {
        _product["price"] = price;
        _product["title"] = name;
        _product["discount"] = parseFloat(discount);
        _product["images"] = newImages;
        _product["newprice"] = newprice;
        changeImage = true;
      }
      if (opId === 0) {
        changeImage = true;
        _product["images"] = newImages;
      }
  
      if (opId === 1) {
        changePrice = true;
        _product["price"] = price;
        _product["title"] = name;
        _product["discount"] = parseFloat(discount);
        // _product["images"] = newImages; removido para no cambiar el color solo por la talla
        _product["newprice"] = newprice;
      }
  
      resp = {
        success: true,
        product: product,
        isValid: isValid1,
        changeImage: changeImage,
        changePrice:changePrice,
        sliderConfig: sliderConfig,
      };
      //		console.log("newImages",newImages)
      if (newImages.length === 0) {
        resp = {
          success: false,
          message: "ERROR IN IMAGES",
        };
      }
      //		console.log("-------")
      //		console.log(resp)
      //		console.log("-------")
      return resp;
    } catch (Excep) {
      resp = {
        success: false,
        message: "ERROR" + Excep,
      };
      console.log(">>>>>>>", Excep);
      return resp;
    }
}
export async function selectOptionOLD(
  _optionId,
  _optionValue,
  _product,
  _price,
  _sku,
  _quantities,
  _selectedImage,
  optionsArr
) {
  try {
    var resp = {
      success: true,
      product: _product,
      isValid: false,
      changeImage: false,
    };

    var product = _product;
    var features = product.features;
    var quantities = _quantities;
    var selectedImage = _selectedImage;
    var isValid = true;
    var isValid1 = true;
    var price = _price;
    var sku = _sku;
    var title = _product.title;
    var maxQuantity = _product.maxQuantity ? _product.maxQuantity : 1;
    var changeImage = false;
    var sliderConfig = {
      imageCarrousel: true,
      arrows: true,
      dots: true,
      initialSlide: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 200,
    };

    ///aqui

    var options = _product.features;
    var optionsselected = _product.optionsselected;
    if (optionsselected) {
      let pos = find(optionsselected, _optionId, "optionid");
      if (pos >= 0) {
        optionsselected[pos] = {
          optionid: _optionId,
          optionvalue: _optionValue,
        };
      } else {
        optionsselected.push({
          optionid: _optionId,
          optionvalue: _optionValue,
        });
      }
    } else {
      optionsselected = [];
      optionsselected.push({ optionid: _optionId, optionvalue: _optionValue });
    }
    _product["optionsselected"] = optionsselected;

    var newImages = _product.originalimages;

    var arrSKUs = [];
    var arrPrices = [];
    var arrPaths = [];
    var arrPaths2 = [];
    var arrNames = [];
    var optionsSelected = [];
    var arrOps = [];
    var arrPricesD = [];
    var arrPricesD2 = [];
    var arrPricesC1 = [];
    var arrPricesC2 = [];
    var arrImagesQtys = [];
    var otherSKUs = [];

    var currentOptionSelected = "";
    optionsselected.map(function (o) {
      optionsSelected.push(o.optionvalue);
      if (o.optionvalue == _optionValue) {
        currentOptionSelected = o.optionvalue;
      }
    });

    if (_product.features.length === optionsselected.length) {
      for (let o in optionsSelected) {
        var arrSKUs = searchArray(
          _product.quantities,
          "optionvalue",
          "sku",
          optionsSelected[o],
          arrSKUs
        );

        var arrPaths = searchArray(
          _product.quantities,
          "optionvalue",
          "imagepath",
          optionsSelected[o],
          arrPaths
        );
        var arrPaths2 = searchArray(
          _product.quantities,
          "optionvalue",
          "imagespath",
          optionsSelected[o],
          arrPaths2
        );
        var arrImagesQtys = searchArray(
          _product.quantities,
          "optionvalue",
          "imagesquantity",
          optionsSelected[o],
          arrImagesQtys
        );
        var arrNames = searchArray(
          _product.quantities,
          "optionvalue",
          "specificname",
          optionsSelected[o],
          arrNames
        );

        var arrPrices = searchArray(
          _product.quantities,
          "optionvalue",
          "price",
          optionsSelected[o],
          arrPrices
        );
        var arrPricesD = searchArray(
          _product.quantities,
          "optionvalue",
          "newpriced",
          optionsSelected[o],
          arrPricesD
        );
        var arrPricesD2 = searchArray(
          _product.quantities,
          "optionvalue",
          "newpriced2",
          optionsSelected[o],
          arrPricesD2
        );
        var arrPricesC1 = searchArray(
          _product.quantities,
          "optionvalue",
          "newpricec1",
          optionsSelected[o],
          arrPricesC1
        );
        var arrPricesC2 = searchArray(
          _product.quantities,
          "optionvalue",
          "newpricec2",
          optionsSelected[o],
          arrPricesC2
        );
      }
    } else {
      var arrSKUs = searchArray(
        _product.quantities,
        "optionvalue",
        "sku",
        currentOptionSelected,
        arrSKUs
      );

      var arrPaths = searchArray(
        _product.quantities,
        "optionvalue",
        "imagepath",
        currentOptionSelected,
        arrPaths
      );
      var arrPaths2 = searchArray(
        _product.quantities,
        "optionvalue",
        "imagespath",
        currentOptionSelected,
        arrPaths2
      );
      var arrImagesQtys = searchArray(
        _product.quantities,
        "optionvalue",
        "imagesquantity",
        currentOptionSelected,
        arrImagesQtys
      );
      var arrNames = searchArray(
        _product.quantities,
        "optionvalue",
        "specificname",
        currentOptionSelected,
        arrNames
      );
    }
    /*
		console.log("optionsSelected",optionsSelected)
		console.log("arrSKUs",arrSKUs)
		console.log("arrPaths",arrPaths)
		console.log("arrPaths2",arrPaths2)
		console.log("arrImagesQtys",arrImagesQtys)
		console.log("arrNames",arrNames)
		console.log("arrPrices",arrPrices)
		console.log("arrPricesD",arrPricesD)
		console.log("arrPricesD2",arrPricesD2)
		console.log("arrPricesC1",arrPricesC1)
		console.log("arrPricesC2",arrPricesC2)
*/
    var sku = arrSKUs[0];
    var price = arrPrices[0];
    var path = arrPaths[0];
    var name = arrNames[0];
    var discount = arrPricesD2[0];
    var imagesQtys = arrImagesQtys[0];
    var newprice = arrPricesD[0];
    var path2 = arrPaths2[0];
    if (path == null) {
      path = path2 + sku + "_1" + getImgagesExtension();
    }
    var image = "";
    var newImages = [];
    for (let i = 1; i <= imagesQtys; i++) {
      if (i === 1) {
        image = getImagePath(path2 + sku + "_" + i + getImgagesExtension());
      }
      newImages.push({
        image: getImagePath(path2 + sku + "_" + i + getImgagesExtension()),
        imagealt: _product.title,
      });
    }

    arrSKUs = [];
    arrOps = [];
    arrOps = searchArray(
      _product.quantities,
      "optionvalue",
      "optionid",
      currentOptionSelected,
      arrOps
    );
    arrSKUs = searchArray(
      _product.quantities,
      "optionvalue",
      "sku",
      currentOptionSelected,
      arrSKUs
    );

    for (let k in _product.quantities) {
      let _key = _product.quantities[k];

      if (arrSKUs.indexOf(_key.sku) >= 0 && arrOps.indexOf(_key.optionid) < 0) {
        if (_key.quantity > 0) {
          otherSKUs.push(_key);
        }
      }
    }
    var opId = find(optionsArr.rows, arrOps[0], "optionid");

    var isInvalid = false;
    //console.log("options",options)
    for (let k in options) {
      var opName = options[k];
      for (let l in opName.optionvalues) {
        if (opName.optionid != arrOps[0]) {
          var opValue = opName.optionvalues[l];
          let pos = find(otherSKUs, opValue.value, "optionvalue");

          opValue["banned"] = pos < 0;
          if (pos < 0) {
            //console.log("ban", opValue)
            isInvalid = true;
          }
        }
      }
    }

    sliderConfig = {
      imageCarrousel: true,
      arrows: true,
      dots: true,
      initialSlide: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 200,
    };

    _product["sku"] = sku;
    _product["path"] = path;
    _product["image"] = image;

    if (_product.features.length === optionsselected.length) {
      _product["price"] = price;
      _product["title"] = name;
      _product["discount"] = parseFloat(discount);
      _product["images"] = newImages;
      _product["newprice"] = newprice;
      changeImage = true;
    }
    if (opId == 0) {
      changeImage = true;
      _product["images"] = newImages;
    }
    resp = {
      success: true,
      product: product,
      isValid: isValid1,
      changeImage: changeImage,
      sliderConfig: sliderConfig,
    };
    //		console.log("newImages",newImages)
    if (newImages.length === 0) {
      resp = {
        success: false,
        message: "ERROR IN IMAGES",
      };
    }
    //		console.log("-------")
    //		console.log(resp)
    //		console.log("-------")
    return resp;
  } catch (Excep) {
    resp = {
      success: false,
      message: "ERROR" + Excep,
    };
    console.log(">>>>>>>", Excep);
    return resp;
  }
}
export function compareArrayObj(_array1, _array2) {
  var isEqual = true;
  if (_array1.length === _array2.length) {
    for (let i in _array1) {
      var key = _array1[i];
      var key2 = _array2[i];
      if (JSON.stringify(key) != JSON.stringify(key2)) {
        isEqual = false;
      }
    }
  } else {
    isEqual = false;
  }
  return isEqual;
}

export function searchArray(_array, _item, _field, _itemVal, _arrValids) {
  var ret = false;
  var result = [];
  for (let i in _array) {
    if (_array[i][_item] == _itemVal) {
      if (_arrValids.length === 0) {
        result.push(_array[i][_field]);
      } else {
        if (_arrValids.indexOf(_array[i][_field]) >= 0) {
          result.push(_array[i][_field]);
        }
      }
    }
  }
  return result;
}

export async function addWishlist(_productId, _toast) {
  var resp = {
    success: true,
    message: "",
  };
  try {
    var _prod = (_productId + "").split("_C_");

    if (getLoginInfo().userId != null) {
      if (_prod.length === 1) {
        resp = await callApi("frontend", "addWishlist", {
          productid: _productId,
          customerid: getLoginInfo().userId,
        });
      } else {
        _productId = _productId.replace("_C_", "");
        resp = await callApi("frontend", "addWishlist", {
          productid: _prod[0],
          sku: _prod[1],
          customerid: getLoginInfo().userId,
        });
        var errorcode = "ADD";
        var tempW = localStorage.getItem(
          process.env.REACT_APP_ORG_NAME + "_wishSession"
        );
        if (tempW) {
          var data = JSON.parse(
            localStorage.getItem(
              process.env.REACT_APP_ORG_NAME + "_wishSession"
            )
          );
          if (data.indexOf(parseInt(_productId)) >= 0) {
            errorcode = "REMOVE";
          }
        }
      }
      if (resp.success) {
        var message;
        if (resp.errorcode === "ADD") {
          addWishListCokie(_productId);
          message = getDefValues().successfullAddedToWishListLabel;
          showMessage(_toast, message);
        } else if (resp.errorcode === "REMOVE") {
          removeWishListCokie(_productId);
          message = getDefValues().successfullRemovedToWishListLabel;
          showError(_toast, message);
        }
      } else {
        showError(_toast, getDefValues().errorAddedToWishListLabel);
      }
    } else {
      showWarning(_toast, getDefValues().firstLoginLabel);
      resp = { success: false };
    }

    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = { success: false, message: err };
    return resp;
  }
}

export async function logout() {
  var resp = {
    success: true,
    message: "",
  };
  try {
    setCookie(process.env.REACT_APP_ORG_NAME + "_evventId", null, 0.0001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_evventQty", null, 0.0001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_evventName", null, 0.0001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_action", null, 0.0001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_userloginV2", null, 0.0001);
    setCookie(
      process.env.REACT_APP_ORG_NAME + "_storesInfoCookie",
      null,
      0.0001
    );

    localStorage.removeItem(process.env.REACT_APP_ORG_NAME + "_userloginV2");
    localStorage.removeItem(process.env.REACT_APP_ORG_NAME + "_storesInfoNew");
    localStorage.removeItem(process.env.REACT_APP_ORG_NAME + "_haveEvent");

    clearWishList();
    clearCart();

    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = { success: false, message: err };
    return resp;
  }
}

export async function recoveryPassOld(_formData, _toast) {
  var resp = {
    success: false,
    message: "",
  };
  try {
    let validation = await validateForm(_formData, _toast);
    _formData = parseFormData(_formData);
    if (validation.isValid) {
      resp = await callApi("frontend", "getTokenEmail", _formData);
      if (!resp.success) {
        if (resp.errorcode === "002") {
          showError(_toast, getDefValues().userExistsLabel);
        } else if (resp.errorcode === "002") {
          //all error codes are added here
          showError(_toast, getDefValues().userExistsLabel);
        }
      } else {
        /*
				var formData = {
			 username : _user,
			 password : _password
			}
			*/
      }
    }

    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = {
      success: false,
      message: err,
    };
    return resp;
  }
}

export async function setCustomers(_formData, _toast) {
  var resp = {
    success: false,
    message: "",
  };
  try {
    let validation = await validateForm(_formData, _toast);
    _formData = parseFormData(_formData);
    if (validation.isValid) {
      resp = await callApi("frontend", "setCustomers", _formData);
      if (!resp.success) {
        if (resp.errorcode === "002") {
          showError(_toast, getDefValues().userExistsLabel);
        }
      }
    }
    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = {
      success: false,
      message: err,
    };
    return resp;
  }
}

export async function updateCustomers(_formData, _toast) {
  var resp = {
    success: false,
    message: "",
  };
  try {
    let validation = await validateForm(_formData, _toast);
    _formData = parseFormData(_formData);
    if (validation.isValid) {
      resp = await callApi("frontend", "updateCustomers", _formData);
      if (!resp.success) {
        if (resp.errorcode === "002") {
          showError(_toast, getDefValues().userExistsLabel);
        } else {
          showError(_toast, getDefValues().updateErrorLabel);
        }
      } else {
        showMessage(_toast, getDefValues().updateSuccessLabel);
      }
    }
    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = {
      success: false,
      message: err,
    };
    return resp;
  }
}

export async function changePassword(_formData, _toast) {
  var resp = {
    success: false,
    message: "",
  };
  try {
    let validation = await validateForm(_formData, _toast);
    _formData = parseFormData(_formData);

    if (validation.isValid) {
      resp = await callApi("frontend", "changePassword", _formData);
      if (!resp.success) {
        if (resp.errorcode === "002") {
          showError(_toast, getDefValues().wrongPasswordLabel);
        }
      } else {
        showError(_toast, getDefValues().updateSuccessChangePassLabel);
      }
    }
    return resp;
  } catch (err) {
    console.log(">>>>>>>", err);
    resp = {
      success: false,
      message: err,
    };
    return resp;
  }
}

/*predefined otpions*/
export async function getTopMessage(_email) {
  var messages = [];
  try {
    var tempMessages = getCookie(
      process.env.REACT_APP_ORG_NAME + "_TopMessagesDefinitions"
    );
    if (tempMessages) {
      messages = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_TopMessagesDefinitions")
      );
    } else {
      var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: "messages",
        foldername: privateFolder + "private/MESSAGES/",
      });
      var _messages = resp.template;
      var _store = getSyncStoresInfo();

      var selectedStoreId = _store.selectedStoreId;
      var languageId = getLanguage();

      _messages.map(function (key) {
        if (key.languageId == languageId) {
          key.messages.map(function (m) {
            let from = new Date(m.from);
            let to = new Date(m.to);
            let sysdate = new Date();
            if (sysdate >= from && sysdate <= to) {
              if (m.storeId == selectedStoreId || m.storeId === "") {
                messages.push({
                  name: m.name,
                  message: m.message,
                  link: m.link,
                  style: m.style,
                });
              }
            }
          });
        }
      });
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_TopMessagesDefinitions",
        JSON.stringify(messages),
        1
      );
    }
  } catch (err) {
    console.log(">>>>>>>", err);
    messages = [];
  } finally {
    return messages;
  }
}

export async function getTagDefinitions() {
  var resp = {
    tags: 0,
    success: false,
  };
  var tags = [];
  try {
    var temOptions = getCookie(
      process.env.REACT_APP_ORG_NAME + "_TagDefinitions"
    );
    if (temOptions) {
      tags = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_TagDefinitions")
      );
      if (tags.length === 0) {
        /*
					var resp = await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "tags", foldername: privateFolder+"private/TAGS/" } )
					var _tags = resp.template;
					var _store = await getStoresInfo()
					var selectedStoreId = _store.selectedStoreId
					var languageId = getLanguage()
					_tags.map(function (key) {
					 if (  key.languageId == languageId  ){
					 	key.tags.map(function (m) {
					 		let from = new Date(m.from)
					 		let to = new Date(m.to)
					 		let sysdate = 	 new Date()
					 		if (  sysdate >= from && sysdate<=to ){

								tags.push ({
									id: m.id,
									storeId: m.storeId,
									name: m.name,
									label: m.label,
									style: m.style
								})

					 		}
						})
					 }
					})
					setCookie(process.env.REACT_APP_ORG_NAME+'_TagDefinitions',  JSON.stringify(tags), 1 )
					*/
      }
    } else {
      var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: "tags",
        foldername: privateFolder + "private/TAGS/",
      });
      var _tags = resp.template;
      var _store = await getStoresInfo();
      var selectedStoreId = _store.selectedStoreId;
      var languageId = getLanguage();
      _tags.map(function (key) {
        if (key.languageId == languageId) {
          key.tags.map(function (m) {
            let from = new Date(m.from);
            let to = new Date(m.to);
            let sysdate = new Date();
            if (sysdate >= from && sysdate <= to) {
              tags.push({
                id: m.id,
                storeId: m.storeId,
                name: m.name,
                label: m.label,
                style: m.style,
              });
            }
          });
        }
      });
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_TagDefinitions",
        JSON.stringify(tags),
        1
      );
    }
    resp = {
      success: tags.length > 1 ? true : false,
      tags: tags,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      tags: [],
      success: false,
      message: Excep,
    };
  } finally {
    return resp;
  }
}

export function getsyncTagDefinitions() {
  var resp = {
    tags: 0,
    success: false,
  };
  var tags = [];
  try {
    var temOptions = getCookie(
      process.env.REACT_APP_ORG_NAME + "_TagDefinitions"
    );
    if (temOptions) {
      var tags = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_TagDefinitions")
      );
    } else {
      tags = [];
    }
    resp = {
      success: tags.length > 1 ? true : false,
      tags: tags,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      tags: 0,
      success: false,
      message: Excep,
    };
  } finally {
    return resp;
  }
}

/*
export async function getTags() {

	var messages =[];
	try{
		var resp = await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "messages", foldername: "layouts/private/MESSAGES/" } )
		var _messages = resp.template;
		var _store = await getStoresInfo()

		var selectedStoreId = _store.selectedStoreId
		var languageId = getLanguage()

		_messages.map(function (key) {
		 if (  key.languageId == languageId  ){
		 	key.messages.map(function (m) {


		 		let from = new Date(m.from)
		 		let to = new Date(m.to)

		 		let sysdate = 	 new Date()

		 		if (  sysdate >= from && sysdate<=to ){
		 			if ( m.storeId == selectedStoreId || m.storeId ==""  ){
					messages.push ({
						name: m.name,
						message: m.message,
						link: m.link,
						style: m.style
					})
					}
		 		}
			})
		 }
		})

	}catch(err){
		console.log(">>>>>>>",err)
		messages=[]
	}finally{
		return messages
	}
}
*/
export async function getPersonalDataForm() {
  var formData = {},
    formConfig = {};
  try {
    //	await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "form2", foldername: "layouts/" } )

    //var resp2 = await getLayoutTemplateByNameDirect(  privateFolder+"private/" , "personalDataForm_"+getLanguage() )

    //var resp2 = await callApi ( "layaoutManager","	", { layoutFile: "personalDataForm_"+getLanguage(), foldername: privateFolder+"private/" } )

    //console.log(resp2)
    var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: "personalDataForm_" + getLanguage(),
      foldername: privateFolder + "private/",
    });

    for (let i in resp.template) {
      let key = resp.template[i];
      if (key.componentType === "FORM") {
        let rendegObj = await getRenderObj(
          key,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        let finalData = rendegObj.curdata.finalData;
        formData = rendegObj.curdata.finalData.formData;
        formConfig = rendegObj.curdata.finalData.formConfig;
      }
    }

    /* 
			  resp =  await getStoresInfo();

			var selectedStoreId = resp.selectedStoreId
			
			var cityValues = []

			if (selectedStoreId == 62 ){
				cityValues = [
			 				{text:"Tarija", value:"4"},
			 				{text:"Sucre", value:"5"},
			 				{text:"Potos\u00ED", value:"6"},

			 				{text:"Oruro", value:"7"},
			 				{text:"Pando", value:"8"},
			 				{text:"Beni", value:"9"}
			 			]
			}else{
				resp.stores.map(function (key) {
				 if (key.storeId == selectedStoreId ){
				 	cityValues = [
			 			{text:key.storeName, value: key.storeId }
			 		]
				 }
				})
			}
		
		

		formData = {
			 		email          : {label: getDefValues().emailLabel     , size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6"  , value:"" , type: "INPUT", validationtype : "email"},
			 		LINEBREAK      : {type: "LINEBREAK", size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6" },
			 		firstname      : {label: getDefValues().firstNameLabel , size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6"  , value:"" , type: "INPUT", validationtype : "text" },
			 		lastname       : {label: getDefValues().lastNameLabel  , size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6"  , value:"" , type: "INPUT", validationtype : "text" },
			 		phonecode      : {label: getDefValues().codeLabel      , size:"col-4", sizesm:"col-sm-3",  sizemd:"col-md-2", sizelg:"col-lg-2"  , value:"+591" , defultvalue:"+591", type: "INPUT", validationtype : "text" , disabled:false },
			 		phone          : {label: getDefValues().phoneLabel , validationlabel: getDefValues().invalidPhoneNumberLabel   , size:"col-8", sizesm:"col-sm-9",  sizemd:"col-md-4", sizelg:"col-lg-4"  , value:"" , type: "INPUT", validationtype : "number", minvalue:20000000, maxvalue:79999999 },
			 		city           : {label: getDefValues().cityLabel      , size:"col-12", sizesm:"col-sm-12", sizemd:"col-md-6", sizelg:"col-lg-6"  , value:selectedStoreId ,
			 											values         : cityValues, type: "SELECT", validationtype : "text" },

			 		invoicelabel   : {label: "Datos de Facturaci\u00F3n"        , size:"col-12", sizesm:"col-sm-12",  sizemd:"col-md-12", sizelg:"col-lg-12"  , value:"" , type: "LABEL", class:"h4-responsive" },
			 		tipodoc        : {label: "Tipo de Documento"           , size:"col-12", sizesm:"col-sm-9",  sizemd:"col-md-12", sizelg:"col-lg-6"  , value:"" , type: "SELECT", validationtype : "number",
			 			values:
			 			 [
			 			 {value:"", text:"Seleccione un Tipo"},
			 			 {value:1, text:"C\u00E9dula de Identidad"},
			 			 {value:2, text:"C\u00E9dula de Identidad de Extranjero"},
			 			 {value:3, text:"Pasaporte"},
			 			 {value:4, text:"Otro Documento de Identidad"},
			 			 {value:5, text:"N\u00FAmero de Identificaci\u00F3n Tributaria"} ] },

			 		invoicenumber  : {label: "N\u00FAmero de Identificaci\u00F3n", minlength:5, size:"col-12", sizesm:"col-sm-12",  sizemd:"col-md-3", sizelg:"col-lg-3"  ,
			 	                    value:"" , type: "INPUT",  validationtype : "number"  },

			 	  complemento  : {label: "Complemento"     , size:"col-12", sizesm:"col-sm-12",  sizemd:"col-md-3", sizelg:"col-lg-3",
			 		aditionalLabel:"(*)S\u00F3lo llenar si tu CI es duplicado", value:"" , type: "INPUT", notRequired:true, maxlength:2, validationtype:"text" },

			 		invoicename    : {label: "Raz\u00F3n Social"    ,defaultvalue:"Ej. P\u00E9rez"          , size:"col-12", sizesm:"col-sm-9",  sizemd:"col-md-12", sizelg:"col-lg-6"  , value:"" , type: "INPUT" },




		}
		var formConfig ={ formlinetitle: "YES", colsize: "col-12", formtitle: getDefValues().personalDataLabel.toUpperCase() }
		*/
  } catch (err) {
    console.log(">>>>>>>", err);
    formData = { error: err };
  } finally {
    return { formData, formConfig };
  }
}

export async function getUserInfo() {
  var login = getLoginInfo();
  if (login.userId) {
    let info = await callApi("admin", "getCustomerById", {
      customerid: login.userId,
    });
    return info;
  } else {
    return {
      USER: null,
      ADDRESS: [],
    };
  }
}

export async function getPolygonsByCity(city) {
  var coords = [];
  try {
    let info = await callApi("frontend", "getPolygonsByCity", { city: city });
    var coords = [];
    if (info.success) {
      coords = info.rows;
    }
    return coords;
  } catch (Ex) {
    return coords;
    console.log(">>>>>>", Ex);
  }
}

export async function getShippingMethodsForm(_formData) {
  var formData = [];
  try {
    var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: "shippingForm_" + getLanguage(),
      foldername: privateFolder + "private/",
    });

    for (let i in resp.template) {
      let key = resp.template[i];
      if (key.componentType === "FORM") {
        let rendegObj = await getRenderObj(
          key,
          null,
          null,
          null,
          null,
          null,
          null,
          _formData
        );

        let finalData = rendegObj.curdata.finalData;
        finalData["shippingCost"] = rendegObj.curdata.finalData.formData
          .shippingCost.value
          ? rendegObj.curdata.finalData.formData.shippingCost.value
          : 0;
        formData.push(finalData);
      }
    }

    for (let i in resp.template) {
      if (resp.template[i].componentType === "FORMCONTAINER") {
        try {
          await eval(resp.template[i].config.postprocessing);
          /*****************EVAL***********/

          /*****************EVAL***********/
        } catch (Excep) {
          console.log(">>>>>>>>ERROR EXECUTING SHIPPING FORM SCRIPT", Excep);
          console.log(resp.template[i].config.postprocessing);
        }
      }
    }
  } catch (err) {
    console.log(">>>>>>>", err);
    formData = [];
  } finally {
    return formData;
  }
}

export async function getShippingMethodsFormOld(_formData) {
  var formData = [];
  try {
    //	await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "form2", foldername: "layouts/" } )

    var coordinates = await getCoordinatesStore();

    var gmpasConfig = { lat: coordinates.lat, lng: coordinates.lng };
    var resp = await getStoresInfo();
    var storeValues = [];
    resp.stores.map(function (key) {
      if (key.storeId == resp.selectedStoreId) {
        storeValues.push({ text: key.storeName, value: key.storeId });
      }
    });

    formData = [
      {
        formName: "Recojo en Tienda",
        formLabel: "Recojo en Tienda",
        formOptionID: "1",
        shippingCost: 0,
        isValid: false,
        formData: {
          storeName: {
            label: getDefValues().storeNameLabel,
            value: storeValues[0].value,
            values: storeValues,
            type: "SELECT",
            validationtype: "text",
          },

          shippingday: {
            label: "Dia para el retiro",
            value: "",
            values: [
              { text: "Lun 24/02/22", value: "20211219" },
              { text: "Mar 25/02/221", value: "20211220" },
              { text: "Mier 26/02/22", value: "20211221" },
            ],
            type: "ITEMS",
            validationtype: "text",
          },
          shippinghour: {
            label: "hora para el retiro",
            value: "",
            values: [
              { text: "9:00 a 9:30", value: "1" },
              { text: "10:00 a 10:30", value: "2" },
              { text: "12:00 a 12:30", value: "3" },
            ],
            type: "ITEMS",
            validationtype: "text",
          },
        },
        formConfig: {},
      },
      {
        formName: "Envio regular",
        formLabel: "Envio regular",
        formOptionID: "2",
        shippingCost: 15,
        isValid: false,
        formData: {
          street: {
            label: getDefValues().streetLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
          referenceaddress: {
            label: getDefValues().referenceAddressLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
          additionalShippingInstructions: {
            label: getDefValues().additionalShippingInstructionsLabel,
            value: "",
            notRequired: true,
            type: "TEXTAREA",
          },
          georeference: {
            label: "",
            config: { lat: -16.5345619, lng: -68.0900024, zoom: 13 },
            value: {},
            type: "GMAP",
            validationtype: "gmap",
          },
        },
        formConfig: {},
      },
      {
        formName: "Envio Express",
        formLabel: "Envio Express",
        formOptionID: "3",
        shippingCost: 25,
        isValid: false,
        formData: {
          street: {
            label: getDefValues().streetLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
          referenceaddress: {
            label: getDefValues().referenceAddressLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
          additionalShippingInstructions: {
            label: getDefValues().additionalShippingInstructionsLabel,
            value: "",
            notRequired: true,
            type: "TEXTAREA",
          },
          georeference: {
            label: "",
            config: { lat: -16.5345619, lng: -68.0900024, zoom: 13 },
            value: {},
            type: "GMAP",
            validationtype: "gmap",
          },
        },
        formConfig: {},
      },

      {
        formName: "Recojo en Terminal",
        formLabel: "Recojo en Terminal",
        formOptionID: "4",
        shippingCost: 25,
        isValid: false,
        formData: {
          terminalName: {
            label: getDefValues().terminalNameLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
        },
        formConfig: {},
      },
      {
        formName: "Envio a domicilio",
        formLabel: "Envio domicilio",
        formOptionID: "5",
        shippingCost: 80,
        isValid: false,
        formData: {
          street: {
            label: getDefValues().streetLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
          referenceaddress: {
            label: getDefValues().referenceAddressLabel,
            value: "",
            type: "INPUT",
            validationtype: "text",
          },
          additionalShippingInstructions: {
            label: getDefValues().additionalShippingInstructionsLabel,
            value: "",
            notRequired: true,
            type: "TEXTAREA",
          },
          georeference: {
            label: "",
            config: { lat: -16.5345619, lng: -68.0900024, zoom: 13 },
            value: {},
            type: "GMAP",
            validationtype: "gmap",
          },
          fastShipping: {
            label: getDefValues().fastShippingLabel,
            value: "NO",
            values: [
              { value: "YES", text: "SI" },
              { value: "NO", text: "NO" },
            ],
            type: "RADIO",
            validationtype: "text",
          },
        },
        formConfig: {},
      },
    ];

    if (
      _formData.city.toString() === "59" ||
      _formData.city.toString() === "60" ||
      _formData.city.toString() === "61"
    ) {
      formData.splice(3, 2);
    } else {
      formData.splice(0, 3);
    }
  } catch (err) {
    console.log(">>>>>>>", err);
    formData = [];
  } finally {
    return formData;
  }
}

export function buildSearchData(
  resultsearch,
  _searchdata,
  _optionsnames,
  buildcat,
  buildopt,
  buildprice
) {
  var searchdata = {};
  try {
    var categories = [];
    var optionsmaster = [];
    var minprice = 0;
    var maxprice = 0;
    for (let r in resultsearch) {
      minprice =
        minprice > convertNumber(resultsearch[r].minprice)
          ? convertNumber(resultsearch[r].minprice)
          : minprice;
      maxprice =
        maxprice < convertNumber(resultsearch[r].maxprice)
          ? convertNumber(resultsearch[r].maxprice)
          : maxprice;

      let category = resultsearch[r].categories;
      for (let c in category) {
        var position = find(categories, category[c].categoryid, "categoryid");
        if (position < 0) {
          categories.push({
            categoryid: category[c].categoryid,
            categoryname: category[c].categoryname,
            quantity: 1,
          });
        } else {
          let curquantity = categories[position].quantity;
          categories[position] = {
            categoryid: category[c].categoryid,
            categoryname: category[c].categoryname,
            quantity: curquantity + 1,
          };
        }
      }

      let option = resultsearch[r].options;
      for (let c in option) {
        var position = find(optionsmaster, option[c].optionid, "optionid");

        var options = [];
        if (position < 0) {
          let newoption = {
            optionid: option[c].optionid,
            optionname: findValueById(
              _optionsnames,
              option[c].optionid,
              "optionid",
              "optionname"
            ),
            showoptions: false,
            listoptions: true,
            selectedoptions: [],
            options: [],
          };
          optionsmaster.push(newoption);
        } else {
          options = optionsmaster[position].options;
        }
        for (let v in option[c].optionvalues) {
          let optionvalue = option[c].optionvalues[v].filtername;
          let position = find(options, optionvalue, "value");
          if (position < 0) {
            options.push({ value: optionvalue, quantity: 1 });
          } else {
            let curquantity = options[position].quantity;
            options[position] = {
              value: optionvalue,
              quantity: curquantity + 1,
            };
          }
        }
        position = find(optionsmaster, option[c].optionid, "optionid");
        let selectedoptions = findValueById(
          _searchdata.options,
          option[c].optionid,
          "optionid",
          "selectedoptions"
        );

        if (Array.isArray(selectedoptions)) {
          let _options = [];
          options.map(function (key) {
            _options.push(key.value);
          });
          let newselectedoptions = selectedoptions.filter(
            (value) => -1 !== _options.indexOf(value)
          );
          optionsmaster[position].selectedoptions = newselectedoptions;
        }

        optionsmaster[position].options = options;
      }
    }

    var searchdata = _searchdata;

    if (buildcat) {
      searchdata["categories"] = categories;
    }
    if (buildopt) {
      searchdata["options"] = optionsmaster;
    }
    if (buildprice) {
      searchdata["tmp_minprice"] = minprice;
      searchdata["tmp_maxprice"] = maxprice;
      searchdata["tmp_minpricedef"] = minprice;
      searchdata["tmp_maxpricedef"] = maxprice;
    }
  } catch (err) {
    console.log(">>>>>>>", err);
    searchdata = {};
  } finally {
    return searchdata;
  }
}

export function manageSearch(
  event,
  _searchdata,
  _originalresults,
  _optionsnames
) {
  var searchdata = _searchdata;
  var updateresults = false;

  var buildcat = false;
  var buildopt = false;
  var buildprice = false;

  try {
    var apllyfilters = false;

    var _data = findObjName(event);
    var data = _data.split("_");
    console.log(data);
    if (data[0] === "search") {
      searchdata["searchinput"] = event.target.value;
    } else if (data[0] === "changeshow") {
      searchdata["showtype"] = data[1];
    } else if (data[0] === "close") {
      searchdata["showresults"] = false;
      searchdata["searchinput"] = "";
    } else if (data[0] === "hide") {
      if (data[2]) {
        let pos = find(searchdata.options, parseInt(data[2]), "optionid");
        searchdata.options[pos][data[1]] = false;
      } else {
        searchdata[data[1]] = false;
      }
    } else if (data[0] === "show") {
      if (data[2]) {
        let pos = find(searchdata.options, parseInt(data[2]), "optionid");
        searchdata.options[pos][data[1]] = true;
      } else {
        searchdata[data[1]] = true;
      }
    } else if (data[0] === "toggle") {
      if (data[2]) {
        let pos = find(searchdata.options, parseInt(data[2]), "optionid");
        searchdata.options[pos][data[1]] = searchdata.options[pos][data[1]]
          ? false
          : true;
      } else {
        searchdata[data[1]] = searchdata[data[1]] ? false : true;
      }
    } else if (data[0] === "filter") {
      console.log(data);
      apllyfilters = true;
      if (data[3]) {
        buildprice = true;
        buildcat = true;
        let pos = find(searchdata.options, parseInt(data[2]), "optionid");
        let pos2 = searchdata.options[pos]["selectedoptions"].indexOf(data[3]);
        if (pos2 >= 0) {
          searchdata.options[pos]["selectedoptions"].splice(pos2, 1);
        } else {
          searchdata.options[pos]["selectedoptions"].push(data[3]);
        }
        searchdata.options[pos]["listoptions"] =
          searchdata.options[pos]["selectedoptions"] == 0 ? true : false;
      } else {
        buildprice = true;
        buildopt = true;
        let pos = searchdata["selected" + data[1]].indexOf(data[2]);
        if (pos >= 0) {
          searchdata["selected" + data[1]].splice(pos, 1);
        } else {
          searchdata["selected" + data[1]].push(data[2]);
        }
        searchdata["list" + data[1]] =
          searchdata["selected" + data[1]].length === 0 ? true : false;
      }
    } else if (data[0] === "changeprice") {
      buildopt = true;
      buildcat = true;
      apllyfilters = true;
      var change = true;
      let maxprice = convertNumber(searchdata["maxprice"]);
      let minprice = convertNumber(searchdata["minprice"]);
      let curprice = convertNumber(event.target.value);
      if (
        (data[1] === "minprice" && curprice > maxprice) ||
        (data[1] === "maxprice" && curprice < minprice)
      ) {
        change = false;
      }
      if (change) {
        searchdata[data[1]] = parseInt(event.target.value);
      }
    }
    var newlist = [];
    if (apllyfilters) {
      updateresults = true;
      for (let k in _originalresults) {
        var additem = true;
        /*categories*/
        if (searchdata.selectedcategories.length > 0) {
          updateresults = false;
          let categories = [];
          _originalresults[k].categories.map(function (key, item) {
            categories.push(key.categoryid + "");
          });
          let cat = searchdata.selectedcategories.filter(
            (value) => -1 !== categories.indexOf(value)
          );
          if (cat.length === 0) {
            additem = false;
          }
        } else {
          additem = true;
        }
        /*prices*/
        if (
          searchdata.minprice !== searchdata.minpricedef ||
          searchdata.maxprice !== searchdata.maxpricedef
        ) {
          updateresults = false;
        }
        if (additem) {
          if (
            _originalresults[k].minprice < searchdata.minprice ||
            _originalresults[k].maxprice > searchdata.maxprice
          ) {
            additem = false;
          }
          if (additem) {
            /*options*/
            var needvalidation = false;
            var tempvalid = false;

            searchdata.options.map(function (key) {
              if (key.selectedoptions.length > 0) {
                updateresults = false;
                needvalidation = true;
                var optionid = key.optionid;
                let options = [];
                _originalresults[k].options.map(function (opt) {
                  if (opt.optionid == optionid) {
                    opt.optionvalues.map(function (val) {
                      options.push(val.filtername + "");
                    });
                  }
                });
                let opt = key.selectedoptions.filter(
                  (value) => -1 !== options.indexOf(value)
                );
                if (opt.length > 0) {
                  tempvalid = true;
                }
              }
            });

            if (needvalidation) {
              additem = tempvalid;
            }
          }
        }
        if (additem) {
          newlist.push(_originalresults[k]);
        }
      }
      searchdata["results"] = newlist;
    }
    if (updateresults) {
      searchdata["results"] = _originalresults;
      searchdata = buildSearchData(
        _originalresults,
        searchdata,
        _optionsnames,
        true,
        true,
        true
      );
    } else {
      searchdata = buildSearchData(
        newlist,
        searchdata,
        _optionsnames,
        buildcat,
        buildopt,
        buildprice
      );
    }
  } catch (err) {
    console.log(">>>>>>>", err);
    searchdata = searchdata;
  } finally {
    return searchdata;
  }
}

export async function getOptions() {
  var resp = {
    rows: 0,
    success: false,
  };
  var rows = [];
  try {
    var temOptions = getCookie(
      process.env.REACT_APP_ORG_NAME + "_ProductOptions"
    );
    if (temOptions) {
      var rows = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_ProductOptions")
      );
      if (rows.length === 0) {
        let resp = await callApi("customer", "getOptionsDB", {
          languageid: getLanguage(),
        });
        rows = resp.rows;
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_ProductOptions",
          JSON.stringify(rows),
          24
        );
      }
    } else {
      let resp = await callApi("customer", "getOptionsDB", {
        languageid: getLanguage(),
      });
      rows = resp.rows;
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_ProductOptions",
        JSON.stringify(rows),
        24
      );
    }
    resp = {
      success: rows.length > 1 ? true : false,
      rows: rows,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      rows: 0,
      success: false,
      message: Excep,
    };
  } finally {
    return resp;
  }
}

export function getsyncOptions() {
  var tempW = getCookie(process.env.REACT_APP_ORG_NAME + "_ProductOptions");
  var data = [];
  var resp = {
    rows: 0,
    success: false,
  };

  try {
    if (tempW) {
      data = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_ProductOptions")
      );
    } else {
      data = [];
    }
    resp = {
      success: data.length > 1 ? true : false,
      rows: data,
    };
  } catch (Excep) {
    resp = {
      rows: 0,
      success: false,
      message: Excep,
    };
  } finally {
    return resp;
  }
}

export async function getLayoutTemplateByName() {
  const axios = require("axios");
  var resp = {};

  try {
    /*
    const response = await axios.get('https://cidev-files.s3.sa-east-1.amazonaws.com/layouts/private/home_3', { responseType: 'json' });
    if (response.status === 200) {
      console.log("ok")
    }*/
  } catch (e) {
    console.log("ERROR", e);
  }
  return resp;
}

export async function getFilterProductsElastic(_data) {
  //	console.log("_data",_data)
  var resp = {
    rows: 0,
    success: false,
  };
  var rows = [];
  var finalRows = [];
  var meta = {};
  try {
    //var store = await getStoresInfo ()
    var isValid = false;
    var data = {};
    if (_data.categoryid) {
      isValid = true;
      data = {
        page: { current: 1, size: _data.limit ? _data.limit : 1000 },
        query: _data.categoryid,
        filters: {
          all: [
            { storeid: parseInt(_data.storeid ? _data.storeid : 0) },
            { categoryids: _data.categoryid + "" },
            { productqty: { from: 1, to: 99999 } },
            { statusid: 5 },
          ],
        },
        search_fields: {
          productid: { weight: 1 },
          genericsku: { weight: 1 },
          categoryids: { weight: 10 },
          colors: { weight: 1 },
          sizes: { weight: 1 },
          //"productname": {"weight": 10},
          tags: { weight: 1 },
          id: { weight: 1 },
        },
        precision: getDefValues().precisionSearch,
      };
    } else if (_data.id) {
      isValid = true;
      data = {
        page: { current: 1, size: _data.limit ? _data.limit : 1000 },
        query: "",
        filters: {
          all: [
            { id: _data.id },
            { productqty: { from: 1, to: 99999 } },
            { statusid: 5 },
          ],
        },
        search_fields: {
          id: { weight: 10 },
        },
        precision: getDefValues().precisionSearch,
      };
    } else {
      if (_data.searchkey) {
        isValid = true;
        data = {
          page: { current: 1, size: _data.limit ? _data.limit : 1000 },
          query: _data.searchkey,
          filters: {
            all: [
              { storeid: parseInt(_data.storeid ? _data.storeid : 0) },
              { productqty: { from: 1, to: 99999 } },
              { statusid: 5 },
            ],
          },
          search_fields: {
            productid: { weight: 1 },
            colors: { weight: 1 },
            sizes: { weight: 1 },
            productname: { weight: 10 },
            options: { weight: 5 },
            tags: { weight: 8 },
          },
          precision: getDefValues().precisionSearch,
        };
      }
    }
    if (!isValid) {
      resp = { success: false, rows: [], meta: null };
      console.log("NO DATA");
    } else {
      const axios = require("axios");

      await axios
        .post(
          process.env.REACT_APP_ENDPOINTBASE +
            "/api/as/v1/engines/" +
            process.env.REACT_APP_ENGINENAME +
            "/search",
          data,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + process.env.REACT_APP_SEARCHKEY,
            },
          }
        )
        .then((response) => response.data)
        .then((data) => {
          meta = data.meta;
          for (let k in data.results) {
            let key = data.results[k];

            let _options = [];
            let _quantities = [];
            let _categories = [];
            let isValid = false;

            if (_data.categoryid) {
              if (
                key.categoryids.raw.indexOf(_data.categoryid.toString()) >= 0
              ) {
                for (let o in key["categories"].raw) {
                  _categories.push(JSON.parse(key["categories"].raw[o]));
                }
                for (let o in key["quantities"].raw) {
                  let item = JSON.parse(key["quantities"].raw[o]);
                  if (item.quantity > 0 || key["packname"].raw) {
                    _quantities.push(item);
                    isValid = true;
                  }
                }
                for (let o in key["options"].raw) {
                  let option = JSON.parse(key["options"].raw[o]);
                  var optionvalues = [];
                  option.optionvalues.map(function (val) {
                    let pos = find(_quantities, val.value, "optionvalue");
                    if (pos >= 0) {
                      optionvalues.push(val);
                    }
                  });
                  let opt = {
                    optionid: option.optionid,
                    optionvalues: optionvalues,
                  };
                  if (optionvalues.length > 0) {
                    _options.push(opt);
                  }
                }
              }
            } else if (_data.id) {
              if (key["id"].raw == _data.id) {
                for (let o in key["categories"].raw) {
                  _categories.push(JSON.parse(key["categories"].raw[o]));
                }
                for (let o in key["quantities"].raw) {
                  let item = JSON.parse(key["quantities"].raw[o]);
                  if (item.quantity > 0) {
                    _quantities.push(item);
                    isValid = true;
                  }
                }
              }
            } else {
              for (let o in key["categories"].raw) {
                _categories.push(JSON.parse(key["categories"].raw[o]));
              }
              for (let o in key["quantities"].raw) {
                let item = JSON.parse(key["quantities"].raw[o]);
                if (item.quantity > 0) {
                  _quantities.push(item);
                  isValid = true;
                }
              }

              for (let o in key["options"].raw) {
                let option = JSON.parse(key["options"].raw[o]);
                var optionvalues = [];
                option.optionvalues.map(function (val) {
                  let pos = find(_quantities, val.value, "optionvalue");
                  if (pos >= 0) {
                    optionvalues.push(val);
                  }
                });
                let opt = {
                  optionid: option.optionid,
                  optionvalues: optionvalues,
                };
                if (optionvalues.length > 0) {
                  _options.push(opt);
                }
              }
            }

            let finalRow = {
              productid: key["productid"].raw,
              languageid: key["languageid"].raw,
              productname: key["productname"].raw,
              productdescription: key["productname"].raw,
              productmodel: key["productmodel"].raw,
              genericsku: key["genericsku"].raw,
              price: key["price"].raw,
              quantity: 1,
              statusid: key["statusid"].raw,
              dateadded: key["dateadded"].raw,
              image: key["image"].raw,
              status: key["status"].raw,
              newprice: key["newprice"].raw,
              newpricediscount: key["newpricediscount"].raw,
              newquantitydiscount: key["newquantitydiscount"].raw,
              datestartdiscount: key["datestartdiscount"].raw,
              dateenddiscount: key["dateenddiscount"].raw,
              datestart: key["datestart"].raw,
              dateend: key["dateend"].raw,
              age: key["age"].raw,
              canonicalpath_id: key["canonicalpath_id"].raw,
              options: _options,
              quantities: _quantities,
              categories: _categories,
              category: key["category"] ? key["category"].raw : "",
              tags: key["tags"].raw,
              minprice: key["minprice"].raw,
              maxprice: key["maxprice"].raw,
              isvalid: true,
              storeid: key["storeid"].raw,
              path: key["path"].raw,
              colors: key["colors"].raw,
              sizes: key["sizes"].raw,
              sortorder: key["sortorder"]
                ? parseInt(key["sortorder"].raw)
                : 9999,
            };
            if (key["packname"].raw) {
              finalRow["packname"] = key["packname"].raw;
            }
            if (isValid) {
              finalRows.push(finalRow);
            }
            if (!key["sortorder"]) {
              //console.log("no sort", key.id);
            }
          }
        })
        .catch(function (error) {
          console.log(">>>>>>>> Error api", error);
          resp = { success: false, rows: [], message: "ERR:" + error };
        });

      finalRows.sort(function (a, b) {
        return b["sortorder"] > a["sortorder"]
          ? -1
          : b["sortorder"] < a["sortorder"]
          ? 1
          : 0;
      });

      resp = {
        success: finalRows.length > 0 ? true : false,
        rows: finalRows,
        meta: meta,
      };
    }
    return resp;
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      rows: 0,
      success: false,
      message: Excep,
    };
    return resp;
  }
}

export async function getLayoutTemplateByNameDirect(foldername, layoutFile) {
  var resp;
  try {
    /*
		const AWS = require('aws-sdk');
		const s3ID = process.env.REACT_APP_S3ACCESSKEY;
		const s3SECRET = process.env.REACT_APP_S3SECRETACCESSKEY;
		const BUCKET_FILES = process.env.REACT_APP_S3FILES;
		const s3 = new AWS.S3({
			accessKeyId: s3ID,
			secretAccessKey: s3SECRET
		});

		const params = {
			Bucket: BUCKET_FILES,
			Key:  (foldername? foldername :"") +layoutFile,
		};

		s3.getObject(params, function(err, data) {
			if (err) {
				console.log("----------", err)
			  resp=({
							  success:false,
							errorcode:"1",
							message:err.message
						});
			return resp
			}else{			        	
				resp=({
							  success:true,
							template:JSON.parse(data.Body.toString('utf-8'))
						});
				console.log("++++++++++++", resp)
				return (resp)
			}
	 	});
		*/
    return true;
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      rows: 0,
      success: false,
      message: Excep,
    };
    return resp;
  }
}

export async function OLDgetFilterProductsElastic(_data) {
  var resp = {
    rows: 0,
    success: false,
  };
  var rows = [];
  var finalRows = [];
  var meta = {};
  try {
    var store = await getStoresInfo();

    var data = {
      page: { current: 1, size: 1000 },
      query: _data.searchkey,
      filters: {
        all: [
          { storeid: _data.storeid },
          { productqty: { from: 1, to: 99999 } },
          { statusid: 5 },
        ],
      },
      search_fields: {
        productid: { weight: 1 },
        genericsku: { weight: 1 },
        colors: { weight: 1 },
        sizes: { weight: 1 },
        productname: { weight: 10 },
        //"tags": {"weight": 8},
        id: { weight: 1 },
      },
      precision: getDefValues().precisionSearch,
    };

    const axios = require("axios");

    await axios
      .post(
        process.env.REACT_APP_ENDPOINTBASE +
          "/api/as/v1/engines/" +
          process.env.REACT_APP_ENGINENAME +
          "/search",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            origin: "x-requested-with",
            "Access-Control-Allow-Headers":
              "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
            "Content-Type": "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_SEARCHKEY,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        meta = data.meta;
        for (let k in data.results) {
          let _options = [];
          let _quantities = [];
          let _categories = [];
          let key = data.results[k];
          for (let o in key["options"].raw) {
            _options.push(JSON.parse(key["options"].raw[o]));
          }
          for (let o in key["categories"].raw) {
            _categories.push(JSON.parse(key["categories"].raw[o]));
          }
          for (let o in key["quantities"].raw) {
            _quantities.push(JSON.parse(key["quantities"].raw[o]));
          }
          let finalRow = {
            productid: key["productid"].raw,
            languageid: key["languageid"].raw,
            productname: key["productname"].raw,
            productdescription: key["productname"].raw,
            productmodel: key["productmodel"].raw,
            genericsku: key["genericsku"].raw,
            price: key["price"].raw,
            quantity: 1,
            statusid: key["statusid"].raw,
            dateadded: key["dateadded"].raw,
            image: key["image"].raw,
            status: key["status"].raw,
            newprice: key["newprice"].raw,
            newpricediscount: key["newpricediscount"].raw,
            newquantitydiscount: key["newquantitydiscount"].raw,
            datestartdiscount: key["datestartdiscount"].raw,
            dateenddiscount: key["dateenddiscount"].raw,
            datestart: key["datestart"].raw,
            dateend: key["dateend"].raw,
            age: key["age"].raw,
            canonicalpath_id: key["canonicalpath_id"].raw,
            options: _options,
            quantities: _quantities,
            categories: _categories,
            category: key["category"].raw,
            tags: key["tags"].raw,
            minprice: key["minprice"].raw,
            maxprice: key["maxprice"].raw,
            isvalid: true,
            storeid: key["storeid"].raw,
            path: key["path"].raw,
            colors: key["colors"].raw,
            sizes: key["sizes"].raw,
          };
          finalRows.push(finalRow);
        }
      })
      .catch(function (error) {
        console.log(">>>>>>>> Error api", error);
        resp = { success: false, rows: [], message: "ERR:" + error };
      });
    resp = {
      success: finalRows.length > 0 ? true : false,
      rows: finalRows,
      meta: meta,
    };
    return resp;
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      rows: 0,
      success: false,
      message: Excep,
    };
    return resp;
  }
}

export async function getMasterCategories() {
  var resp = {
    rows: [],
    rowsId: [],
    rowsIdLocked: [],
    success: false,
  };
  var rows = [];
  var rowsId = [];
  var rowsIdLocked = [];
  try {
    var temOptions = getCookie(
      process.env.REACT_APP_ORG_NAME + "_MasterCategories"
    );
    var temOptions2 = getCookie(
      process.env.REACT_APP_ORG_NAME + "_MasterCategoryIds"
    );
    var temOptions3 = getCookie(
      process.env.REACT_APP_ORG_NAME + "_CategoriresLockedDiscount"
    );

    if (temOptions && temOptions2 && temOptions3) {
      var rows = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_MasterCategories")
      );
      var rowsId = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_MasterCategoryIds")
      );
      var rowsIdLocked = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_CategoriresLockedDiscount")
      );

      if (
        rows.length === 0 ||
        rowsId.length === 0 ||
        rowsIdLocked.length === 0
      ) {
        let resp = await callApi("customer", "getCategories", {
          languageid: getLanguage(),
        });
        for (let r in resp.rows) {
          if (resp.rows[r].CATEGORYLEVEL == "1") {
            rows.push(formatPathName(resp.rows[r].CATEGORYLABEL));

            if (rowsId.indexOf(resp.rows[r].CATEGORYID) < 0) {
              //console.log("NUEVO", resp.rows[r].CATEGORYID)
              rowsId.push(formatPathName(resp.rows[r].CATEGORYID));
            }
          }
          if (resp.rows[r].STATUSID == "150") {
            rowsIdLocked.push(formatPathName(resp.rows[r].CATEGORYID));
          }
        }
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_MasterCategories",
          JSON.stringify(rows),
          2
        );
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_MasterCategoryIds",
          JSON.stringify(rowsId),
          2
        );
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_CategoriresLockedDiscount",
          JSON.stringify(rowsIdLocked),
          2
        );
      }
    } else {
      let resp = await callApi("customer", "getCategories", {
        languageid: getLanguage(),
      });
      for (let r in resp.rows) {
        if (resp.rows[r].CATEGORYLEVEL == "1") {
          if (rows.indexOf(formatPathName(resp.rows[r].CATEGORYLABEL)) < 0) {
            rows.push(formatPathName(resp.rows[r].CATEGORYLABEL));
          }

          if (rows.indexOf(formatPathCategory(resp.rows[r].CATEGORYNAME)) < 0) {
            rows.push(formatPathCategory(resp.rows[r].CATEGORYNAME));
          }
          if (rowsId.indexOf(resp.rows[r].CATEGORYID) < 0) {
            rowsId.push(formatPathName(resp.rows[r].CATEGORYID));
          }
        }
        if (resp.rows[r].STATUSID == "150") {
          rowsIdLocked.push(formatPathName(resp.rows[r].CATEGORYID));
        }
      }
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_MasterCategories",
        JSON.stringify(rows),
        2
      );
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_MasterCategoryIds",
        JSON.stringify(rowsId),
        2
      );
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_CategoriresLockedDiscount",
        JSON.stringify(rowsIdLocked),
        2
      );
    }
    resp = {
      success: rows.length > 1 ? true : false,
      rows: rows,
      rowsId: rowsId,
      rowsIdLocked: rowsIdLocked,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      rows: [],
      rowsId: [],
      rowsIdLocked: [],
      success: false,
      message: Excep,
    };
  } finally {
    return resp;
  }
}

export async function getRelatedProducts(productid, categories) {
  var products = [];
  try {
    var store = await getStoresInfo();
    var data = {
      languageid: getLanguage(),
      productid: productid,
      storeid: store.selectedCatalogId,
    };
    var resp = await callApi("customer", "getFilterProductsDB", data);
    //console.log("RELATED")
    for (let k in resp.rows) {
      if (products.length <= 10 && resp.rows[k].productid != productid) {
        products.push(resp.rows[k]);
      }
    }

    if (products.length < 10 && categories[0]) {
      var data = {
        languageid: getLanguage(),
        categoryid: categories[0].id,
        storeid: store.selectedCatalogId,
      };
      var resp = await callApi("customer", "getFilterProducts", data);
      //console.log("CATEGORY0")
      for (let k in resp.rows) {
        let pos = find(products, resp.rows[k].productid, "productid");
        if (
          products.length < 10 &&
          resp.rows[k].productid != productid &&
          pos < 0
        ) {
          products.push(resp.rows[k]);
        }
      }
    }
    if (products.length < 10 && categories[1]) {
      var data = {
        languageid: getLanguage(),
        categoryid: categories[1].id,
        storeid: store.selectedCatalogId,
      };
      var resp = await callApi("customer", "getFilterProducts", data);
      //console.log("CATEGORY1")
      for (let k in resp.rows) {
        let pos = find(products, resp.rows[k].productid, "productid");
        if (
          products.length < 10 &&
          resp.rows[k].productid != productid &&
          pos < 0
        ) {
          products.push(resp.rows[k]);
        }
      }
    }
    if (products.length < 10 && categories[2]) {
      var data = {
        languageid: getLanguage(),
        categoryid: categories[2].id,
        storeid: store.selectedCatalogId,
      };
      var resp = await callApi("customer", "getFilterProducts", data);
      //console.log("CATEGORY2")
      for (let k in resp.rows) {
        let pos = find(products, resp.rows[k].productid, "productid");
        if (
          products.length < 10 &&
          resp.rows[k].productid != productid &&
          pos < 0
        ) {
          products.push(resp.rows[k]);
        }
      }
    }
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    products = [];
  } finally {
    return products;
  }
}

export async function getAttributes() {
  var resp = {
    rows: 0,
    success: false,
  };
  var rows = [];
  try {
    var temOptions = getCookie(process.env.REACT_APP_ORG_NAME + "_Attributes");
    if (temOptions) {
      var rows = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_Attributes")
      );
      if (rows.length === 0) {
        let resp = await callApi("customer", "getAttributesDB", {
          languageid: getLanguage(),
        });
        rows = resp.rows;
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_Attributes",
          JSON.stringify(rows),
          24
        );
      }
    } else {
      let resp = await callApi("customer", "getAttributesDB", {
        languageid: getLanguage(),
      });
      rows = resp.rows;
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_Attributes",
        JSON.stringify(rows),
        24
      );
    }
    resp = {
      success: rows.length > 1 ? true : false,
      rows: rows,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    resp = {
      rows: 0,
      success: false,
      message: Excep,
    };
  } finally {
    return resp;
  }
}
export function replaceAll2(string, key, keyToReplace) {
  string.split(key).join(keyToReplace);
  return string;
}

export async function buildProductsCard(
  _products,
  _managepagination,
  _manageCart,
  _prodDefConfig,
  _container,
  _categoryRouteObj,
  _catId,
  _sugegstedSkus
) {
  /*
	console.log(_products)
	console.log(_prodDefConfig)
	console.log(_categoryRouteObj, _catId,)
	*/
  var resp = {
    cardProducts: [],
    cardConfig: {},
  };
  try {
    var cardProducts = [];
    var item = 0;
    var wishlist = await getWishList();
    var res = await getOptions();
    var temOptions = res.rows;
    //console.log("iniciamos con ",_products)

    var _resp = await getTagDefinitions();
    var tags = _resp.tags;
    var CategoriresLocked = [];
    var _cats = getCookie(
      process.env.REACT_APP_ORG_NAME + "_CategoriresLockedDiscount"
    );

    if (_cats && _catId) {
      CategoriresLocked = JSON.parse(
        getCookie(process.env.REACT_APP_ORG_NAME + "_CategoriresLockedDiscount")
      );
    }
    var isLockedCat = false;
    if (CategoriresLocked && _catId) {
      if (CategoriresLocked.indexOf(_catId) >= 0) {
        isLockedCat = true;
      }
    }
    var isLockedClon = false;
    if (_catId == -1) {
      isLockedClon = true;
    }
    //console.log("BUILD",_prodDefConfig)
    /*
		var prods = []
		for (let k in _products){
			if 	(_products[k].productid==21863 || _products[k].productid== 18806 || _products[k].productid== -20885  ){
		 		prods.push(_products[k])
			}
		}
		_products = prods
		*/
    for (var k in _products) {
      //console.log("productid",_products[k].productid)
      var key = _products[k];

      var isValid = false;
      var clonable = false;
      var quantities = [];
      var path = undefined; //= key.image;

      if (key.packname) {
        var productname = key.productname;
        if (key.quantities.length > 0) {
          let _skus = [];
          var _quantity = 99999999;
          isValid = true;
          let _sku = key.quantities[0];
          key.quantities.map(function (key) {
            if (key["quantity"] <= 0) {
              isValid = false;
            } else {
              if (key.quantity < _quantity) {
                _quantity = key.quantity;
              }
              if (!path) {
                path = key.imagepath
                  ? key.imagepath
                  : key.imagespath + key.sku + "_1" + getImgagesExtension();
              }
              let pos = find(_skus, key.sku, "productsku");
              if (pos < 0) {
                _skus.push({ productsku: key.sku, price: key.price });
              }
            }
          });

          let imgPath = key.image;
          var imagesquantity = key.producttotalimages;
          var imagepath = null;
          var imagespath = null;
          if (imgPath.indexOf(getImgagesExtension()) >= 0) {
            imagepath = imgPath;
            imagesquantity = 1;
            path = imgPath;
          } else {
            imagespath = imgPath;
            path = imgPath + key.packname + "_1" + getImgagesExtension();
          }
          var fixedSku = {
            imagesquantity: imagesquantity,
            imagespath: imagespath,
            imagepath: imagepath,
            isrequiredid: _sku.isrequiredid,
            tagid: _sku.tagid,
            optionid: 3,
            optionmaster: "",
            optionvalue: "",
            optioncustomer: "PACK",
            newpriced: null,
            newpriced2: null,
            newpricec1: null,
            newpricec2: null,
            languageid: _sku.languageid,
            price: key.price,
            sku: key.packname,
            skus: _skus,
            specificname: key.productname,
            quantity: _quantity,
          };
          quantities.push(fixedSku);
        }
      } else {
        key.quantities.map(function (key) {
          if (key["quantity"] > 0) {
            quantities.push(key);
            isValid = true;
            if (!path) {
              path = key.imagepath
                ? key.imagepath
                : key.imagespath + key.sku + "_1" + getImgagesExtension();
            }
          }
        });
      }

      if (isValid) {
        item++;
        var tag = null;
        if (key.age < 5) {
          tag = "new";
        }
        if (key.newprice != null && key.newprice != undefined) {
          tag = "-" + key.newprice + "%";
        }
        if (key.newpricediscount != null && key.newpricediscount != undefined) {
          tag =
            key.newpricediscount +
            "x" +
            key.newpricediscount +
            ",99" +
            getCurrency();
        }
        var optionsselected = null;
        var firstTime = true;
        var showVariants = false;

        var options = [];
        if (key.packname) {
          var fixedOption = {
            optionid: 3,
            optionvalues: [
              {
                filtername: "",
                label: "",
                price: key.price,
                sku: key.productid,
                value: "",
              },
            ],
          };
          options.push(fixedOption);
        } else {
          key.options.map(function (key) {
            var optionvalues = [];
            key.optionvalues.map(function (val) {
              let pos = find(quantities, val.value, "optionvalue");
              if (pos >= 0) {
                optionvalues.push(val);
              }
            });
            var opt = { optionid: key.optionid, optionvalues: optionvalues };
            if (optionvalues.length > 0) {
              options.push(opt);
            }
          });
        }
        for (let o in options) {
          if (options[o].optionvalues.length === 1) {
            if (firstTime) {
              optionsselected = [];
              firstTime = false;
            }
            optionsselected.push({
              optionid: options[o].optionid,
              optionvalue: options[o].optionvalues[0].value,
            });
          } else {
            showVariants = true;
          }
        }

        var discount = null;
        var customTag = null;
        var priceold = 0;
        var pricenew = 0;
        var firstTime = true;
        var allSKusD = 0;
        var allSKusC = 0;
        for (let o in quantities) {
          if (firstTime) {
            priceold = quantities[o].price;
            if (quantities[o].newpriced2) {
              discount = quantities[o].newpriced2;
              pricenew = quantities[o].newpriced;
            }
            var arrPrices = [];
            var arrPricesD = [];
            var arrPricesD2 = [];
            var arrQuantities = [];
            var arrPrices = searchArray(
              quantities,
              "optionvalue",
              "price",
              quantities[o].optionvalue,
              arrPrices
            );
            var arrPricesD = searchArray(
              quantities,
              "optionvalue",
              "newpriced",
              quantities[o].optionvalue,
              arrPricesD
            );
            var arrPricesD2 = searchArray(
              quantities,
              "optionvalue",
              "newpriced2",
              quantities[o].optionvalue,
              arrPricesD2
            );
            var arrQuantities = searchArray(
              quantities,
              "optionvalue",
              "quantity",
              quantities[o].optionvalue,
              arrQuantities
            );
            priceold = quantities[o].price;
            for (let q in arrQuantities) {
              if (arrQuantities[q] > 0) {
                if (arrPrices[q] <= priceold) {
                  priceold = arrPrices[q];
                  if (arrPricesD[q]) {
                    pricenew = arrPricesD[q];
                  }
                  if (arrPricesD2[q]) {
                    discount = arrPricesD2[q];
                  }
                }
              }
            }
            firstTime = false;
          }
          if (quantities[o].newpriced2) {
            allSKusD++;
          }
          if (quantities[o].newpricec1) {
            allSKusC++;
          }
          if (quantities[o].tagid) {
            let pos = find(tags, quantities[o].tagid + "", "id");
            if (pos >= 0) {
              if (tags[pos].storeId === "") {
                customTag = {
                  label: tags[pos].label,
                  sku: quantities[o].sku,
                  style: tags[pos].style,
                };
              } else {
                if (tags[pos].storeId == getCurrentStore()) {
                  customTag = {
                    label: tags[pos].label,
                    sku: quantities[o].sku,
                    style: tags[pos].style,
                  };
                }
              }
            }
          }
        }
        var category = formatPathName(key.category);
        var productpath = formatPathName(key.productname + "-" + key.productid);
        productpath = productpath.replace("_c_", "_C_");
        if (_categoryRouteObj) {
          if (Array.isArray(_categoryRouteObj)) {
            category = "";
            for (let k in _categoryRouteObj) {
              let _category = formatPathName(_categoryRouteObj[k].label);
              category = category + "/" + _category;
            }
            category = category.substr(1, category.length);
          } else {
            category = _categoryRouteObj;
          }
        }
        var objdata = {
          productid: key.productid,
          label: key.productname,
          path: path,
          width: 250,
          height: 250,
          currency: getCurrency(),
          value: "",
          customTag: customTag,
          options: options,
          quantities: quantities,
          pricenew: pricenew,
          priceold: priceold,
          allSKusD: allSKusD,
          allSKusC: allSKusC,
          discount: discount,
          minprice: key.minprice,
          maxprice: key.maxprice,
          tag: tag,
          genericsku: quantities[0].sku,
          category: category,
          productpath: productpath,
        };
        var objconfig = {
          quantitySelected: 1,
          manageCart: _manageCart,
          optionsselected: optionsselected,
          favorite:
            wishlist.indexOf(parseInt(key.productid)) < 0 ? false : true,
          showVariants: showVariants,
          fatherConfig: _prodDefConfig,
        };

        if (!showVariants) {
          objdata["productsku"] = quantities[0].sku;
          objdata["hideoptions"] = true;
          objdata["maxQuantity"] = quantities[0].quantity;
        }

        if (quantities.length === 1) {
          //
          objconfig["optionsselected"] = [
            {
              optionid: quantities[0].optionid,
              optionvalue: quantities[0].optionvalue,
            },
          ];
          objdata["productsku"] = quantities[0].sku;
          objdata["hideoptions"] = true;
          objdata["maxQuantity"] = quantities[0].quantity;

          cardProducts.push({
            body: (
              <Product
                name={_container}
                productData={objdata}
                productConfig={objconfig}
              />
            ),
            productid: key.productid,
            productData: objdata,
          });
        } else {
          var colors = [];

          if (!isLockedClon) {
            for (let o in quantities) {
              let posF = find(temOptions, quantities[o].optionid, "optionid");
              if (temOptions[posF].optionname.toUpperCase() === "COLOR") {
                let pos = find(
                  colors,
                  quantities[o].optionvalue,
                  "optionvalue"
                );

                if (pos < 0 && quantities[o].ordersku > 0) {
                  colors.push({
                    optionvalue: quantities[o].optionvalue,
                    sku: quantities[o].sku,
                    ordersku: quantities[o].ordersku,
                    option: quantities[o].optioncustomer,
                  });
                }
              }
            }
          } else {
            if (_sugegstedSkus) {
              for (let o in quantities) {
                let posF = find(temOptions, quantities[o].optionid, "optionid");
                if (temOptions[posF].optionname.toUpperCase() === "COLOR") {
                  let pos = find(
                    colors,
                    quantities[o].optionvalue,
                    "optionvalue"
                  );
                  let pos2 = find(
                    _sugegstedSkus,
                    quantities[o].sku,
                    "preferredsku"
                  );
                  if (pos < 0 && pos2 >= 0 && quantities[o].ordersku > 0) {
                    colors.push({
                      optionvalue: quantities[o].optionvalue,
                      sku: quantities[o].sku,
                      ordersku: quantities[o].ordersku,
                      option: quantities[o].optioncustomer,
                    });
                  }
                }
              }
            }
          }
          if (colors.length > 0) {
            for (let c = 0; c < colors.length; c++) {
              let newPath = path.replace(colors[0].sku, colors[c].sku);

              objdata.quantities.map(function (opt) {
                if (opt.sku == colors[c].sku) {
                  newPath =
                    opt.imagespath + opt.sku + "_1" + getImgagesExtension();
                }
              });

              var arrPrices = [];
              var arrPricesD = [];
              var arrPricesD2 = [];
              var arrQuantities = [];

              var arrPrices = searchArray(
                quantities,
                "optionvalue",
                "price",
                colors[c].optionvalue,
                arrPrices
              );
              var arrPricesD = searchArray(
                quantities,
                "optionvalue",
                "newpriced",
                colors[c].optionvalue,
                arrPricesD
              );
              var arrPricesD2 = searchArray(
                quantities,
                "optionvalue",
                "newpriced2",
                colors[c].optionvalue,
                arrPricesD2
              );
              var arrQuantities = searchArray(
                quantities,
                "optionvalue",
                "quantity",
                colors[c].optionvalue,
                arrQuantities
              );

              let _priceold = arrPrices[0];
              let _discount = arrPricesD2[0];
              let _pricenew = arrPricesD[0];

              for (let q in arrQuantities) {
                if (arrQuantities[q] > 0) {
                  if (arrPrices[q] <= _priceold) {
                    _priceold = arrPrices[q];
                    if (arrPricesD[q]) {
                      _pricenew = arrPricesD[q];
                    }
                    if (arrPricesD2[q]) {
                      _discount = arrPricesD2[q];
                    }
                  }
                }
              }
              let objdataClon = {
                productid: key.productid + "_C_" + colors[c].sku,
                label: key.productname,
                path: newPath,
                width: 250,
                height: 250,
                currency: getCurrency(),
                value: "",
                customTag: customTag,
                options: options,
                quantities: quantities,
                pricenew: _pricenew,
                priceold: _priceold,
                allSKusD: allSKusD,
                allSKusC: allSKusC,
                discount: _discount,
                minprice: key.minprice,
                maxprice: key.maxprice,
                tag: tag,
                genericsku: colors[c].sku,
                category: category,
                skuCloned: colors[c].optionvalue,
                productpath: productpath,
              };

              let objconfigClon = {
                quantitySelected: 1,
                manageCart: _manageCart,
                optionsselected: optionsselected,
                favorite:
                  wishlist.indexOf(
                    parseInt(key.productid + "" + colors[c].sku)
                  ) < 0
                    ? false
                    : true,
                showVariants: showVariants,
                fatherConfig: _prodDefConfig,
              };

              if (objdataClon.options.length === 1) {
                objconfigClon["optionsselected"] = [
                  {
                    optionid: objdataClon.options[0].optionid,
                    optionvalue: colors[c].optionvalue,
                  },
                ];
                var arrQtys = [];
                arrQtys = searchArray(
                  quantities,
                  "optionvalue",
                  "quantity",
                  colors[c].optionvalue,
                  arrQtys
                );
                objdataClon["maxQuantity"] = arrQtys[0];
              } else {
                var optionsselected = [];
                objdataClon.options.map(function (optv) {
                  if (optv.optionvalues.length === 1) {
                    optionsselected.push({
                      optionid: optv.optionid,
                      optionvalue: optv.optionvalues[0].value,
                    });
                  } else {
                    let pos = find(
                      optv.optionvalues,
                      colors[c].optionvalue,
                      "value"
                    );
                    if (pos >= 0) {
                      optionsselected.push({
                        optionid: optv.optionid,
                        optionvalue: colors[c].optionvalue,
                      });
                    }
                  }
                });
                if (optionsselected.length > 0) {
                  objconfigClon["optionsselected"] = optionsselected;
                }
              }
              var add = true;
              if (isLockedCat) {
                add = false;
                var arrCombos = [];
                var arrCombos = searchArray(
                  quantities,
                  "optionvalue",
                  "newpricec1",
                  colors[c].optionvalue,
                  arrCombos
                );
                if (arrCombos[0] || _pricenew > 0) {
                  add = true;
                  objdataClon["isLockedCat"] = true;
                }
              }
              if (add) {
                cardProducts.push({
                  body: (
                    <Product
                      name={_container}
                      productData={objdataClon}
                      productConfig={objconfigClon}
                    />
                  ),
                  productid: key.productid + "_C_" + colors[c].sku,
                  color: colors[c].option,
                  productData: objdataClon,
                  isclonable: true,
                });
              }
            }
          } else {
            if (colors.length === 1) {
              //console.log("revisar el color", colors, objdata)
            }

            cardProducts.push({
              body: (
                <Product
                  name={_container}
                  productData={objdata}
                  productConfig={objconfig}
                />
              ),
              productid: key.productid,
              productData: objdata,
            });
          }
        }
        /*var style={}
	  		style["margin-bottom"] = "0.9rem"*/
      }
    }

    if (_prodDefConfig.optionsFilter) {
      var colorsFilter = [];
      //console.log("tiene filtro", _prodDefConfig.optionsFilter)
      let _optionsselected = optionsselected;
      var content = _prodDefConfig.optionsFilter;
      content = _prodDefConfig.optionsFilter.sort(function (a, b) {
        return convertNumber(a.optionid) - convertNumber(b.optionid);
      });
      content.map(function (key2) {
        //console.log("checkin filter", key2)
        if (key2.optionname.toUpperCase() === "COLOR") {
          for (let o in key2.selectedoptions) {
            colorsFilter.push(key2.selectedoptions[o]);
            //console.log("key2.selectedoptions[o]",key2.selectedoptions[o])
          }
        }
      });
      if (colorsFilter.length > 0) {
        //console.log("colorsFilter", colorsFilter)
        //console.log("cardProducts ORIGINAL", cardProducts)
        var newCardProducts = [];
        cardProducts.map(function (prod, index) {
          if (prod.isclonable) {
            //console.log("es clon podriamos borrar", prod.color, index)
            if (colorsFilter.indexOf(prod.color) >= 0) {
              //console.log("existe ponemos", index)
              //cardProducts.splice(index, 1)
              newCardProducts.push(prod);
            }
          } else {
            //console.log("no es clon debemos actualizar", index)
            //console.log(prod.productData)
            if (prod.productData.quantities.length > 1) {
              //console.log("tiene variantes",prod.productData.quantities)
              var productData = prod.productData;
              for (let c in colorsFilter) {
                let pos = find(
                  productData.quantities,
                  colorsFilter[c],
                  "optioncustomer"
                );
                if (pos >= 0) {
                  //console.log("este es el color", productData.quantities[pos].optioncustomer)
                  let newPath = productData.quantities[pos].imagepath;
                  if (newPath == null) {
                    newPath =
                      productData.quantities[pos].imagespath +
                      productData.quantities[pos].sku +
                      "_1" +
                      getImgagesExtension();
                  }
                  productData["path"] = newPath;
                  //console.log("newPath", newPath )
                }
              }
              newCardProducts.push(prod);
            }
          }
        });
        cardProducts = newCardProducts;
      }
    }

    //console.log("cardProducts",cardProducts)
    var itemsperpage = 15;
    var pages = Math.ceil(cardProducts.length / itemsperpage);
    var paginationGroup = [];
    for (let i = 1; i <= pages; i++) {
      paginationGroup.push(i);
    }
    var cardConfig = _prodDefConfig;

    cardConfig["managepagination"] = _managepagination;
    cardConfig["pagesettings"] = {
      itemsperpage: itemsperpage,
      paginationGroup: paginationGroup,
      currentPage: 1,
      pages: pages,
      cardProducts: cardProducts.length,
    };
    resp = {
      cardProducts,
      cardConfig,
    };
    //console.log("cardConfig",cardConfig)
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
  } finally {
    return resp;
  }
}

export function buildsyncProductsCard(
  _products,
  _managepagination,
  _manageCart,
  _prodDefConfig,
  _container,
  _categoryRouteObj
) {
  var resp = {
    cardProducts: [],
    cardConfig: {},
  };
  try {
    var cardProducts = [];
    //var item = 0;
    var wishlist = getsyncWishList();
    var res = getsyncOptions();
    //		console.log(res)
    var temOptions = res.rows;

    var _resp = getsyncTagDefinitions();
    var tags = _resp.tags;

    for (var k in _products) {
      var key = _products[k];
      var isValid = false;
      var clonable = false;
      var quantities = [];
      var path = undefined; //= key.image;
      if (key.packname) {
        var productname = key.productname;
        if (key.quantities.length > 0) {
          let _skus = [];
          var _quantity = 99999999;
          isValid = true;
          let _sku = key.quantities[0];
          key.quantities.map(function (key) {
            if (key["quantity"] <= 0) {
              isValid = false;
            } else {
              if (key.quantity < _quantity) {
                _quantity = key.quantity;
              }
              if (!path) {
                path = key.imagepath
                  ? key.imagepath
                  : key.imagespath + key.sku + "_1" + getImgagesExtension();
              }
              let pos = find(_skus, key.sku, "productsku");
              if (pos < 0) {
                _skus.push({ productsku: key.sku, price: key.price });
              }
            }
          });

          let imgPath = key.image;
          var imagesquantity = key.producttotalimages;
          var imagepath = null;
          var imagespath = null;
          if (imgPath.indexOf(getImgagesExtension()) >= 0) {
            imagepath = imgPath;
            imagesquantity = 1;
            path = imgPath;
          } else {
            imagespath = imgPath;
            path = imgPath + key.packname + "_1" + getImgagesExtension();
          }
          var fixedSku = {
            imagesquantity: imagesquantity,
            imagespath: imagespath,
            imagepath: imagepath,
            isrequiredid: _sku.isrequiredid,
            tagid: _sku.tagid,
            optionid: 3,
            optionmaster: "",
            optionvalue: "",
            optioncustomer: "PACK",
            newpriced: null,
            newpriced2: null,
            newpricec1: null,
            newpricec2: null,
            languageid: _sku.languageid,
            price: key.price,
            sku: key.packname,
            skus: _skus,
            specificname: key.productname,
            quantity: _quantity,
          };
          quantities.push(fixedSku);
        }
      } else {
        key.quantities.map(function (key) {
          if (key["quantity"] > 0) {
            quantities.push(key);
            isValid = true;
            if (!path) {
              path = key.imagepath
                ? key.imagepath
                : key.imagespath + key.sku + "_1" + getImgagesExtension();
            }
          }
        });
      }
      if (isValid) {
        //item++;
        var tag = null;
        if (key.age < 5) {
          tag = "new";
        }
        if (key.newprice != null && key.newprice != undefined) {
          tag = "-" + key.newprice + "%";
        }
        if (key.newpricediscount != null && key.newpricediscount != undefined) {
          tag =
            key.newpricediscount +
            "x" +
            key.newpricediscount +
            ",99" +
            getCurrency();
        }
        var optionsselected = null;
        var firstTime = true;
        var showVariants = false;

        var options = [];
        if (key.packname) {
          var fixedOption = {
            optionid: 3,
            optionvalues: [
              {
                filtername: "",
                label: "",
                price: key.price,
                sku: key.productid,
                value: "",
              },
            ],
          };
          options.push(fixedOption);
        } else {
          key.options.map(function (key) {
            var optionvalues = [];
            key.optionvalues.map(function (val) {
              let pos = find(quantities, val.value, "optionvalue");
              if (pos >= 0) {
                optionvalues.push(val);
              }
            });
            var opt = { optionid: key.optionid, optionvalues: optionvalues };
            if (optionvalues.length > 0) {
              options.push(opt);
            }
          });
        }
        for (let o in options) {
          if (options[o].optionvalues.length === 1) {
            if (firstTime) {
              optionsselected = [];
              firstTime = false;
            }
            optionsselected.push({
              optionid: options[o].optionid,
              optionvalue: options[o].optionvalues[0].value,
            });
          } else {
            showVariants = true;
          }
        }
        var discount = null;
        var customTag = null;
        var priceold = 0;
        var pricenew = 0;
        var firstTime = true;
        var allSKusD = 0;
        var allSKusC = 0;
        for (let o in quantities) {
          if (firstTime) {
            priceold = quantities[o].price;
            if (quantities[o].newpriced2) {
              discount = quantities[o].newpriced2;
              pricenew = quantities[o].newpriced;
            }
            var arrPrices = [];
            var arrPricesD = [];
            var arrPricesD2 = [];
            var arrQuantities = [];
            var arrPrices = searchArray(
              quantities,
              "optionvalue",
              "price",
              quantities[o].optionvalue,
              arrPrices
            );
            var arrPricesD = searchArray(
              quantities,
              "optionvalue",
              "newpriced",
              quantities[o].optionvalue,
              arrPricesD
            );
            var arrPricesD2 = searchArray(
              quantities,
              "optionvalue",
              "newpriced2",
              quantities[o].optionvalue,
              arrPricesD2
            );
            var arrQuantities = searchArray(
              quantities,
              "optionvalue",
              "quantity",
              quantities[o].optionvalue,
              arrQuantities
            );
            priceold = quantities[o].price;
            for (let q in arrQuantities) {
              if (arrQuantities[q] > 0) {
                if (arrPrices[q] <= priceold) {
                  priceold = arrPrices[q];
                  if (arrPricesD[q]) {
                    pricenew = arrPricesD[q];
                  }
                  if (arrPricesD2[q]) {
                    discount = arrPricesD2[q];
                  }
                }
              }
            }
            firstTime = false;
          }
          if (quantities[o].newpriced2) {
            allSKusD++;
          }
          if (quantities[o].newpricec1) {
            allSKusC++;
          }
          if (quantities[o].tagid) {
            let pos = find(tags, quantities[o].tagid + "", "id");
            if (pos >= 0) {
              if (tags[pos].storeId === "") {
                customTag = {
                  label: tags[pos].label,
                  sku: quantities[o].sku,
                  style: tags[pos].style,
                };
              } else {
                if (tags[pos].storeId == getCurrentStore()) {
                  customTag = {
                    label: tags[pos].label,
                    sku: quantities[o].sku,
                    style: tags[pos].style,
                  };
                }
              }
            }
          }
        }
        var category = formatPathName(key.category);
        var productpath = formatPathName(key.productname + "-" + key.productid);

        if (_categoryRouteObj) {
          if (Array.isArray(_categoryRouteObj)) {
            category = "";
            for (let k in _categoryRouteObj) {
              let _category = formatPathName(_categoryRouteObj[k].label);
              category = category + "/" + _category;
            }
            category = category.substr(1, category.length);
          } else {
            category = _categoryRouteObj;
          }
        }
        var objdata = {
          productid: key.productid,
          label: key.productname,
          path: path,
          width: 250,
          height: 250,
          currency: getCurrency(),
          value: "",
          customTag: customTag,
          options: options,
          quantities: quantities,
          pricenew: pricenew,
          priceold: priceold,
          allSKusD: allSKusD,
          allSKusC: allSKusC,
          discount: discount,
          minprice: key.minprice,
          maxprice: key.maxprice,
          tag: tag,
          genericsku: quantities[0].sku,
          category: category,
          productpath: productpath,
        };
        var objconfig = {
          quantitySelected: 1,
          manageCart: _manageCart,
          optionsselected: optionsselected,
          favorite:
            wishlist.indexOf(parseInt(key.productid)) < 0 ? false : true,
          showVariants: showVariants,
          fatherConfig: _prodDefConfig,
        };

        if (!showVariants) {
          objdata["productsku"] = quantities[0].sku;
          objdata["hideoptions"] = true;
          objdata["maxQuantity"] = quantities[0].quantity;
        }

        if (quantities.length === 1) {
          //	console.log("solo una",quantities )
          objconfig["optionsselected"] = [
            {
              optionid: quantities[0].optionid,
              optionvalue: quantities[0].optionvalue,
            },
          ];
          objdata["productsku"] = quantities[0].sku;
          objdata["hideoptions"] = true;
          objdata["maxQuantity"] = quantities[0].quantity;

          cardProducts.push({
            body: (
              <Product
                name={_container}
                productData={objdata}
                productConfig={objconfig}
              />
            ),
            productid: key.productid,
            productData: objdata,
          });
        } else {
          var colors = [];
          for (let o in quantities) {
            let posF = find(temOptions, quantities[o].optionid, "optionid");
            if (temOptions[posF].optionname.toUpperCase() === "COLOR") {
              let pos = find(colors, quantities[o].optionvalue, "optionvalue");

              if (pos < 0 && quantities[o].ordersku > 0) {
                //console.log("los datos a clonar!!!!",quantities[o])
                colors.push({
                  optionvalue: quantities[o].optionvalue,
                  sku: quantities[o].sku,
                  ordersku: quantities[o].ordersku,
                  option: quantities[o].optioncustomer,
                });
              }
            }
          }
          //console.log("colors",colors)
          if (colors.length > 1) {
            //console.log("es clonable")
            objdata["cloned"] = true;
            if (objdata.options.length === 1) {
              objconfig["optionsselected"] = [
                {
                  optionid: objdata.options[0].optionid,
                  optionvalue: colors[0].optionvalue,
                },
              ];
              var arrQtys = [];
              arrQtys = searchArray(
                quantities,
                "optionvalue",
                "quantity",
                colors[0].optionvalue,
                arrQtys
              );
              objdata["maxQuantity"] = arrQtys[0];
            }
            cardProducts.push({
              body: (
                <Product
                  name={_container}
                  productData={objdata}
                  productConfig={objconfig}
                />
              ),
              productid: key.productid,
              color: colors[0].option,
              productData: objdata,
              isclonable: true,
            });
            for (let c = 1; c < colors.length; c++) {
              let newPath = path.replace(colors[0].sku, colors[c].sku);

              objdata.quantities.map(function (opt) {
                //console.log("checkin opt", opt , colors[c].sku)
                if (opt.sku == colors[c].sku) {
                  newPath =
                    opt.imagespath + opt.sku + "_1" + getImgagesExtension();
                }
              });

              var arrPrices = [];
              var arrPricesD = [];
              var arrPricesD2 = [];
              var arrQuantities = [];
              var arrPrices = searchArray(
                quantities,
                "optionvalue",
                "price",
                colors[c].optionvalue,
                arrPrices
              );
              var arrPricesD = searchArray(
                quantities,
                "optionvalue",
                "newpriced",
                colors[c].optionvalue,
                arrPricesD
              );
              var arrPricesD2 = searchArray(
                quantities,
                "optionvalue",
                "newpriced2",
                colors[c].optionvalue,
                arrPricesD2
              );
              var arrQuantities = searchArray(
                quantities,
                "optionvalue",
                "quantity",
                colors[c].optionvalue,
                arrQuantities
              );

              let _priceold = arrPrices[0];
              let _discount = arrPricesD2[0];
              let _pricenew = arrPricesD[0];

              for (let q in arrQuantities) {
                if (arrQuantities[q] > 0) {
                  if (arrPrices[q] <= _priceold) {
                    _priceold = arrPrices[q];
                    if (arrPricesD[q]) {
                      _pricenew = arrPricesD[q];
                    }
                    if (arrPricesD2[q]) {
                      _discount = arrPricesD2[q];
                    }
                  }
                }
              }
              let objdataClon = {
                productid: key.productid + "_C_" + colors[c].sku,
                label: key.productname,
                path: newPath,
                width: 250,
                height: 250,
                currency: getCurrency(),
                value: "",
                customTag: customTag,
                options: options,
                quantities: quantities,
                pricenew: _pricenew,
                priceold: _priceold,
                allSKusD: allSKusD,
                allSKusC: allSKusC,
                discount: _discount,
                minprice: key.minprice,
                maxprice: key.maxprice,
                tag: tag,
                genericsku: colors[c].sku,
                category: category,
                skuCloned: colors[c].optionvalue,
                productpath: productpath,
              };
              let objconfigClon = {
                quantitySelected: 1,
                manageCart: _manageCart,
                optionsselected: optionsselected,
                favorite:
                  wishlist.indexOf(
                    parseInt(key.productid + "" + colors[c].sku)
                  ) < 0
                    ? false
                    : true,
                showVariants: showVariants,
                fatherConfig: _prodDefConfig,
              };

              if (objdataClon.options.length === 1) {
                objconfigClon["optionsselected"] = [
                  {
                    optionid: objdataClon.options[0].optionid,
                    optionvalue: colors[c].optionvalue,
                  },
                ];
                var arrQtys = [];
                arrQtys = searchArray(
                  quantities,
                  "optionvalue",
                  "quantity",
                  colors[c].optionvalue,
                  arrQtys
                );
                objdataClon["maxQuantity"] = arrQtys[0];
              }

              cardProducts.push({
                body: (
                  <Product
                    name={_container}
                    productData={objdataClon}
                    productConfig={objconfigClon}
                    //	productData={objdataClon}
                  />
                ),
                productid: key.productid + "_C_" + colors[c].sku,
                color: colors[c].option,
                productData: objdataClon,
                isclonable: true,
              });
            }
          } else {
            cardProducts.push({
              body: (
                <Product
                  name={_container}
                  productData={objdata}
                  productConfig={objconfig}
                />
              ),
              productid: key.productid,
              productData: objdata,
            });
          }
        }
      }
    }

    if (_prodDefConfig.optionsFilter) {
      var colorsFilter = [];
      //console.log("tiene filtro", _prodDefConfig.optionsFilter)
      let _optionsselected = optionsselected;
      var content = _prodDefConfig.optionsFilter;
      content = _prodDefConfig.optionsFilter.sort(function (a, b) {
        return convertNumber(a.optionid) - convertNumber(b.optionid);
      });
      content.map(function (key2) {
        //console.log("checkin filter", key2)
        if (key2.optionname.toUpperCase() === "COLOR") {
          for (let o in key2.selectedoptions) {
            colorsFilter.push(key2.selectedoptions[o]);
            //console.log("key2.selectedoptions[o]",key2.selectedoptions[o])
          }
        }
      });
      if (colorsFilter.length > 0) {
        //console.log("colorsFilter", colorsFilter)
        //console.log("cardProducts ORIGINAL", cardProducts)
        var newCardProducts = [];
        cardProducts.map(function (prod, index) {
          if (prod.isclonable) {
            //console.log("es clon podriamos borrar", prod.color, index)
            if (colorsFilter.indexOf(prod.color) >= 0) {
              //console.log("existe ponemos", index)
              //cardProducts.splice(index, 1)
              newCardProducts.push(prod);
            }
          } else {
            //console.log("no es clon debemos actualizar", index)
            //console.log(prod.productData)
            if (prod.productData.quantities.length > 1) {
              //console.log("tiene variantes",prod.productData.quantities)
              var productData = prod.productData;
              for (let c in colorsFilter) {
                let pos = find(
                  productData.quantities,
                  colorsFilter[c],
                  "optioncustomer"
                );
                if (pos >= 0) {
                  //console.log("este es el color", productData.quantities[pos].optioncustomer)
                  let newPath = productData.quantities[pos].imagepath;
                  if (newPath == null) {
                    newPath =
                      productData.quantities[pos].imagespath +
                      productData.quantities[pos].sku +
                      "_1" +
                      getImgagesExtension();
                  }
                  productData["path"] = newPath;
                  //console.log("newPath", newPath )
                }
              }
              newCardProducts.push(prod);
            }
          }
        });
        cardProducts = newCardProducts;
      }
    }

    //console.log("cardProducts",cardProducts)
    var itemsperpage = 15;
    var pages = Math.ceil(cardProducts.length / itemsperpage);
    var paginationGroup = [];
    for (let i = 1; i <= pages; i++) {
      paginationGroup.push(i);
    }
    var cardConfig = _prodDefConfig;

    cardConfig["managepagination"] = _managepagination;
    cardConfig["pagesettings"] = {
      itemsperpage: itemsperpage,
      paginationGroup: paginationGroup,
      currentPage: 1,
      pages: pages,
      cardProducts: cardProducts.length,
    };
    resp = {
      cardProducts,
      cardConfig,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
  } finally {
    return resp;
  }
}

export async function buildProductsSlider(
  _products,
  _managepagination,
  _manageCart,
  _prodDefConfig,
  _container,
  _categoryRouteObj
) {
  var resp = {
    cardProducts: [],
    cardConfig: {},
  };
  try {
    var cardProducts = [];
    var item = 0;
    var wishlist = getsyncWishList();
    var _resp = await getTagDefinitions();
    var tags = _resp.tags;

    for (var k in _products) {
      let key = _products[k];
      var isValid = false;
      var clonable = false;
      var quantities = [];
      var path = undefined;

      if (key.packname) {
        var productname = key.productname;
        if (key.quantities.length > 0) {
          let _skus = [];
          var _quantity = 99999999;
          isValid = true;
          let _sku = key.quantities[0];
          key.quantities.map(function (key) {
            if (key["quantity"] <= 0) {
              isValid = false;
            } else {
              if (key.quantity < _quantity) {
                _quantity = key.quantity;
              }
              if (!path) {
                path = key.imagepath
                  ? key.imagepath
                  : key.imagespath + key.sku + "_1" + getImgagesExtension();
              }
              let pos = find(_skus, key.sku, "productsku");
              if (pos < 0) {
                _skus.push({ productsku: key.sku, price: key.price });
              }
            }
          });

          let imgPath = key.image;
          var imagesquantity = key.producttotalimages;
          var imagepath = null;
          var imagespath = null;
          if (imgPath.indexOf(getImgagesExtension()) >= 0) {
            imagepath = imgPath;
            imagesquantity = 1;
            path = imgPath;
          } else {
            imagespath = imgPath;
            path = imgPath + key.packname + "_1" + getImgagesExtension();
          }
          var fixedSku = {
            imagesquantity: imagesquantity,
            imagespath: imagespath,
            imagepath: imagepath,
            isrequiredid: _sku.isrequiredid,
            tagid: _sku.tagid,
            optionid: 3,
            optionmaster: "",
            optionvalue: "",
            optioncustomer: "PACK",
            newpriced: null,
            newpriced2: null,
            newpricec1: null,
            newpricec2: null,
            languageid: _sku.languageid,
            price: key.price,
            sku: key.packname,
            skus: _skus,
            specificname: key.productname,
            quantity: _quantity,
          };
          quantities.push(fixedSku);
        }
      } else {
        key.quantities.map(function (key) {
          if (key["quantity"] > 0) {
            quantities.push(key);
            isValid = true;
            if (!path) {
              path = key.imagepath
                ? key.imagepath
                : key.imagespath + key.sku + "_1" + getImgagesExtension();
            }
          }
        });
      }

      if (isValid) {
        item++;
        var tag = null;
        if (key.age < 5) {
          tag = "new";
        }
        if (key.newprice != null && key.newprice != undefined) {
          tag = "-" + key.newprice + "%";
        }
        if (key.newpricediscount != null && key.newpricediscount != undefined) {
          tag =
            key.newpricediscount +
            "x" +
            key.newpricediscount +
            ",99" +
            getCurrency();
        }
        var optionsselected = null;
        var firstTime = true;
        var showVariants = false;

        var options = [];
        if (key.packname) {
          var fixedOption = {
            optionid: 3,
            optionvalues: [
              {
                filtername: "",
                label: "",
                price: key.price,
                sku: key.productid,
                value: "",
              },
            ],
          };
          options.push(fixedOption);
        } else {
          key.options.map(function (key) {
            var optionvalues = [];
            key.optionvalues.map(function (val) {
              let pos = find(quantities, val.value, "optionvalue");
              if (pos >= 0) {
                optionvalues.push(val);
              }
            });
            var opt = { optionid: key.optionid, optionvalues: optionvalues };
            if (optionvalues.length > 0) {
              options.push(opt);
            }
          });
        }
        for (let o in options) {
          if (options[o].optionvalues.length === 1) {
            if (firstTime) {
              optionsselected = [];
              firstTime = false;
            }
            optionsselected.push({
              optionid: options[o].optionid,
              optionvalue: options[o].optionvalues[0].value,
            });
          } else {
            showVariants = true;
          }
        }

        var discount = null;
        var customTag = null;
        var priceold = 0;
        var pricenew = 0;
        var firstTime = true;
        var allSKusD = 0;
        var allSKusC = 0;
        for (let o in quantities) {
          if (firstTime) {
            priceold = quantities[o].price;
            if (quantities[o].newpriced2) {
              discount = quantities[o].newpriced2;
              pricenew = quantities[o].newpriced;
            }
            firstTime = false;
          }
          if (quantities[o].newpriced2) {
            allSKusD++;
          }
          if (quantities[o].newpricec1) {
            allSKusC++;
          }

          if (quantities[o].tagid) {
            let pos = find(tags, quantities[o].tagid, "id");
            if (pos >= 0) {
              customTag = {
                label: tags[pos].label,
                sku: quantities[o].sku,
                style: tags[pos].style,
              };
            }
          }
        }
        var category = formatPathName(key.category);
        var productpath = formatPathName(key.productname + "-" + key.productid);

        if (_categoryRouteObj) {
          if (Array.isArray(_categoryRouteObj)) {
            category = "";
            for (let k in _categoryRouteObj) {
              let _category = formatPathName(_categoryRouteObj[k].label);
              category = category + "/" + _category;
            }
            category = category.substr(1, category.length);
          } else {
            category = _categoryRouteObj;
          }
        }

        var objdata = {
          productid: key.productid,
          label: key.productname,
          //path	 	 	 : key.image,
          path: path,
          width: 250,
          height: 250,
          pricenew: pricenew,
          priceold: priceold,
          discount: discount,
          allSKusD: allSKusD,
          allSKusC: allSKusC,
          currency: getCurrency(),
          value: "",
          isnew: false,
          customTag: customTag,
          options: key.options,
          quantities: quantities,
          minprice: key.minprice,
          maxprice: key.maxprice,
          tag: tag,
          genericsku: key.genericsku,
          category: category,
          productpath: productpath,
        };

        var objconfig = {
          quantitySelected: 1,
          manageCart: _manageCart,
          optionsselected: optionsselected,
          favorite:
            wishlist.indexOf(parseInt(key.productid)) < 0 ? false : true,
          showVariants: showVariants,
          fatherConfig: _prodDefConfig,
        };

        if (quantities.length === 1) {
          objconfig["optionsselected"] = [
            {
              optionid: quantities[0].optionid,
              optionvalue: quantities[0].optionvalue,
            },
          ];
          objdata["productsku"] = quantities[0].sku;
          objdata["hideoptions"] = true;
          objdata["maxQuantity"] = quantities[0].quantity;
        }

        if (!showVariants) {
          objdata["productsku"] = quantities[0].sku;
          objdata["hideoptions"] = true;
          objdata["maxQuantity"] = quantities[0].quantity;
        }

        var style = {};
        style["margin-bottom"] = "0.9rem";
        cardProducts.push({
          body: (
            <Product
              name={_container}
              productData={objdata}
              productConfig={objconfig}
            />
          ),
          productid: key.productid,
        });
      }
    }
    var itemsperpage = 5;
    var pages = Math.ceil(cardProducts.length / itemsperpage);
    var paginationGroup = [];
    for (let i = 1; i <= pages; i++) {
      paginationGroup.push(i);
    }
    var cardConfig = _prodDefConfig;

    cardConfig["managepagination"] = _managepagination;
    cardConfig["pagesettings"] = {
      itemsperpage: itemsperpage,
      paginationGroup: paginationGroup,
      currentPage: 1,
      pages: pages,
      cardProducts: cardProducts.length,
    };
    resp = {
      cardProducts,
      cardConfig,
    };
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
  } finally {
    return resp;
  }
}

export function formatPathName(string) {
  try {
    var category = string.toString().toLowerCase();

    category = category.split("inicio").join("/");
    category = category.split("home").join("/");
    category = category.split(" > ").join("/");
    category = category.split(" ").join("-");

    category = category.split(" ").join("-");
    category = category.split("/").join("-");
    category = category.split("|").join("-");
    category = category.split("\u00E1").join("a");
    category = category.split("\u00E9").join("e");
    category = category.split("\u00ED").join("i");
    category = category.split("\u00F3").join("o");
    category = category.split("\u00FA").join("u");
    category = category.split("\u00F1").join("n");
    category = category.split(",").join("-");
    category = category.split("--").join("-");
    category = category.split("&").join("-");

    return category;
  } catch (Ex) {
    console.log("Error", string);
    return string;
  }
}
export function formatPathCategory(string) {
  try {
    var category = string.toString().toLowerCase();

    category = category.split("inicio").join("");
    category = category.split("home").join("");
    category = category.split(" > ").join("/");
    category = category.split(" ").join("-");

    category = category.split(" ").join("-");

    category = category.split("|").join("-");
    category = category.split("\u00E1").join("a");
    category = category.split("\u00E9").join("e");
    category = category.split("\u00ED").join("i");
    category = category.split("\u00F3").join("o");
    category = category.split("\u00FA").join("u");
    category = category.split("\u00F1").join("n");
    category = category.split(",").join("-");
    category = category.split("--").join("-");

    return category;
  } catch (Ex) {
    console.log("Error", string);
    return string;
  }
}

export const refreshTokenSetup = (res) => {
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;

    localStorage.setItem("authToken", newAuthRes.id_token);

    setTimeout(refreshToken, refreshTiming);
  };

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};

/*predefined otpions*/
export async function suscribe(_email) {
  //await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "form2", foldername: "layouts/" } )
  console.log("_email", _email);
  return {
    response: true,
    descResponse: "Gracias por suscribirse!",
  };
}

export function shareEvent(
  socialNetwork,
  url,
  title,
  _width,
  _height,
  aditionalInfo
) {
  var finalurl;
  let url2 = url; // replaceAll2 ( url, ' ', '%20')
  /*
	var x=(window.screen.width/2)-(_width/2);
	//Ajustar verticalmente
	var y=(window.screen.height/2)-(_height/2);
	*/
  if (socialNetwork === "facebook") {
    finalurl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      url2 +
      "&t=" +
      title +
      " " +
      aditionalInfo +
      "&quote=";
  } else if (socialNetwork === "twitter") {
    finalurl =
      "https://twitter.com/intent/tweet?text=" +
      title +
      " \n" +
      aditionalInfo +
      " " +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      url2 +
      "&related=AddToAny,micropat";
  } else if (socialNetwork === "whatsapp") {
    finalurl =
      "https://api.whatsapp.com/send?text=" +
      "" +
      title +
      " \n" +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      aditionalInfo +
      " " +
      url2;
  }

  window.open(
    encodeURI(finalurl).replace("cidev-admin", "cidev-front"),
    getDefValues().publishFacebookLabel,
    "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=" +
      _width +
      ",height=" +
      _height
  );

  //window.open(finalurl, getDefValues().publishFacebookLabel, 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width='+_width+',height='+_height + ', left=' + x + ', top=' + y +'' );
}

export function shareProduct(
  socialNetwork,
  url,
  title,
  _width,
  _height,
  aditionalInfo
) {
  var finalurl;
  let url2 = url; // replaceAll2 ( url, ' ', '%20')
  /*
	var x=(window.screen.width/2)-(_width/2);
	//Ajustar verticalmente
	var y=(window.screen.height/2)-(_height/2);
	*/
  if (socialNetwork === "facebook") {
    finalurl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      url2 +
      "&t=Mira%20esto...\uD83D\uDC40" +
      title +
      "&quote=";
  } else if (socialNetwork === "twitter") {
    finalurl =
      "https://twitter.com/intent/tweet?text=Mira esto...\uD83D\uDC40" +
      title +
      " \n" +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      url2 +
      "&related=AddToAny,micropat";
  } else if (socialNetwork === "whatsapp") {
    finalurl =
      "https://api.whatsapp.com/send?text=" +
      "Mira esto...\uD83D\uDC40" +
      title +
      " \n" +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      url2 +
      "";
  } else if (socialNetwork === "email") {
    finalurl =
      "https://mail.google.com/mail/u/0/?ui=2&fs=1&tf=cm&su=Mira%20esto..." +
      title +
      "&body=" +
      process.env.REACT_APP_URL_FRONT_CONFIG +
      url2 +
      "%0D%0A%0D%0A" +
      aditionalInfo;
  }

  window.open(
    encodeURI(finalurl).replace("cidev-admin", "cidev-front"),
    getDefValues().publishFacebookLabel,
    "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=" +
      _width +
      ",height=" +
      _height
  );

  //window.open(finalurl, getDefValues().publishFacebookLabel, 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width='+_width+',height='+_height + ', left=' + x + ', top=' + y +'' );
}

export function setGTMEvent(_event, _data) {
  var resp;
  try {
    if (process.env.REACT_APP_ENV === "production") {
      resp = true;
      //	console.log("_event",_event, _data)
      if (_event === "category") {
        const data1 = {
          event: "isCategoryView",
          current_view: _data.current_view,
          current_list: _data.current_list,
          current_currency: _data.current_currency,
          userId: _data.userId,
          string_searched: _data.string_searched,
          store_name: _data.store_name,
          googleDynamicRemarketing: _data.googleDynamicRemarketing,
          criteoParams: _data.criteoParams,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          ecommerce: _data.ecommerce,
        };
        window.dataLayer.push(data1);

        const data2 = {
          event: "enhancedEcommerceProductImpressions",
          current_view: _data.current_view,
          current_list: _data.current_list,
          current_currency: _data.current_currency,
          userId: _data.userId,
          string_searched: _data.string_searched,
          store_name: _data.store_name,
          googleDynamicRemarketing: _data.googleDynamicRemarketing,
          criteoParams: _data.criteoParams,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          ecommerce: _data.ecommerce,
        };
        window.dataLayer.push(data2);

        console.log("GTM4 view_item_list");
        window.dataLayer.push({
          event: "view_item_list",
          eventProps: {
            items: _data.items,
            item_list_name: _data.current_list,
            item_list_id: _data.categoryid,
          },
        });
      } else if (_event === "productDetails") {
        const data1 = _data;
        data1["event"] = "productDetails";
        window.dataLayer.push(data1);

        const data2 = {
          current_view: _data.current_view,
          current_list: _data.current_list,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          fb_pixel_track_name: _data.fb_pixel_track_name,
          fb_pixel_track_info: _data.fb_pixel_track_info,
          event: "trackFBPixel",
        };
        window.dataLayer.push(data2);

        const data3 = {
          checkoutStep: { step: 1 },
          event: "checkoutStep",
          current_view: _data.current_view,
          current_list: _data.current_list,
          productDetails: _data.productDetails,
          googleDynamicRemarketing: _data.googleDynamicRemarketing,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          ecommerce: _data.ecommerce,
          fb_pixel_track_name: _data.fb_pixel_track_name,
          fb_pixel_track_info: _data.fb_pixel_track_info,
        };
        window.dataLayer.push(data3);

        const data4 = {
          event: "enhancedEcommerceProductDetails",
          current_view: _data.current_view,
          current_list: _data.current_list,
          productDetails: _data.productDetails,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          ecommerce: _data.ecommerce,
          fb_pixel_track_name: _data.fb_pixel_track_name,
          fb_pixel_track_info: _data.fb_pixel_track_info,
        };
        window.dataLayer.push(data4);

        console.log(
          "GTM4 view_item",
          _data.productDetails.product.price_final.toFixed(2)
        );
        window.dataLayer.push({
          event: "view_item",
          eventProps: {
            affiliation: "Google Store",
            currency: "BOB",
            value: _data.productDetails.product.price_final.toFixed(2),
            items: [
              {
                item_id: _data.productDetails.product_id,
                item_name: _data.productDetails.name,
                coupon: "",
                discount: 0,
                affiliation: "Google Store",
                item_brand: "Casa ideas",
                item_category: _data.productDetails.category,
                item_variant: _data.productDetails.sku,
                price: _data.productDetails.product.price_final.toFixed(2),
                currency: "BOB",
                quantity: 1,
              },
            ],
          },
        });
      } else if (_event === "addToCart") {
        const data1 = {
          fb_pixel_track_name: "ViewContent",
          fb_pixel_track_info: {
            value: _data.price,
            currency: "BOB",
            content_ids: ["" + _data.id],
            content_name: _data.name,
            content_type: "product",
          },
          addToCart: _data,
          event: "addToCart",
        };
        // 	console.log(data1)
        window.dataLayer.push(data1);

        const data2 = {
          checkoutStep: { step: 2, action: "checkout" },
          event: "checkoutStep",
        };
        window.dataLayer.push(data2);

        console.log("GTM4 add_to_cart", _data.price.toFixed(2));
        window.dataLayer.push({
          event: "add_to_cart",
          eventProps: {
            currency: "USD",
            items: [
              {
                item_id: _data.id,
                item_name: _data.name,
                coupon: "",
                discount: 0,
                affiliation: "Google Store",
                item_brand: "Casa Ideas",
                item_category: _data.category,
                item_variant: _data.variant,
                price: _data.price.toFixed(2),
                currency: "BOB",
                quantity: 1,
              },
            ],
            value: 7.77,
          },
        });
      } else if (_event === "removeFromCart") {
        console.log("GTM4 removeFromCart", _data.price.toFixed(2));
        const data1 = {
          fb_pixel_track_name: "ViewContent",
          fb_pixel_track_info: {
            value: _data.price.toFixed(2),
            currency: "BOB",
            content_ids: ["" + _data.productid],
            content_name: _data.label,
            content_type: "product",
          },
          addToCart: _data,
          event: "removeFromCart",
        };
        window.dataLayer.push(data1);

        const data2 = {
          checkoutStep: { step: 2, action: "checkout" },
          event: "checkoutStep",
        };
        window.dataLayer.push(data2);

        window.dataLayer.push({
          event: "remove_from_cart",
          eventProps: {
            currency: "USD",
            items: [
              {
                item_id: _data.id,
                item_name: _data.name,
                coupon: "",
                discount: 0,
                affiliation: "Google Store",
                item_brand: "Casa Ideas",
                item_category: _data.category,
                item_variant: _data.variant,
                price: _data.price.toFixed(2),
                currency: "BOB",
                quantity: 1,
              },
            ],
            value: _data.price,
          },
        });
      } else if (_event === "googleDynamicRemarketing") {
        window.dataLayer.push({
          event: "googleDynamicRemarketing",
        });
      } else if (_event === "GDPRStatisticsAccepted") {
        window.dataLayer.push({
          event: "GDPRStatisticsAccepted",
        });
      } else if (_event === "orderSuccess") {
        //console.log("_data",_data)
        const data1 = {
          purchasedProducts: _data.purchasedProducts
            ? _data.purchasedProducts
            : [],
        };

        window.dataLayer.push(data1);
        //console.log(JSON.stringify(data1))

        const data2 = _data;
        window.dataLayer.push(data2);
        //console.log(JSON.stringify(data2))

        const data3 = {
          current_view: "purchase",
          current_list: "purchase",
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",

          event: "GDPRStatisticsAccepted",
        };
        window.dataLayer.push(data3);

        //console.log(JSON.stringify(data3))
        const data3_5 = {
          event: "checkoutStep",
          checkoutStep: { step: 5, action: "checkout" },
        };

        window.dataLayer.push(data3_5);

        window.dataLayer.push({ ecommerce: null });

        //console.log(JSON.stringify(data3_5))
        const data4 = {
          event: "enhancedEcommercePurchase",
          ecommerce: _data.ecommerce,
        };

        console.log("GTM4 purcharse", _data);
        window.dataLayer.push({
          event: "purchase",
          eventProps: {
            affiliation: "Google Store",
            coupon: _data.ecommerce.purchase.actionField.coupon
              ? _data.ecommerce.purchase.actionField.coupon
              : "",
            currency: "BOB",
            items: _data.ecommerce.purchase.items,
            transaction_id: _data.ecommerce.purchase.actionField.id,
            shipping: _data.ecommerce.purchase.actionField.shipping,
            value: _data.ecommerce.purchase.actionField.revenue,
            tax: 0,
          },
        });

        //console.log(JSON.stringify(data4))
        window.dataLayer.push(data4);
        /*
				const data6 = {
					current_view: _data.current_view,
					current_list: _data.current_list,
					gdpr_marketing_status: "accepted",
	  			gdpr_statistics_status: "accepted",
					fb_pixel_track_name: _data.fb_pixel_track_name,
				  fb_pixel_track_info: _data.fb_pixel_track_info,
				  event:"trackFBPixel"
				  }
				window.dataLayer.push(data6);
				*/
      } else if (_event === "loadPage") {
        window.dataLayer.push(_data);

        const data2 = _data;
        data2["event"] = "GDPRMarketingAccepted";
        window.dataLayer.push(_data);

        const data3 = _data;
        data2["event"] = "GDPRStatisticsAccepted";
        window.dataLayer.push(_data);
      } else if (_event === "checkoutStep") {
        window.dataLayer.push(_data);
      } else if (_event === "shopcart") {
        try {
          _data.cart_products.map(function (key, item) {
            key["price"] = key.price.toFixed(2);
          });
          _data.items.map(function (key, item) {
            key["price"] = key.price.toFixed(2);
          });
        } catch (Ex) {
          console.log("formatted");
        }

        console.log("GTM4 begin_checkout", _data.items);

        const data3 = {
          event: "GDPRMarketingAccepted",
          current_view: _data.current_view,
          current_list: _data.current_list,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          criteoParams: _data.criteoParams,
          cart_products: _data.cart_products,
        };
        window.dataLayer.push(data3);

        const data4 = {
          event: "GDPRStatisticsAccepted",
          current_view: _data.current_view,
          current_list: _data.current_list,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          criteoParams: _data.criteoParams,
          cart_products: _data.cart_products,
        };
        window.dataLayer.push(data4);

        const data5 = {
          event: "googleDynamicRemarketing",
          current_view: _data.current_view,
          current_list: _data.current_list,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          criteoParams: _data.criteoParams,
          cart_products: _data.cart_products,
        };
        window.dataLayer.push(data5);

        const data1 = _data;
        data1["event"] = "checkoutStep";
        data1["checkoutStep"] = { step: 3 };
        window.dataLayer.push(data1);

        const data2 = {
          event: "trackFBPixel",
          current_view: _data.current_view,
          current_list: _data.current_list,
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          fb_pixel_track_name: _data.fb_pixel_track_name,
          fb_pixel_track_info: _data.fb_pixel_track_info,
          cart_products: _data.cart_products,
        };
        window.dataLayer.push(data2);

        window.dataLayer.push({
          event: "begin_checkout",
          eventProps: {
            coupon: "",
            currency: "BOB",
            items: _data.items,
            value: _data.googleDynamicRemarketing.ecomm_totalvalue,
          },
        });
      } else if (_event === "add_shipping_info") {
        try {
          _data.items.map(function (key, item) {
            key["price"] = key.price.toFixed(2);
          });
        } catch (Ex) {
          console.log("formatted");
        }
        console.log("GTM4 add_shipping_info", _data.items);
        window.dataLayer.push({
          event: "add_shipping_info",
          eventProps: {
            coupon: "",
            currency: "BOB",
            items: _data.items,
            shipping_tier: _data.shipping_tier,
            value: _data.value,
          },
        });
      } else if (_event === "add_payment_info") {
        console.log("GTM4 add_payment_info", _data);
        try {
          _data.items.map(function (key, item) {
            key["price"] = key.price.toFixed(2);
          });
        } catch (Ex) {
          console.log("formatted");
        }

        console.log("GTM4 add_payment_info", _data.items);
        window.dataLayer.push({
          event: "add_payment_info",
          eventProps: {
            coupon: "",
            currency: "BOB",
            items: _data.items,
            payment_type: _data.payment_type,
            value: _data.value,
          },
        });
      }
    } else {
      resp = true;
      console.log("developer mode");
    }
    return resp;
  } catch (err) {
    console.log("ERR GTM>>>>>>>", err);
    resp = false;
    return resp;
  }
}
//send codePoint= "//icon//".codePointAt(0)
/*
function toUTF16(codePoint) {
  var TEN_BITS = parseInt("1111111111", 2);
  function u(codeUnit) {
    return "\\u" + codeUnit.toString(16).toUpperCase();
  }

  if (codePoint <= 0xffff) {
    return u(codePoint);
  }
  codePoint -= 0x10000;

  // Shift right to get to most significant 10 bits
  var leadSurrogate = 0xd800 + (codePoint >> 10);

  // Mask to get least significant 10 bits
  var tailSurrogate = 0xdc00 + (codePoint & TEN_BITS);

  return u(leadSurrogate) + u(tailSurrogate);
}
*/
