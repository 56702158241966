import React from "react";
import { useParams } from "react-router-dom";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import ContentLoader from "react-content-loader";
import Card from "../../components/Card";
import { getParamValues, getDefValues } from "../../common/functions";

const maxItemsFilter = getParamValues().maxItemsFilter;

const Search = (props) => {
  const [fistTime, setFistTime] = React.useState(true);

  React.useEffect(() => {
    if (props.searchinput === "" && fistTime) {      
      props.changeHandlerS({ target: { name: "search_input", value: param } });
      setFistTime(false);
    } else {
      setFistTime(false);
    }
  }, [props]);

  let { param } = useParams();

  return (
    <div className="content custom-container container-page container-fluid container-page">
      <div className="row row-top mb-2 mt-2">
        <div className="col-12 flex items-center text-center hide-mobile">
          <div
            className="h1-responsive mb-2 mt-30"
            style={{ textAlign: "center" }}
          >
            {getDefValues().searchTitleLabel}
          </div>
        </div>
      </div>
      <div className="row row-top mb-2 mt-2">
        <div className="col-12 flex items-center text-center hide-mobile">
          <div className="search-style-n">
            <div style={{ justifyContent: "center" }}>
              <input
                type="text"
                name="search_input"
                ref={(input) =>
                  input && props.mobileFirstTime ? input.focus() : ""
                }
                value={props.searchinput}
                onChange={props.changeHandlerS}
                onKeyDown={props.changeHandlerS}
                placeholder={getDefValues().searchLabel}
              />
            </div>
          </div>
        </div>
      </div>

      {props.searchData.showResults ? (
        <div className="row">
          <div className="col-12">
            <div className={"container search-container"}>
              <div className="row mb-4">
                <div className="col col-3 col-cat">
                  <div className="col-cat-content">
                    {props.searchData.listcategories ? (
                      <>
                        <ul className="categories">
                          {props.searchData.categories.map((item, i) =>
                            props.searchData.showcategories ||
                            i < maxItemsFilter ? (
                              <li
                                className={
                                  props.searchData.selectedcategories.indexOf(
                                    "" + item.categoryid
                                  ) >= 0
                                    ? "active"
                                    : ""
                                }
                              >
                                <button
                                  name={"filter_categories_" + item.categoryid}
                                  onClick={props.manageSearch}
                                  className="btn btn-sm name searcher-mobile-button "
                                >
                                  {item.categoryname}
                                </button>
                                <button
                                  name={"filter_categories_" + item.categoryid}
                                  onClick={props.manageSearch}
                                  className="btn btn-sm quantity searcher-mobile-button"
                                >
                                  {item.quantity}
                                </button>
                              </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                        {!props.searchData.showcategories &&
                        props.searchData.categories.length > maxItemsFilter ? (
                          <div className="flex items-center">
                            <button
                              name="show_showcategories"
                              onClick={props.manageSearch}
                              className="btn btn-sm searcher-mobile-button"
                            >
                              {getDefValues().showMoreLabel}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                        {props.searchData.showcategories &&
                        props.searchData.categories.length > maxItemsFilter ? (
                          <div className="flex items-center">
                            <button
                              name="hide_showcategories"
                              onClick={props.manageSearch}
                              className="btn btn-sm searcher-mobile-button"
                            >
                              {getDefValues().hideLabel}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {/*props.searchData.maxpricedef> 0?
	  					 	<div className="mt-2 mb-2">

									<ul className="categories">
										<li className="" >
											<button name="toggle_listprices" onClick={props.manageSearch}	className="btn btn-sm title-name">
							 		 		 { getDefValues().PriceLabel}
									 		</button>

											<button name="toggle_listprices" onClick={props.manageSearch}	className="btn btn-sm title-button">
						 		 				<i className={"fas "+(props.searchData.listPrices?"fa-minus":"fa-plus")}  />
								 		 	</button>
										</li>
									</ul>


									{props.searchData.listprices?
										<div className="col-12 mt-3" style={{"min-height":"40px"}} >

											<div className="price-slider-container">
												<div className="item" style={{"left":"0%"}} >{ getCurrency() + ""+ props.searchData.minprice}</div>
												<div className="item" style={{"right": "5%"}}  >{ getCurrency() + ""+props.searchData.maxprice}</div>
											</div>
	    								<input  type="range"
								        min={props.searchData.minpricedef}
								        max={props.searchData.maxpricedef}
								        name={"changeprice_minprice"}
								        value={props.searchData.minprice}
								        className={"slider-range thumb " + (props.searchData.minprice == props.searchData.maxpricedef?"thumb--zindex-5":"thumb--zindex-3") }
								        onChange={props.manageSearch}
											/>
								      <input type="range"
								        min={props.searchData.minpricedef}
								        max={props.searchData.maxpricedef}
								        name={"changeprice_maxprice"}
								      	value={props.searchData.maxprice}
								      className={"slider-range thumb " + (props.searchData.maxprice == props.searchData.minpricedef?"thumb--zindex-5":"thumb--zindex-4") }
								        onChange={props.manageSearch}
								      />

	        						<div className="price-slider">
											  <div className="price-slider__track" />
											  <div className="price-slider__range" />
											  <div className="price-slider__left-value">{getCurrency() +""+props.searchData.minpricedef}</div>

											  <div className="item-mark-big" style={{"left":"6px"}}></div>
											  <div className="item-mark-big" style={{"right":"5px"}}></div>
											  <div className="item-mark-big" style={{"left": "107px"}}></div>
											  {rangeItems.map(item=> (

											  	<div className="item-mark" style={{"left": (6+( item/ rangeItems.length *275   ))+"px"}}></div>

												))}
												<div className="price-slider__right-value">{getCurrency() +""+props.searchData.maxpricedef}</div>
											</div>
										</div>
									:""}
								</div>
							:""
							*/}
                    {props.searchData.options.length > 0
                      ? props.searchData.options.map((item, i) => (
                          <div className="mt-2 mb-2">
                            <ul className="categories">
                              <li className="">
                                <button
                                  name={"toggle_listoptions_" + item.optionid}
                                  onClick={props.manageSearch}
                                  className="btn btn-sm title-name searcher-mobile-button"
                                >
                                  {item.optionname}
                                  {item.selectedoptions.length > 0
                                    ? " (" + item.selectedoptions.length + ")"
                                    : ""}
                                </button>

                                <button
                                  name={"toggle_listoptions_" + item.optionid}
                                  onClick={props.manageSearch}
                                  className="btn btn-sm title-button searcher-mobile-button"
                                >
                                  <i
                                    className={
                                      "fas " +
                                      (item.listoptions
                                        ? "fa-minus"
                                        : "fa-plus")
                                    }
                                  />
                                </button>
                              </li>
                            </ul>

                            {item.listoptions ? (
                              <>
                                <ul className="categories">
                                  {item.options.map((option, i) =>
                                    item.showoptions || i < maxItemsFilter ? (
                                      <li
                                        className={
                                          item.selectedoptions.indexOf(
                                            "" + option.value
                                          ) >= 0
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <button
                                          name={
                                            "filter_options_" +
                                            item.optionid +
                                            "_" +
                                            option.value
                                          }
                                          onClick={props.manageSearch}
                                          className="btn btn-sm name searcher-mobile-button "
                                        >
                                          {option.value}
                                        </button>
                                        <button
                                          name={
                                            "filter_options_" +
                                            item.optionid +
                                            "_" +
                                            option.value
                                          }
                                          onClick={props.manageSearch}
                                          className="btn btn-sm quantity searcher-mobile-button"
                                        >
                                          {option.quantity}
                                        </button>
                                      </li>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </ul>
                                {!item.showoptions &&
                                item.options.length > maxItemsFilter ? (
                                  <div className="flex items-center">
                                    <button
                                      name={"show_showoptions_" + item.optionid}
                                      onClick={props.manageSearch}
                                      className="btn btn-sm searcher-mobile-button"
                                    >
                                      {getDefValues().showMoreLabel}
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {item.showoptions &&
                                item.options.length > maxItemsFilter ? (
                                  <div className="flex items-center">
                                    <button
                                      name={"hide_showoptions_" + item.optionid}
                                      onClick={props.manageSearch}
                                      className="btn btn-sm searcher-mobile-button"
                                    >
                                      {getDefValues().hideLabel}
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            {item.selectedoptions.length > 0 &&
                            !item.listoptions ? (
                              <>
                                <ul className="categories">
                                  {item.options.map((option, i) =>
                                    item.selectedoptions.indexOf(
                                      "" + option.value
                                    ) >= 0 ? (
                                      <li className="active">
                                        <button
                                          name={
                                            "filter_options_" +
                                            item.optionid +
                                            "_" +
                                            option.value
                                          }
                                          onClick={props.manageSearch}
                                          className="btn btn-sm name searcher-mobile-button "
                                        >
                                          {option.value}
                                        </button>
                                        <button
                                          name={
                                            "filter_options_" +
                                            item.optionid +
                                            "_" +
                                            option.value
                                          }
                                          onClick={props.manageSearch}
                                          className="btn btn-sm quantity "
                                        >
                                          {option.quantity}
                                        </button>
                                      </li>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </ul>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
                <div className="col col-9 col-list ">
                  <div className="list-header">
                    <div>
                      {" "}
                      {getDefValues().resultsLabel.toUpperCase() +
                        ": " +
                        props.cardData.length}
                    </div>
                  </div>
                  <span className="or-line mt-2"></span>

                  {props.searchData.noresults ? (
                    <span className="flex items-center">
                      {props.searchData.noresults}{" "}
                    </span>
                  ) : (
                    ""
                  )}

                  <div className="list-content row">
                    {props.isSearching ? (
                      <>
                        {props.windowWidth <= 800 && (
                          <ContentLoader
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            speed={2}
                            viewBox="0 0 420 300"
                            height={300}
                            width={420}
                            {...props}
                          >
                            <rect
                              x="35"
                              y="10"
                              rx="0"
                              ry="0"
                              width="190"
                              height="200"
                            />
                            <rect
                              x="240"
                              y="10"
                              rx="0"
                              ry="0"
                              width="190"
                              height="200"
                            />
                            <rect
                              x="35"
                              y="225"
                              rx="0"
                              ry="0"
                              width="190"
                              height="35"
                            />
                            <rect
                              x="240"
                              y="225"
                              rx="0"
                              ry="0"
                              width="190"
                              height="35"
                            />
                          </ContentLoader>
                        )}
                        {props.windowWidth > 800 &&
                          props.windowWidth <= 1200 && (
                            <ContentLoader
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                              speed={2}
                              viewBox="0 0 620 300"
                              height={300}
                              width={620}
                              {...props}
                            >
                              <rect
                                x="35"
                                y="10"
                                rx="0"
                                ry="0"
                                width="190"
                                height="200"
                              />
                              <rect
                                x="240"
                                y="10"
                                rx="0"
                                ry="0"
                                width="190"
                                height="200"
                              />
                              <rect
                                x="445"
                                y="10"
                                rx="0"
                                ry="0"
                                width="190"
                                height="200"
                              />
                              <rect
                                x="35"
                                y="225"
                                rx="0"
                                ry="0"
                                width="190"
                                height="35"
                              />
                              <rect
                                x="240"
                                y="225"
                                rx="0"
                                ry="0"
                                width="190"
                                height="35"
                              />
                              <rect
                                x="445"
                                y="225"
                                rx="0"
                                ry="0"
                                width="190"
                                height="35"
                              />
                            </ContentLoader>
                          )}
                        {props.windowWidth > 1200 && (
                          <ContentLoader
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            speed={2}
                            viewBox="0 0 820 300"
                            height={300}
                            width={820}
                            {...props}
                          >
                            <rect
                              x="35"
                              y="10"
                              rx="0"
                              ry="0"
                              width="190"
                              height="200"
                            />
                            <rect
                              x="240"
                              y="10"
                              rx="0"
                              ry="0"
                              width="190"
                              height="200"
                            />
                            <rect
                              x="445"
                              y="10"
                              rx="0"
                              ry="0"
                              width="190"
                              height="200"
                            />
                            <rect
                              x="650"
                              y="10"
                              rx="0"
                              ry="0"
                              width="190"
                              height="200"
                            />
                            <rect
                              x="35"
                              y="225"
                              rx="0"
                              ry="0"
                              width="190"
                              height="35"
                            />
                            <rect
                              x="240"
                              y="225"
                              rx="0"
                              ry="0"
                              width="190"
                              height="35"
                            />
                            <rect
                              x="445"
                              y="225"
                              rx="0"
                              ry="0"
                              width="190"
                              height="35"
                            />
                            <rect
                              x="650"
                              y="225"
                              rx="0"
                              ry="0"
                              width="190"
                              height="35"
                            />
                          </ContentLoader>
                        )}
                      </>
                    ) : (
                      <Card
                        cardData={props.cardData}
                        cardConfig={props.cardConfig}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Search;
