import React from 'react';

import {getImagePath} from '../../common/functions';
const Category = (props) => {
	 
	if (props.image || props.text){
	 
 
	  return ( 
			<>
			
			<div className="owl-item" >
				<div className="banner-item">
       		<button className="banner-image transparent-button" id={props.id} name={props.id} onClick={props.callback }>
          	
              <img src={getImagePath(props.image)}  alt={props.imageAlt} title={props.text} />
          
        	</button>
	      	<div className="content_slider">
	      		<button className="transparent-button" id={props.id} name={props.id}  onClick={props.callback }>
		        	<span  className="banner-description">{props.text}</span>
	          </button>
	       	</div>
	  		</div>
	  	</div>
			 
			</>	 
	  );
	}else{
		return "NO CONFIG"
	}
};
export default Category;
				 