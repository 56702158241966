import React from "react";
import {getImagePath} from '../../common/functions';

 
const loadvideo = props => {
	if (props.videoConfig){
		 
			return (   
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			    	<video style={{"width":"100%", "max-height":"100%" }}
			    		autoplay={false}  controls="true" preload="auto">
			    		<source src={getImagePath(props.videoData.video)}
			    	 	type="video/mp4"/>
			    	</video>
			    </div>
				</div> 
	  	);
		 
	}else{
		return "NO V CONFIG"
	}
};
export default loadvideo;
				 