import React from "react";
import AsyncSelect from 'react-select/async';

import { getDefValues } from '../../common/functions';

const  LoadAutoComplete = (props) => {
  const [inputValue, setValue] =  React.useState('');
  const [selectedValue, setSelectedValue] = React.useState(null);
	const [defaultOptions, setDefaultOptions] = React.useState([]);


  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
    
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
    props.onChange(value)
  }

  // load options using API call
  const loadOptions = (inputValue) => {
  	if (inputValue.length>2){
  		return fetch(`${process.env.REACT_APP_API_CONFIG}/api/frontend/searchEvents/${inputValue}`).then(res => res.json());	
  	}else{
  		return []
  	}
  };
  
  React.useEffect(()=>{
  	/*
		const fetchFn = async ()=>{
			let resp= await callApi ( "frontend","searchEvents" )
			setDefaultOptions(resp)
	   }
	 	fetchFn(); */
	 	setDefaultOptions(props.defaultOptions)
	 },[props])
	 return (
    <div className="App">
      
      <AsyncSelect
      
      className="searcher-event"
        cacheOptions
        theme ={"danger"}
	      placeholder={"Busca tu evento"}
        loadingMessage={() => getDefValues().loadingLabel }
        noOptionsMessage={() => getDefValues().writeEvent }
        defaultOptions={defaultOptions}
        value={selectedValue}
        getOptionLabel={e => e.eventname + " - "+ e.eventperson+ " ("+e.eventdate+")"}
        getOptionValue={e => e.eventid}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
      />
    </div>
  )
};
LoadAutoComplete.propTypes = {};
export default LoadAutoComplete;