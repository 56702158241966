/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React from "react";
import Slider from "../../components/Slider";
import SubCategory from "../../components/SubCategory";

import { getLanguage } from "../../common/functions";

const Category = (props) => {
  if (props.categories) {
    var categories = props.categories;
    var categoryName = "";
    var _categoryRoute = "";
    var categoryRoute = [];
    var categoryID = 0;
    var subcategories = [];

    categories.map(function (cat) {
      if (cat.CATEGORYID === parseInt(props.categoryid)) {
        categoryName = cat.CATEGORYLABEL;
        categoryID = cat.CATEGORYID;
        _categoryRoute = cat.CATEGORYIDROOT;
        for (let s in categories) {
          if (
            categoryID === categories[s].PARENTID &&
            parseInt(getLanguage()) === categories[s].LANGUAGEID
          ) {
            subcategories.push({
              body: (
                <SubCategory
                  image={categories[s].IMAGEPATH}
                  imageAlt={categories[s].CATEGORYLABEL}
                  id={categories[s].CATEGORYID}
                  callback={props.setCategory}
                  text={categories[s].CATEGORYLABEL}
                />
              ),
            });
          }
        }
      }
    });

    categoryRoute = _categoryRoute.split(">");
    var categoryRouteObj = [];
    for (let key in categoryRoute) {
      for (let cat in categories) {
        if (
          parseInt(categoryRoute[key]) === parseInt(categories[cat].CATEGORYID)
        ) {
          categoryRouteObj.push({
            label: categories[cat].CATEGORYLABEL,
            categoryid: categories[cat].CATEGORYID,
          });
        }
      }
    }

    var categoryObj = {
      orgName: process.env.REACT_APP_ORG_NAME,
      categoryName,
      categoryRouteObj,
      categoryID,
      subcategories,
    };

    return (
      <>
        {props.showcategoryroot ? (
          <div className="categoryRoot mt-4">
            <>{categoryObj.orgName + " > "}</>
            {categoryObj.categoryRouteObj.map((cat) =>
              parseInt(cat.categoryid) === parseInt (props.categoryid) ? (
                <span>{cat.label}</span>
              ) : cat.categoryid > 0 ? (
                <a href="#" onClick={props.setCategory}>
                  {" "}
                  {cat.label + " > "}
                </a>
              ) : (
                <>{cat.label + " > "}</>
              )
            )}
          </div>
        ) : (
          ""
        )}
        {subcategories.length > 0 && props.settingsSlider ? (
          <Slider
            sliderData={subcategories}
            sliderConfig={props.settingsSlider}
          />
        ) : (
          ""
        )}
      </>
    );
  } else {
    return "NO CONFIG";
  }
};
export default Category;
