import React from "react";

import { Link, useHistory } from "react-router-dom";

import { getImagePath, getDefValues } from "../../common/functions";
import { MDBModal, MDBModalBody } from "mdbreact";

//import QRCode from "react-qr-code";
import QRCode from "qrcode.react";

import whatsapp from "../../assets/images/icons/icon-whatsapp.png";

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const EventMessage = (props) => {
  const history = useHistory();
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen-print");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `evento${props.eventname}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <MDBModal
      isOpen={props.modal}
      toggle={props.toggle}
      className="zoom-modal-2"
      style={{ height: windowHeight }}
      centered
    >
      {!props.hideClose && (
        <div
          className="row"
          style={{
            width: "100%",
            backgroundColor: "#f6f4f2",
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <div
            className="col-11 mt-3 mb-3 text-left text-bold"
            style={{ cursor: "pointer", fontSize: 18 }}
          >
            {getDefValues().eventListHeader}
          </div>

          <div
            onClick={props.toggle}
            className="col-1 mt-3 mb-3 text-right text-bold"
            style={{ cursor: "pointer", fontSize: 18 }}
          >
            <b>X</b>
          </div>
        </div>
      )}

      {/**		:
			  <div className="row"  style={{ width:"100%", backgroundColor:"#f6f4f2" , marginLeft: 0, marginRight: 0 }}>
				<div className="col-12 mt-3 mb-3 text-left text-bold" style={{cursor:"pointer", fontSize:18}}>
					{getDefValues().thankslabel} 
	      	  	</div>
			  </div>  
			  
			  height:"95vh",
			  */}

      <MDBModalBody
        style={{
          alignItems: "start",
          padding: 0,
          background: windowWidth > 799 ? "#fff" : "#fff",
          overflowY: props.eventSuccess ? "hidden" : "auto",
          overflowX: "hidden",
        }}
      >
        <div
          className="card flex items-center middle"
          style={{ width: "100%", marginTop: 0 }}
        >
          <div
            className="row flex items-center"
            style={{
              width: "100%",
              background: props.eventSuccess ? "#bfe2d8" : "#fff",
            }}
          >
            <div
              className="col-12"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                background: props.eventSuccess ? "#bfe2d8" : "#fff",
              }}
            >
              {props.eventSuccess ? (
                <div className="row">
                  <div className="col-12 flex items-center">
                    <img
                      style={{
                        height: windowWidth > 799 ? 375 : "auto",
                        width: windowWidth > 799 ? "auto" : "100%",
                      }}
                      src={getImagePath(
                        windowWidth > 799
                          ? "/regalables/evento_creado.png"
                          : "/regalables/evento_creado_movil.png"
                      )}
                      alt="order-success"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 flex items-center middle ">
              <div className="row-2 flex items-center middle share-url-container no-border no-padding">
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={
                    {
                      /*
								backgroundPosition : windowWidth>799?"top": "top",
								marginLeft: windowWidth>799? 0: "auto", 
								backgroundRepeat: "no-repeat",
								justifyContent: "center",
								"background-position-x": "center",
								"background-position-y": "center",
							backgroundImage: 'url("'+getImagePath(props.eventimage)+'")' , height:375 */
                    }
                  }
                >
                  {/*props.eventimage &&
										<img className="event-image" src={props.eventimage} />
										*/}
                  {props.eventimage && (
                    <img
                      className="event-image"
                      src={getImagePath(getImagePath(props.eventimage))}
                      alt="event"
                    />
                  )}
                </div>

                {props.eventname ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-center">
                    <h2 style={{ color: "#696158", fontSize: "2.5rem" }}>
                      {props.eventname.toUpperCase()}
                    </h2>
                    {/**
									  <h1 style={{fontSize:"2rem", fontWeight:"bold", color: "#696158" , }}> 
										{props.eventperson}
									  </h1>
									  */}
                    <h5 style={{ color: "#696158" }}>
                      {props.eventdate.replace("/", " - ").replace("/", " - ")}
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {props.eventid ? (
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9"
                style={{
                  position: windowWidth > 799 ? "absolute" : "re",
                  left: windowWidth > 799 ? "25%" : "auto",
                  top: windowWidth > 799 ? "20%" : "auto",
                  bottom: windowWidth > 799 ? "auto" : "-29%",
                }}
              >
                <div className="row" style={{}}>
                  <div className="col-12 flex items-center">
                    <span className="h1-responsive">
                      {" "}
                      {getDefValues().thanksForCreateEventMessage}{" "}
                    </span>
                  </div>
                </div>
                <div className="row mt-1 mb-10">
                  <div className="col-12 flex items-center">
                    <span className="h1-responsive">
                      {" "}
                      {getDefValues().codeEventLabel}: {props.eventid}{" "}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col mt-3 mb-3 col-12 flex items-center middle">
                    <Link
                      className="btn btn-sm btn-event"
                      to={"/customer/event/manage/addProducts_" + props.eventid}
                    >
                      <span style={{ textTransform: "uppercase" }}>
                        {getDefValues().creataEventProductListLabel}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
              {/*props.eventname?
						    	<div className="row mt-30 mb-2">				        
					        	<div className="col-12 mb-2 text-center">
					         		<h1 style={{color: "#ddaea4", fontWeight:"bold", fontFamily:"Roboto" }}> 
						          		{props.eventname.toUpperCase()}
						          	</h1>
						        </div> 
						        
						      </div> 
					:""*/}

              {props.eventmessage ? (
                <div className="row mt-2 mb-2">
                  <div className="col-12 mb-2 text-center"></div>
                  <div className="col-12 mb-10 flex items-center">
                    <div
                      className="col-12 col-xl-9 col-lg-9 col-md-12  col-sm-12 text-center"
                      style={{ fontSize: "1.5rem" }}
                    >
                      {props.eventmessage}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {!props.shareUrl ? (
                ""
              ) : props.shareUrl !== "" ? (
                <>
                  <div className="row mb-20 flex items-center">
                    <div className="col-12 col-xl-9 col-lg-9 col-md-12  col-sm-12 mt-2">
                      <div className="row flex items-center middle">
                        <div
                          style={{
                            textAlign: windowWidth > 779 ? "left" : "center",
                          }}
                          className="col-12 col-lg-9  no-margin no-padding"
                        >
                          <h3
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            {getDefValues().shareEventListLabel}
                          </h3>
                          <div
                            style={{ marginTop: windowWidth > 779 ? 23 : 10 }}
                            className="row flex middle share-url-container"
                          >
                            <div className="col-8 text-left">
                              <i className="fas fa-link mr-1" />
                              <span>
                                {" "}
                                {props.shareUrl.length > 50 ? (
                                  <>
                                    {" "}
                                    {props.showMoreUrl ? (
                                      <>
                                        <a target="black" href={props.shareUrl}>
                                          {" "}
                                          {props.shareUrl}
                                        </a>
                                        <button
                                          className="no-button text-underline text-bold"
                                          onClick={props.toggleMoreUrl}
                                        >
                                          {" "}
                                          ver menos{" "}
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <a target="black" href={props.shareUrl}>
                                          {" "}
                                          {props.shareUrl.substr(0, 50) +
                                            "..."}{" "}
                                        </a>
                                        <button
                                          className="no-button text-underline text-bold"
                                          onClick={props.toggleMoreUrl}
                                        >
                                          {" "}
                                          ver mas{" "}
                                        </button>
                                      </>
                                    )}{" "}
                                  </>
                                ) : (
                                  <a target="black" href={props.shareUrl}>
                                    {" "}
                                    {props.shareUrl}{" "}
                                  </a>
                                )}
                              </span>
                            </div>
                            <div className="col-4 text-right">
                              <div
                                onClick={props.copyTextToClipboard}
                                className="btn btn-filled-event button-fit"
                              >
                                {" "}
                                {props.isCopied
                                  ? getDefValues().copiedLinkLabel
                                  : getDefValues().copyLinkLabel}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-12 col-lg-3 no-margin no-padding"
                          style={{
                            paddingLeft: 35,
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center",
                          }}
                        >
                          <QRCode
                            id="qr-gen"
                            value={props.shareUrl}
                            size={windowWidth > 779 ? 165 : windowWidth - 35}
                            level={"H"}
                            includeMargin={true}
                          />
                          <div
                            onClick={downloadQRCode}
                            className="download-link btn-event no-border no-padding"
                          >
                            <i className="fas fa-download" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**
									<div className="row mt-3 mb-3">
										<div className="col-12 flex items-center middle">
											<div className="row flex middle share-url-container">
												<div className="col-8 text-center">
													<i className="fas fa-link mr-1" />
													<span>	{props.shareUrl.length> 50? <> {props.showMoreUrl? 
															<> 
																<a target="black" href={props.shareUrl}> {props.shareUrl}</a> 
																<button className="no-button text-underline text-bold" onClick={props.toggleMoreUrl} > ver menos </button> 
															</>: 
															<> 
																<a target="black" href={props.shareUrl}>  {props.shareUrl.substr(0,50)+"..."} </a> 
																	<button className="no-button text-underline text-bold" onClick={props.toggleMoreUrl} > ver mas </button> 
															</>
															} </>
																: 
														<a target="black" href={props.shareUrl}> {props.shareUrl} </a> } 
													</span>
												</div>
												<div className="col-4 text-right">
													<div onClick={props.copyTextToClipboard} className="btn btn-outline-secondary button-fit"> {props.isCopied ? getDefValues().copiedLinkLabel: getDefValues().copyLinkLabel}  </div>
												</div>
											</div> 
										</div> 
									</div> 
									 */}
                  <div
                    className="row flex items-center"
                    style={{ display: "none" }}
                  >
                    <div className="col-12">
                      <div
                        className=""
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: "100%",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <QRCode
                          id="qr-gen-print"
                          value={props.shareUrl}
                          size={370}
                          level={"H"}
                          includeMargin={true}
                        />
                        <p style={{ marginTop: "-0.75rem" }}>
                          <div className="social-icons single-share flex items-center midle">
                            <button
                              className="h6-responsive btn btn-sm btn-secondary mr-2"
                              type="button"
                              onClick={downloadQRCode}
                            >
                              {getDefValues().downloadLabel}
                            </button>
                            <ul className="d-inline-block">
                              <li className="social-whatsapp">
                                <button
                                  name="whatsapp"
                                  onClick={props.shareEvent}
                                >
                                  <img src={whatsapp} alt="" />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-30">
                    <div className="col-12 flex items-center middle">
                      {/**
											<div className="row flex middle divider-container-2">

											</div> 
											 */}
                    </div>
                  </div>

                  <div className="row mt-2 flex items-center">
                    <div style={{ marginLeft: -5 }} className="col-6 col-lg-3">
                      <div
                        onClick={() => {
                          props.toggle();
                          props.toggleI();
                        }}
                        className="btn btn-block btn-sm btn-filled-event mr-2"
                      >
                        {" "}
                        {getDefValues().editEventInfoButton}{" "}
                      </div>
                    </div>
                    <div className="col-6 col-lg-3">
                      <div
                        onClick={() => {
                          props.toggle();
                          props.toggleL();
                        }}
                        className="btn btn-block btn-sm btn-filled-event"
                      >
                        {" "}
                        {getDefValues().editEventListButton}{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2 flex items-center">
                    <div
                      style={{ marginLeft: -5 }}
                      className="col-12 col-lg-6 text-center"
                    >
                      <p>
                        <button
                          className="btn btn-block btn-sm btn-filled-event"
                          type="button"
                          onClick={() => {
                            history.push("/");
                            props.setEventMode();
                          }}
                        >
                          {getDefValues().gotoStart}
                        </button>
                      </p>
                      {/*
												<div className="col-12 text-center mt-2 ">
											 		<div onClick={props.toggle} className="h4-responsive ml-2 px-2 btn btn-secondary"> {getDefValues().closeWindowLabel}  </div>
											 	</div>
												*/}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {props.updateEventList ? (
                <div className="row mb-1">
                  <div className="col-12 text-right mb-1">
                    <button
                      onClick={props.updateEventList}
                      className="btn btn-sm btn-secondary"
                    >
                      {getDefValues().editEventButton}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              {props.updateEventList ? (
                <div className="row mb-1">
                  <div className="col-12 text-right mb-1">
                    <button
                      onClick={props.updateEventList}
                      className="btn btn-sm btn-secondary"
                    >
                      {getDefValues().editEventListButton}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {props.showList ? (
                <div className="row mb-1 mt-2">
                  <div className="col-12 text-center mb-1">
                    <button
                      onClick={props.showList}
                      className="btn event-button2"
                    >
                      {getDefValues().showEventList}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};
export default EventMessage;
