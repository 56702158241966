/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getDefValues } from "../../common/functions";
import { Link } from "react-router-dom";
const loadlogin = (props) => {
  return (
    <div className="login-right-wrap loginModal">
      <form className="customform userForm">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
            <div className="form-group  card-label ">
              <label> {props.formData.email.label} </label>
              <input
                type="text"
                className={
                  "form-control" +
                  (props.formData.email.isInvalid ? " is-invalid" : "")
                }
                value={props.formData.email.value}
                name="email"
                onKeyDown={props.changeHandler}
                onChange={props.changeHandler}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
            <div className="form-group  card-label ">
              <label> {props.formData.password.label} </label>
              <input
                type={props.formData.password.showPass ? "text" : "password"}
                className={
                  "form-control " +
                  (props.formData.password.isInvalid ? "is-invalid" : "")
                }
                value={props.formData.password.value}
                name="password"
                onKeyDown={props.changeHandler}
                onChange={props.changeHandler}
              />
              {!props.formData.password.isInvalid ? (
                <button
                  type="button"
                  className="hidePass"
                  name="togglePasspassword"
                  onClick={props.changeHandler}
                ></button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="form-group mt-30">
          <button
            className="btn btn-primary btn-block"
            type="button"
            onClick={props.login}
          >
            {getDefValues().loginLabel}
          </button>
        </div>
        {!props.needLogin ? (
          <div className="form-group">
            <button
              className="btn btn-outline-primary btn-block"
              type="button"
              onClick={props.guest}
            >
              {getDefValues().continueAsGuest}
            </button>
          </div>
        ) : (
          ""
        )}
      </form>

      <span className="or-line mb-2"></span>

      <div className="row">
        <div className="col-6 flex items-left">
          <div className="small-label">
            <Link to="/customer/account/forgotpassword">
              <a name="forgot" onClick={props.toggleF}>
                {" "}
                {getDefValues().forgotPasswordLabel}
              </a>
            </Link>
          </div>
        </div>
        <div className="col-6 text-right">
          <div className="small-label">
            <Link to="/customer/account/create">
              <a name="recovery" onClick={props.toggleR}>
                {" "}
                {getDefValues().createAccountLabel}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default loadlogin;
