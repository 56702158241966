/*****components***/
import React, { Component } from "react";

import { Redirect } from "react-router-dom";

/*custom components*/
import PageLoading from "../../components/PageLoading";

import Form from "../../components/Form";
import MenuInfo from "../../components/MenuInfo";
import houseIcon from "../../assets/images/house.webp";

import {
  toast,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  getLoginInfo,
  getDefValues,
  changeHandler,
  parseFormData,
  showMessage,
  showError,
  find,
  getRenderObj,
  mergeFormData,
  validateForm,
  callApi,
} from "../../common/functions";

import { withScriptjs } from "react-google-maps";
import Map from "../../components/Map";

const GoogleMapsAPI = process.env.REACT_APP_API_KEY_GMAP;
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressObj: [],
      isLoading: true,
      goLogin: false,
      modal1: false,
      showFormAdd: false,
      addressId: 0,
      userId: 0,
      address: "",
      city: "",
      MetaTags: {
        title: getDefValues().myAddressLabel,
        names: [
          { name: "description", content: getDefValues().myAddressLabel },
          { name: "keywords", content: process.env.REACT_APP_ORG_NAME },
          { name: "robots", content: "INDEX,FOLLOW" },
        ],
      },
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      var login = getLoginInfo();
      if (login.userId == null) {
        this.setState({ isLoading: false, goLogin: true });
      } else {
        this.props.hideCart();

        //let registerForm= await callApi ( "init","init", {} )
        let addressInfo = await callApi("admin", "getCustomerById", {
          customerid: login.userId,
        });

        var addressObj = [];
        for (let a in addressInfo.ADDRESS) {
          let addId = addressInfo.ADDRESS[a].ADDRESSID;
          let addName = addressInfo.ADDRESS[a].ADDRESSNAME
            ? addressInfo.ADDRESS[a].ADDRESSNAME
            : getDefValues().noNameLabel;
          let addInfo = JSON.parse(addressInfo.ADDRESS[a].ADDRESSINFO);
          let _addFomData = JSON.parse(addressInfo.ADDRESS[a].DATA);
          let _addFomConfig = JSON.parse(addressInfo.ADDRESS[a].CONFIG);

          _addFomData["componentType"] = "FORM";
          _addFomData["config"] = {};

          let rendegObj = await getRenderObj(
            _addFomData,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );
          delete rendegObj.curdata.config["formtitle"];
          let addFomData = rendegObj.curdata.data;
          await mergeFormData(addFomData, addInfo);
          let addFomConfig = _addFomConfig.config;

          addressObj.push({
            addId,
            addName,
            addInfo,
            addFomData,
            addFomConfig,
          });
        }
        // 	console.log(addressObj)
        this.setState({
          userId: login.userId,
          isLoading: false,
          addressObj: addressObj,
        });
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ redirect: false, isLoading: false });
    }
  };

  showFormAddress = async (event) => {
    //await mergeFormData ( this.state.addressFormData      , this.state  )
    this.setState({ modal1: true });
    var addId;
    if (event.target.name) {
      addId = event.target.name.split("_");
    } else {
      addId = event.target.parentNode.name.split("_");
    }
    let pos = find(this.state.addressObj, addId, "addId");

    //console.log("addFomData", event.target, this.state.addressObj[pos].addFomData )
    //console.log("addInfo", this.state.addressObj[pos].addInfo )

    this.setState({
      addressFormData: this.state.addressObj[pos].addFomData,
      addressFormConfig: this.state.addressObj[pos].addFomConfig,
      addressId: this.state.addressObj[pos].addId,
      modal4: true,
      showFormAdd: true,
      showMap: false,
    });
  };

  setMyLocation = async (event) => {
    this.setState({ showMap: false });
    await navigator.geolocation.getCurrentPosition(
      async function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        this.setState({
          latitude: latitude,
          longitude: longitude,
          showMap: true,
        });
      }.bind(this)
    );
  };
  confirmLocation = async (event) => {
    this.setState({ isLoading: true });
    try {
      let _formData = this.state.addressFormData;
      _formData = parseFormData(_formData);

      //var	directionsLabel =  _formData["alias"].toUpperCase()
      //var shippingCost = event.shippingCost+ parseFloat(this.state.baseShippingCost);

      _formData["latitude"] = event.lat;
      _formData["longitude"] = event.lng;
      _formData["shippingCost"] = event.shippingCost;
      _formData["zonecode"] = event.zonecode;

      var data = {
        addressid: this.state.addressId,
        /*shippingtypeid : this.state.shippingOptionId,*/
        addressinfo: JSON.stringify(_formData),
        customerid: this.state.userId,
      };
      if (this.state.userId > 0) {
        let resp = await callApi("frontend", "updateCustomerAddress", data);

        if (resp.success) {
          showMessage(toast, getDefValues().succUpdatingAddress.toUpperCase());
          this.setState({
            isLoading: false,
            modal4: false,
            showMap: false,
            showFormAdd: true,
          });
          this.props.toggleListener();
        } else {
          showError(toast, getDefValues().errorUpdatingAddress.toUpperCase());
        }
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  changeHandlerA = async (event) => {
    var formData = changeHandler(event, this.state.addressFormData);
    this.setState({ addressFormData: formData });
  };

  closeMap = (event) => {
    this.setState({ ...this.state, modal4: false, showMap: false });
    this.props.toggleListener();
  };

  hideMap = (event) => {
    this.setState({ showMap: false, showFormAdd: true });
    this.props.toggleListener();
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  saveTempLocation = async (event) => {
    var resp = await validateForm(this.state.addressFormData, toast);

    if (resp.isValid) {
      //console.log(this.state.addressFormData)
      //console.log(this.state.addressFormData.address.value)
      this.props.toggleListener();
      this.setState({
        showMap: true,
        showFormAdd: false,
        address: this.state.addressFormData.address.value,
        city: this.state.addressFormData.city.value,
      });
    }
  };
  setLocation = async (event, add) => {
    console.log("setLocation", event);
    if (event.lat) {
      this.setState({
        clatitude: event.lat,
        clongitude: event.lng,
        caddress: add,
      });
    }
  };
  render() {
    const MapLoader = withScriptjs(Map);

    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.goLogin ? (
          <Redirect to={{ pathname: "/customer/account/login" }} />
        ) : (
          ""
        )}
        <PageLoading
          isLoading={this.state.isLoading}
          MetaTags={this.state.MetaTags}
        />

        <MDBModal isOpen={this.state.modal4} toggle={this.toggle(4)} fade>
          <MDBModalHeader>
            <span className="h6-responsive">
              {" "}
              {getDefValues().editAddressLabel}{" "}
            </span>
          </MDBModalHeader>
          <MDBModalBody className="popup-map">
            {this.state.showFormAdd ? (
              <Form
                formData={this.state.addressFormData}
                changeHandler={this.changeHandlerA}
                formConfig={this.state.addressFormConfig}
              />
            ) : (
              ""
            )}

            {this.state.showMap ? (
              <MapLoader
                coords={{ lat: this.state.latitude, lng: this.state.longitude }}
                height="400px"
                setMyLocation={this.setMyLocation}
                setLocation={this.setLocation}
                address={this.state.address}
                city={this.state.city}
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  GoogleMapsAPI +
                  "&libraries=places"
                }
                backLocation={this.hideMap}
                cancelLocation={this.closeMap}
                confirmLocation={this.confirmLocation}
                loadingElement={<div style={{ height: `400px` }}></div>}
              />
            ) : (
              ""
            )}
          </MDBModalBody>
          <MDBModalFooter className="flex items-center">
            {this.state.showFormAdd ? (
              <>
                <button
                  className="btn btn-md btn-primary shipping-form-btn mr-1"
                  type="button"
                  onClick={this.saveTempLocation}
                >
                  {getDefValues().selectLocationLabel}
                </button>
                <button
                  className="btn btn-md btn-outline-secondary shipping-form-btn"
                  type="button"
                  onClick={this.closeMap}
                >
                  {getDefValues().cancelLabel}
                </button>
              </>
            ) : (
              ""
            )}
          </MDBModalFooter>
        </MDBModal>

        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content custom-container container-fluid ">
            <div className="card card-user-info">
              <div className="row">
                <div className="mobile-col menu-info col-12  col-sm-12 col-md-4 col-lg-3">
                  <MenuInfo activePanel="panel4" />
                </div>
                <div className="mobile-col col-12  col-sm-12 col-md-1 col-lg-1"></div>
                <div className="mobile-col menu-content col-12 col-sm-12 col-md-8 col-lg-8">
                  <div id="panel4">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 mt-3 mb-3">
                      <p>
                        <span className="h1-responsive">
                          {getDefValues().myAddressLabel.toUpperCase()}
                        </span>
                        <span
                          className="or-line mb-3"
                          style={{ "margin-right": "0px !important" }}
                        ></span>
                      </p>
                    </div>
                  </div>
                  {!this.state.isLoading ? (
                    <>
                      {this.state.addressObj.map((item, i) => (
                        <div className="addressCard col-12 col-sm-12 col-md-12 col-lg-9 mt-3 mb-3">
                          <div className="row mt-2 mb-2">
                            <div className="col-10">
                              <img
                                className="ml-1"
                                width="20px"
                                src={houseIcon}
                                alt="shop-cart"
                              />
                              <span className="titleAddress ml-1">
                                {" "}
                                {item.addFomData.alias.value.toUpperCase()}{" "}
                              </span>
                              <span className="or-line mt-3"></span>
                            </div>

                            <div className="col-2 flex items-right">
                              <button
                                className="btn btn-edit-orderdata"
                                name={item.addId}
                                type="button"
                                onClick={this.showFormAddress}
                              >
                                <i className="fas fa-edit" />
                              </button>
                              <span className="or-line mt-3"></span>
                            </div>
                          </div>

                          <>
                            {Object.keys(item.addFomData).map((subitem, j) =>
                              (item.addFomData[subitem].type === "SELECT" ||
                                item.addFomData[subitem].type === "TEXTAREA" ||
                                item.addFomData[subitem].type === "INPUT" ||
                                item.addFomData[subitem].name ===
                                  "shippincCost") &&
                              item.addFomData[subitem].name !== "alias" ? (
                                <div className="row mt-2">
                                  <div className="col-12">
                                    <b>
                                      {" "}
                                      {item.addFomData[subitem].label +
                                        ": "}{" "}
                                    </b>{" "}
                                    <span>
                                      {" "}
                                      {item.addFomData[subitem].value}{" "}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
