/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";
import { toast } from "mdbreact";
import { Redirect } from "react-router-dom";
/*custom components*/
import PageLoading from "../../components/PageLoading";

import Modal from "../../components/Modal";
import Form from "../../components/Form";
import Card from "../../components/Card";
import { Helmet } from "react-helmet";
import List from "../../components/List";
import BlogContainer from "../../components/BlogContainer";
import Slider from "../../components/Slider";
import SliderImage from "../../components/SliderImage";

import Text from "../../components/Text";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Video from "../../components/Video";
import Div from "../../components/Div";
import CategoryBlog from "../../components/CategoryBlog";

/*functions*/
import {
  manageCart,
  manageButton,
  managesort,
  getShopCart,
  managepagination,
  getCurrentCatalog,
  findValueById,
  getDefValues,
  toFixed,
  setGTMEvent,
  find,
  getLoginInfo,
  getLanguage,
  getSyncStoresInfo,
  findObjName,
  changeHandlerManager,
  getHomeLayout,
  toggleModalManager,
  loadPageV2,
  callApi,
  showError,
  showMessage,
} from "../../common/functions";

//var windowHeight = window.innerHeight
var windowWidth = window.innerWidth;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

class homePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      mainClass: "page-wrapper",
      redirectProductDetails: false,
      selectedProductID: 0,
      selectedPath: "",
      selectedCategory: "",
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      prodDefConfig: {},
    };
  }

  /*loading data*/
  componentDidMount = async () => {
    console.log("home");
    try {
      this.props.hideCart();
      let _cart = this.props.shopCart;

      getShopCart("HOMEPAGE");

      var products = [];

      var eventId = this.props.eventId;
      var action = this.props.action;

      var storeid = getCurrentCatalog();

      if (action !== "") {
        try {
          let resp2 = await callApi("frontend", "getCustomerEventById", {
            languageid: getLanguage(),
            eventid: eventId,
          });
          const prodDefConfig = this.state.prodDefConfig;
          prodDefConfig["action"] = action;
          prodDefConfig["id"] = eventId;
          var fixedStoreid = resp2.EVENT.STOREID;
          var storeInfo = getSyncStoresInfo();
          this.setState({
            action: action,
          });
          let fixedCatalogId = findValueById(
            storeInfo.stores,
            fixedStoreid,
            "storeId",
            "catalogId"
          );
          if (storeid != fixedCatalogId) {
            //console.log("stre update"  )
            storeid = fixedCatalogId;
            //let	resp = await setStore(fixedStoreid)
            //this.props.updateStore(resp.storesInfo)
            showMessage(toast, getDefValues().storeChangedByEventMessage);
          }
        } catch (err) {
          console.log("INVALID EVENT OR TOKEN EXPIRED", err);
        }
      }

      if (_cart.products) {
        _cart.products.map(function (key) {
          var variant = "";

          key.optionsselected.map(function (v) {
            variant = variant + "," + v.optionvalue;
          });
          variant = variant.substr(1, variant.length);
          products.push({
            id: key.productid,
            sku: key.productsku,
            name: key.productname,
            price: parseFloat(toFixed(key.price)),
            brand: "CasaIdeas",
            category: key.category,
            variant: variant,
            quantity: key.quantity,
          });
        });
      }
      let storesInfo = getSyncStoresInfo(); //await getStoresInfo()

      var pos = 0;
      if (storesInfo.selectedStoreId) {
        if (storesInfo.selectedStoreId != null) {
          pos = find(
            storesInfo.stores,
            storesInfo.selectedStoreId ? storesInfo.selectedStoreId : 0,
            "storeId"
          );
        }
      }

      var storeName =
        storesInfo.stores.length > 0 ? storesInfo.stores[pos].storeName : "";

      var objData = {
        current_view: "homepage",
        current_list: "homepage",
        current_currency: "BOB",
        userId: getLoginInfo().userId ? getLoginInfo().userId : "0",
        cart_products: products,
        string_searched: "",
        store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
        googleDynamicRemarketing: { ecomm_pagetype: "home" },
        event: "gtm.load",
        criteoParams: { PageType: "HomePage", email: "" },
        gdpr_marketing_status: "accepted",
        gdpr_statistics_status: "accepted",
      };
      setGTMEvent("loadPage", objData);

      var layoutFile = getHomeLayout();
      let resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: layoutFile,
        foldername: foldername + "private/",
      });
      let _pageData = await loadPageV2(
        resp.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        windowWidth
      );
      this.setState({ template: resp.template });

      this.renderData(_pageData);

      //this.setState({pageData:_pageData, template: resp.template});
    } catch (err) {
      console.log(">>>>>>>>>>err", err);
    } finally {
      this.setState({ isLoading: false });
    }
    window.addEventListener("resize", this.handleResize);
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = async () => {
    var dif = (window.innerWidth * 100) / this.state.windowWidth;

    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

    if (dif <= 40 || dif >= 250) {
      this.setState({ isLoading: true });
      let _pageData = await loadPageV2(
        this.state.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        window.innerWidth
      );
      this.renderData(_pageData);
      this.setState({ isLoading: false });
    }
  };

  renderData = (_pageData) => {
    try {
      for (let r in _pageData) {
        //		console.log(_pageData[r])
        this.setState({
          ["RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  toggleModal = (event) => {
    let object = findObjName(event);
    //	console.log(object, this.state["RENDEROBJ"+object])
    let newTemplate = toggleModalManager(this.state["RENDEROBJ" + object]);
    this.setState({ ["RENDEROBJ" + object]: newTemplate });
  };

  changeHandler = async (event) => {
    this.setState({ isLoading: false });
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = changeHandlerManager(
      this.state["RENDEROBJ" + fieldName[0]],
      fieldName[1],
      event.target.value
    );
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
  };

  changeForm = async (event) => {
    this.setState({ isLoading: true });
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = this.state["RENDEROBJ" + fieldName[0]];
    newTemplate["activeForm"] = event.target.value;
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
    setTimeout(
      function () {
        this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
  };

  manageCart = async (event) => {
    //let object = findObjName(event)
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }

    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state["RENDEROBJ" + container].data,
        this.manageCart,
        toast
      );
      console.log("resp", resp);
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          } else {
            if (resp.isEvent) {
              this.props.addQtyEvent(resp.eventQty);
            }
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  manageButton = async (event) => {
    try {
      this.setState({ isLoading2: true });
      let object = findObjName(event);
      var fieldName = object.split("__");
      var resp = await manageButton(fieldName[1], this.state, toast);
      if (resp.success) {
        showMessage(toast, resp.message);
      } else {
        showError(toast, resp.message);
      }
      this.setState({ isLoading2: false });
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
      this.setState({ isLoading2: false });
    }
  };

  managepagination = (event) => {
    this.setState({ isLoading: true });

    //let object = findObjName(event)
    let _container;
    if (event.target.name) {
      _container = event.target.id.split("__");
    } else {
      _container = event.target.parentNode.id.split("__");
    }
    let container = _container[0];
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state["RENDEROBJ" + container].pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-2_5",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6",
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };

  managesort = (event) => {
    this.setState({ isLoading: true });
    //console.log(event.target)
    //let object = findObjName(event)
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }
    setTimeout(
      function () {
        var newcardData = managesort(
          this.state["RENDEROBJ" + container],
          event
        );
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        
        <PageLoading isLoading={this.state.isLoading} />
        <PageLoading isLoading={this.state.isLoading2} />

        <div className="page-wrapper" onClick={this.props.hideCart}>
          {this.state.redirectProductDetails ? (
            <Redirect
              to={{
                pathname:
                  (this.state.selectedCategory
                    ? "/" + this.state.selectedCategory + "/"
                    : "/productDetails/") +
                  (this.state.selectedPath ? this.state.selectedPath : "show"),
              }}
            />
          ) : (
            ""
          )}
          <div className="content container-page container-fluid custom-container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="card ">
                  {Object.keys(this.state).map((obj, index) => (
                    <div key={"div1" + index}>
                      {obj.startsWith("RENDEROBJ") && !this.state.isLoading ? (
                        <>
                          {this.state[obj].type === "TEXT" ? (
                            <Text
                              name={this.state[obj].ObjectName}
                              textData={this.state[obj].data}
                              textConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "IMAGE" ? (
                            <Image
                              name={this.state[obj].ObjectName}
                              imageData={this.state[obj].data}
                              imageConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "VIDEO" ? (
                            <Video
                              name={this.state[obj].ObjectName}
                              videoData={this.state[obj].data}
                              videoConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}

                          {this.state[obj].type === "DIV" ? (
                            <Div
                              name={this.state[obj].ObjectName}
                              divData={this.state[obj].data}
                              divConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}

                          {this.state[obj].type === "LIST" ? (
                            <List
                              name={this.state[obj].ObjectName}
                              listValues={this.state[obj].data}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "BLOGCONTAINER" ? (
                            <BlogContainer
                              name={this.state[obj].ObjectName}
                              blogContainerData={this.state[obj].data}
                              blogContainerContent={this.state[obj].content}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CATEGORYBLOG" ? (
                            <CategoryBlog
                              name={this.state[obj].ObjectName}
                              categoryBlogData={this.state[obj].data}
                              categoryBlogContent={this.state[obj].content}
                              categoryBlogConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "FORM" ? (
                            <Form
                              name={this.state[obj].ObjectName}
                              container={this.state[obj].ObjectName}
                              formData={this.state[obj].data}
                              activeForm={this.state[obj].activeForm}
                              changeForm={this.changeForm}
                              changeHandler={this.changeHandler}
                              manageButton={this.manageButton}
                              formConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDERPRODUCTS" ? (
                            <Slider
                              name={this.state[obj].ObjectName}
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CARDPRODUCTS" ? (
                            <Card
                              name={this.state[obj].ObjectName}
                              cardData={this.state[obj].data}
                              cardConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CARDCONTAINER" ? (
                            <Card
                              name={this.state[obj].ObjectName}
                              cardData={this.state[obj].data}
                              cardConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDERIMAGE" ? (
                            <SliderImage
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "MODAL" ? (
                            <Modal
                              name={this.state[obj].ObjectName}
                              modalData={this.state[obj].data}
                              modalConfig={this.state[obj].config}
                              toggle={this.toggleModal}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDER" ||
                          this.state[obj].type === "SLIDERCONTAINER" ? (
                            <Slider
                              name={this.state[obj].ObjectName}
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "BUTTON" ? (
                            <Button
                              name={this.state[obj].ObjectName}
                              buttonData={this.state[obj].data}
                              buttonConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default homePage;
