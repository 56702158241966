/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { getImagePath } from "../../common/functions";

const windowWidthDef = window.innerWidth;
const loadButton = (props) => {
  if (props.buttonData) {
    var show = false;
    //var itemsAlign="items-center"

    if (props.buttonConfig.version) {
      if (windowWidthDef <= 799) {
        //mobile
        if (
          props.buttonConfig.version === "mobile" ||
          props.buttonConfig.version === "both"
        ) {
          show = true;
        }
        //itemsAlign= "items-left"
      } else {
        show = true;
        if (props.buttonConfig.version === "mobile") {
          show = false;
        }
      }
    } else {
      show = true;
    }
    var classname = "flex items-";
    if (props.buttonConfig.alignbutton) {
      classname = classname + props.buttonConfig.alignbutton;
    }
    if (show) {
      return (
        <div
          className={classname}
          style={{
            marginTop: props.buttonConfig.margintop
              ? props.buttonConfig.margintop + "px"
              : "0",
            marginLeft: props.buttonConfig.marginleft
              ? props.buttonConfig.marginleft + "px"
              : "0",
            marginBottom: props.buttonConfig.marginbottom
              ? props.buttonConfig.marginbottom + "px"
              : "0",
            marginRight: props.buttonConfig.marginright
              ? props.buttonConfig.marginright + "px"
              : "0",
          }}
        >
          <div
            style={{
              height: props.buttonConfig.height
                ? props.buttonConfig.height == "0"
                  ? "auto"
                  : props.buttonConfig.height + "px"
                : "auto",
              width: props.buttonConfig.width
                ? props.buttonConfig.width == "0"
                  ? "100%"
                  : props.buttonConfig.width + "px"
                : "100%",
            }}
          >
            {props.buttonData.link ? (
              <a
                href={props.buttonData.link}
                target={
                  props.buttonConfig.targetlink
                    ? props.buttonConfig.targetlink
                    : "_self"
                }
              >
                <button
                  className={
                    props.buttonConfig.aligntext
                      ? props.buttonConfig.aligntext
                      : "text-center"
                  }
                  style={{
                    fontSize: props.buttonConfig.fontsize + "px",
                    fontWeight: props.buttonConfig.fontweight,
                    color:
                      props.buttonConfig.fontcolor === "gray"
                        ? "#696158"
                        : props.buttonConfig.fontcolor,
                    fontFamily: '"' + props.buttonConfig.fontfamily + '"',
                    textDecoration: props.buttonConfig.textdecoration,
                    background: props.buttonConfig.background,
                    borderRadius: props.buttonConfig.borderradius
                      ? props.buttonConfig.borderradius + "px"
                      : 0,
                    height: props.buttonConfig.height
                      ? props.buttonConfig.height == "0"
                        ? "auto"
                        : props.buttonConfig.height + "px"
                      : "auto",
                    width: props.buttonConfig.width
                      ? props.buttonConfig.width == "0"
                        ? "100%"
                        : props.buttonConfig.width + "px"
                      : "100%",
                    border:
                      props.buttonConfig.bordersize == 0
                        ? "0"
                        : "solid " +
                          props.buttonConfig.bordersize +
                          "px " +
                          props.buttonConfig.bordercolor,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: props.buttonConfig.aligntext
                        ? props.buttonConfig.aligntext.replace("text-", "")
                        : "center",
                      alignItems: "center",
                    }}
                  >
                    {props.buttonData.image !== "" ? (
                      <img
                        className="mr-2"
                        src={getImagePath(props.buttonData.image)}
                        width={props.buttonConfig.imagewidth}
                      />
                    ) : (
                      ""
                    )}
                    {props.buttonData.text}
                  </div>
                </button>
              </a>
            ) : (
              <button
                className={
                  props.buttonConfig.aligntext
                    ? props.buttonConfig.aligntext
                    : "text-center"
                }
                style={{
                  fontSize: props.buttonConfig.fontsize + "px",
                  fontWeight: props.buttonConfig.fontweight,
                  color:
                    props.buttonConfig.fontcolor === "gray"
                      ? "#696158"
                      : props.buttonConfig.fontcolor,
                  fontFamily: '"' + props.buttonConfig.fontfamily + '"',
                  textDecoration: props.buttonConfig.textdecoration,
                  background: props.buttonConfig.background,
                  borderRadius: props.buttonConfig.borderradius
                    ? props.buttonConfig.borderradius + "px"
                    : 0,
                  height: props.buttonConfig.height
                    ? props.buttonConfig.height == "0"
                      ? "auto"
                      : props.buttonConfig.height + "px"
                    : "auto",
                  width: props.buttonConfig.width
                    ? props.buttonConfig.width == "0"
                      ? "100%"
                      : props.buttonConfig.width + "px"
                    : "100%",
                  border:
                    props.buttonConfig.bordersize == 0
                      ? "0"
                      : "solid " +
                        props.buttonConfig.bordersize +
                        "px " +
                        props.buttonConfig.bordercolor,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: props.buttonConfig.aligntext
                      ? props.buttonConfig.aligntext.replace("text-", "")
                      : "center",
                    alignItems: "center",
                  }}
                >
                  {props.buttonData.image !== "" ? (
                    <img
                      className="mr-2"
                      src={getImagePath(props.buttonData.image)}
                      width={props.buttonConfig.imagewidth}
                    />
                  ) : (
                    ""
                  )}
                  {props.buttonData.text}
                </div>
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return "";
    }
  } else {
    return "NO TEXT DATA";
  }
};
export default loadButton;
