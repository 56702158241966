import React from "react"; 
import {getDefValues} from '../../common/functions';
import  { Link } from 'react-router-dom'
const loadMenuInfo = props => {
	 
	  return ( 
	  	<>
   			<div className="active">
					{getDefValues().myAccountLabel.toUpperCase()}
				</div>
				
				<ul>
					<li>
						<Link to="/customer/account">
							<button name="panel1" className={props.activePanel==="panel1"?"option-active":"" }  > 
								{getDefValues().myAccountLabel}
							</button>
						</Link>
					</li>
					<li>
						<Link to="/sales/order/history">
							<button name="panel2" className={props.activePanel==="panel2"?"option-active":"" }  > 
								{getDefValues().myOrdersLabel}
							</button>
						</Link>
					</li>
					{/*
					<li>
						<Link to="/customer/event/manage">
							<button name="panel3" className={props.activePanel=="panel6"?"option-active":"" }  > 
								{getDefValues().eventListTitleLabel}
							</button>
						</Link>
					</li>
					*/}
					<li>
						<Link to="/wishlist/index/index">
							<button name="panel3" className={props.activePanel==="panel3"?"option-active":"" }  > 
								{getDefValues().myWishListLabel}
							</button>
						</Link>
					</li>
					<span className="or-line"></span>
					<li>
						<Link to="/customer/address">
							<button name="panel4" className={props.activePanel==="panel4"?"option-active":"" }  > 
								{getDefValues().myAddressLabel}
							</button>
						</Link>
					</li>
					<span className="or-line"></span>
					<li>
						<button name="panel5"  className={props.activePanel==="panel5"?"option-active":"" }  > 
							{getDefValues().mySuscriptionsLabel}
						</button>
					</li>
				</ul>
			</>
	  );
	 
};
export default loadMenuInfo;
				 
