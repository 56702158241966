/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from 'react';
import  {  Redirect } from 'react-router-dom'
import {toast} from 'mdbreact';

/*custom components*/
import PageLoading from '../../components/PageLoading';
//import Category from '../../components/Category';
//import Product from "../../components/Product";
import Card from "../../components/Card";
import MenuInfo from "../../components/MenuInfo";
import InfiniteScroll from 'react-infinite-scroll-component'

/*treeview component*/
//import TreeMenu from 'react-simple-tree-menu';
import '../../assets/css/main-treeview.css';
 

/*functions*/
import { manageCart, getStoresInfo, showError, getCookie, callApi,getLoginInfo,buildProductsCard, getDefValues, managesort, managepagination, getLanguage} from '../../common/functions';
//var windowHeight = window.innerHeight
//const foldername = process.env.REACT_APP_FOLDER_LAYOUT


 

class page extends Component{
	constructor(props) {
		super(props);
		this.state = {
						categories:[],
						categoryid:"",
						items: Array.from({ length: 10 }),
						message:"",
						shareUrl:"",
						showMoreUrl: false,
						newcategoryid:"",
						categoryLabel:"",
						categoryImage:"",
						showCategoryMenu: false,
						isValidToken:false,
						isLoading:true,
						windowWidth: window.innerWidth,
						categoryHomeLabel:"",
						isCopied: false,
						redirect: false,
						redirectProductDetails: false,
						settingsSlider:{
					      	dots           : false,
					      	adaptiveHeight : true,
					      	arrows         : true,
					      	autoplay       : false,
					      	cssEase        : "linear",
					     		slidesToShow   :  3,
					        slidesToScroll :  1,
					      	speed          : 500,
					      	divWidth       : "card col-md-12 col-lg-12 col-xl-12",
					      	specialArrows  : "2"
			    	},
			    	cardConfig:{},
			    	cardData:[],
			    	suggcardData:[],
			    	selectedProductID:0,
			    	selectedPath: "",
	  				selectedCategory: "",
			    	categoryObj:{
			    		orgName: process.env.REACT_APP_ORG_NAME,
			    		categoryRouteObj :[]
			    	},
			    	prodDefConfig: {pagewidth          : "col-md-12 col-lg-12 col-xl-12",
														colsize            : "col-6",
														colsizelg          : "col-lg-4",
														colsizemd          : "col-md-6",
														colsizesm          : "col-sm-6", 
												    limit              : 10,
												    /*overtop            : "YES"*/ },
			    	sortmethod:"AZ" ,
			    	goLogin:false,
			    	MetaTags : {title: getDefValues().myWishListLabel,
									names:[
										{  name: "description" , content: getDefValues().myWishListLabel},
										{  name: "keywords" , content: process.env.REACT_APP_ORG_NAME },
										{  name: "robots"   , content: "INDEX,FOLLOW"}
									]},
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	
	 	var token = this.props.match.params.token
		var wishlist=[] 
		var sugegstedSkus=[]
	 	if (token){
	 		this.setState({  isValidToken:true })
	 		//var respT = await callApi ( "frontend","decodeToken", {token:token} )
			var respT = {success:true, message: "new", tokenData :{ customerid: token, firstname: this.props.location.search.replace("?user=", "") } }

 
	 	  if (respT.success){
	 	  	this.props.hideCart() 
				let  data ={ 
	  			customerid : respT.tokenData.customerid
	  		}
				let resp = await callApi ( "frontend","getWishListDB", data )
				
				
				resp.rows.map(function (key, item) {
					if (key.PREFERREDSKU){
						wishlist.push ( parseInt(key.PRODUCTID+""+key.PREFERREDSKU) )
						sugegstedSkus.push( {productid:key.PRODUCTID, preferredsku:key.PREFERREDSKU})
					}else{
						wishlist.push ( parseInt(key.PRODUCTID) )
					}
				});
				let _message = getDefValues().myWishListSharedMessage.split("<firstname>") 
				let message =<>{_message[0]} <span className="text-bold"> {respT.tokenData.firstname}</span> {_message[1]} </>
				var prodDefConfig= {	pagewidth          : "col-md-12 col-lg-12 col-xl-12",
										colsize            : "col-6",
										colsizelg          : "col-lg-2_5",
										colsizemd          : "col-md-4",
										colsizesm          : "col-sm-6",
										limit              : 10,
												   }
				
				this.setState({ sugegstedSkus:sugegstedSkus, isValidToken:respT.success, prodDefConfig })
				await this.getProducts(respT.tokenData.customerid)
				if (this.state.cardData.length>0){
					this.setState({ message:message })	
				}
	 	  }else{
	 	  	this.setState({ isLoading:false, goLogin:true, isValidToken:false })
	 	  }
	 		
	 	}else{
	 		var  login = getLoginInfo()
			if (login.userId ==null) {
				this.setState({ isLoading:false, goLogin:true })
			}else{	
				
				this.props.hideCart()
				
				let  data ={ 
	  				customerid : getLoginInfo().userId
	  			}
				let resp = await callApi ( "frontend","getWishListDB", data )
								 
				resp.rows.map(function (key, item) {
					if (key.PREFERREDSKU){
						wishlist.push ( parseInt(key.PRODUCTID+""+key.PREFERREDSKU) )
						sugegstedSkus.push( {productid:key.PRODUCTID, preferredsku:key.PREFERREDSKU})
					}else{
						wishlist.push ( parseInt(key.PRODUCTID) )
					}
				});
				
				localStorage.setItem(process.env.REACT_APP_ORG_NAME+'_wishSession',  JSON.stringify(wishlist))
				var TokenButton  = <div onClick={this.getTokenWishlist} className="ml-1 mr-1 btn btn-primary button-fit"> {getDefValues().clickLabel}  </div>
				let _message = getDefValues().myWishListMessage.split("<CLICK>")
				let message =<>{_message[0]} {TokenButton} {_message[1]} </>
				
				this.setState({ sugegstedSkus:sugegstedSkus })
				await this.getProducts()
				if (this.state.cardData.length>0){
					this.setState({ message:message })	
				}
				
	 		}
	 	}
	 	this.handleResize()
	 	window.addEventListener("resize", this.handleResize);	 
	}	
	componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  
  fetchMoreData = () => {
		 
		let cardConfig = this.state.cardConfig
		let lengthRows = this.state.cardData.length
		if (lengthRows===0){
			lengthRows = this.state.suggcardData.length
		}
		console.log(lengthRows)
    if (this.state.items.length >= lengthRows) {
    	cardConfig["limit"] = lengthRows
      this.setState({ hasMore: false, cardConfig:cardConfig});
      return;
    }else{
    	cardConfig["limit"] = this.state.items.length
    	this.setState({
        cardConfig: cardConfig
      });
			
    }
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 10 })) 
      });
    }, 500);
  }
  
  getProducts = async(_customerid) => {  	
  	try{
  		this.setState({...this.state,isLoading:true });		
  		var customerid = _customerid
  		if (!customerid){
  			customerid = getLoginInfo().userId
  		}
  	  let respS = await getStoresInfo()
  	  let storeid  = respS.selectedStoreId
  		var  data ={
  			languageid : getLanguage(),
  			customerid : customerid,
  			storeid    : storeid,
  			wishlist   : true
  		}
  		var resp = await callApi ( "customer","getFilterProductsDB", data )
	  	if (resp.rows.length < 1){
  			if (!this.state.isValidToken){
		  		var catId =28
			 		var catIds = getCookie(process.env.REACT_APP_ORG_NAME+'_MasterCategoryIds')
			 		if (catIds){
			 			catIds = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_MasterCategoryIds'))
			 			var pos = Math.floor(Math.random() * catIds.length); 
			 			catId = catIds[pos]
			 		}
			 		var store = await getStoresInfo ()
			 		let  data ={
						languageid : getLanguage(),
						categoryid: catId ,
						storeid: store.selectedCatalogId
					}
		  		resp = await callApi ( "customer","getFilterProducts", data )
		  		var rows =[]
		  		var _rows =Array.from({length: resp.rows.length}, () => Math.floor(Math.random() * resp.rows.length));
		  		for (let r=0; r<6; r++){
		  			rows.push( resp.rows[_rows[r] ] )
		  		} 
		  		let respCat =await buildProductsCard (rows, this.managepagination, this.manageCart , this.state.prodDefConfig, "categories", null, -1, this.state.sugegstedSkus )
	  			
	  			let cardConfig= respCat.cardConfig
	  			if (this.props.windowWidth<767){
	  				delete cardConfig["managepagination"]
	  				delete cardConfig["pagesettings"]
	  				cardConfig["mobile"] = true
	  			} 
	  			this.setState({ isLoading: false, productNotFounded:true, cardConfig:respCat.cardConfig, suggcardData: respCat.cardProducts, message: getDefValues().notFoundsubTitleLabel4  })
  			}else{
  				this.setState({...this.state, isLoading:false, message:""});	
  			}
  		}else{
  				let respCat =await buildProductsCard (resp.rows, this.managepagination, this.manageCart , this.state.prodDefConfig, "categories", null, -1, this.state.sugegstedSkus )
  			 	let cardConfig= respCat.cardConfig
	  			if (this.props.windowWidth<767){
	  				delete cardConfig["managepagination"]
	  				delete cardConfig["pagesettings"]
	  				//cardConfig["mobile"] = true
	  			}
	  			this.setState({...this.state, isLoading:false, cardConfig:respCat.cardConfig, cardData: respCat.cardProducts, suggcardData:[] });	
  		}
  		
  		
	  	
		}catch(Excep){
			console.log(">>>>>>>",Excep )
		}finally{
			this.setState({redirect: false, isLoading:false })
		}
  }
   
  getTokenWishlist = async() => {
  	
  	try{
  		this.setState({...this.state,isLoading2:true });		
  		//var resp = await callApi ( "frontend","getTokenWishlist")

		var  login = getLoginInfo()
		  
		var resp = {success:true, message: "new", token : 1 }

  		if (resp.success){
  				var url = process.env.REACT_APP_URL_FRONT_CONFIG+"/wishlist/index/index/"+login.token.userId+"/?user="+login.token.firstname
  				//    url = "http://cidev-front.casaideas.com.bo"+"/wishlist/index/index/"+resp.token
				/*
		  		try {
		  			const bitly = new BitlyClient(process.env.REACT_APP_BITLYKEY, {})
		  			resp = await bitly.shorten(url)	
		  			url = resp.url
		  		}catch(Exc){
		  			console.log("Exc bitly >>>",Exc )
		  		}
				*/ 		
		  		var message = <span className="text-primary"> {getDefValues().myWishListTokenMessage} </span>
		  		this.setState({...this.state,shareUrl:url, message });		
	 		}else{
  			showError(toast, getDefValues().errorGenerationWishListsMessage)
  		}
  		
		}catch(Excep){
			console.log(">>>>>>>",Excep )
		}finally{
			this.setState({ isLoading2:false })
		}
  } 
  toggleMoreUrl =  () => {
		this.setState({
	    showMoreUrl: !this.state.showMoreUrl
	  });
	}
	
	copyTextToClipboard = async () => {
		await navigator.clipboard.writeText(this.state.shareUrl);
		this.setState({
	    isCopied: true
	  });
	}
  
  manageCart=async (event) => {
  	try{ 
 			this.setState({ isLoading2:true })
 			var resp
 			if (this.state.suggcardData.length>0){
 				resp = await manageCart( event, this.state.suggcardData , this.manageCart, toast )
 			}else{
 				resp = await manageCart( event, this.state.cardData , this.manageCart, toast )	
 			}
	  		if ( resp.success){
	  			if (!resp.redirect){ 
  					this.setState({...this.state,isLoading:false, 
  						 //cardData: resp._cardData
  						 });	
  					if (resp.dataCart){
  						this.props.updateCart(resp.dataCart)
  					} 
  					if (event.target.name && !this.state.isValidToken){
  						if (event.target.name.indexOf("favorite")>=0) {
		  					
		  					var TokenButton  = <div onClick={this.getTokenWishlist} className="ml-1 mr-1 btn btn-primary button-fit"> {getDefValues().clickLabel}  </div>
								var _message = getDefValues().myWishListMessage.split("<CLICK>")
								var message =<>{_message[0]} {TokenButton} {_message[1]} </>
								await this.getProducts()
								if (this.state.cardData.length>0){
									this.setState({ message:message })	
								}
		  					
		  				}
  					}
	  			}else{
	  				this.setState({...this.state,isLoading:false, 
	  												selectedProductID: resp.selectedProductID, 
	  												selectedPath: resp.selectedPath, 
	  												selectedCategory: resp.selectedCategory, 
	  												redirectProductDetails:true});	
	  				window.location.reload();
	  			}
	  		}else{
	  			this.setState({...this.state,isLoading:false });	
					console.log(">>>>>>>",resp.message)
	  		}
				 
	  	this.setState({isLoading2:false }) 
	  	
	  }catch(Excep){
			this.setState({isLoading2:false })
			console.log(">>>>>>>",Excep )
		} 
  }
   
  managepagination = (event) => {
  	this.setState({isLoading:true })  	
  	setTimeout(function() {				
				var newpagesettings = managepagination (this.state.cardConfig.pagesettings,event)
		  	var newcardConfig ={
		  										pagewidth          : "col-md-12 col-lg-12 col-xl-12",
		  										colsize            : "col-6",
		  										colsizelg          : "col-lg-2_5",
		  										colsizemd          : "col-md-4",
		  										colsizesm          : "col-sm-6",		  										
		  										pagination         : true,
		  										managepagination   : this.managepagination,
		  										pagesettings       : newpagesettings,
		  										/*overtop            :"YES"*/
		  	}
		  	//console.log(newcardConfig)
		  	this.setState({cardConfig: newcardConfig, isLoading:false});
			}.bind(this),1000);
  }
  managesort = (event) => {
  	this.setState({isLoading:true })  	
  	setTimeout(function() {				
				var newcardData = managesort (this.state.cardData,event)
		  	this.setState({cardData: newcardData, isLoading:false});
			}.bind(this),1000);
  }
 
  toggleCategoryMenu= () =>{  	
  	let state = this.state.showCategoryMenu===false?true:false
  	this.setState({showCategoryMenu : state  })
  }
 
  
  handleResize = () =>{
		this.setState({
			windowWidth: window.innerWidth
		})
		var slidesToShow =0
		if ( window.innerWidth > 1250){
			slidesToShow=6
		}else if ( window.innerWidth <= 1250 && window.innerWidth >950 ){
			slidesToShow=5
		}else if ( window.innerWidth <= 950 && window.innerWidth >768 ){
			slidesToShow=4
		}else{
			slidesToShow=3
		}
		var settingsSlider={
					      	dots           : false,
					      	adaptiveHeight : true,
					      	arrows         : true,
					      	autoplay       : false,
					      	cssEase        : "linear",
					     		slidesToShow   :  slidesToShow,
					        slidesToScroll :  1,
					      	speed          : 500,
					      	divWidth       : "card col-md-12 col-lg-12 col-xl-12",
					      	specialArrows  : "2",
			    	}
		
		this.setState({
			windowWidth: window.innerWidth,
			settingsSlider:settingsSlider
		})
		
		
	};
	 
	render(){

			return(this.props.showSearcher?"":
			<> 
			  {this.state.redirectProductDetails?	
		      	<Redirect
						  to={{  pathname: ( this.state.selectedCategory? "/"+this.state.selectedCategory+"/"  :"/productDetails/") + ( this.state.selectedPath?this.state.selectedPath:"show"  ) }}
						/>:""
				}
				{this.state.goLogin?
					<Redirect
							  to={{  pathname:  "/customer/account/login" }}
					/>
					:""
				}  
				<PageLoading isLoading={this.state.isLoading} MetaTags={this.state.MetaTags}/>
				<PageLoading isLoading={this.state.isLoading2}/>
				<div className="page-wrapper" onClick={this.props.hideCart}>
					<div className="content custom-container container-fluid ">
						<div className="card card-user-info">
						  
						  <div className="row">
						  
							  {!this.state.isValidToken?
									<div className="mobile-col menu-info col-12  col-sm-12 col-md-3 col-lg-3">
										<MenuInfo activePanel="panel3" />
									</div> 
								:""}
								<div id="panel3" className={!this.state.isValidToken?"lmobile-col menu-content col-12 col-sm-12 col-md-9 col-lg-9":"mobile-col col-12 col-sm-12 "} >
									<>
										<div className="row">
											<div className="mobile-col col-12 col-sm-12">
												<h3 style={{width: "95%" }} className="mt-3 mb-3">
													{!this.state.isLoading?getDefValues().myWishListLabel:""}
												</h3>
												{this.state.cardData.length>0?
													<h6 style={{width: "95%", textAlign: "justify" }} className="mt-3 mb-3">
														{!this.state.isLoading?this.state.message:""}
													</h6>
												:""}
												
											</div>
										 </div>
										{this.state.shareUrl!==""?	
											<div className="row mt-3 mb-3">
												<div className="col-12 flex text-center-web middle">
													 <div className="row flex middle share-url-container">
													 	<div className="col-8 text-left">
															<i className="fas fa-link mr-1" />
															<span>	{this.state.shareUrl.length> 50? <> {this.state.showMoreUrl? <> <a target="black" href={this.state.shareUrl}> {this.state.shareUrl}</a> <button className="no-button text-underline text-bold" onClick={this.toggleMoreUrl} > ver menos </button> </>: <>  <a target="black" href={this.state.shareUrl}>  {this.state.shareUrl.substr(0,50)+"..."} </a> <button className="no-button text-underline text-bold" onClick={this.toggleMoreUrl} > ver mas </button> </>} </> : <a target="black" href={this.state.shareUrl}> {this.state.shareUrl} </a> } </span>
													 	</div>
													 	<div className="col-4 text-right">
													 		<div onClick={this.copyTextToClipboard} className="btn btn-outline-primary button-fit"> {this.state.isCopied ? getDefValues().copiedLinkLabel: getDefValues().copyLinkLabel}  </div>
													 	</div>
													  	
													 </div>
												</div> 
									  	</div> 
										:""}
										{this.state.isLoading?"": this.state.cardData.length>0?
											<>
												{this.props.windowWidth<767?
													<InfiniteScroll
									          dataLength={this.state.items.length}
									          next={this.fetchMoreData}
									          hasMore={this.state.hasMore}
									          loader={<h4>{ getDefValues().loadingProductsLabel}</h4>}
									          endMessage={
									            <p style={{ textAlign: "center" }}>
									              <b>{getDefValues().endOfResultsLabel }</b>
									            </p>
									          }
									          >
									        	<Card 
															cardData={this.state.cardData} 
															cardConfig={this.state.cardConfig}
														/> 
							      			  </InfiniteScroll>
												:
													<Card 
														cardData={this.state.cardData} 
														cardConfig={this.state.cardConfig}
													/> 
												}
											</>
											:
											<>
												<h6 style={{width: "95%", textAlign: "justify" }} className="mt-3 mb-3">
													{!this.state.isLoading?this.state.message:""}
												</h6>
												
												<>
													{this.props.windowWidth<767?
														<InfiniteScroll
										          dataLength={this.state.items.length}
										          next={this.fetchMoreData}
										          hasMore={this.state.hasMore}
										          loader={<h4>{ getDefValues().loadingProductsLabel}</h4>}
										          endMessage={
										            <p style={{ textAlign: "center" }}>
										              <b>{getDefValues().endOfResultsLabel }</b>
										            </p>
										          }
										          >
										        	<Card 
																cardData={this.state.suggcardData} 
																cardConfig={this.state.cardConfig}
															/> 
								      			  </InfiniteScroll>
													:
														<Card 
															cardData={this.state.suggcardData} 
															cardConfig={this.state.cardConfig}
														/> 
													}
												</>
												
											</>
										}
									</>
								</div>
							</div>	
							
						</div> 
					</div>
				</div>
			</>				 
			)
		
	}
}
export default page; 