/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import logo from "../../assets/images/logo.png";
import Card from "../../components/Card";

import PageLoading from "../../components/PageLoading";
import { getCookie } from "../../common/functions";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "mdbreact";
import {
  getParamValues,
  getImagePath,
  toFixed,
  getDefValues,
  getCurrency,
  getCurrentCatalog,
  buildsyncProductsCard,
  find,
  manageCart,
  getLoginInfo,
} from "../../common/functions";

import defaultImage from "../../assets/images/default.jpg";
import Modal from "../../components/ModalV2";

const maxItemsFilter = getParamValues().maxItemsFilter;
const loginMenuoptions = getParamValues().loginMenuoptions;
const eventMenuoptions = getParamValues().eventMenuoptions;

const loggedMenuoptions = getParamValues().loggedMenuoptions;
const shippingMenuoptions = getParamValues().shippingMenuoptions;
const mobileMenuoptions = getParamValues().mobileMenuoptions;

const connector = new AppSearchAPIConnector({
  searchKey: process.env.REACT_APP_SEARCHKEY,
  engineName: process.env.REACT_APP_ENGINENAME,
  endpointBase: process.env.REACT_APP_ENDPOINTBASE,
  cacheResponses: true,
});

//var hide=false;

var cardData = [];
var cardConfig = {};
var timeout = null;
const Header = (props) => {
  const history = useHistory();
  //const [searchResults, setSearchResults] = useState([]);
  //const [searchKey, setSearchKey] = useState("");
  const [loading, setloading] = useState("NO");
  //const [searchTermVal, setsearchTermVal] = useState("");
  const [showForm, setShowForm] = React.useState(false);
  const [tempStore, setTempStore] = React.useState(0);

  //const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  /*const pathN = window.location.pathname
    console.log("path:", pathN, pathN.indexOf("search"))
    if ( pathN.indexOf("search") >=0  ) {
    	props.hideFilter()
    	console.log("oculta")

    }*/

  //console.log(props)

  const [eventActionM, setEventActionM] = useState("");

  const [loginInfo, setLoginInfo] = React.useState({ userId: null });
  React.useEffect(() => {
    let _loginInfo = getLoginInfo();
    setLoginInfo(_loginInfo);
    let eventActionM = getCookie(process.env.REACT_APP_ORG_NAME + "_action");
    setEventActionM(eventActionM);
  }, [props]);

  const checkEventStatus = (subitem) => {
    var eventId = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
    var eventAction = getCookie(process.env.REACT_APP_ORG_NAME + "_action");
    //console.log(subitem,eventId, eventAction, subitem.link )

    if (parseInt(eventId) > 0) {
      if (
        eventAction === "addEventList" &&
        subitem.link.indexOf("/customer/event/manage") >= 0
      ) {
        //console.log("push")
        //history.push(subitem.link );
      } else if (
        eventAction === "addEventCart" &&
        subitem.link === "/customer/event/event-details"
      ) {
        history.push(subitem.link);
      } else {
        toggleModal(subitem);
      }
    } else {
      history.push(subitem.link);
    }
  };

  const _manageCart = async (event) => {
    try {
      setloading("YES");
      props.forceHideCart();
      var resp = await manageCart(event, cardData, _manageCart, toast);
      //console.log(resp)
      if (resp.success) {
        if (!resp.redirect) {
          cardData = resp._cardData;

          //console.log("UPDATE")
          //console.log("!!!",resp._cardData)

          if (resp.dataCart) {
            //console.log("dataCart")
            cardData = resp._cardData;
            props.updateCart(resp.dataCart);
          }
        } else {
          redirectTo(resp.selectedCategory + "/" + resp.selectedPath);
          //	window.location.reload();
        }
      } else {
        //console.log(">>>>>>>",resp.message)
      }
      setloading("NO!");
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    }
  };
  const redirectTo = (pathValue) => {
    props.closePopUpSearcher();
    history.push("/" + pathValue);
  };
  /*
  const getSearchResults = async (searchkey, page, size) => {
    let data = {
      searchkey: searchkey,
      page: page,
      storeid: storeid,
      size: size,
    };
    //setSearchKey(searchkey);
    if (searchkey.length > 3) {
      //setLoadingSearchResults(true);
      callApi("customer", "getFilterProductsElastic", data).then((res) => {
        if (res.errorcode === "0") {
          //setSearchResults(res.rows);
          //setLoadingSearchResults(false);
        }
        //console.log(res)
      });
    } else {
      //setSearchResults([]);
      //setLoadingSearchResults(false);
    }
  };
  */
  const navigateTo = (searchValue) => {
    history.push("/search/" + searchValue);
    props.hideFilter();
    props.changeHandlerS({
      target: { value: searchValue, name: "search_input" },
    });
  };
  let storeid = getCurrentCatalog();
  var menu = [];

  if (props.menuTemplate) {
    menu = props.menuTemplate;
  }

  var totalProd = 0;
  if (props.shopCart?.products) {
    props.shopCart.products.map(function (key) {
      totalProd = totalProd + key.quantity;
    });
  }

  const toggleModal = (event) => {
    //console.log("toggleModal", tempStore)
    setShowForm(!showForm);
  };

  const setEventMode = async (event) => {
    history.push("/");
    await props.setEventMode(null, null, null, null, tempStore);
  };

  return props.action === "" && props.showSearcher ? (
    <>
      <div className="searcher-mobile" style={{ position: "absolute" }}>
        <div className="row row-top">
          <div className="col-9">
            <input
              type="text"
              name="search_input"
              ref={(input) =>
                input && props.mobileFirstTime ? input.focus() : ""
              }
              value={props.searchinput}
              onChange={props.changeHandlerS}
              onKeyDown={props.changeHandlerS}
              className="search-mobile"
              placeholder={getDefValues().searchLabel}
            />
          </div>
          <div className="col-3 flex middle items-right text-right">
            <button
              type="button"
              className="searcher-mobile-button"
              onClick={props.toggleSearcher}
            >
              {getDefValues().closeLabel.toUpperCase()}
            </button>
          </div>
        </div>
        <div className="row options">
          <div className="col-9">
            {getDefValues().resultsLabel.toUpperCase() +
              ": " +
              props.cardData.length}
          </div>
          <div className="col-3 flex middle  items-right text-right">
            <button
              type="button"
              className="searcher-mobile-button"
              onClick={
                props.searchData.results.length > 0
                  ? props.toggleMobileFilters
                  : console.log("no results")
              }
            >
              {getDefValues().filterLabel.toUpperCase()}
            </button>
          </div>
        </div>

        <div
          className={
            props.showMobileFilters
              ? "mobile-showFilters filter-background visible"
              : "mobile-showFilters"
          }
        >
          <div className="col-12 mobile-col-cat">
            <div className="col-cat-content">
              {props.searchData.categories.length > 0 ? (
                <>
                  <ul className="categories">
                    <li className="">
                      <button
                        name="toggle_listcategories"
                        onClick={props.manageSearch}
                        className="btn btn-sm title-name searcher-mobile-button"
                      >
                        {getDefValues().categoryLabel}
                        {props.searchData.selectedcategories.length > 0
                          ? " (" +
                            props.searchData.selectedcategories.length +
                            ")"
                          : ""}
                      </button>

                      <button
                        name="toggle_listcategories"
                        onClick={props.manageSearch}
                        className="btn btn-sm title-button searcher-mobile-button"
                      >
                        <i
                          className={
                            "fas " +
                            (props.searchData.listcategories
                              ? "fa-minus"
                              : "fa-plus")
                          }
                        />
                      </button>
                    </li>
                  </ul>
                  {props.searchData.listcategories ? (
                    <>
                      <ul className="categories">
                        {props.searchData.categories.map((item, i) =>
                          props.searchData.showcategories ||
                          i < maxItemsFilter ? (
                            <li
                              key={"li1" + i}
                              className={
                                props.searchData.selectedcategories.indexOf(
                                  "" + item.categoryid
                                ) >= 0
                                  ? "active"
                                  : ""
                              }
                            >
                              <button
                                key={"button5" + i}
                                name={"filter_categories_" + item.categoryid}
                                onClick={props.manageSearch}
                                className="btn btn-sm name searcher-mobile-button "
                              >
                                {item.categoryname}
                              </button>
                              <button
                                key={"button6" + i}
                                name={"filter_categories_" + item.categoryid}
                                onClick={props.manageSearch}
                                className="btn btn-sm quantity searcher-mobile-button"
                              >
                                {item.quantity}
                              </button>
                            </li>
                          ) : (
                            ""
                          )
                        )}
                      </ul>
                      {!props.searchData.showcategories &&
                      props.searchData.categories.length > maxItemsFilter ? (
                        <div className="flex items-center">
                          <button
                            name="show_showcategories"
                            onClick={props.manageSearch}
                            className="btn btn-sm searcher-mobile-button"
                          >
                            {getDefValues().showMoreLabel}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {props.searchData.showcategories &&
                      props.searchData.categories.length > maxItemsFilter ? (
                        <div className="flex items-center">
                          <button
                            name="hide_showcategories"
                            onClick={props.manageSearch}
                            className="btn btn-sm searcher-mobile-button"
                          >
                            {getDefValues().hideLabel}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {props.searchData.selectedcategories.length > 0 &&
                  !props.searchData.listcategories ? (
                    <>
                      <ul className="categories ml-1 mr-2">
                        {props.searchData.categories.map((item, i) =>
                          props.searchData.selectedcategories.indexOf(
                            "" + item.categoryid
                          ) >= 0 ? (
                            <li key={"li2" + i} className="active">
                              <button
                                key={"button3" + i}
                                name={"filter_categories_" + item.categoryid}
                                onClick={props.manageSearch}
                                className="btn btn-sm name searcher-mobile-button "
                              >
                                {item.categoryname}
                              </button>
                              <button
                                key={"button4" + i}
                                name={"filter_categories_" + item.categoryid}
                                onClick={props.manageSearch}
                                className="btn btn-sm quantity searcher-mobile-button"
                              >
                                {item.quantity}
                              </button>
                            </li>
                          ) : (
                            ""
                          )
                        )}
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {/*props.searchData.maxpricedef> 0?
							  					 	<div className="mt-2 mb-2">

															<ul className="categories">
																<li className="" >
																	<button name="toggle_listprices" onClick={props.manageSearch}	className="btn btn-sm title-name">
													 		 		 { getDefValues().PriceLabel}
															 		</button>

																	<button name="toggle_listprices" onClick={props.manageSearch}	className="btn btn-sm title-button">
												 		 				<i className={"fas "+(props.searchData.listPrices?"fa-minus":"fa-plus")}  />
														 		 	</button>
																</li>
															</ul>


															{props.searchData.listprices?
																<div className="col-12 mt-3" style={{"min-height":"40px"}} >

																	<div className="price-slider-container">
																		<div className="item" style={{"left":"0%"}} >{ getCurrency() + ""+ props.searchData.minprice}</div>
																		<div className="item" style={{"right": "5%"}}  >{ getCurrency() + ""+props.searchData.maxprice}</div>
																	</div>
							    								<input  type="range"
														        min={props.searchData.minpricedef}
														        max={props.searchData.maxpricedef}
														        name={"changeprice_minprice"}
														        value={props.searchData.minprice}
														        className={"slider-range thumb " + (props.searchData.minprice == props.searchData.maxpricedef?"thumb--zindex-5":"thumb--zindex-3") }
														        onChange={props.manageSearch}
																	/>
														      <input type="range"
														        min={props.searchData.minpricedef}
														        max={props.searchData.maxpricedef}
														        name={"changeprice_maxprice"}
														      	value={props.searchData.maxprice}
														      className={"slider-range thumb " + (props.searchData.maxprice == props.searchData.minpricedef?"thumb--zindex-5":"thumb--zindex-4") }
														        onChange={props.manageSearch}
														      />

							        						<div className="price-slider">
																	  <div className="price-slider__track" />
																	  <div className="price-slider__range" />
																	  <div className="price-slider__left-value">{getCurrency() +""+props.searchData.minpricedef}</div>

																	  <div className="item-mark-big" style={{"left":"6px"}}></div>
																	  <div className="item-mark-big" style={{"right":"5px"}}></div>
																	  <div className="item-mark-big" style={{"left": "107px"}}></div>
																	  {rangeItems.map(item=> (

																	  	<div className="item-mark" style={{"left": (6+( item/ rangeItems.length *275   ))+"px"}}></div>

																		))}
																		<div className="price-slider__right-value">{getCurrency() +""+props.searchData.maxpricedef}</div>
																	</div>
																</div>
															:""}
														</div>
													:""
													*/}
              {props.searchData.options.length > 0
                ? props.searchData.options.map((item, i) => (
                    <div key={"div1" + i} className="mt-2 mb-2">
                      <ul key={"ul1" + i} className="categories">
                        <li key={"li2" + i} className="">
                          <button
                            key={"button2" + i}
                            name={"toggle_listoptions_" + item.optionid}
                            onClick={props.manageSearch}
                            className="btn btn-sm title-name searcher-mobile-button"
                          >
                            {item.optionname}
                            {item.selectedoptions.length > 0
                              ? " (" + item.selectedoptions.length + ")"
                              : ""}
                          </button>

                          <button
                            key={"button2b" + i}
                            name={"toggle_listoptions_" + item.optionid}
                            onClick={props.manageSearch}
                            className="btn btn-sm title-button searcher-mobile-button"
                          >
                            <i
                              key={"i1" + i}
                              className={
                                "fas " +
                                (item.listoptions ? "fa-minus" : "fa-plus")
                              }
                            />
                          </button>
                        </li>
                      </ul>

                      {item.listoptions ? (
                        <>
                          <ul key={"ul2a" + i} className="categories">
                            {item.options.map((option, i) =>
                              item.showoptions || i < maxItemsFilter ? (
                                <li
                                  key={"li2a" + i}
                                  className={
                                    item.selectedoptions.indexOf(
                                      "" + option.value
                                    ) >= 0
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <button
                                    key={"button3b" + i}
                                    name={
                                      "filter_options_" +
                                      item.optionid +
                                      "_" +
                                      option.value
                                    }
                                    onClick={props.manageSearch}
                                    className="btn btn-sm name searcher-mobile-button "
                                  >
                                    {option.value}
                                  </button>
                                  <button
                                    key={"button4b" + i}
                                    name={
                                      "filter_options_" +
                                      item.optionid +
                                      "_" +
                                      option.value
                                    }
                                    onClick={props.manageSearch}
                                    className="btn btn-sm quantity searcher-mobile-button"
                                  >
                                    {option.quantity}
                                  </button>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                          </ul>
                          {!item.showoptions &&
                          item.options.length > maxItemsFilter ? (
                            <div
                              key={"div2a" + i}
                              className="flex items-center"
                            >
                              <button
                                key={"button5b" + i}
                                name={"show_showoptions_" + item.optionid}
                                onClick={props.manageSearch}
                                className="btn btn-sm searcher-mobile-button"
                              >
                                {getDefValues().showMoreLabel}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          {item.showoptions &&
                          item.options.length > maxItemsFilter ? (
                            <div
                              key={"div3a" + i}
                              className="flex items-center"
                            >
                              <button
                                key={"button5c" + i}
                                name={"hide_showoptions_" + item.optionid}
                                onClick={props.manageSearch}
                                className="btn btn-sm searcher-mobile-button"
                              >
                                {getDefValues().hideLabel}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {item.selectedoptions.length > 0 && !item.listoptions ? (
                        <>
                          <ul key={"ul2b" + i} className="categories">
                            {item.options.map((option, i) =>
                              item.selectedoptions.indexOf("" + option.value) >=
                              0 ? (
                                <li key={"li2b" + i} className="active">
                                  <button
                                    key={"button6c" + i}
                                    name={
                                      "filter_options_" +
                                      item.optionid +
                                      "_" +
                                      option.value
                                    }
                                    onClick={props.manageSearch}
                                    className="btn btn-sm name searcher-mobile-button "
                                  >
                                    {option.value}
                                  </button>
                                  <button
                                    key={"button7c" + i}
                                    name={
                                      "filter_options_" +
                                      item.optionid +
                                      "_" +
                                      option.value
                                    }
                                    onClick={props.manageSearch}
                                    className="btn btn-sm quantity "
                                  >
                                    {option.quantity}
                                  </button>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                          </ul>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
        {/*
									<div className="row"> 
										<div className="list-content row  mt-1" > 
											{props.searchData.results.map(item=> (

												 	<div 	key= {"col-"+item.productid}  className="col-prod">
													 	<a href={item.path} tabIndex="-1">
															<div 	key= {"image"+item.productid}  className="image ">
																<img
																	key={"img"+item.productid}
																	name={"manageImage_"+item.productname}
																	className="product-image search-container-image"
																	src={item.image!=""?getImagePath(item.image):defaultImage}
																/>
															</div>
															<div key={"price"+item.productid}  className="price">
															{ item.productname}
															</div>
															<div key={"info"+item.productid}  className="info">
																<span key={"spaninfo"+item.productid}  className="h6-responsive"> { getCurrency()+ "" +toFixed(item.minprice) }</span>
															</div>
														</a>
												 	</div> 
									 	  ))
      								}
		        				</div>
									</div>
									*/}

        <div className="row searcher-mobile-results">
          <div className="mobile-col col-md-12 col-lg-12	">
            <Card cardData={props.cardData} cardConfig={props.cardConfig} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <PageLoading isLoading={loading === "YES" ? true : false} />
      {props.windowWidth >= 799 ? (
        <div className="">
          <>
            {props.eventId > 0 ? (
              <div className="message-top">
                <div className="event">
                  <span
                    style={{
                      cursor:
                        props.action === "addEventList"
                          ? "pointer"
                          : props.action === "addEventCart"
                          ? "pointer"
                          : "auto",
                    }}
                    target="_top"
                    onClick={() => {
                      if (props.action === "addEventList") {
                        history.push("/customer/event/manage");
                      } else if (props.action === "addEventCart") {
                        history.push("/customer/event/event-details");
                      } else {
                        console.log("no");
                      }
                    }}
                  >
                    {(props.action === "addEventCart"
                      ? getDefValues().eventShopTopMessage
                      : getDefValues().eventTopMessage) +
                      " " +
                      props.eventName}
                    <span style={{ textDecoration: "underline" }}>
                      {" "}
                      {getDefValues().eventShopTopMessage2}{" "}
                    </span>
                  </span>
                </div>

                <button
                  type="button"
                  onClick={() => {
                    setTempStore(0);
                    toggleModal();
                  }}
                  className="close white-text"
                  aria-label="Close"
                >
                  <span aria-hidden="true">x</span>
                </button>
                <Modal
                  content={
                    <>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center mb-30 mt-30 ">
                          <h4> {getDefValues().gooutOfEventModeMessage} </h4>
                        </div>
                      </div>
                    </>
                  }
                  staticBackdrop={false}
                  toogleCloseButton={true}
                  formAction={setEventMode}
                  btncolor={"secondary"}
                  formButtonLabel={getDefValues().yeslabel}
                  secondaryFormButtonLabel={getDefValues().nolabel}
                  toggleModal={toggleModal}
                  modal={showForm}
                  btncolorbtncolor="secondary"
                />
              </div>
            ) : (
              ""
            )}
            {props.topMessages.map((item, i) => (
              <div
                key={"div5" + i}
                className={
                  (item.hide ? " message-top-hide " : "") + "message-top"
                }
              >
                <div key={"div6" + i} className="" style={item.style}>
                  <a
                    key={"a6" + i}
                    style={item.style}
                    href={item.link}
                    target="_top"
                  >
                    {" "}
                    {item.message}{" "}
                  </a>
                </div>
                {item.style.closable === "NO" ? (
                  ""
                ) : (
                  <button
                    key={"button8" + i}
                    type="button"
                    name={item.name}
                    onClick={props.hideMessage}
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">x</span>
                  </button>
                )}
              </div>
            ))}
          </>
        </div>
      ) : (
        ""
      )}
      <div className={"header-area header-style-1 header-height-2  "}>
        <div className="header-middle header-middle-ptb-1-sm d-none d-lg-block">
          <div className="container header-container">
            <div className="header-wrap">
              <div className="logo logo-width-1">
                <a onClick={props.forceHideCart} href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
              <div className="header-right">
                <div className="search-style-2">
                  <div className="row mr-1">
                    {storeid > 0 ? (
                      <SearchProvider
                        config={{
                          apiConnector: connector,
                          trackUrlState: false,
                          searchQuery: {
                            search_fields: {
                              productid: { weight: 1 },
                              genericsku: { weight: 1 },
                              colors: { weight: 1 },
                              sizes: { weight: 1 },
                              productname: { weight: 10 },
                              tags: { weight: 8 },
                              id: { weight: 1 },
                            },
                            precision: getDefValues().precisionSearch,
                          },

                          suggestions: {
                            types: {
                              documents: {
                                fields: ["productname"],
                              },
                            },
                          },

                          autocompleteQuery: {
                            // Customize the query for autocompleteResults
                            results: {
                              result_fields: {
                                // Add snippet highlighting
                                title: {
                                  snippet: { size: 10, fallback: false },
                                },
                                nps_link: { raw: {} },
                              },
                            },
                            // Customize the query for autocompleteSuggestions
                            suggestions: {
                              types: {
                                // Limit query to only suggest based on "title" field
                                documents: { fields: ["productname"] },
                              },
                            },
                          },
                        }}
                      >
                        <WithSearch
                          mapContextToProps={({
                            searchTerm,
                            setSearchTerm,
                            results,
                            setResultsPerPage,
                            autocompletedSuggestions,
                            autocompletedResults,
                            addFilter,
                            setFilter,
                            removeFilter,
                          }) => ({
                            searchTerm,
                            setSearchTerm,
                            results,
                            setResultsPerPage,
                            autocompletedSuggestions,
                            autocompletedResults,
                            addFilter,
                            setFilter,
                            removeFilter,
                          })}
                        >
                          {(context) => {
                            const searchTerm = context.searchTerm;
                            const setSearchTerm = context.setSearchTerm;
                            //const results = context.results;

                            if (searchTerm.length >= 3) {
                              var finalRows = [];
                              for (let k in context.results) {
                                let key = context.results[k];
                                let _options = [];
                                let _quantities = [];
                                let _categories = [];
                                let isValid = false;
                                for (let o in key["categories"].raw) {
                                  _categories.push(
                                    JSON.parse(key["categories"].raw[o])
                                  );
                                }
                                for (let o in key["quantities"].raw) {
                                  let item = JSON.parse(
                                    key["quantities"].raw[o]
                                  );
                                  if (
                                    item.quantity > 0 ||
                                    key["packname"].raw
                                  ) {
                                    _quantities.push(item);
                                    isValid = true;
                                  }
                                }
                                //let finalOption = [];
                                for (let o in key["options"].raw) {
                                  let option = JSON.parse(
                                    key["options"].raw[o]
                                  );
                                  var optionvalues = [];
                                  option.optionvalues.map(function (val) {
                                    let pos = find(
                                      _quantities,
                                      val.value,
                                      "optionvalue"
                                    );
                                    if (pos >= 0) {
                                      optionvalues.push(val);
                                    }
                                  });
                                  let opt = {
                                    optionid: option.optionid,
                                    optionvalues: optionvalues,
                                  };
                                  if (optionvalues.length > 0) {
                                    _options.push(opt);
                                  }
                                }
                                let sortorder = 999999;
                                try {
                                  sortorder = parseInt(key["sortorder"].raw);
                                } catch (e) {}
                                let finalRow = {
                                  productid: key["productid"].raw,
                                  languageid: key["languageid"].raw,
                                  productname: key["productname"].raw,
                                  productdescription: key["productname"].raw,
                                  productmodel: key["productmodel"].raw,
                                  genericsku: key["genericsku"].raw,
                                  price: key["price"].raw,
                                  quantity: 1,
                                  statusid: key["statusid"].raw,
                                  dateadded: key["dateadded"].raw,
                                  image: key["image"].raw,
                                  status: key["status"].raw,
                                  newprice: key["newprice"].raw,
                                  newpricediscount: key["newpricediscount"].raw,
                                  newquantitydiscount:
                                    key["newquantitydiscount"].raw,
                                  datestartdiscount:
                                    key["datestartdiscount"].raw,
                                  dateenddiscount: key["dateenddiscount"].raw,
                                  datestart: key["datestart"].raw,
                                  dateend: key["dateend"].raw,
                                  age: key["age"].raw,
                                  canonicalpath_id: key["canonicalpath_id"].raw,
                                  options: _options,
                                  quantities: _quantities,
                                  categories: _categories,
                                  category: key["category"]
                                    ? key["category"].raw
                                    : "",
                                  tags: key["tags"].raw,
                                  minprice: key["minprice"].raw,
                                  maxprice: key["maxprice"].raw,
                                  isvalid: true,
                                  storeid: key["storeid"].raw,
                                  path: key["path"].raw,
                                  colors: key["colors"].raw,
                                  sizes: key["sizes"].raw,
                                  sortorder: sortorder,
                                };

                                if (key["packname"].raw) {
                                  finalRow["packname"] = key["packname"].raw;
                                }

                                if (key["statusid"].raw != 5) {
                                  isValid = false;
                                }
                                if (isValid) {
                                  finalRows.push(finalRow);
                                }
                              }
                              //console.log("finalRows",finalRows);
                              var prodDefConfig = {
                                pagewidth: "col-md-12 col-lg-12 col-xl-12",
                                colsize: "col-6",
                                colsizelg: "col-lg-4",
                                colsizemd: "col-md-2",
                                colsizesm: "col-sm-12",
                                maxrows: 6,
                              };

                              finalRows.sort(function (a, b) {
                                return b["sortorder"] > a["sortorder"]
                                  ? -1
                                  : b["sortorder"] < a["sortorder"]
                                  ? 1
                                  : 0;
                              });

                              //console.log(finalRows)
                              let respCat = buildsyncProductsCard(
                                finalRows,
                                null,
                                _manageCart,
                                prodDefConfig,
                                "searchResult"
                              );
                              if (loading === "NO") {
                                cardData = respCat.cardProducts;
                                cardConfig = respCat.cardConfig;
                              }
                            }

                            context.setResultsPerPage(6);
                            const addFilter = context.addFilter;
                            //const removeFilter = context.removeFilter;
                            //const setFilter = context.setFilter;
                            addFilter("storeid", storeid);
                            addFilter("productqty", {
                              from: 1,
                              name: "instock",
                              to: 99999,
                            });
                            //console.log("autocompletedResults", context.autocompletedResults);
                            //console.log("autocompletedSuggestions", context.autocompletedSuggestions);
                            const autocompletedSuggestions =
                              context.autocompletedSuggestions;
                            return (
                              <>
                                {" "}
                                {props.action === "" && props.showFilters ? (
                                  <form
                                    onSubmit={(e) => {
                                      navigateTo(searchTerm);
                                      setSearchTerm("");
                                    }}
                                  >
                                    <input
                                      key={"search_input"}
                                      type="text"
                                      name="search_input"
                                      id="search_input_web"
                                      onChange={(e) => {
                                        //searh web
                                        clearTimeout(timeout);
                                        timeout = setTimeout(() => {
                                          setloading("NO");
                                          setSearchTerm(
                                            e.target.value.toLowerCase(),
                                            {
                                              autocompleteSuggestions: true,
                                            }
                                          );
                                        }, 300);
                                      }}
                                      autoComplete={"off"}
                                      placeholder={getDefValues().searchLabel}
                                    />
                                  </form>
                                ) : (
                                  ""
                                )}
                                {searchTerm.length >= 3 && (
                                  <div className="search-container-2">
                                    <div
                                      style={{
                                        backgroundColor: "#fff",
                                        display: "flex",
                                        padding: "10px",
                                        flexWrap: "wrap",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "80%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {" "}
                                          {(
                                            "Productos para " + searchTerm
                                          ).toUpperCase()}{" "}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          width: "20%",
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <span
                                          onClick={(e) => {
                                            setSearchTerm("");
                                            props.forceHideCart();
                                          }}
                                          className="font-md text-bold cursor-pointer"
                                        >
                                          Cerrar X
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "20%",
                                        paddingRight: "5px",
                                        borderRight: "1px dotted #ccc",
                                      }}
                                    >
                                      {/*<span style={{fontWeight: "bold", width: "100%"}}>Recomendaciones</span>*/}
                                      {/*<br/>*/}

                                      <div className="suggest-label mt-2 text-bold">
                                        SUGERENCIAS
                                      </div>

                                      {autocompletedSuggestions.documents &&
                                        autocompletedSuggestions.documents.map(
                                          (item, i) => {
                                            return (
                                              <p
                                                key={"p1" + i}
                                                className="suggest-label"
                                                onClick={() => {
                                                  setSearchTerm(
                                                    item.suggestion,
                                                    {
                                                      autocompleteSuggestions: true,
                                                    }
                                                  );
                                                }}
                                              >
                                                <b key={"b1" + i}>
                                                  {searchTerm}
                                                </b>
                                                <span key={"span1" + i}>
                                                  {item.suggestion.replace(
                                                    searchTerm,
                                                    ""
                                                  )}
                                                </span>
                                              </p>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div
                                      style={{
                                        width: "80%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          width: "100%",
                                        }}
                                      >
                                        <button
                                          onClick={(e) => {
                                            navigateTo(searchTerm);
                                            setSearchTerm("");
                                          }}
                                          style={{
                                            height: 20,
                                            textDecoration: "underline",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            width: "100%",
                                            backgroundColor: "#fff",
                                            border: "0px",
                                          }}
                                        >
                                          Ver Todos los Resultados
                                        </button>
                                      </div>

                                      <Card
                                        cardData={cardData}
                                        cardConfig={cardConfig}
                                      />

                                      {/*results.map(item => ( !item.productid?"":
                                                                        	
                                                                            <div style={{width: "33%", display:"block"}}>
                                                                            
                                                                            
                                                                                <div onClick={props.selectProduct} key={"col-" + item.productid.raw}
                                                                                     className="search-container-col-prod">
                                                                                    <a href={ item.path.raw}
                                                                                       tabIndex="-1">
                                                                                        <div key={"image" + item.productid.raw} className="d-flex justify-content-center">
                                                                                            <img
                                                                                                key={"img" + item.productid.raw}
                                                                                                name={"manageImage_" + item.productname.raw}
                                                                                                className="product-image search-container-image"
                                                                                                src={item.image.raw != "" ? getImagePath(item.image.raw) : defaultImage}
                                                                                            />
                                                                                        </div>
                                                                                        <div key={"info-" + item.productid.raw} className="col-12 text-center">
	                                                                                        <text key={"price-" + item.productid.raw} className="price">
	                                                                                            {item.productname.raw}
	                                                                                        </text>
	                                                                                      </div>
                                                                                        <div key={"info-" + item.productid.raw} className="col-12 text-center">
                                                                                            <span key={"spaninfo-" + item.productid.raw} className="h6-responsive"> {getCurrency() + "" + toFixed(item.minprice.raw)}</span>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                
                                                                                
                                                                            </div>
                                                                            
                                                                        ))*/}
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          }}
                        </WithSearch>
                      </SearchProvider>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="header-action-right">
                  <div className="header-action-2">
                    {/*eventIco */}
                    <div
                      className="header-action-icon-2"
                      style={{ width: 28, height: 28, marginRight: 15 }}
                    >
                      <a href="/customer/event/home">
                        <button className="no-button mini-event-icon">
                          <img
                            className="svgInject"
                            alt="event-web"
                            style={{ height: 27, opacity: 0.9 }}
                            src={getImagePath("/catalog/images/event.png")}
                          />

                          {props.eventQty > 0 && (
                            <span className="pro-count blue">
                              {" "}
                              {props.eventQty}{" "}
                            </span>
                          )}
                        </button>
                      </a>
                      <div
                        style={{ right: 0, left: 0 }}
                        className="user-info card"
                      >
                        <ul>
                          {eventMenuoptions.map((item, i) =>
                            item.validationValue ? (
                              <>
                                {localStorage.getItem(
                                  process.env.REACT_APP_ORG_NAME +
                                    "_" +
                                    item.validationValue
                                ) && (
                                  <li key={"li3e" + i}>
                                    {item.link.indexOf(
                                      "/customer/event/manage"
                                    ) >= 0 &&
                                    eventActionM !== "addEventCart" ? (
                                      <a
                                        href={item.link}
                                        key={"sh1_2" + i}
                                        className="link-menu"
                                      >
                                        {item.label}
                                      </a>
                                    ) : (
                                      <a
                                        href="#"
                                        key={"sh1" + i}
                                        onClick={() => {
                                          if (item.link) {
                                            if (item.action) {
                                              if (
                                                item.action ===
                                                "checkEventStatus"
                                              ) {
                                                checkEventStatus(item);
                                              }
                                            } else {
                                              history.push(item.link);
                                            }
                                            props.forceHideCart();
                                          } else {
                                            props.forceHideCart();
                                          }
                                        }}
                                        className="link-menu"
                                      >
                                        {item.label}
                                      </a>
                                    )}
                                  </li>
                                )}
                              </>
                            ) : (
                              <li key={"li3e" + i}>
                                <a
                                  href="#"
                                  key={"sh1" + i}
                                  onClick={() => {
                                    if (item.link) {
                                      if (item.action) {
                                        if (
                                          item.action === "checkEventStatus"
                                        ) {
                                          checkEventStatus(item);
                                        }
                                      } else {
                                        history.push(item.link);
                                      }
                                      props.forceHideCart();
                                    } else {
                                      props.forceHideCart();
                                    }
                                  }}
                                  className="link-menu"
                                >
                                  {item.label}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    {/**/}
                    <div className="header-action-icon-2">
                      <button className="no-button mini-stores-icon">
                        <img
                          className="svgInject"
                          alt="Shipping"
                          style={{ height: 21, opacity: 1 }}
                          src={getImagePath("/catalog/images/shipping.svg")}
                        />
                      </button>
                      <div
                        className="shipping-info card"
                        style={{
                          top: 35,
                          position: "absolute",
                          width: 351,
                          right: -117,
                          border: "#fff",
                        }}
                      >
                        <div
                          className="row"
                          style={{
                            border: "1px solid #e6e6e6",
                            borderRadius: 6,
                            marginTop: 4,
                            background: "#fff",
                            marginBottom: 10,
                          }}
                        >
                          {shippingMenuoptions.map((item, i) => (
                            <div
                              key={"div9" + i}
                              className={
                                (item.size ? item.size : " col-12 ") +
                                " " +
                                (item.height ? item.height : " mt-3 ") +
                                " " +
                                (item.align
                                  ? "items-" + item.align + " flex"
                                  : "") +
                                " " +
                                (i === shippingMenuoptions.length - 1 //check!
                                  ? "mb-4"
                                  : "")
                              }
                            >
                              <a key={"sh1" + i} href={item.link}>
                                {item.type === "IMAGE" ? (
                                  <>
                                    <img
                                      key={"img1" + i}
                                      style={{
                                        width: item.width ? item.width : "45px",
                                      }}
                                      name={"manageImage_" + item.PRODUCTNAME}
                                      className=""
                                      src={
                                        item.image !== ""
                                          ? item.image
                                          : defaultImage
                                      }
                                    />
                                    <div key={"div9a" + i} className="mt-3">
                                      {" "}
                                      {item.label}{" "}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {item.type === "BUTTON" ? (
                                  <div
                                    key={"div9b" + i}
                                    className="btn btn-primary"
                                  >
                                    {" "}
                                    {item.label}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {item.type === "TEXT" ? (
                                  <span key={"span9a" + i}> {item.label} </span>
                                ) : (
                                  ""
                                )}
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="header-action-icon-2">
                      <a href={"/pages/tiendas"}>
                        <img
                          className="svgInject"
                          style={{ height: 21, opacity: 0.65 }}
                          alt="ci"
                          src={getImagePath("/catalog/images/stores.jpeg")}
                        />
                      </a>
                    </div>

                    <div className="header-action-icon-2">
                      <Link to="/customer/account">
                        <span>
                          <img
                            className="svgInject"
                            style={{ height: 21, opacity: 0.65 }}
                            alt="ci"
                            src={getImagePath("/catalog/images/user.jpeg")}
                          />
                        </span>
                      </Link>
                      <div className="user-info card">
                        <ul>
                          {loginInfo.userId != null ? (
                            <>
                              {loggedMenuoptions.map((item, i) => (
                                <li key={"li3" + i}>
                                  <a
                                    key={"info1" + i}
                                    href={item.link}
                                    className={i === 0 ? "text-bold" : ""}
                                  >
                                    {item.label +
                                      (i === 0
                                        ? " " + loginInfo.token.firstname
                                        : "")}
                                  </a>
                                </li>
                              ))}
                            </>
                          ) : (
                            <>
                              {loginMenuoptions.map((item, i) => (
                                <li key={"li3b" + i}>
                                  <a key={"info2" + i} href={item.link}>
                                    {item.label}
                                  </a>
                                </li>
                              ))}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="header-action-icon-2 mt-2">
                      {" "}
                      {/*WEB*/}
                      {props.action !== "addEventList" && (
                        <button
                          onClick={props.toggleCart}
                          className="no-button mini-cart-icon"
                        >
                          <img
                            className="svgInject"
                            alt="Shop Cart"
                            style={{ height: 22, opacity: 0.65 }}
                            src={getImagePath("/catalog/images/shopcart.jpeg")}
                          />

                          {props.shopCart?.products ? (
                            <>
                              {props.shopCart.products.length > 0 ? (
                                <span
                                  className={
                                    "pro-count blue" +
                                    (props.action === "addEventCart"
                                      ? " event-pro-count"
                                      : "")
                                  }
                                >
                                  {" "}
                                  {totalProd}{" "}
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </button>
                      )}
                      <div
                        className={
                          props.showCart ? "showCart-active" : "showCart"
                        }
                      >
                        {props.shopCart?.products ? (
                          <>
                            {props.shopCart.products.length === 0 ? (
                              <div className="card">
                                <div className="card-body">
                                  <div className="row text-08 mt-30 mb-3 ml-2">
                                    <div className="col-12 flex items-center">
                                      <b>{getDefValues().noProductsOnCart} </b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="card">
                                <div className="card-body mini-cart">
                                  <div className="row text-08 mt-3 ml-2">
                                    <div className="col-8 flex items-left text-bold">
                                      {totalProd} {getDefValues().productsLabel}
                                    </div>

                                    <div className="col-4 text-right">
                                      {getDefValues().shopCartSubtotalLabel}
                                      <p className="text-bold text-price">
                                        {" "}
                                        {getCurrency()}
                                        {toFixed(props.shopCart.total)}{" "}
                                      </p>
                                    </div>
                                  </div>

                                  {props.shopCart?.products.map((item, i) => (
                                    <div key={"div10" + i} className="row mt-2">
                                      <div
                                        key={"div10" + i}
                                        className="col-4 flex items-left"
                                      >
                                        <a
                                          key={"a2" + i}
                                          href={
                                            (item.category
                                              ? "/" + item.category + "/"
                                              : "/productDetails/") +
                                            (item.productpath
                                              ? item.productpath
                                              : item.productname)
                                          }
                                        >
                                          <img
                                            key={"img2" + i}
                                            alt={item.productname}
                                            src={getImagePath(item.image)}
                                            className="card-img-top"
                                          />
                                        </a>
                                      </div>
                                      <div
                                        key={"div10b" + i}
                                        className="col-6  text-left middle"
                                      >
                                        <a
                                          key={"a2b" + i}
                                          onClick={props.forceHideCart}
                                          href={
                                            (item.category
                                              ? "/" + item.category + "/"
                                              : "/productDetails/") +
                                            (item.productpath
                                              ? item.productpath
                                              : item.productname)
                                          }
                                        >
                                          {item.productname}
                                        </a>

                                        {!item.optionsselected ? (
                                          ""
                                        ) : item.optionsselected.length > 1 ? (
                                          <ul key={"ul10" + i} className="mt-1">
                                            {item.optionsselected.map(
                                              (op, i) => (
                                                <li
                                                  key={"li10" + i}
                                                  className=""
                                                >
                                                  <>
                                                    {op.optionLabel ? (
                                                      op.optionLabel + ": "
                                                    ) : (
                                                      <>
                                                        {props.options.map(
                                                          (ol) =>
                                                            ol.OPTIONID ==
                                                            op.optionid
                                                              ? ol.OPTIONNAME +
                                                                ": "
                                                              : ""
                                                        )}
                                                      </>
                                                    )}
                                                    <span
                                                      key={"span10" + i}
                                                      className="text-bold"
                                                    >
                                                      {op.optionvalue}
                                                    </span>
                                                  </>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        ) : (
                                          ""
                                        )}
                                        <div key={"div11" + i} className="mb-2">
                                          {item.NEWPRICED ? (
                                            <>
                                              <span
                                                key={"span11" + i}
                                                className="product-price-new-mini"
                                              >
                                                {getCurrency() +
                                                  " " +
                                                  toFixed(item.NEWPRICED)}
                                              </span>
                                              <span
                                                key={"span11b" + i}
                                                className="product-price-old-mini"
                                              >
                                                {" " +
                                                  getCurrency() +
                                                  " " +
                                                  toFixed(item.price)}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {item.NEWPRICEC1 ? (
                                                <>
                                                  <b
                                                    key={"b11" + i}
                                                    className="text-price"
                                                  >
                                                    {getCurrency() +
                                                      " " +
                                                      toFixed(item.price) +
                                                      " "}
                                                  </b>
                                                  <span
                                                    key={"span11c" + i}
                                                    style={{ fontSize: "14px" }}
                                                    className="discount-span"
                                                  >
                                                    {item.priceOld}
                                                  </span>
                                                </>
                                              ) : (
                                                <b
                                                  key={"b11b" + i}
                                                  className="text-price"
                                                >
                                                  {getCurrency() +
                                                    toFixed(item.price)}
                                                </b>
                                              )}
                                            </>
                                          )}
                                        </div>
                                        <input
                                          type="text"
                                          key={"input11" + i}
                                          className="mini-qty-val"
                                          name={"quantity_" + item.productsku}
                                          value={
                                            item.prequantity != null
                                              ? item.prequantity
                                              : item.quantity
                                          }
                                          onChange={props.updateCart}
                                        />

                                        {item.showUpdate ? (
                                          <button
                                            key={"button11" + i}
                                            name={"update_" + item.productsku}
                                            onClick={props.updateCart}
                                            className="confirm-upd-mini"
                                          >
                                            {/*<i className="fas fa-sync-alt"></i>*/}
                                            Actualizar
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        key={"div11d" + i}
                                        className="col-1 flex  middle ml-2"
                                      >
                                        <button
                                          key={"button11b" + i}
                                          name={"del_" + item.productsku}
                                          onClick={props.updateCart}
                                          className="icon-trash-mini text-muted ml-1"
                                        >
                                          <img
                                            src={getImagePath(
                                              "/catalog/images/ico_trash.svg"
                                            )}
                                            alt="delete-product-web"
                                            width="20px"
                                            height="20px"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="card-footer">
                                  <div className="row">
                                    <div className="col-12 flex items-center">
                                      <button
                                        onClick={() => {
                                          history.push("/checkout/cart", {
                                            step: "formstep1",
                                          });
                                          props.toggleCart();
                                        }}
                                        className="btn btn-sm btn-primary mr-2"
                                      >
                                        {" "}
                                        {/*web*/}
                                        {getDefValues().completeOrder.toUpperCase()}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {props.windowWidth < 799 ? (
          <div className="">
            <>
              {props.eventId > 0 ? (
                <div className="message-top">
                  <div className="event">
                    <span
                      style={{
                        cursor:
                          props.action === "addEventList"
                            ? "pointer"
                            : props.action === "addEventCart"
                            ? "pointer"
                            : "auto",
                      }}
                      target="_top"
                      onClick={() => {
                        if (props.action === "addEventList") {
                          history.push("/customer/event/manage");
                        } else if (props.action === "addEventCart") {
                          history.push("/customer/event/event-details");
                        } else {
                          console.log("no");
                        }
                      }}
                    >
                      {(props.action === "addEventCart"
                        ? getDefValues().eventShopTopMessage
                        : getDefValues().eventTopMessage) +
                        " " +
                        props.eventName}
                      <span style={{ textDecoration: "underline" }}>
                        {" "}
                        {getDefValues().eventShopTopMessage2}{" "}
                      </span>
                    </span>

                    <button
                      type="button"
                      onClick={() => {
                        setTempStore(0);
                        toggleModal();
                      }}
                      className="close white-text"
                      aria-label="Close"
                    >
                      <span style={{ paddingRight: 5 }} aria-hidden="true">
                        x
                      </span>
                    </button>
                  </div>

                  <Modal
                    content={
                      <>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center mb-30 mt-30 ">
                            <h4> {getDefValues().gooutOfEventModeMessage} </h4>
                          </div>
                        </div>
                      </>
                    }
                    staticBackdrop={false}
                    toogleCloseButton={true}
                    formAction={setEventMode}
                    btncolor={"secondary"}
                    formButtonLabel={getDefValues().yeslabel}
                    secondaryFormButtonLabel={getDefValues().nolabel}
                    toggleModal={toggleModal}
                    modal={showForm}
                    btncolorbtncolor="secondary"
                  />
                </div>
              ) : (
                ""
              )}

              {props.topMessages.map((item, i) => (
                <div
                  key={"div12" + i}
                  className={
                    (item.hide ? " message-top-hide " : "") + "message-top"
                  }
                >
                  <div key={"div12b" + i} className=" " style={item.style}>
                    <a
                      key={"a12" + i}
                      style={item.style}
                      href={item.link}
                      target="_top"
                    >
                      {" "}
                      {item.message}{" "}
                    </a>
                    {item.style.closable === "NO" ? (
                      ""
                    ) : (
                      <button
                        key={"button12" + i}
                        type="button"
                        name={item.name}
                        onClick={props.hideMessage}
                        className="close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">x</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          </div>
        ) : (
          ""
        )}
        <div className="container main-store-location flex">
          <Dropdown className="user-dropdown">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="user-dropdown-link"
            >
              <span>
                <i className="fas fa-map-marker-alt mr-1" />
                {props.storesInfo.stores.map((st) =>
                  st.storeId == props.storesInfo.selectedStoreId
                    ? st.storeName
                    : ""
                )}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.storesInfo.stores.map((st) =>
                st.storeId != props.storesInfo.selectedStoreId ? (
                  <Dropdown.Item
                    className="dropdown-item-header"
                    name={st.storeId}
                    onClick={() => {
                      if (props.eventId > 0) {
                        setTempStore(st.storeId);
                        toggleModal();
                      } else {
                        props.setStore({ target: { name: st.storeId } });
                      }
                    }}
                    href="#"
                  >
                    {st.storeName}
                  </Dropdown.Item>
                ) : (
                  ""
                )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/*
                    <div className={props.scroll
                        ? "header-bottom header-bottom-bg-color sticky-bar stick"
                      : "header-bottom header-bottom-bg-color sticky-bar"}> */}
        <div className="header-bottom header-bottom-bg-color sticky-bar">
          <div className="container header-container">
            <div className="header-wrap header-space-between position-relative">
              <div className="header-action-icon-2 d-block d-lg-none">
                <div
                  className="burger-icon burger-icon-white"
                  onClick={props.toggleClick}
                >
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </div>

              <div className="logo logo-width-1 d-block d-lg-none">
                <a href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>

              <div className="header-nav d-none d-lg-flex">
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block">
                  <nav className="main-menu-navigator">
                    <ul>
                      {/*level 1*/}
                      {menu.map((l1) =>
                        l1.MENULEVEL == "1" ? (
                          <li
                            className={
                              l1.ADITIONALCONFIG.configmenu2 === "YES"
                                ? "position-static"
                                : ""
                            }
                          >
                            <a
                              style={{
                                background: l1.ADITIONALCONFIG.configbackground
                                  ? l1.ADITIONALCONFIG.configbackground
                                  : "#fff",
                                color: l1.ADITIONALCONFIG.configcolor
                                  ? l1.ADITIONALCONFIG.configcolor
                                  : "#696158",
                              }}
                              href={l1.MENUURL ? l1.MENUURL : "#"}
                            >
                              {l1.MENULABEL}{" "}
                              <i className="fi-rs-angle-down"></i>
                            </a>
                            {/*level 2*/}
                            {l1.ISPARENT ? (
                              <>
                                {l1.ADITIONALCONFIG.configmenu2 === "YES" &&
                                l1.MENUTOTALROWS > 1 ? (
                                  <ul className="mega-menu">
                                    {l1.MENUROWS.map((row) => (
                                      <li
                                        className={
                                          "sub-mega-menu " + l1.MENUWIDTHCLASS
                                        }
                                      >
                                        <ul>
                                          {l1.MENUCHILDS.map((l2) =>
                                            l2.row === row ? (
                                              <li>
                                                <a
                                                  href={
                                                    l2.MENUURL
                                                      ? l2.MENUURL
                                                      : "#"
                                                  }
                                                >
                                                  <span>{l2.MENULABEL}</span>
                                                </a>
                                              </li>
                                            ) : (
                                              ""
                                            )
                                          )}
                                        </ul>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <ul className="sub-menu">
                                    {menu.map((l2) =>
                                      l2.MENULEVEL == "2" &&
                                      l1.MENUID == l2.PARENTMENUID ? (
                                        <li>
                                          <a
                                            href={l2.MENUURL ? l2.MENUURL : "#"}
                                          >
                                            <span>
                                              {l2.PARENTMENUID === 6 &&
                                              l2.configimage !== "" ? (
                                                <img
                                                  src={getImagePath(
                                                    l2.configimage
                                                  )}
                                                  alt={l2.MENULABEL}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              {l2.MENULABEL}
                                            </span>
                                          </a>
                                          {/*level 3*/}
                                          {l2.ISPARENT ? (
                                            <ul className="level-menu1 level-menu-modify">
                                              {menu.map((l3) =>
                                                l3.MENULEVEL == "3" &&
                                                l2.MENUID == l3.PARENTMENUID ? (
                                                  <li>
                                                    <a
                                                      href={
                                                        l3.MENUURL
                                                          ? l3.MENUURL
                                                          : "#"
                                                      }
                                                    >
                                                      <span>
                                                        {l3.MENULABEL}
                                                      </span>
                                                    </a>
                                                    {/*level 4*/}
                                                    {l3.ISPARENT ? (
                                                      <ul className="level-menu2 level-menu-modify">
                                                        {menu.map((l4) =>
                                                          l4.MENULEVEL == "4" &&
                                                          l3.MENUID ==
                                                            l4.PARENTMENUID ? (
                                                            <li>
                                                              <a
                                                                href={
                                                                  l4.MENUURL
                                                                    ? l4.MENUURL
                                                                    : "#"
                                                                }
                                                              >
                                                                {l4.MENULABEL}
                                                              </a>
                                                            </li>
                                                          ) : (
                                                            ""
                                                          )
                                                        )}
                                                      </ul>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </li>
                                                ) : (
                                                  ""
                                                )
                                              )}
                                            </ul>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </ul>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </li>
                        ) : (
                          ""
                        )
                      )}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="header-action-right d-block d-lg-none">
                <div className="header-action-2">
                  <div className="header-action-icon-2">
                    {/*eventIco */}
                    <a href="#" onClick={props.toggleEventInfo}>
                      <img
                        className="svgInject"
                        style={{ height: 24, opacity: 0.9 }}
                        src={getImagePath("/catalog/images/event.png")}
                      />

                      {props.eventQty > 0 && (
                        <span className="pro-count event-pro-count">
                          {" "}
                          {props.eventQty}{" "}
                        </span>
                      )}
                    </a>

                    <div
                      className={
                        "user-info card" + (props.showEventInfo ? " show " : "")
                      }
                    >
                      <ul>
                        {eventMenuoptions.map((item, i) =>
                          item.validationValue ? (
                            <>
                              {localStorage.getItem(
                                process.env.REACT_APP_ORG_NAME +
                                  "_" +
                                  item.validationValue
                              ) && (
                                <li key={"li3e" + i}>
                                  {item.link.indexOf(
                                    "/customer/event/manage"
                                  ) >= 0 && eventActionM !== "addEventCart" ? (
                                    <a
                                      href={item.link}
                                      key={"sh1_3" + i}
                                      className="link-menu"
                                    >
                                      {item.label}
                                    </a>
                                  ) : (
                                    <a
                                      href="#"
                                      key={"sh1" + i}
                                      onClick={() => {
                                        if (item.link) {
                                          if (item.action) {
                                            if (
                                              item.action === "checkEventStatus"
                                            ) {
                                              checkEventStatus(item);
                                            }
                                          } else {
                                            history.push(item.link);
                                          }
                                          props.forceHideEventIco();
                                          props.forceHideCart();
                                        } else {
                                          props.forceHideEventIco();
                                          props.forceHideCart();
                                        }
                                      }}
                                      className="link-menu"
                                    >
                                      {item.label}
                                    </a>
                                  )}
                                </li>
                              )}
                            </>
                          ) : (
                            <li key={"li3e" + i}>
                              <a
                                href="#"
                                key={"sh1" + i}
                                onClick={() => {
                                  if (item.link) {
                                    if (item.action) {
                                      if (item.action === "checkEventStatus") {
                                        checkEventStatus(item);
                                      }
                                    } else {
                                      history.push(item.link);
                                    }
                                    props.forceHideCart();
                                    props.forceHideEventIco();
                                  } else {
                                    props.forceHideCart();
                                    props.forceHideEventIco();
                                  }
                                }}
                                className="link-menu"
                              >
                                {item.label}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>

                    {/*mob*/}
                  </div>

                  <div className="header-action-icon-2">
                    <a href="#" onClick={props.toggleUserInfo}>
                      <img
                        className="svgInject"
                        style={{ height: 21, opacity: 0.65 }}
                        alt="ci"
                        src={getImagePath("/catalog/images/user.jpeg")}
                      />
                    </a>

                    <div
                      className={
                        "user-info card" + (props.showUserInfo ? " show " : "")
                      }
                    >
                      <ul>
                        {loginInfo.userId != null ? (
                          <>
                            {loggedMenuoptions.map((item, i) => (
                              <li>
                                <a
                                  key={"info" + i}
                                  href={item.link}
                                  className={i === 0 ? "text-bold" : ""}
                                >
                                  {item.label +
                                    (i === 0
                                      ? " " + loginInfo.token.firstname
                                      : "")}
                                </a>
                              </li>
                            ))}
                          </>
                        ) : (
                          <>
                            {loginMenuoptions.map((item, i) => (
                              <li>
                                <a key={"info" + i} href={item.link}>
                                  {item.label}
                                </a>
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="header-action-icon-2 ml--2">
                    {props.action !== "addEventList" && (
                      <a href="#" onClick={props.toggleCart}>
                        <img
                          className="svgInject"
                          alt="Shop Cart"
                          style={{ height: 21, opacity: 0.65 }}
                          src={getImagePath("/catalog/images/shopcart.jpeg")}
                        />
                        {props.shopCart?.products ? (
                          <>
                            {props.shopCart.products.length > 0 ? (
                              <span
                                className={
                                  "pro-count blue" +
                                  (props.action === "addEventCart"
                                    ? " event-pro-count"
                                    : "")
                                }
                              >
                                {" "}
                                {totalProd}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </a>
                    )}
                    <div
                      className={
                        props.showCart
                          ? "mobile-showCart visible"
                          : "mobile-showCart"
                      }
                    >
                      {props.shopCart?.products ? (
                        <>
                          {props.shopCart.products.length === 0 ? (
                            <div className="card">
                              <div className="card-header">
                                <div className="row ">
                                  <div className="col-10 flex items-left">
                                    <span className="h5-responsive">
                                      {getDefValues().cartLabel}
                                    </span>
                                  </div>
                                  <div className="col-2 flex items-right">
                                    <button
                                      type="button"
                                      name="close"
                                      onClick={props.forceHideCart}
                                      className="close"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">x</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row text-08 mt-30 mb-3 ml-2">
                                  <div className="col-12 flex items-center">
                                    <b>{getDefValues().noProductsOnCart} </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="card">
                              <div className="card-header">
                                <div className="row ">
                                  <div className="col-10 flex text-left items-left">
                                    <span className="h5-responsive">
                                      {getDefValues().cartLabel}
                                    </span>
                                  </div>
                                  <div className="col-2 flex text-right items-right">
                                    <button
                                      type="button"
                                      name="close"
                                      onClick={props.forceHideCart}
                                      className="close"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">x</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body mini-cart">
                                <div className="row text-08 mt-3 ml-2">
                                  <div className="col-8 flex items-left">
                                    {totalProd} {getDefValues().productsLabel}
                                  </div>

                                  <div className="col-4 text-right">
                                    {getDefValues().shopCartSubtotalLabel}
                                    <p className="text-bold text-price">
                                      {" "}
                                      {getCurrency()}
                                      {toFixed(props.shopCart.total)}{" "}
                                    </p>
                                  </div>
                                </div>

                                {props.shopCart?.products.map((item) => (
                                  <div className="row mt-2">
                                    <div className="col-4 flex items-left">
                                      <a
                                        href={
                                          (item.category
                                            ? "/" + item.category + "/"
                                            : "/productDetails/") +
                                          (item.productpath
                                            ? item.productpath
                                            : item.productname)
                                        }
                                      >
                                        <img
                                          alt={item.productname}
                                          src={getImagePath(item.image)}
                                          className="card-img-top"
                                        />
                                      </a>
                                    </div>
                                    <div className="col-6 text-left middle">
                                      <a
                                        href={
                                          (item.category
                                            ? "/" + item.category + "/"
                                            : "/productDetails/") +
                                          (item.productpath
                                            ? item.productpath
                                            : item.productname)
                                        }
                                      >
                                        {item.productname}
                                      </a>

                                      {!item.optionsselected ? (
                                        ""
                                      ) : item.optionsselected.length > 1 ? (
                                        <ul className="mt-1">
                                          {item.optionsselected.map((op) => (
                                            <li className="">
                                              <>
                                                {op.optionLabel ? (
                                                  op.optionLabel + ": "
                                                ) : (
                                                  <>
                                                    {props.options.map((ol) =>
                                                      ol.OPTIONID == op.optionid
                                                        ? ol.OPTIONNAME + ": "
                                                        : ""
                                                    )}
                                                  </>
                                                )}
                                                <span className="text-bold">
                                                  {op.optionvalue}
                                                </span>
                                              </>
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                      <div className="mb-2">
                                        {item.NEWPRICED ? (
                                          <>
                                            <span className="product-price-new-mini">
                                              {getCurrency() +
                                                " " +
                                                toFixed(item.NEWPRICED)}
                                            </span>
                                            <span className="product-price-old-mini">
                                              {" " +
                                                getCurrency() +
                                                " " +
                                                toFixed(item.price)}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            {item.NEWPRICEC1 ? (
                                              <>
                                                <b className="text-price">
                                                  {getCurrency() +
                                                    " " +
                                                    toFixed(item.price) +
                                                    " "}
                                                </b>
                                                <span
                                                  style={{ fontSize: "14px" }}
                                                  className="discount-span"
                                                >
                                                  {item.priceOld}
                                                </span>
                                              </>
                                            ) : (
                                              <b className="text-price">
                                                {getCurrency() +
                                                  toFixed(item.price)}
                                              </b>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <input
                                        type="text"
                                        className="mini-qty-val"
                                        name={"quantity_" + item.productsku}
                                        value={
                                          item.prequantity != null
                                            ? item.prequantity
                                            : item.quantity
                                        }
                                        onChange={props.updateCart}
                                      />
                                      {item.showUpdate ? (
                                        <button
                                          name={"update_" + item.productsku}
                                          onClick={props.updateCart}
                                          className="confirm-upd-mini"
                                        >
                                          {/*<i className="fas fa-sync-alt"></i>*/}
                                          Actualizar
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-2 flex items-left middle">
                                      <button
                                        name={"del_" + item.productsku}
                                        onClick={props.updateCart}
                                        className="icon-trash-mini text-muted ml-1"
                                      >
                                        <img
                                          src={getImagePath(
                                            "/catalog/images/ico_trash.svg"
                                          )}
                                          alt="delete-product-mobile"
                                          width="20px"
                                          height="20px"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="card-footer">
                                <div className="row">
                                  <div className="col-12 flex items-center">
                                    {/*mob*/}
                                    <button
                                      onClick={() => {
                                        history.push("/checkout/cart", {
                                          step: "formstep1",
                                        });
                                        props.toggleCart();
                                        props.forceHideCart();
                                      }}
                                      className="btn btn-sm btn-primary mr-2"
                                    >
                                      {getDefValues().completeOrder.toUpperCase()}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      props.isToggled
                        ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
                        : "mobile-header-active mobile-header-wrapper-style"
                    }
                  >
                    <div className="mobile-header-wrapper-inner">
                      <div className="mobile-header-top">
                        <div className="mobile-header-logo">
                          <Link to="/">
                            <span>
                              <img src={logo} alt="logo" />
                            </span>
                          </Link>
                        </div>
                        <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                          <button
                            className="close-style search-close"
                            onClick={props.toggleClick}
                          >
                            <i className="icon-top"></i>
                            <i className="icon-bottom"></i>
                          </button>
                        </div>
                      </div>
                      <div className="mobile-header-content-area">
                        <div className="mobile-social-menu">
                          <nav>
                            <ul className="mobile-menu">
                              {mobileMenuoptions.map((item, i) =>
                                item.type === "TOP" ? (
                                  (item.needlogin === "YES" &&
                                    loginInfo.userId != null) ||
                                  item.needlogin === "NO" ? (
                                    <li>
                                      <a
                                        onClick={props.toggleClick}
                                        href={item.link}
                                      >
                                        {item.image ? (
                                          <img
                                            src={getImagePath(item.image)}
                                            alt="logo"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {item.label}
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              )}
                              {menu.map((l1) =>
                                l1.MENULEVEL == "1" ? (
                                  <li className="category-mobile-menu">
                                    <div
                                      name={l1.MENUID}
                                      onClick={
                                        l1.ISPARENT
                                          ? props.toggleMenuTemplate
                                          : props.emtpyFunction
                                      }
                                    >
                                      <a
                                        name={l1.MENUID}
                                        href={
                                          l1.MENUURL
                                            ? l1.ISPARENT
                                              ? "#"
                                              : l1.MENUURL
                                            : "#"
                                        }
                                      >
                                        <span
                                          className={l1.OPEN && "text-bold"}
                                        >
                                          {l1.MENULABEL}
                                        </span>
                                      </a>
                                      {l1.ISPARENT ? (
                                        <i
                                          name={l1.MENUID}
                                          className={
                                            "fi-rs-angle-" +
                                            (l1.OPEN ? "up" : "down")
                                          }
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/*level 2*/}
                                    {l1.ISPARENT && l1.OPEN && (
                                      <ul className="subcategory-mobile-menu2">
                                        {l1.MENUURL && l1.MENUURL !== "#" ? (
                                          <li>
                                            <div name={l1.MENUID}>
                                              <a
                                                name={l1.MENUID}
                                                href={l1.MENUURL}
                                              >
                                                <span>
                                                  {" "}
                                                  {getDefValues().allLabel +
                                                    " " +
                                                    l1.MENULABEL}{" "}
                                                </span>
                                              </a>
                                            </div>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {menu.map((l2) =>
                                          l2.MENULEVEL == "2" &&
                                          l1.MENUID == l2.PARENTMENUID ? (
                                            <li>
                                              <div
                                                name={l2.MENUID}
                                                onClick={
                                                  l2.ISPARENT
                                                    ? props.toggleMenuTemplate
                                                    : props.emtpyFunction
                                                }
                                              >
                                                <a
                                                  name={l2.MENUID}
                                                  href={
                                                    l2.MENUURL
                                                      ? l2.ISPARENT
                                                        ? "#"
                                                        : l2.MENUURL
                                                      : "#"
                                                  }
                                                >
                                                  {l2.PARENTMENUID === 6 &&
                                                  l2.configimage !== "" ? (
                                                    <div>
                                                      <img
                                                        src={getImagePath(
                                                          l2.configimage
                                                        )}
                                                        alt={l2.MENULABEL}
                                                      />
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <span
                                                    className={
                                                      l2.OPEN && "text-bold"
                                                    }
                                                  >
                                                    {l2.MENULABEL}
                                                  </span>
                                                </a>
                                                {l2.ISPARENT ? (
                                                  <i
                                                    name={l2.MENUID}
                                                    className={
                                                      "fi-rs-angle-" +
                                                      (l2.OPEN ? "up" : "down")
                                                    }
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              {/*level 3*/}
                                              {l2.ISPARENT && l2.OPEN && (
                                                <ul className="subcategory-mobile-menu3">
                                                  {l2.MENUURL &&
                                                  l2.MENUURL !== "#" ? (
                                                    <li>
                                                      <div name={l2.MENUID}>
                                                        <a
                                                          name={l2.MENUID}
                                                          href={l2.MENUURL}
                                                        >
                                                          <span>
                                                            {" "}
                                                            {getDefValues()
                                                              .allLabel +
                                                              " " +
                                                              l2.MENULABEL}{" "}
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {menu.map((l3) =>
                                                    l3.MENULEVEL == "3" &&
                                                    l2.MENUID ==
                                                      l3.PARENTMENUID ? (
                                                      <li name={l3.MENUID}>
                                                        <div
                                                          onClick={
                                                            l3.ISPARENT
                                                              ? props.toggleMenuTemplate
                                                              : props.emtpyFunction
                                                          }
                                                          name={l3.MENUID}
                                                        >
                                                          <a
                                                            href={
                                                              l3.MENUURL
                                                                ? l3.ISPARENT
                                                                  ? "#"
                                                                  : l3.MENUURL
                                                                : "#"
                                                            }
                                                          >
                                                            <span
                                                              className={
                                                                l3.OPEN &&
                                                                "text-bold"
                                                              }
                                                            >
                                                              {l3.MENULABEL}
                                                            </span>
                                                          </a>
                                                          {l3.ISPARENT ? (
                                                            <i
                                                              name={l3.MENUID}
                                                              className={
                                                                "fi-rs-angle-" +
                                                                (l3.OPEN
                                                                  ? "up"
                                                                  : "down")
                                                              }
                                                            ></i>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                        {/*level 4*/}
                                                        {l3.ISPARENT &&
                                                          l3.OPEN && (
                                                            <ul className="subcategory-mobile-menu4">
                                                              {l3.MENUURL &&
                                                              l3.MENUURL !==
                                                                "#" ? (
                                                                <li>
                                                                  <div
                                                                    name={
                                                                      l3.MENUID
                                                                    }
                                                                  >
                                                                    <a
                                                                      name={
                                                                        l3.MENUID
                                                                      }
                                                                      href={
                                                                        l3.MENUURL
                                                                      }
                                                                    >
                                                                      <span>
                                                                        {" "}
                                                                        {getDefValues()
                                                                          .allLabel +
                                                                          " " +
                                                                          l3.MENULABEL}{" "}
                                                                      </span>
                                                                    </a>
                                                                  </div>
                                                                </li>
                                                              ) : (
                                                                ""
                                                              )}
                                                              {menu.map((l4) =>
                                                                l4.MENULEVEL ==
                                                                  "4" &&
                                                                l3.MENUID ==
                                                                  l4.PARENTMENUID ? (
                                                                  <li
                                                                    name={
                                                                      l4.MENUID
                                                                    }
                                                                  >
                                                                    <div
                                                                      name={
                                                                        l4.MENUID
                                                                      }
                                                                    >
                                                                      <a
                                                                        href={
                                                                          l4.MENUURL
                                                                            ? l4.MENUURL
                                                                            : "#"
                                                                        }
                                                                      >
                                                                        <span
                                                                          className={
                                                                            l4.OPEN &&
                                                                            "text-bold"
                                                                          }
                                                                        >
                                                                          {
                                                                            l4.MENULABEL
                                                                          }
                                                                        </span>
                                                                      </a>
                                                                      {l4.ISPARENT ? (
                                                                        <i
                                                                          onClick={
                                                                            props.toggleMenuTemplate
                                                                          }
                                                                          name={
                                                                            l4.MENUID
                                                                          }
                                                                          className={
                                                                            "fi-rs-angle-" +
                                                                            (l4.OPEN
                                                                              ? "up"
                                                                              : "down")
                                                                          }
                                                                        ></i>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </div>
                                                                    {/*level 4*/}
                                                                    {l4.ISPARENT &&
                                                                    l4.OPEN ? (
                                                                      <></>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </li>
                                                                ) : (
                                                                  ""
                                                                )
                                                              )}
                                                            </ul>
                                                          )}
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            </li>
                                          ) : (
                                            ""
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                              {mobileMenuoptions.map((item, i) =>
                                item.type === "BOTTOM" ? (
                                  (item.needlogin === "YES" &&
                                    loginInfo.userId != null) ||
                                  item.needlogin === "NO" ? (
                                    <li>
                                      <a
                                        onClick={props.toggleClick}
                                        href={item.link}
                                      >
                                        {item.image ? (
                                          <img
                                            src={getImagePath(item.image)}
                                            alt="logo"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {item.label}
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              )}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.action === "" ? (
              <div className="hide-web">
                <div className="search-style-2">
                  <form>
                    <input
                      type="text"
                      name="search_input"
                      value={props.searchinput}
                      onClick={props.openSearcher}
                      placeholder={getDefValues().searchLabel}
                    />
                  </form>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
