/*****components***/
import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
/*custom components*/
import PageLoading from "../../components/PageLoading";
import LoginG from "../login/loginG";
import Form from "../../components/Form";
import { toast } from "mdbreact";
import {
  loginSN,
  setCustomers,
  getStoresInfo,
  callCustomApi,
  login,
  changeHandler,
  getRenderObj,
  getLoginInfo,
  preloadForm,
  getLanguage,
  getDefValues,
  callApi,
} from "../../common/functions";

/*functions*/

const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerFormData: {},
      registerFormConfig: {},
      successFormData: {},
      successFormConfig: {},
      successForm: false,
      rememberme: true,
      tryOut: false,
      goHome: false,
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      var login = getLoginInfo();
      if (login.userId != null) {
        this.setState({ isLoading: false, goHome: true });
      } else {
        this.props.hideCart();
        this.setState({ isLoading: true });
        let registerForm = await callApi(
          "layaoutManager",
          "getLayoutTemplateByName",
          {
            layoutFile: "registerForm_" + getLanguage(),
            foldername: foldername + "private/",
          }
        );
        let rendegObj = await getRenderObj(
          registerForm.template[0],
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        this.setState({
          registerFormData: rendegObj.curdata.data,
          registerFormConfig: rendegObj.curdata.config,
          isLoading: false,
        });
        await preloadForm(this.state.registerFormData);

        let successForm = await callApi(
          "layaoutManager",
          "getLayoutTemplateByName",
          {
            layoutFile: "accountCreatedForm_" + getLanguage(),
            foldername: foldername + "private/",
          }
        );
        rendegObj = await getRenderObj(
          successForm.template[0],
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );

        this.setState({
          successFormData: rendegObj.curdata.data,
          successFormConfig: rendegObj.curdata.config,
        });
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
      this.setState({ isLoading: false });
    }
  };

  changeHandler = async (event) => {
    if (event.target.name === "rememberme") {
      this.setState({
        rememberme: !this.state.rememberme,
      });
    } else {
      //let resp = await validateForm(this.state.registerFormData, toast, event);
      //this.setState({ registerFormData: resp.formData });

      var formData = changeHandler(event, this.state.registerFormData);
      this.setState({ registerFormData: formData });

      if (event.key === "Enter") {
        this.setCustomers(event);
      }
    }
  };
  responseFacebook = (response) => {
    console.log(response);
  };

  callBackSN = async (res) => {
    try {
      this.setState({ isLoading: true });
      var status = await loginSN(res, toast, this.state.rememberme);
      if (status) {
        this.props.setLoginInfo(res);
        this.setState({ goHome: true });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }

    this.setState({ tryOut: true });
  };

  callBackSNError = (res) => {
    console.log("Login failed: res:", res);
  };

  setCustomers = async (event) => {
    try {
      this.setState({ isLoading: true });

      let storeid = await getStoresInfo().selectedStoreId;
      const registerFormData = this.state.registerFormData;
      registerFormData["storeid"] = { value: storeid, name: "storeid" };

      var resp = await setCustomers(this.state.registerFormData, toast);
      let subscribe = false;
      this.setState({ successForm: resp.success });
      if (resp.success) {
        let resp = await login(
          this.state.registerFormData,
          this.state.rememberme,
          toast
        );
        try {
          if (this.state.registerFormData.suscribe.value.length > 0) {
            subscribe = true;
            await callCustomApi("suscribe", {
              email: this.state.registerFormData.email.value,
            });
          }
        } catch (Exc) {
          console.log(">>> NEWS ERR", Exc);
        }

        if (resp.success) {
          console.log("resp", resp);

          if (resp.email && resp.email !== "") {
            var objBloomreach_login = {
              email: resp.email,
              firstname: resp.firstname || "",
              lastname: resp.lastname || "",
              lastname2: resp.lastname2 || "",
              opt_in_ofertas: subscribe,
              opt_in_tyc: true,
              origen: "web",
              phone: resp.phone || "",
              DNI: resp.code || "",

              //aqui pueden añadir los otros atributos que puedan obtener al login
            };
            var objBloomreach_setCustomer = {
              registered: resp.code || "", //Este atributo es OBLIGATORIO, Aqui va la identificacion si se tiene si no va vacia NO ES NULL DEBE IR VACIA si va vacio se debe incluir obligatorio el de email_id que es el soft_id
              email_id: resp.email, //Si se tiene el valor se debe añadir
            };

            console.log("BR new");

            window.exponea.track("register", objBloomreach_login);
            window.exponea.update(objBloomreach_login);
            window.exponea.identify(objBloomreach_setCustomer);

            localStorage.setItem(
              process.env.REACT_APP_ORG_NAME + "exponea_registered",
              "YES"
            );
          } else {
            console.log("ALERT!! no email");
          }

          this.props.setLoginInfo(event);
          this.setState({ isLoading: false });
          //this.setState({ goHome: true })
          //window.location.reload();
        }
      } else {
        this.setState({ isLoading: false });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.goHome ? <Redirect to={{ pathname: "/" }} /> : ""}
        <PageLoading isLoading={this.state.isLoading} />
        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content container-page custom-container container-fluid ">
            <div
              className={
                "card  mt-30 " + (!this.state.isLoading ? "card-user-info" : "")
              }
            >
              <div className="row mt-30 mb-50 ">
                {this.state.successForm ? (
                  <div
                    className="row"
                    style={{
                      height: this.props.windowHeight - 228 + "px",
                      width: "100%",
                    }}
                  >
                    <div className="mobile-col col-1  col-lg-2"></div>
                    <div className="mobile-col col-11  col-lg-9 mt-3">
                      <Form
                        formData={this.state.successFormData}
                        formConfig={this.state.successFormConfig}
                      />
                    </div>
                    <div className="mobile-col col-12 flex items-center">
                      <Link to="/">
                        <button className="btn btn-primary text-bold">
                          {" "}
                          {getDefValues().gotoStart}{" "}
                        </button>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      "mobile-col col-12 col-sm-12 col-md-9 col-lg-7" +
                      (this.props.windowWidth > 992
                        ? " ml-5 border-right"
                        : " ml-1")
                    }
                  >
                    <div className="row mt-30 mb-30">
                      {!this.state.isLoading ? (
                        <div className="col-12 col-sm-12 col-md-9 col-lg-9 flex items-center">
                          <p className="mobile-col col-12 col-sm-12 col-md-5 col-lg-5 social-login flex items-center">
                            <LoginG
                              customLabel={"Registrate con Google"}
                              setExecuting={() => {
                                this.setState({
                                  isLoading: !this.state.isLoading,
                                });
                              }}
                            />
                            {/** 
								<GoogleLogin
									clientId={process.env.REACT_APP_API_KEY_GOOGLE}
									buttonText="Google"
									className="google"
									onSuccess={this.callBackSN}
									onFailure={this.callBackSNError}
									disabled={false}
								/> 
								<FacebookLogin
									cssClass="ml-2 facebook"
									appId={process.env.REACT_APP_API_KEY_LOGIN_FB}
									autoLoad={false}
									fields="name,email,picture,last_name,first_name"
									scope="public_profile, email"
									callback={this.callBackSN}
									onFailure={this.callBackSNError}
									textButton="Facebook"
									icon="fa-facebook" />
							*/}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mt-30">
                      <Form
                        customClass={"userForm"}
                        formData={this.state.registerFormData}
                        changeHandler={this.changeHandler}
                        formConfig={this.state.registerFormConfig}
                      />
                    </div>
                    {!this.state.isLoading ? (
                      <div className="flex items-left">
                        <div className="col-12 col-lg-9 col-md-12 col-sm-12">
                          <button
                            onClick={this.setCustomers}
                            className="accout-btn btn btn-primary text-bold btn-block"
                          >
                            {" "}
                            {getDefValues().registerLabel}{" "}
                          </button>
                          <div className="row" style={{ margin: "10px 0 0 0" }}>
                            <div className="col-6 flex items-left">
                              <label>
                                <input
                                  name="rememberme"
                                  className="myinput"
                                  checked={this.state.rememberme}
                                  value="YES"
                                  onChange={this.changeHandler}
                                  type="checkbox"
                                />{" "}
                                {getDefValues().remembermeLabel}{" "}
                              </label>
                            </div>
                            <div className="col-6 flex items-right text-underline">
                              <Link to="/customer/account/forgotpassword/">
                                {" "}
                                {getDefValues().forgotPasswordLabel}{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
