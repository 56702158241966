/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "mdbreact";

import {
  getDefValues,
  callApi,
  getImagePath,
  getLoginInfo,
} from "../../common/functions";
import PageLoading from "../PageLoading";
const DragUploader = (props) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  //const [status, setStatus] = useState("");
  const [imagePath, setImagePath] = useState(props.value);
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("filename", acceptedFiles[0], acceptedFiles[0].name);
      formData.append(
        "foldername",
        "customer_images/customer_" + getLoginInfo().userId + "/"
      );
      formData.append("maxsize", 5120);
      var resp = await callApi("s3", "uploadImage", formData);
      if (resp.success) {
        setImagePath(resp.message.Location);
        setIsLoading(false);
        var event = {
          target: {
            name: props.fieldName,
            value: resp.message.Location,
          },
        };
        props.changeHandler(event);
      } else {
        toast.error(resp.message, {
          closeButton: true,
          position: "bottom-right",
        });
        setIsLoading(false);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, maxFiles: 1 });

  /*
	 const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
*/

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <>
      <PageLoading isLoading={isLoading} />
      {imagePath === "" ? (
        <div className="col-12">
          <section className="dropzone-container">
            <div {...getRootProps({ style })}>
              <input id="fileElem" {...getInputProps()} />
              <p
                className={
                  "text-" + (props.btncolor ? props.btncolor : "primary")
                }
              >
                {" "}
                {props.value !== ""
                  ? getDefValues().uploadUpdateMessage
                  : getDefValues().uploadMessage}{" "}
              </p>
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {imagePath !== "" ? (
        <>
          <div className="col-12 text-center mt-2 mb-2">
            <img src={getImagePath(imagePath)} style={{ maxWidth: "100%" }} />
          </div>
          {!props.lockform ? (
            <div className="col-12 text-right mb-2">
              <button
                className={
                  "btn btn-outline-" +
                  (props.btncolor ? props.btncolor : "primary") +
                  " btn-sm"
                }
                type="button"
                onClick={() => {
                  setImagePath("");
                  var el = window.document.getElementById("fileElem");
                  if (el) {
                    el.click();
                  } else {
                    //console.log("no hay ")
                  }
                }}
              >
                <i className="fas fa-trash" />{" "}
                {getDefValues().changeImageButton2}
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DragUploader;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  fontSize: 14,
  paddingTop: "26px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#d50032",
};

const rejectStyle = {
  borderColor: "#ffbc34",
};
