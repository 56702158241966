import React from "react";
import CategoryBlog from "react-slick";
import {validateNumber, findValueById,getLanguage,getImagePath, getDefValues,getCoordinatesStore } from '../../common/functions';

const loadCategoryBlog = props => {
	if (props.categoryBlogData){
	 
	  return ( 
			<>
   			<h1 className=" header-center" >{props.categoryBlogData.title?props.categoryBlogData.title:""} </h1>
				<div className="grid-categories">
					{props.categoryBlogContent.map(field => (
						
						
						<div className="pagebuilder-column-group" style={{"display": "flex"}}>
							<div className="pagebuilder-column">
								{field.title?
									<h2>{field.title}</h2>
								:""}
								{field.content?
									<>
										<div>
											<hr/>
										</div>
										<h3 ></h3>
							 			<div>
							 				<p>
							 					{field.content}
											</p>
										</div>
									</>
								:""}
								{field.image?
									<figure data-content-type="image" data-appearance="full-width" data-element="main" data-pb-style="JW0X0VK">
										<img className="pagebuilder-mobile-only" src={getImagePath(field.image)} alt="" title="" />
									</figure>
								:""}
							</div>
						</div>
					 ))
 					} 
 				</div>
			</>	 
	  );
	}else{
		return "NO CONFIG"
	}
};
export default loadCategoryBlog;
				 