import React from "react";
import {
  getSortMethods,
  convertNumber,
  getRowsPaginationValues,
  getImagePath,
} from "../../common/functions";
import { find } from "../../common/functions";
import {useHistory} from "react-router-dom";
const windowWidthDef = window.innerWidth;

const addRow = (props, ini, numCols) => {
  let row = [];
  let keys = Object.keys(props.cardData);

  var size = ini + numCols;
  if (props.cardConfig.maxrows) {
    size = props.cardConfig.maxrows;
  }

  for (let key = ini; key < size; key++) {
    if (key < Object.keys(props.cardData).length) {
      var field = props.cardData[keys[key]];

      var col = props.cardConfig.colsize + " ";
      var colsm, colmd, collg;

      if (props.cardConfig.colsizesm) {
        colsm = " " + props.cardConfig.colsizesm + " ";
      }
      if (props.cardConfig.colsizemd) {
        colmd = " " + props.cardConfig.colsizemd + " ";
      }
      if (props.cardConfig.colsizelg) {
        collg = " " + props.cardConfig.colsizelg + " ";
      }

      if (props.cardConfig.content) {
        var pos = find(
          props.cardConfig.content,
          "" + field.body.props.ObjectName,
          "children"
        );

        if (pos >= 0) {
          if (props.cardConfig.content[pos].size) {
            col = props.cardConfig.content[pos].size + " ";
          }
          if (props.cardConfig.content[pos].sizesm) {
            colsm = props.cardConfig.content[pos].sizesm + " ";
          }
          if (props.cardConfig.content[pos].sizemd) {
            colmd = props.cardConfig.content[pos].sizemd + " ";
          }
          if (props.cardConfig.content[pos].sizelg) {
            collg = props.cardConfig.content[pos].sizelg + " ";
          }
        }
      }

      var style = {};
      if (field.style) {
        style = field.style;
      }

      var show = true;

      if (field.body.props.config) {
        show = false;
        if (windowWidthDef <= 799) {
          //mobile
          if (
            field.body.props.config.version === "mobile" ||
            field.body.props.config.version === "both"
          ) {
            show = true;
          }
        } else {
          show = true;
          if (field.body.props.config.version === "mobile") {
            show = false;
          }
        }
        if (field.body.props.config.boxwidth) {
          if (field.body.props.config.boxwidth === "1_3") {
            //col   = ""
            //colsm =
            colmd = "col-md-4 ";
            collg = "col-lg-4 ";
          } else if (field.body.props.config.boxwidth === "2_3") {
            //col   = ""
            //colsm =
            colmd = "col-md-8 ";
            collg = "col-lg-8 ";
          } else if (field.body.props.config.boxwidth === "2_3") {
            //col   = ""
            //colsm =
            colmd = "col-md-6 ";
            collg = "col-lg-6 ";
          }
        }
        if (field.body.props.config.margintop) {
          if (convertNumber(field.body.props.config.margintop)) {
            style["marginTop"] = parseInt(field.body.props.config.margintop);
          }
        }
      }

      /*
			if (field.body.props.imageConfig  ){
				
				show= false;
				if (windowWidthDef<=799){
				 	//mobile
			 		if (field.body.props.imageConfig.version=="mobile" || field.body.props.imageConfig.version=="both"){
			 			show= true
			 		} 
				}else{
					show= true
					if (field.body.props.imageConfig.version=="mobile"){
				 		show= false
					} 
				} 
				if (field.body.props.imageConfig.boxwidth){ 
					if (field.body.props.imageConfig.boxwidth=="1_3"){
						//col   = "" 
						//colsm = 
						colmd = "col-md-4 "
						collg = "col-lg-4 "						 
					 
					}else if (field.body.props.imageConfig.boxwidth=="2_3"){
						//col   = "" 
						//colsm = 
						colmd = "col-md-8 "
						collg = "col-lg-8 "						 
					 
					}else if (field.body.props.imageConfig.boxwidth=="2_3"){
						//col   = "" 
						//colsm = 
						colmd = "col-md-6 "
						collg = "col-lg-6 "				 
					} 
				}
				if (field.body.props.imageConfig.margintop){
					console.log(field.body.props.imageConfig , convertNumber(field.body.props.imageConfig.margintop))
					if ( convertNumber(field.body.props.imageConfig.margintop) ){
						style["marginTop"] = parseInt(field.body.props.imageConfig.margintop)
					}
				}
			}
			if (field.body.props.buttonConfig  ){
			
				show= false;
				if (windowWidthDef<=799){
				 	//mobile
			 		if (field.body.props.buttonConfig.version=="mobile" || field.body.props.buttonConfig.version=="both"){
			 			show= true
			 		} 
				}else{
					show= true
					if (field.body.props.buttonConfig.version=="mobile"){
				 		show= false
					} 
				} 
				if (field.body.props.buttonConfig.boxwidth){ 
					if (field.body.props.buttonConfig.boxwidth=="1_3"){
						//col   = "" 
						//colsm = 
						colmd = "col-md-4 "
						collg = "col-lg-4 "						 
					 
					}else if (field.body.props.buttonConfig.boxwidth=="2_3"){
						//col   = "" 
						//colsm = 
						colmd = "col-md-8 "
						collg = "col-lg-8 "						 
					 
					}else if (field.body.props.buttonConfig.boxwidth=="2_3"){
						//col   = "" 
						//colsm = 
						colmd = "col-md-6 "
						collg = "col-lg-6 "				 
					} 
				}
				if (field.body.props.buttonConfig.margintop){
					if ( convertNumber(field.body.props.buttonConfig.margintop) ){
												console.log("config", convertNumber(field.body.props.buttonConfig.margintop) )
						style["marginTop"] = parseInt(field.body.props.buttonConfig.margintop)
					}
				}
			}
			*/
      if (show) {
        row.push(
          <div
            key={"card_div1" + key}
            className={col + colsm + colmd + collg + " mobile-col d-flex"}
            style={style}
          >
            <div
              key={"card_div2" + key}
              className={
                (field.cardclass ? field.cardclass : "") + " card flex-fill"
              }
            >
              {field.imagesrc ? (
                <img
                  key={"card_img1" + key}
                  alt={field.imagealt}
                  src={getImagePath(field.imagesrc)}
                  className="card-img-top"
                />
              ) : (
                ""
              )}
              {field.header ? (
                <div key={"card_div3" + key} className="card-header">
                  <h5 key={"card_h51" + key} className="card-title mb-0">
                    {field.header}{" "}
                  </h5>
                </div>
              ) : (
                ""
              )}

              {field.body.length > 0 ? (
                <div key={"card_div5" + key} className="card-body">
                  {field.body.map((item, index) => (
                    <>
                      {item.type === "text" ? (
                        <p key={"p11" + index} className="card-text">
                          {" "}
                          {item.text}
                        </p>
                      ) : (
                        ""
                      )}
                      {item.type === "link" ? (
                        <a
                          key={"a11" + index}
                          className="card-link"
                          target={item.target ? item.target : "_self"}
                          href={item.url}
                        >
                          {item.text}
                        </a>
                      ) : (
                        ""
                      )}
                      {item.type === "button" ? (
                        <a
                          key={"a12" + index}
                          className={
                            "btn " +
                            (item.buttonclass
                              ? item.buttonclass
                              : "btn-primary")
                          }
                          target={item.target ? item.target : "_self"}
                          href={item.url}
                        >
                          {item.text}
                        </a>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
              ) : (
                <>{field.body}</>
              )}
              {field.list ? (
                <ul
                  key={"card_ul1" + key}
                  className="list-group list-group-flush"
                >
                  {field.list.map((item, index) => (
                    <li key={"li1" + index} className="list-group-item">
                      {item.type === "text" ? (
                        <p key={"p12" + index} className="card-text">
                          {" "}
                          {item.text}
                        </p>
                      ) : (
                        ""
                      )}
                      {item.type === "link" ? (
                        <a
                          key={"a12" + index}
                          className="card-link"
                          target={item.target ? item.target : "_self"}
                          href={item.url}
                        >
                          {item.text}
                        </a>
                      ) : (
                        ""
                      )}
                      {item.type === "button" ? (
                        <a
                          key={"a22" + index}
                          className={
                            "btn " +
                            (item.buttonclass
                              ? item.buttonclass
                              : "btn-primary")
                          }
                          target={item.target ? item.target : "_self"}
                          href={item.url}
                        >
                          {item.text}
                        </a>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }
    }
  }
  return row;
};

const LoadCard = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    if (props.cardHistoryControl) {
      if (window.location.href.indexOf("#page=") > 0) {
        let data = window.location.href.split("#page=");

        props.cardHistoryControl(data[1]);
      } else {
        props.cardHistoryControl(1);
      }
    }
  }, [props]);

  if (props.cardData && props.cardData.length > 0) {
    const cardStructure = [];

    var from = props.cardConfig.pagesettings
      ? (props.cardConfig.pagesettings.currentPage - 1) *
        props.cardConfig.pagesettings.itemsperpage
      : 0;
    var numCols = props.cardConfig.pagesettings
      ? props.cardConfig.pagesettings.itemsperpage
      : Object.keys(props.cardData).length;

    if (props.cardConfig.mobile) {
      numCols = props.cardConfig.limit;
    }
    var row = addRow(props, from, numCols);
    cardStructure.push(
      <div key={"row_" + 0} className="row">
        {row}
      </div>
    );
    if (props.cardConfig.managepagination) {
      from = 0;
      var to = props.cardConfig.pagesettings.pages;
      if (props.cardConfig.pagesettings.pages > 10) {
        if (props.cardConfig.pagesettings.currentPage > 5) {
          from = props.cardConfig.pagesettings.currentPage - 5;
          to = props.cardConfig.pagesettings.currentPage + 5;
        } else {
          to = 10;
        }
      }
      if (to > props.cardConfig.pagesettings.pages) {
        var fix = to - props.cardConfig.pagesettings.pages;
        from = from - fix;
        to = to - fix;
      }
    }
    return (
      <>
        {props.cardConfig.managesort ? (
          <div className="card items-right mt-3">
            <div className="row">
              <div
                className={
                  "mobile-col " +
                  (props.cardConfig.pagewidth
                    ? props.cardConfig.pagewidth
                    : "col-md-12 col-lg-12 col-xl-12")
                }
              >
                <select
                  id="itemsperpage"
                  name="itemsperpage"
                  className="pagination-select"
                  defaultValue={props.cardConfig.sortmethod}
                  onChange={props.cardConfig.managepagination}
                >
                  {getSortMethods().map((item, index) => (
                    <option key={"pag_i1" + item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="card items-center mt-1">
          <div
            style={{
              background: props.cardConfig.background
                ? props.cardConfig.background
                : "transparent",
            }}
            className={
              props.cardConfig.pagewidth
                ? props.cardConfig.pagewidth
                : "col-md-12 col-lg-12 col-xl-12"
            }
          >
            {cardStructure}
          </div>
        </div>

        {props.cardConfig.managepagination ? (
          <>
            <div
              className={
                props.cardConfig.pagewidth
                  ? props.cardConfig.pagewidth
                  : "col-md-12 col-lg-12 col-xl-12"
              }
            >
              <div>
                <ul className="pagination-area justify-content-center">
                  {props.cardConfig.pagesettings.paginationGroup.length <=
                  0 ? null : (
                    <li
                      name="doubleprev"
                      id={
                        (props.cardConfig.container
                          ? props.cardConfig.container + "__"
                          : "") + "doubleprev"
                      }
                      onClick={() => {
                        let newPath =
                          window.location.pathname +
                          "#page=" +
                          (props.cardConfig.pagesettings.currentPage - 3);
                        history.push(newPath);
                        props.cardConfig.managepagination({
                          target: {
                            name:
                              "select" +
                              (props.cardConfig.pagesettings.currentPage - 3),
                          },
                        });
                      }}
                      className="icon-item"
                    >
                      {props.cardConfig.pagesettings.currentPage < 4 ? null : (
                        <a
                          href={
                            "#page=" +
                            (props.cardConfig.pagesettings.currentPage - 3)
                          }
                          name="doubleprev"
                          id={
                            (props.cardConfig.container
                              ? props.cardConfig.container + "__"
                              : "") + "doubleprev"
                          }
                          className="icon-link"
                        >
                          <i
                            name="doubleprev"
                            id={
                              (props.cardConfig.container
                                ? props.cardConfig.container + "__"
                                : "") + "doubleprev"
                            }
                            className="fi-rs-angle-double-small-left"
                          ></i>
                        </a>
                      )}
                    </li>
                  )}
                  {props.cardConfig.pagesettings.paginationGroup.length <=
                  0 ? null : (
                    <li
                      name="prev"
                      id={
                        (props.cardConfig.container
                          ? props.cardConfig.container + "__"
                          : "") + "prev"
                      }
                      onClick={() => {
                        let newPath =
                          window.location.pathname +
                          "#page=" +
                          (props.cardConfig.pagesettings.currentPage - 1);
                        history.push(newPath);
                        props.cardConfig.managepagination({
                          target: {
                            name:
                              "select" +
                              (props.cardConfig.pagesettings.currentPage - 1),
                          },
                        });
                      }}
                      className="icon-item"
                    >
                      {props.cardConfig.pagesettings.currentPage == 1 ? null : (
                        <a
                          href={
                            "#page=" +
                            (props.cardConfig.pagesettings.currentPage - 1)
                          }
                          name="prev"
                          id={
                            (props.cardConfig.container
                              ? props.cardConfig.container + "__"
                              : "") + "prev"
                          }
                          className="icon-link"
                        >
                          <i
                            name="prev"
                            id={
                              (props.cardConfig.container
                                ? props.cardConfig.container + "__"
                                : "") + "prev"
                            }
                            className="fi-rs-angle-small-left"
                          ></i>
                        </a>
                      )}
                    </li>
                  )}
                  {props.cardConfig.pagesettings.paginationGroup.length <=
                  10 ? (
                    <>
                      {props.cardConfig.pagesettings.paginationGroup.map(
                        (item, index) => {
                          return (
                            <li
                              name={"select" + item}
                              id={
                                (props.cardConfig.container
                                  ? props.cardConfig.container + "__"
                                  : "") +
                                "select" +
                                item
                              }
                              onClick={props.cardConfig.managepagination}
                              key={"li1" + index}
                              className={
                                props.cardConfig.pagesettings.currentPage ==
                                item
                                  ? "page-item active"
                                  : "page-item inactive"
                              }
                            >
                              <a
                                href={"#page=" + item}
                                key={"a1" + index}
                                name={"select" + item}
                                id={
                                  (props.cardConfig.container
                                    ? props.cardConfig.container + "__"
                                    : "") +
                                  "select" +
                                  item
                                }
                                onClick={() => {
                                  let newPath =
                                    window.location.pathname + "#page=" + item;
                                  history.push(newPath);
                                  props.cardConfig.managepagination({
                                    target: { name: "select" + item },
                                  });
                                }}
                                className="page-link"
                              >
                                {item}
                              </a>
                            </li>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>
                      {props.cardConfig.pagesettings.paginationGroup.map(
                        (item, index) => {
                          return (
                            <>
                              {index >= from && index < to ? (
                                <li
                                  name={"select" + item}
                                  id={
                                    (props.cardConfig.container
                                      ? props.cardConfig.container + "__"
                                      : "") +
                                    "select" +
                                    item
                                  }
                                  onClick={props.cardConfig.managepagination}
                                  key={"li2" + index}
                                  className={
                                    props.cardConfig.pagesettings.currentPage ==
                                    item
                                      ? "page-item active"
                                      : "page-item inactive"
                                  }
                                >
                                  <a
                                    href={"#page=" + item}
                                    key={"a1" + index}
                                    name={"select" + item}
                                    id={
                                      (props.cardConfig.container
                                        ? props.cardConfig.container + "__"
                                        : "") +
                                      "select" +
                                      item
                                    }
                                    onClick={() => {
                                      let newPath =
                                        window.location.pathname +
                                        "#page=" +
                                        item;
                                      history.push(newPath);
                                      props.cardConfig.managepagination({
                                        target: { name: "select" + item },
                                      });
                                    }}
                                    className="page-link"
                                  >
                                    {item}
                                  </a>
                                </li>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      )}
                    </>
                  )}
                  {props.cardConfig.pagesettings.paginationGroup.length <=
                  0 ? null : (
                    <li
                      name="next"
                      id={
                        (props.cardConfig.container
                          ? props.cardConfig.container + "__"
                          : "") + "next"
                      }
                      onClick={() => {
                        let newPath =
                          window.location.pathname +
                          "#page=" +
                          (props.cardConfig.pagesettings.currentPage + 1);
                        history.push(newPath);
                        props.cardConfig.managepagination({
                          target: {
                            name:
                              "select" +
                              (props.cardConfig.pagesettings.currentPage + 1),
                          },
                        });
                      }}
                      className="icon-item"
                    >
                      {props.cardConfig.pagesettings.currentPage >=
                      props.cardConfig.pagesettings.pages ? null : (
                        <a
                          href={
                            "#page=" +
                            (props.cardConfig.pagesettings.currentPage + 1)
                          }
                          name="next"
                          id={
                            (props.cardConfig.container
                              ? props.cardConfig.container + "__"
                              : "") + "next"
                          }
                          className="icon-link"
                        >
                          <i
                            name="next"
                            id={
                              (props.cardConfig.container
                                ? props.cardConfig.container + "__"
                                : "") + "next"
                            }
                            className="fi-rs-angle-small-right"
                          ></i>
                        </a>
                      )}
                    </li>
                  )}

                  {props.cardConfig.pagesettings.paginationGroup.length <= 3 &&
                  props.cardConfig.pagesettings.paginationGroup
                    .length ? null : (
                    <li
                      name="doublenext"
                      id={
                        (props.cardConfig.container
                          ? props.cardConfig.container + "__"
                          : "") + "doublenext"
                      }
                      onClick={() => {
                        let newPath =
                          window.location.pathname +
                          "#page=" +
                          (props.cardConfig.pagesettings.currentPage + 3 <=
                          props.cardConfig.pagesettings.paginationGroup.length
                            ? props.cardConfig.pagesettings.currentPage + 3
                            : props.cardConfig.pagesettings.paginationGroup
                                .length);
                        history.push(newPath);
                        props.cardConfig.managepagination({
                          target: {
                            name:
                              "select" +
                              (props.cardConfig.pagesettings.currentPage + 3 <=
                              props.cardConfig.pagesettings.paginationGroup
                                .length
                                ? props.cardConfig.pagesettings.currentPage + 3
                                : props.cardConfig.pagesettings.paginationGroup
                                    .length),
                          },
                        });
                      }}
                      className="icon-item"
                    >
                      {props.cardConfig.pagesettings.currentPage >=
                      props.cardConfig.pagesettings.pages ? null : (
                        <a
                          href={
                            "#page=" +
                            (props.cardConfig.pagesettings.currentPage + 3 <=
                            props.cardConfig.pagesettings.paginationGroup.length
                              ? props.cardConfig.pagesettings.currentPage + 3
                              : props.cardConfig.pagesettings.paginationGroup
                                  .length)
                          }
                          name="doublenext"
                          id={
                            (props.cardConfig.container
                              ? props.cardConfig.container + "__"
                              : "") + "doublenext"
                          }
                          className="icon-link"
                        >
                          <i
                            name="doublenext"
                            id={
                              (props.cardConfig.container
                                ? props.cardConfig.container + "__"
                                : "") + "doublenext"
                            }
                            className="fi-rs-angle-double-small-right"
                          ></i>
                        </a>
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </div>

            {/*
						<div className= {props.cardConfig.pagewidth?props.cardConfig.pagewidth:"col-md-12 col-lg-12 col-xl-12"} >
				   		<div>
				   			<ul className="pagination-area justify-content-center">
		                {props.cardConfig.pagesettings.paginationGroup.length <= 0 ? null : (
		                    <li name="doubleprev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"doubleprev"} onClick={props.cardConfig.managepagination} className="icon-item">
		                        {props.cardConfig.pagesettings.currentPage <3 ? null : (
		                            <a name="doubleprev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"doubleprev"} className="icon-link">
		                              <i name="doubleprev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"doubleprev"} className="fi-rs-angle-double-small-left"></i>
		                            </a>
		                        )}
		                    </li>
		                )}
		                
		                {props.cardConfig.pagesettings.paginationGroup.length <= 0 ? null : (
		                    <li name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} onClick={props.cardConfig.managepagination} className="icon-item">
		                        {props.cardConfig.pagesettings.currentPage == 1 ? null : (
		                            <a name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} className="icon-link">
		                                <i name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} className="fi-rs-angle-small-left"></i>
		                            </a>
		                        )}
		                    </li>
		                )}
		                
		                {props.cardConfig.pagesettings.paginationGroup.length<=6?
		                	<>
		                	
		                		 {props.cardConfig.pagesettings.paginationGroup.map((item, index) => { 
			                    return (
			                        <li
			                            name={"select"+item} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+item} onClick={props.cardConfig.managepagination}
			                            key={index}
			                            className={
			                                props.cardConfig.pagesettings.currentPage == item
			                                    ? "page-item active"
			                                    : "page-item inactive"
			                            }
			                        >
			                            <a name={"select"+item} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+item} onClick={props.cardConfig.managepagination} className="page-link">{item}</a>
			                        </li>
			                    );
			                })}
		                	
		                	</>
		                	
		               	:
		                <>

			                {props.cardConfig.pagesettings.paginationGroup.map((item, index) => { 
			                    return (
			                    <>
			                    	{index<3?
			                        <li
		                            name={"select"+item} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+item} onClick={props.cardConfig.managepagination}
		                            key={index}
		                            className={
		                                props.cardConfig.pagesettings.currentPage == item
		                                    ? "page-item active"
		                                    : "page-item inactive"
		                            }>
		                            <a name={"select"+item} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+item} onClick={props.cardConfig.managepagination} className="page-link">{item}</a>
			                        </li>
			                      :""}
			                    </>
			                    );
			                })}
			                
			                {props.cardConfig.pagesettings.currentPage>3 && props.cardConfig.pagesettings.currentPage < props.cardConfig.pagesettings.paginationGroup.length-3  ?
			                	<>
			                	
													<li name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} onClick={props.cardConfig.managepagination} className="ml-1 mr-1">
													      {props.cardConfig.pagesettings.currentPage == 1 ? null : (
													          <a name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} className="cursor-pointer"  >
													              ...
													          </a>
													      )}
													</li>
													<li
													  name={"select"+props.cardConfig.pagesettings.currentPage} 
													  id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+props.cardConfig.pagesettings.currentPage} 
													  onClick={props.cardConfig.managepagination}
													  key={1}
													  className={  "page-item active"  }>
													  <a name={"select"+props.cardConfig.pagesettings.currentPage} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+props.cardConfig.pagesettings.currentPage} onClick={props.cardConfig.managepagination} 
													  className="page-link">{props.cardConfig.pagesettings.currentPage}</a>
													</li>

													<li name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} onClick={props.cardConfig.managepagination}  className="ml-1 mr-1 cursor-pointer">
													    {props.cardConfig.pagesettings.currentPage >= props.cardConfig.pagesettings.pages ? null : (
													        <a name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} className="" >
													            ...
													        </a>
													    )}
													</li>
		                    
			                	</>
			                :
				                <>
				                {props.cardConfig.pagesettings.currentPage>3?
				                	
				                	<li name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} onClick={props.cardConfig.managepagination} className="ml-1 mr-1">
											      {props.cardConfig.pagesettings.currentPage == 1 ? null : (
											          <a name="prev" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"prev"} className="cursor-pointer"  >
											              ...
											          </a>
											      )}
													</li>
												:
													<li name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} onClick={props.cardConfig.managepagination}  className="ml-1 mr-1">
												    {props.cardConfig.pagesettings.currentPage >= props.cardConfig.pagesettings.pages ? null : (
												        <a name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} className="cursor-pointer" >
												            ...
												        </a>
												    )}
													</li>
				                }
				                </>
				              }  																					 
			                {props.cardConfig.pagesettings.paginationGroup.map((item, index) => {
			                    return (
			                    	<>
			                    		{ index>3 &&  props.cardConfig.pagesettings.paginationGroup.length-3 <= ( index)?
				                        <li
			                            name={"select"+item} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+item} onClick={props.cardConfig.managepagination}
			                            key={index}
			                            className={
			                                props.cardConfig.pagesettings.currentPage == item
			                                    ? "page-item active"
			                                    : "page-item inactive"
			                            }>
			                            <a name={"select"+item} id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"select"+item} onClick={props.cardConfig.managepagination} className="page-link">{item}</a>
				                        </li>
			                      	:""}
			                      </>
			                   :"" );
			                })}
			                
			                
										</>
										}
		                {props.cardConfig.pagesettings.paginationGroup.length <= 0 ? null : (
		                    <li name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} onClick={props.cardConfig.managepagination}  className="icon-item">
		                        {props.cardConfig.pagesettings.currentPage >= props.cardConfig.pagesettings.pages ? null : (
		                            <a name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} className="icon-link">
		                                <i name="next" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"next"} className="fi-rs-angle-small-right"></i>
		                            </a>
		                        )}
		                    </li>
		                )}
		                
		                {props.cardConfig.pagesettings.paginationGroup.length <=3 && props.cardConfig.pagesettings.paginationGroup.length ? null : (
		                    <li name="doublenext" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"doublenext"} onClick={props.cardConfig.managepagination}  className="icon-item">
		                        {props.cardConfig.pagesettings.currentPage >= props.cardConfig.pagesettings.pages ? null : (
		                            <a name="doublenext" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"doublenext"} className="icon-link">
		                                <i name="doublenext" id={( props.cardConfig.container?props.cardConfig.container+"__":"" )+"doublenext"} className="fi-rs-angle-double-small-right"></i>
		                            </a>
		                        )}
		                    </li>
		                )}
		                
		            </ul> 
		         	</div>	
						</div>
						*/}
            <div
              className="card text-right mt-3"
              style={
                {
                  /*"z-index":"1150"*/
                }
              }
            >
              <div className="row">
                <div
                  className={
                    (props.cardConfig.pagewidth
                      ? props.cardConfig.pagewidth
                      : "col-md-12 col-lg-12 col-xl-12") +
                    (props.cardConfig.overtop === "YES" ? " over-top" : "")
                  }
                >
                  <select
                    id={
                      (props.cardConfig.container
                        ? props.cardConfig.container + "__"
                        : "") + "itemsperpage"
                    }
                    name="itemsperpage"
                    className="pagination-select"
                    defaultValue={props.cardConfig.pagesettings.itemsperpage}
                    onChange={props.cardConfig.managepagination}
                  >
                    {getRowsPaginationValues().map((item) => (
                      <option key={"pag_i2" + item.value} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  } else {
    return "";
  }
};
export default LoadCard;
