import React from "react"; 
import {getDefValues } from '../../common/functions'; 
const loadModalV2 = props => {
 
	  return ( 
			<>
				 <div  onClick={() => { if (!props.staticBackdrop) { props.toggleModal()} }} className={  "modal-v2-container"+ ( props.modal?"":"-hide")} >

		 			<div className={"modal-v2-container-body"+(props.lg?"-lg":"" )  +(props.top?"-top":"" ) } tabIndex="-1">
		 				
						
						{props.toogleCloseButton &&
							<div className={""+ ( props.modal?"":"hide-div")}  style={{textAlign: "right"}} >
								<div style={{ marginRight: 2, cursor:"pointer"}} > <span onClick={props.toggleModal} > X</span> </div>
							</div>
						}
						
						<div style={{  minHeight: props.minHeight?props.minHeight: "auto",  height: props.height?props.height: "auto", overflowY: props.overflowY?props.overflowY:"auto" ,
		 			 		 overflowX: props.overflowX?props.overflowX:"hidden"  }}> 

							<div className={"content mb-1 "+ ( props.modal?"":"hide-div")}  style={{textAlign: props.textLeft ? "left": "right"}} >
								{/*props.toogleCloseButton &&
									<div style={{ marginRight: 10, cursor:"pointer"}} > <span onClick={props.toggleModal} > X</span> </div>
								*/}
								{props.content}
							</div>
							{props.toogleCloseButton || props.formAction?
								<div className="btn-container mb-1" style={{ 
									position: "absolute",
									bottom: props.overflowY==="scroll"? 6:0,
									right: props.overflowY==="scroll"? 42:12,
								}}>
									{props.formAction?
									<button onClick={() => {
			              props.formAction();  }}
										className={"ml-1 btn btn-sm btn-"+ ( props.btncolor?props.btncolor:"primary")}>
										<span>	{props.formButtonLabel}</span>
									</button>
								 :""}
								 {props.toogleCloseButton && !props.hideCloseButton ?
										<button
											style={{/*
												position: "absolute",
												bottom: 5,
												right: 15,
												*/
											}}
										
										 onClick={props.toggleModal}	className={"btn btn-sm btn-outline-"+ ( props.btncolor?props.btncolor:"primary")} >
											{props.secondaryFormButtonLabel?props.secondaryFormButtonLabel:getDefValues().closeLabel}
										</button> 
									:""}
								</div>
							:""}
						</div> 
					</div>
				</div>
			</>
		);

};

export default loadModalV2;
				 