/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Slider from "react-slick";
import ARROW_next from "../../assets/images/next.png";
import ARROW_prev from "../../assets/images/prev.png";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";

import { getImagePath } from "../../common/functions";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const windowWidthDef = window.innerWidth;

function NextArrow1(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow"
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        marginTop: "-22px",
        zIndex: "100",
        cursor: "pointer",
        left: windowWidthDef < 779 ? "94%" : "98%",
        opacity: "0.6",
      }}
      onClick={onClick}
    >
      <img src={ARROW_next} height="25px" width="25px" alt="arrow_next" />
    </div>
  );
}

function PrevArrow1(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow"
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        marginTop: "-22px",
        zIndex: "100",
        cursor: "pointer",
        right: windowWidthDef < 779 ? "94%" : "98%",
        opacity: "0.6",
      }}
      onClick={onClick}
    >
      <img src={ARROW_prev} height="25px" width="25px" alt="arrow_prev" />
    </div>
  );
}

var images = [];

const loadSliderImg = (props) => {
 
  if (props.sliderConfig) {
    images = props.sliderData;
    const settings = props.sliderConfig;
    settings["nextArrow"] = <NextArrow1 />;
    settings["prevArrow"] = <PrevArrow1 />;

    if (props.sliderData.length < settings["slidesToShow"]) {
      settings["slidesToShow"] = props.sliderData.length;
    }

    if (props.sliderConfig.imageCarrousel && images.length > 0) {
      settings["customPaging"] = function (i) {
        return (
          <a>
            <img
              src={images[i] ? getImagePath(images[i].image, "sku") : ""}
              alt={images[i] ? images[i].imagealt : ""}
              height="100%"
              width="100%"
            />
          </a>
        );
      };

      settings["dotsClass"] = props.sliderConfig.zoomable
        ? "slick-thumb-zoom"
        : "slick-thumb";
      settings["slidesToShow"] = 1;
    }

    var sliderData = props.sliderData;
    for (let k in props.sliderData) {
      var key = props.sliderData[k];

      var show = false;
      if (windowWidthDef < 799) {
        //mobile
        if (key.version === "mobile" || key.version === "both") {
          show = true;
        }
      } else {
        show = true;
        if (key.version === "mobile") {
          show = false;
        }
      }
      key["show"] = show;
    }
    return (
      <div
        className={
          (props.sliderConfig.divwidth
            ? props.sliderConfig.divwidth
            : "card mobile-col col-md-12 col-lg-12 col-xl-12") +
          (props.sliderConfig.dots ? " mb-50" : "")
        }
        style={{ width: "100%", borderRadius: "5px" }}
      >
        <Slider {...settings}>
          {sliderData.map((field, index) =>
            !field.show ? (
              ""
            ) : (
              <div
                key={"div1" + index}
                className={
                  "cslider " +
                  (props.sliderConfig.spaceitems
                    ? " space-items-slider "
                    : "") +
                  (props.sliderConfig.zoomable ? " sliderzoomable " : "")
                }
              >
                {field.title ? (
                  <span key={"span1" + index} className="h4-responsive">
                    {field.title}
                  </span>
                ) : (
                  ""
                )}
                {field.image ? (
                  <div key={"div2" + index} className="mt-1 slider-img">
                    {field.link ? (
                      <a
                        href={field.link}
                        key={"a1" + index}
                        style={{width:"100%"}}
                        target={field.targetlink ? field.targetlink : "_self"}
                      >
                        <img
                          alt={field.imagealt}
                          key={"img1" + index}
                          className="xxx1"
                          width={
                            field.width || field.mwidth
                              ? windowWidthDef < 779
                                ? field.mwidth
                                  ? field.mwidth
                                  : "100%"
                                : field.width
                              : "100%"
                          }
                          height={
                            field.height || field.mheight
                              ? windowWidthDef < 779
                                ? field.mheight
                                  ? field.mheight
                                  : "100%"
                                : field.height
                              : "100%"
                          }
                          src={getImagePath(field.image)}
                        />
                      </a>
                    ) : (
                      <>
                        {field.onclick ? (
                          <div
                            onClick={field.onclick}
                            alt={field.imagealt}
                            key={"div3" + index}
                          >
                            <InnerImageZoom
                              zoomType={"hover"}
                              hideHint={true}
                              width={
                                field.width || field.mwidth
                                  ? windowWidthDef < 779
                                    ? field.mwidth
                                      ? field.mwidth
                                      : "100%"
                                    : field.width
                                  : "100%"
                              }
                              height={
                                field.height || field.mheight
                                  ? windowWidthDef < 779
                                    ? field.mheight
                                      ? field.mheight
                                      : "100%"
                                    : field.height
                                  : "100%"
                              }
                              src={getImagePath(field.image)}
                              zoomSrc={getImagePath(field.image)}
                            />
                            {/*
	            				<img  style={{"cursor":"pointer"}} onClick={field.onclick} alt={field.imagealt} 
												width={field.width  || field.mwidth?  (windowWidthDef <779 ? ( field.mwidth?field.mwidth  : "100%"  ) : field.width ):"100%"}  
												height={field.height || field.mheight? (windowWidthDef <779 ? ( field.mheight?field.mheight: "100%"  ) : field.height ):"100%"} 
												src={getImagePath(field.image)}  />
											*/}{" "}
                          </div>
                        ) : props.sliderConfig.zoomable ? (
                          <TransformWrapper initialScale={1}>
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                              <React.Fragment>
                                <div
                                  className="zoomable-tools"
                                  key={"div4" + index}
                                >
                                  <div onClick={() => zoomIn()}>
                                    {" "}
                                    <i className="fas fa-search-plus"></i>{" "}
                                  </div>
                                  <div onClick={() => zoomOut()}>
                                    {" "}
                                    <i className="fas fa-search-minus"></i>{" "}
                                  </div>
                                  <div onClick={() => resetTransform()}>
                                    {" "}
                                    <i className="fas fa-redo-alt"></i>{" "}
                                  </div>
                                </div>
                                <TransformComponent
                                  wrapperClass="golac"
                                  contentClass="golc2"
                                  wrapperStyle={{ overflow: "initial" }}
                                >
                                  <img
                                    alt={field.imagealt}
                                    key={"img2" + index}
                                    className="xxx2"
                                    width={
                                      field.width || field.mwidth
                                        ? windowWidthDef < 779
                                          ? field.mwidth
                                            ? field.mwidth
                                            : "100%"
                                          : field.width
                                        : "100%"
                                    }
                                    height={
                                      field.height || field.mheight
                                        ? windowWidthDef < 779
                                          ? field.mheight
                                            ? field.mheight
                                            : "100%"
                                          : field.height
                                        : "100%"
                                    }
                                    src={getImagePath(field.image)}
                                  />
                                </TransformComponent>
                              </React.Fragment>
                            )}
                          </TransformWrapper>
                        ) : (
                          <img
                            alt={field.imagealt}
                            key={"img3" + index}
                            className="xxx3"
                            width={
                              field.width || field.mwidth
                                ? windowWidthDef < 779
                                  ? field.mwidth
                                    ? field.mwidth
                                    : "100%"
                                  : field.width
                                : "100%"
                            }
                            height={
                              field.height || field.mheight
                                ? windowWidthDef < 779
                                  ? field.mheight
                                    ? field.mheight
                                    : "100%"
                                  : field.height
                                : "100%"
                            }
                            src={getImagePath(field.image)}
                          />
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )
          )}
        </Slider>
      </div>
    );
  } else {
    return "";
  }
};
export default loadSliderImg;
