/* eslint-disable array-callback-return */
//componets
import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
//pages
import Header from "./pages/header/index";
import Footer from "./pages/footer/index";
import HomePage from "./pages/homePage/index";
import CustomPage from "./pages/customPage";
import CustomPrivatePage from "./pages/customPrivatePage";

import ProductDetails from "./pages/productDetails";
import Categories from "./pages/categories";
import Wishlist from "./pages/wishlist";

import ShopCart from "./pages/shopCart";
import RecoveryCart from "./pages/shopCart/recoveryCart";

import Login from "./pages/login/index";
import LoginTest from "./pages/login/indexTest";
import Logout from "./pages/logout";
import ForgotPassword from "./pages/forgotPassword";
import RecoveryPassword from "./pages/recoveryPassword";
import UserInfo from "./pages/userInfo";
import AddressInfo from "./pages/addressInfo";
import OrdersInfo from "./pages/ordersInfo";
import OrderDetails from "./pages/orderDetails";
import CheckOrder from "./pages/checkOrder";
import SuccessfulPayment from "./pages/successfulPayment";
import SuccessfulPaymentOffline from "./pages/successfulPaymentOffline";
import EventCreatePage from "./pages/eventCreatePage";
import EventManager from "./pages/eventManager";
import EventShop from "./pages/eventShop";

import EventHomePage from "./pages/eventManager/home";

import Search from "./pages/search";
import Register from "./pages/register";

import PageLoading from "./components/PageLoading";

import {
  setStore,
  getCookie,
  setCookie,
  findObjName,
  getTagDefinitions,
  findValueById,
  updateCart,
  getSyncStoresInfo,
  clearCart,
  getMasterCategories,
  managepagination,
  buildProductsCard,
  managesort,
  manageCart,
  getShopCart,
  formatPathCategory,
  find,
  getLoginInfo,
  getStoresInfo,
  getOptions,
  buildSearchData,
  manageSearch,
  getLanguage,
  getTopMessage,
  getDefValues,
  searchParent,
  callApi,
  getImagePath,
} from "./common/functions";
import { toast } from "mdbreact";

//custom apis
import { setApiToken } from "./common/api";

import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID,
  auth: process.env.REACT_APP_GTMAUTH,
  preview: process.env.REACT_APP_GTMENV,
};

TagManager.initialize(tagManagerArgs);

const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
var timeout = null;
var footerconfig = {
  style: { fontSize: 11, background: "fff", color: "#696158" },
  footeroptions: [
    {
      title: {
        image: "https://www.casaideas.com.bo/static/media/logo.40b7cd09.png",
        link: "/",
        label: "casaideas",
        style: { width: 139 },
      },
      size: "col-5 col-sm-5 col-md-12 col-lg-5",
      hideweb: false,
      hidemobile: true,

      childs: [
        {
          type: "TEXT",
          label:
            "Un hogar es un lugar para vivir, donde nos sentimos seguros, queridos y donde tambi\u00E9n compartimos con otros. En Casaideas encontrar\u00E1s art\u00EDculos de dise\u00F1o, para vivir d\u00EDa a d\u00EDa en un espacio que te haga feliz.",
        },
      ],
    },

    {
      title: { label: "SERVICIO AL CLIENTE", style: { fontSize: 13 } },
      subtitle: { label: "Call Center 09:00 a 20:00 hrs.", style: {} },
      size: "col-4 col-sm-4 col-md-2_5 col-lg-2_5",
      childs: [
        {
          type: "LINK",
          label: "La Paz: 76759953",
          link: "tel:76759953",
          style: { color: "#696158" },
        },
        {
          type: "LINK",
          label: "Santa Cruz: 76759951",
          link: "tel:76759951",
          style: { color: "#696158" },
        },
        {
          type: "LINK",
          label: "Cochabamba: 76759952",
          link: "tel:76759952",
          style: { color: "#696158" },
        },
        {
          type: "LINK",
          label: "Sucre: 69900223",
          link: "tel:69900223",
          style: { color: "#696158" },
        },
      ],
    },

    {
      title: { label: "MI CUENTA", style: { fontSize: 13 } },
      size: "col-4 col-sm-4 col-md-2_5 col-lg-2_5",
      //	style:{background:"red"},
      childs: [
        {
          type: "LINK",
          logged: true,
          label: "Mis datos personales",
          link: "/customer/account",
          style: { color: "#696158" },
        },
        {
          type: "LINK",
          notlogged: true,
          label: "Ingresar",
          link: "/customer/account/login",
          style: { color: "#696158" },
        },
        {
          type: "LINK",
          label: "Mis compras",
          link: "/sales/order/history",
          style: { color: "#696158" },
        },
      ],
    },

    {
      title: { label: "S\u00EDGUENOS", style: { fontSize: 13 } },
      size: "col-4 col-sm-4 col-md-2 col-lg-2",
      childs: [
        {
          type: "IMAGE",
          image:
            "https://cidev-media.s3.amazonaws.com/catalog/page_info/ico_facebook_2x.0bf5bf32.png",
          style: { width: "36px", opacity: "0.8" },
          label: "Facebook CasaIdea  Bolivia",
          link: "https://www.facebook.com/CasaIdeasBolivia/",
          target: "_blank",
        },
        {
          type: "IMAGE",
          image:
            "https://cidev-media.s3.amazonaws.com/catalog/page_info/ico_instagram_2x.021d2939.png",
          style: { width: "36px", opacity: "0.8" },
          label: "Instagram CasaIdeas Bolivia",
          link: "https://www.instagram.com/casaideasbolivia/",
          target: "_blank",
        },
        {
          type: "IMAGE",
          image:
            "https://cidev-media.s3.amazonaws.com/catalog/page_info/ico_pinterest_2x.244177b8.png",
          style: { width: "36px", opacity: "0.8" },
          label: "Pinterest CasaIdeas Bolivia",
          link: "https://www.pinterest.es/Casaideas_/_created/",
          target: "_blank",
        },
      ],
    },
  ],
};

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,

      changeCustomPage: false,
      shopCart: { total: 0, subtotal: 0, products: [], discount: 0 },
      scroll: false,
      isLoading: true,
      isLoading2: false,
      cardConfig: {},
      cardData: [],
      menuTemplate: [],
      showUserInfo: false,
      routes: [
        { path: "/", priority: 1.0 },
        { path: "/private/:page", priority: 0.8 },
        { path: "/pages/:page", priority: 0.6 },
        // Agrega más rutas según sea necesario
      ],
      hasMore: true,
      eventId: 0,
      eventName: "",
      isSearching: false,
      action: "",
      eventQty: 0,
      showFilters: true,
      showMobileFilters: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      showCart: false,
      topMessages: [],
      isLoadingMobileSearch: false,
      showSearcher: false,
      searchinput: "",
      mobileFirstTime: false,
      loginInfo: { userId: null },
      resultSearch: [],

      prodDefConfig: {
        pagewidth: "col-md-12 col-lg-12 col-xl-12",
        colsize: "col-6",
        colsizelg: "col-lg-3",
        colsizemd: "col-md-4",
        colsizesm: "col-sm-6",
        limit: 200,
        /*overtop           : "YES"*/
      },

      searchData: {
        searchinput: "",
        showtype: "grid",
        results: [],
        showresults: false,
        categories: [],
        showcategories: false,
        listcategories: true,
        selectedcategories: [],
        options: [],
        minprice: 0,
        maxprice: 0,
        minpricedef: 0,
        maxpricedef: 0,
        listprices: true,
      },
      results: [],
      storesInfo: { stores: [] },
      options: [],
      selectStore: false,
      masterCategories: [],
      menuRoutes: [],
      menuRoutes2: [],
      prodRoutes: [],
      enableListener: true,
    };
  }

  componentDidMount = async () => {
    if (
      !localStorage.getItem("def_language") ||
      localStorage.getItem("def_language") === "undefined" ||
      localStorage.getItem("def_language") == null
    ) {
      localStorage.setItem("def_language", process.env.REACT_APP_DEF_LANGUAGE);
    }

    //localStorage.removeItem(process.env.REACT_APP_ORG_NAME+'_cartSession');

    const pathN = window.location.pathname;
    if (pathN.indexOf("search") >= 0) {
      this.hideFilter();
    }
    require("./assets/css/bootstrap.css");
    require("./assets/css/feathericon.min.css");
    require("./assets/plugins/fontawesome/css/fontawesome.min.css");
    require("./assets/plugins/fontawesome/css/all.min.css");
    require("./assets/css/font-awesome.min.css");
    require("./assets/css/style.css");
    require("./assets/css/uicons-regular-straight.css");
    //carousel
    require("./assets/css/slick/slick.css");
    require("./assets/css/slick/slick-theme.css");

    var eventId = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
    var eventName = getCookie(process.env.REACT_APP_ORG_NAME + "_eventName");
    var action = getCookie(process.env.REACT_APP_ORG_NAME + "_action");
    var eventQty = getCookie(process.env.REACT_APP_ORG_NAME + "_eventQty");

    let exponea_registered = localStorage.getItem(
      process.env.REACT_APP_ORG_NAME + "exponea_registered"
    );

    var login = getLoginInfo();
    if (login.userId) {
      if (!exponea_registered) {
        let data = await callApi("admin", "getCustomerById", {
          customerid: login.userId,
        });

        console.log("BR true");
        if (data.success) {
          let resp = data.USER;
          if (resp.EMAIL && resp.EMAIL !== "") {
            var objBloomreach_register = {
              email: resp.EMAIL,
              firstname: resp.FIRSTNAME || "",
              lastname: resp.LASTNAME || "",
              lastname2: resp.LASTNAME2 || "",
              opt_in_ofertas: false,
              opt_in_tyc: true,
              origen: "web",
              phone: resp.PHONE || "",
              DNI: resp.CODE || "",
            };
            window.exponea.track("register", objBloomreach_register);
            window.exponea.update(objBloomreach_register);
            //Objeto para Set Customer Bloomreach
            var objBloomreach_setCustomer = {
              registered: resp.code || "", //Este atributo es OBLIGATORIO, Aqui va la identificacion si se tiene si no va vacia NO ES NULL DEBE IR VACIA si va vacio se debe incluir obligatorio el de email_id que es el soft_id
              email_id: resp.EMAIL, //Si se tiene el valor se debe añadir
            };
            window.exponea.identify(objBloomreach_setCustomer);
            localStorage.setItem(
              process.env.REACT_APP_ORG_NAME + "exponea_registered",
              "YES"
            );
          } else {
            console.log("ALERT!! no email");
          }
        }
      } else {
        console.log("BR false");
      }
    }
    if (
      action === "undefined" ||
      !action ||
      typeof action === "undefined" ||
      action === undefined
    ) {
      eventId = 0;
      eventName = "";
      action = "";
      eventQty = 0;
    }

    this.setState({
      eventId: eventId,
      eventName: eventName,
      action: action,
      eventQty: eventQty,
      footerconfig: footerconfig,
    });
    var topMessages = await getTopMessage();
    this.setState({ topMessages: topMessages });

    try {
      var isInvalid = false;
      var isrecoveryCart = false;

      let loginInfo = getLoginInfo();

      await getTagDefinitions();
      let storesInfo = await getStoresInfo();

      //console.log("loginInfo",loginInfo)

      this.setState({ storesInfo: storesInfo, loginInfo: loginInfo });
      if (
        this.props.location.pathname.indexOf(
          "/customer/event/event-details/"
        ) >= 0 ||
        this.props.location.pathname.indexOf("/customer/event/manage/") >= 0
      ) {
        //console.log("evento", this.props.location, storesInfo)
        let token = this.props.location.pathname
          .replace("/customer/event/event-details/", 0)
          .replace("/customer/event/manage/", 0);
        if (token.length > 5) {
          try {
            if (token.length > 100) {
              var respT = await callApi("frontend", "decodeToken", {
                token: token,
              });

              let respStores = await callApi("frontend", "getStores");
              let stores = [];
              respStores.rows.map(function (key) {
                let coords = JSON.parse(key.CLOB_VALUE);
                let store = {
                  storeId: key.DETAILDOMAINID,
                  storeName: key.STRING_VALUE,
                  catalogId: key.NUMBER_VALUE,
                  lat: coords.lat,
                  lng: coords.lng,
                  add: coords.address,
                };
                stores.push(store);
              });
              let catalogId = findValueById(
                stores,
                respT.tokenData.storeid,
                "storeId",
                "catalogId"
              );
              storesInfo = {
                stores: stores,
                selectedStoreId: respT.tokenData.storeid,
                selectedCatalogId: catalogId,
              };
            } else {
              let _token = token.split("-");
              let eventId = _token[_token.length - 1];
              let respT = await callApi("frontend", "getCustomerEventById", {
                languageid: getLanguage(),
                eventid: eventId,
              });

              let respStores = await callApi("frontend", "getStores");
              let stores = [];
              respStores.rows.map(function (key) {
                let coords = JSON.parse(key.CLOB_VALUE);
                let store = {
                  storeId: key.DETAILDOMAINID,
                  storeName: key.STRING_VALUE,
                  catalogId: key.NUMBER_VALUE,
                  lat: coords.lat,
                  lng: coords.lng,
                  add: coords.address,
                };
                stores.push(store);
              });
              let catalogId = findValueById(
                stores,
                respT.EVENT.STOREID,
                "storeId",
                "catalogId"
              );
              storesInfo = {
                stores: stores,
                selectedStoreId: respT.EVENT.STOREID,
                selectedCatalogId: catalogId,
              };
            }

            let updateDate = new Date();
            updateDate.setDate(updateDate.getDate() + 10);
            setCookie(
              process.env.REACT_APP_ORG_NAME + "_storesInfoCookie",
              updateDate,
              240
            );
            localStorage.setItem(
              process.env.REACT_APP_ORG_NAME + "_storesInfoNew",
              JSON.stringify(storesInfo)
            );
            this.setState({ storesInfo: storesInfo });
          } catch (Exc) {
            console.log(">>>Err", Exc);
          }
        }
      } else if (
        this.props.location.pathname.indexOf("/checkout/recovery-cart/") >= 0
      ) {
        let storeid = this.props.location.search.replace("?store=", "");
        let respStores = await callApi("frontend", "getStores");
        let stores = [];
        respStores.rows.map(function (key) {
          let coords = JSON.parse(key.CLOB_VALUE);
          let store = {
            storeId: key.DETAILDOMAINID,
            storeName: key.STRING_VALUE,
            catalogId: key.NUMBER_VALUE,
            lat: coords.lat,
            lng: coords.lng,
            add: coords.address,
          };
          stores.push(store);
        });
        let catalogId = findValueById(stores, storeid, "storeId", "catalogId");
        if (catalogId > 0) {
          storesInfo = {
            stores: stores,
            selectedStoreId: parseInt(storeid),
            selectedCatalogId: catalogId,
          };
          let updateDate = new Date();
          updateDate.setDate(updateDate.getDate() + 10);
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_storesInfoCookie",
            updateDate,
            240
          );
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_storesInfoNew",
            JSON.stringify(storesInfo)
          );
          this.setState({ storesInfo: storesInfo });
          isrecoveryCart = true;

          setCookie(process.env.REACT_APP_ORG_NAME + "_eventId", "", 0.00001);
          setCookie(process.env.REACT_APP_ORG_NAME + "_eventName", "", 0.00001);
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_eventToken",
            "",
            0.00001
          );
          setCookie(process.env.REACT_APP_ORG_NAME + "_action", "", 0.00001);
          setCookie(process.env.REACT_APP_ORG_NAME + "_eventQty", 0, 0.00001);

          let eventId = 0;
          let eventName = "";
          let action = "";
          let eventQty = 0;

          this.setState({
            eventId: eventId,
            eventName: eventName,
            action: action,
            eventQty: eventQty,
            footerconfig: footerconfig,
          });
        } else {
          getSyncStoresInfo();
        }
      } else {
        getSyncStoresInfo();
      }

      if (!storesInfo.selectedStoreId || storesInfo.selectedStoreId == null) {
        isInvalid = true;
      }

      if (isInvalid) {
        this.setState({ selectStore: true, isLoading: false });
      } else {
        //

        var _masterCategories = await getMasterCategories();
        let resp = await getOptions();
        var options = resp.rows;

        resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
          layoutFile: "menu_" + storesInfo.selectedCatalogId,
          foldername: foldername + "private/CUSTOMERHEADER/",
        });
        var menuTemplate = [];
        if (resp.template) {
          var menuRoutes = [];
          var menuRoutes2 = [];
          resp.template.map(function (lng) {
            //console.log(lng.languageId , localStorage.getItem("def_language"))
            if (
              parseInt(lng.languageId) ===
              parseInt(localStorage.getItem("def_language"))
            ) {
              lng.menu.map(function (key) {
                let ISPARENT = searchParent(
                  lng.menu,
                  key.MENUID,
                  "PARENTMENUID"
                );
                key["ISPARENT"] = ISPARENT > 0 ? true : false;
                key["NUM_CHILDRENS"] = ISPARENT;
                key["OPEN"] = false;
                if (key["MENUURL"].indexOf("categories") >= 0) {
                  menuRoutes.push(formatPathCategory(key["MENUDESC"]));
                  menuRoutes2.push(
                    formatPathCategory(key["MENUDESC"]) +
                      "/" +
                      key["MENUURL"].replace("/categories/", "")
                  );

                  key["MENUURL"] =
                    formatPathCategory(key["MENUDESC"]) +
                    "/" +
                    key["MENUURL"].replace("/categories/", "");
                } else if (key["MENUURL"].indexOf("/") >= 0) {
                  menuRoutes.push(formatPathCategory(key["MENUDESC"]));
                  menuRoutes2.push(formatPathCategory(key["MENUURL"]));

                  //key["MENUURL"] = key["MENUURL"];
                }

                if (key["MENULEVEL"] === 1) {
                  //console.log("L1", key)
                  key["MENULABEL"] = key["MENULABEL"].toUpperCase();

                  let childs = [];
                  let row = 0;
                  let maxRows = 10;
                  if (key.ADITIONALCONFIG) {
                    let conf = JSON.parse(key.ADITIONALCONFIG);
                    if (conf.configmaxrowscol) {
                      let _maxRows = parseInt(conf.configmaxrowscol);
                      if (_maxRows > 0) {
                        maxRows = _maxRows;
                      }
                    }
                  }
                  lng.menu.map(function (child) {
                    if (key.MENUID === child.PARENTMENUID) {
                      row++;
                      child["row"] = Math.ceil(row / maxRows);
                      childs.push(child);
                    }
                  });
                  let _rows = Math.ceil(row / maxRows);
                  let rows = [];
                  let widthclass = "sub-mega-menu-width-50";
                  for (let r = 0; r < _rows; r++) {
                    rows.push(r + 1);
                  }
                  if (_rows === 2) {
                    widthclass = "sub-mega-menu-width-50";
                  } else if (_rows === 3) {
                    widthclass = "sub-mega-menu-width-33";
                  } else if (_rows === 4) {
                    widthclass = "sub-mega-menu-width-25";
                  } else if (_rows > 4) {
                    widthclass = "sub-mega-menu-width-20";
                  }
                  key["MENUTOTALROWS"] = _rows;
                  key["MENUCHILDS"] = childs;
                  key["MENUROWS"] = rows;
                  key["MENUWIDTHCLASS"] = widthclass;
                }

                if (key["MENULEVEL"] === 2) {
                  try {
                    let config = JSON.parse(key.ADITIONALCONFIG);
                    key["configimage"] = config.configimage;
                  } catch (e) {
                    console.log("no config", key.configimage);
                  }

                  //console.log("L2", key.MENULABEL, key.ADITIONALCONFIG)
                }
                if (key.ADITIONALCONFIG != null) {
                  try {
                    let conf = JSON.parse(key.ADITIONALCONFIG);
                    key["ADITIONALCONFIG"] = conf;
                  } catch (Exc) {
                    //console.log("ERROR", Exc, key.MENUID,  key.ADITIONALCONFIG)
                    key["ADITIONALCONFIG"] = {};
                  }
                } else {
                  key["ADITIONALCONFIG"] = {};
                }
                menuTemplate.push(key);
              });
            }
          });
        }
        var masterCategories = [];
        _masterCategories.rows.map(function (cat) {
          if (menuRoutes.indexOf("/" + cat) < 0) {
            masterCategories.push(cat);
          } else {
            //console.log("ya existe", menuTemplate)
          }
        });
        masterCategories.push(
          "dia-de-la-madre/accesorios-de-abrigo",
          "dia-de-la-madre/ambiente-acogedor",
          "dia-de-la-madre/ayuda-a-planificar",
          "dia-de-la-madre/detalles-para-decorar",
          "dia-de-la-madre/momento-creativo",
          "dia-de-la-madre/relajo-y-tranquilidad",
          "dia-de-la-madre/salud-y-bienestar",
          "dia-de-la-madre/tiempo-para-conversar"
        );
        this.setState({
          menuTemplate: menuTemplate,
          menuRoutes2: menuRoutes2,
          menuRoutes: menuRoutes,
          isLoading: false,
          options: options,
          masterCategories: masterCategories,
        });

        if (!isrecoveryCart) {
          setTimeout(
            async function () {
              await this.getShopCart();
            }.bind(this),
            10
          );
        } else {
          console.log("recovering shopcart");
        }
        this.setState({ isLoading: false, options: options });

        window.addEventListener("resize", this.handleResize);
        //var intervalId = setInterval(this.getShopCart, 1000 * 10 * 60);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", e);
    }

    this.setState({ isLoading: false });

    document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        this.setState({ mobileFirstTime: false });
      } else {
        //this.setState({mobileFirstTime:true});
      }
      if (this.state.windowWidth < 799) {
        this.setState({ showUserInfo: false });
        this.setState({ showEventInfo: false });
      }
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.state.intervalId);
  }

  handleResize = () => {
    if (this.state.enableListener) {
      this.hideCart();
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }
  };

  clearEvent = () => {
    this.hideCart();

    //console.log("free")
    setCookie(process.env.REACT_APP_ORG_NAME + "_eventId", "", 0.00001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_eventName", "", 0.00001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_action", "", 0.00001);
    setCookie(process.env.REACT_APP_ORG_NAME + "_eventQty", 0, 0.00001);
    clearCart();
  };

  setEventMode = async (eventId, eventName, action, qty, newStore) => {
    this.hideCart();
    //this.updateCart({})
    //console.log("setEventMode",eventId, newStore)
    if (eventId) {
      //clearCart();
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventId", eventId, 1);
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventName", eventName, 1);
      setCookie(process.env.REACT_APP_ORG_NAME + "_action", action, 1);
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventQty", qty, 1);
      this.setState({
        eventId: eventId,
        eventName: eventName,
        action: action,
        showSearcher: false,
        eventQty: qty,
        shopCart: { total: 0, subtotal: 0, products: [], discount: 0 },
      });
    } else {
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventId", "", 0.00001);
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventName", "", 0.00001);
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventToken", "", 0.00001);
      setCookie(process.env.REACT_APP_ORG_NAME + "_action", "", 0.00001);
      setCookie(process.env.REACT_APP_ORG_NAME + "_eventQty", qty, 1);

      let tempCart = localStorage.getItem(
        process.env.REACT_APP_ORG_NAME + "_activeCartV2"
      );
      let shopCart = JSON.parse(tempCart);
      shopCart["products"] = [];
      let _data = {
        id: shopCart.id,
        updatedate: new Date().getTime(),
        status: "EVENTMODEOUT",
        data: (shopCart = {
          id: shopCart.id,
          userID: getLoginInfo().userId,
          discount: 0,
          needCommit: false,
          products: [],
          subtotal: 0,
          total: 0,
        }),
      };
      localStorage.setItem(
        process.env.REACT_APP_ORG_NAME + "_activeCartV2",
        JSON.stringify(shopCart)
      );

      if (newStore) {
        if (newStore > 0) {
          await this.setStore({ target: { name: newStore } });
        } else {
          await callApi("frontend", "updateCart", _data)
            .then(async (resp) => {
              this.setState({
                eventId: 0,
                eventName: "",
                action: "",
                eventQty: qty,
              });
              window.location.reload();
            })
            .bind(this);
        }
      } else {
        callApi("frontend", "updateCart", _data)
          .then(async (resp) => {
            this.setState({
              eventId: 0,
              eventName: "",
              action: "",
              eventQty: qty,
            });
            window.location.reload();
          })
          .bind(this);
        //console.log("SIGUEEE_____________________________")
        //window.location.reload();
      }

      //
    }
  };

  toggleListener = () => {
    this.setState({
      enableListener: !this.state.enableListener,
    });
  };

  toggleSearchPage = () => {
    this.setState({
      enableSearchPage: !this.state.enableSearchPage,
    });
  };

  selectProduct = () => {
    this.setState({
      searchData: {
        searchinput: "",
        results: [],
        showtype: "grid",
        showresults: false,
        categories: [],
        showcategories: false,
        listcategories: true,
        selectedcategories: [],
        options: [],
        minprice: 0,
        maxprice: 0,
        minpricedef: 0,
        maxpricedef: 0,
        listprices: true,
        reloadpage: false,
      },
    });
  };

  changeHandlerS = (event) => {
    //console.log(event.target.value,this.state.searchinput );

    if (event.target.value !== this.state.searchinput) {
      this.setState({ searchinput: event.target.value });

      if (event.target.value.length < 3) {
        this.setState({
          searchData: {
            searchinput: "",
            results: [],
            showtype: "grid",
            showresults: false,
            categories: [],
            showcategories: false,
            listcategories: true,
            selectedcategories: [],
            options: [],
            minprice: 0,
            maxprice: 0,
            minpricedef: 0,
            maxpricedef: 0,
            listprices: true,
            reloadpage: false,
          },
        });
      } else {
        setTimeout(
          async function () {
            await this.manageSearch(event);
          }.bind(this),
          10
        );
      }
    }
  };
  manageSearch = async (event) => {
    this.hideCart();
    try {
      if (event.target.name !== "search_input") {
        var resultSearch = this.state.resultSearch;
        var _searchData = manageSearch(
          event,
          this.state.searchData,
          resultSearch,
          this.state.options
        );
        //console.log("_searchData.results length", _searchData.results.length)
        await this.getProducts(_searchData.results, _searchData.options);
        this.setState({ isLoading: false, searchData: _searchData });
      }

      if (event.target.name === "search_input") {
        //console.log("search_input");
        //this.setState({  });
        if (event.key === "Enter") {
          this.setState({ mobileFirstTime: false });
          event.target.blur();
        }

        var searchData = this.state.searchData;
        //searchData["searchinput"] = event.target.value
        if (this.state.searchinput.length < 3) {
          this.setState({
            searchData: {
              searchinput: "",
              showMobileFilters: false,
              results: [],
              showtype: "grid",
              showresults: false,
              categories: [],
              showcategories: false,
              listcategories: true,
              selectedcategories: [],
              options: [],
              minprice: 0,
              maxprice: 0,
              minpricedef: 0,
              maxpricedef: 0,
              listprices: true,
              reloadpage: false,
            },
          });
        } else {
          if (!this.state.options) {
            var options = await getOptions();
            this.setState({ options: options.rows });
          }

          var searchkey = event.target.value;

          if (!this.state.isLoadingMobileSearch) {
            clearTimeout(timeout);

            timeout = setTimeout(
              async function () {
                try {
                  searchData["showResults"] = true;
                  this.setState({
                    isSearching: true,
                    showMobileFilters: false,
                    searchinput: event.target.value,
                    searchData: searchData,
                    isLoadingMobileSearch: true,
                  });

                  let resp = await callApi("customer", "getFilterProductsNew", {
                    searchkey: searchkey,
                    storeid: this.state.storesInfo.selectedCatalogId,
                  });

                  if (resp.success) {
                    let resultSearch = resp.rows; //list of products
                    //								console.log("resultSearch",resultSearch)
                    let searchData = buildSearchData(
                      resultSearch,
                      this.state.searchData,
                      this.state.options,
                      true,
                      true,
                      true
                    );
                    searchData["results"] = resultSearch;
                    searchData["minprice"] = searchData["tmp_minprice"];
                    searchData["maxprice"] = searchData["tmp_maxprice"];
                    searchData["minpricedef"] = searchData["tmp_minpricedef"];
                    searchData["maxpricedef"] = searchData["tmp_maxpricedef"];
                    searchData["selectedcategories"] = [];
                    searchData["listcategories"] = true;
                    await this.getProducts(resultSearch);

                    if (searchData["options"]) {
                      for (let o in searchData["options"]) {
                        searchData["options"][o]["selectedoptions"] = [];
                        searchData["options"][o]["listoptions"] = true;
                      }
                    }
                    if (resultSearch.length < 1) {
                      searchData["noresults"] =
                        getDefValues().noRecordsFoundLabel;
                    } else {
                      searchData["noresults"] = undefined;
                    }
                    this.setState({
                      searchData: searchData,
                      resultSearch: resultSearch,
                      isLoadingMobileSearch: false,
                      isSearching: false,
                    });
                  } else {
                    let resultSearch = [];
                    let searchData = buildSearchData(
                      resultSearch,
                      this.state.searchData,
                      this.state.options,
                      true,
                      true,
                      true
                    );
                    searchData["results"] = resultSearch;
                    searchData["minprice"] = searchData["tmp_minprice"];
                    searchData["maxprice"] = searchData["tmp_maxprice"];
                    searchData["minpricedef"] = searchData["tmp_minpricedef"];
                    searchData["maxpricedef"] = searchData["tmp_maxpricedef"];
                    //console.log ("resultSearch",resultSearch)
                    await this.getProducts(resultSearch);
                    this.setState({
                      resultSearch: resultSearch,
                      isLoadingMobileSearch: false,
                      isSearching: false,
                    });
                  }
                } catch (e) {
                  console.log(">>>>>>>> ERROR BUSCANDO", e);
                }
              }.bind(this),
              300
            );
          } else {
            //	console.log("espera")
          }
        }
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  getProducts = async (rows, _options) => {
    try {
      if (rows.length > 0) {
        let prodDefConfig = {
          pagewidth: this.state.prodDefConfig.pagewidth,
          colsize: this.state.prodDefConfig.colsize,
          colsizelg: this.state.prodDefConfig.colsizelg,
          colsizemd: this.state.prodDefConfig.colsizemd,
          colsizesm: this.state.prodDefConfig.colsizesm,
          limit: this.state.prodDefConfig.limit,
        };
        if (_options) {
          prodDefConfig["optionsFilter"] = _options;
        }
        //console.log("APPprodDefConfig", prodDefConfig)

        let respCat = await buildProductsCard(
          rows,
          this.managepagination,
          this.manageCart,
          prodDefConfig,
          "searchResult"
        );
        let cardConfig = respCat.cardConfig;
        if (this.state.windowWidth < 767) {
          delete cardConfig["managepagination"];
          delete cardConfig["pagesettings"];
          cardConfig["mobile"] = true;
        }

        this.setState({
          ...this.state,
          cardConfig: respCat.cardConfig,
          cardData: respCat.cardProducts,
        });
      } else {
        this.setState({
          ...this.state,
          cardConfig: this.state.prodDefConfig,
          cardData: [],
        });
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ redirect: false, isLoading: false });
    }
  };

  managesort = (event) => {
    this.setState({ isLoading: true });
    setTimeout(
      function () {
        var newcardData = managesort(this.state.cardData, event);
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };
  manageCart = async (event) => {
    try {
      this.setState({ isLoading2: true, mobileFirstTime: false });
      var resp = await manageCart(
        event,
        this.state.cardData,
        this.manageCart,
        toast
      );
      //console.log(resp)
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading2: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.updateCart(resp.dataCart);
          }
        } else {
          this.setState({
            ...this.state,
            isLoading2: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
          window.location.reload();
        }
      } else {
        this.setState({ ...this.state, isLoading2: false });
        console.log(">>>>>>>", resp.message);
      }
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };
  managepagination = (event) => {
    this.setState({ isLoading: true });
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state.cardConfig.pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: this.state.prodDefConfig.pagewidth,
          colsize: this.state.prodDefConfig.colsize,
          colsizelg: this.state.prodDefConfig.colsizelg,
          colsizemd: this.state.prodDefConfig.colsizemd,
          colsizesm: this.state.prodDefConfig.colsizesm,
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        //console.log(newcardConfig)
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };

  updateUserInfo = async (event) => {
    console.log("updateUserInfo!!!!");
    this.setState({ loginInfo: event });
  };
  updateCart = async (event) => {
    if (event.target) {
      //console.log("target!!!!", this.state.shopCart)
      try {
        this.setState({ isLoading: true });
        var values;
        if (event.target.name) {
          values = event.target.name.split("_");
        } else {
          values = event.target.parentNode.name.split("_");
        }

        var action = values[0];
        var productId = values[1];
        var data;
        if (action === "quantity" || action === "update") {
          data = await updateCart(
            productId,
            action,
            toast,
            event.target.value,
            this.state.shopCart
          );
        } else {
          data = await updateCart(productId, action, toast, event.target.value);
        }

        if (data) {
          this.setState({ shopCart: data, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      } catch (Excep) {
        this.setState({ isLoading: false });
        toast.error("SHOPCART Excep:" + Excep, { closeButton: true });
      }
    } else {
      //console.log("update cart", event)
      this.hideCart();
      if (event.products) {
        this.setState({ shopCart: event });
      } else {
        //console.log("no hay ")
      }
    }
  };

  setLoginInfo = async (event) => {
    //console.log("updateUserInfo");
    let loginInfo = getLoginInfo();
    this.setState({ loginInfo: loginInfo });

    //setApiToken(loginInfo.keyToken);
  };

  addQtyEvent = async (qty) => {
    setCookie(
      process.env.REACT_APP_ORG_NAME + "_eventQty",
      parseInt(this.state.eventQty) + qty,
      1
    );
    this.setState({ eventQty: parseInt(this.state.eventQty) + qty });
  };

  setQtyEvent = async (qty) => {
    setCookie(process.env.REACT_APP_ORG_NAME + "_eventQty", qty, 1);
    this.setState({ eventQty: qty });
  };

  removeQtyEvent = async (qty) => {
    this.setState({ eventQty: parseInt(this.state.eventQty) - qty });
  };

  getShopCart = async (event) => {
    //const pathN = window.location.pathname;

    var cart = await getShopCart();
    let _page = window.location.pathname + "";
    let page = "";

    if (_page === "/") {
      page = "HOME";
    }

    if (_page === "/checkout/cart") {
      page = "CHECKOUTSTART";
      let _cpage = window.location + "";
      if (_cpage.indexOf("/checkout/cart#formstep1") >= 0) {
        page = "CHECKOUTSTART";
      }
      if (_cpage.indexOf("/checkout/cart#formstep2") >= 0) {
        page = "CHECKOUTPERSONALDATA";
      }
      if (_cpage.indexOf("/checkout/cart#formstep3") >= 0) {
        page = "CHECKOUTPAYMENT";
      }
    }

    if (this.state.menuRoutes.indexOf(_page) >= 0) {
      page = "CATEGORY";
    }

    if (this.state.menuRoutes2.indexOf(_page) >= 0) {
      page = "CATEGORY";
    }
    if (page === "") {
      let ppage = _page.split("-");
      let id = ppage[ppage.length - 1];
      if (!isNaN(id)) {
        page = "PRODUCT";
      } else {
        page = "OTHER";
      }
    }

    if (cart.success) {
      let loginInfo = getLoginInfo();
      if (loginInfo.userId) {
        try {
          if (cart.shopCart.products.length > 0) {
            await callApi("frontend", "shopStatus", {
              customerid: loginInfo.userId,
              cartsessionid: cart.id,
              storeid: this.state.storesInfo.selectedStoreId,
              data: JSON.stringify(cart.shopCart),
              page: page,
            });
          }
        } catch (e) {
          console.log("no products" + e);
        }
      }

      var data = cart.shopCart;
      //console.log("pathN", pathN, page, data)
      this.setState({ shopCart: data });
    }
  };

  setStore = async (event) => {
    //console.log("event.target.name",event.target.name)
    this.hideCart();
    var resp = await setStore(event.target.name);

    this.setState({ storesInfo: resp.storesInfo, reloadPage: true });
    window.location.reload();
  };

  setStoreOff = async (storeId) => {
    this.hideCart();
    var resp = await setStore(storeId);
    this.setState({ storesInfo: resp.storesInfo });
  };

  updateStore = (storesInfo) => {
    this.setState({ storesInfo: storesInfo });
  };

  updateStoreToEvent = (storesInfo) => {
    this.setState({
      storesInfo: storesInfo,
      selectStore: false,
      isLoading: false,
    });
  };

  onMouseUpdate = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    this.setState({
      positionx: x,
      positiony: y,
    });
  };

  toggleCart = () => {
    let page = window.location + "";
    if (page.indexOf("checkout") < 0) {
      this.setState({
        showCart: !this.state.showCart,
      });
    }
  };

  toggleUserInfo = () => {
    this.setState({
      showUserInfo: !this.state.showUserInfo,
    });
  };

  toggleEventInfo = () => {
    this.setState({
      showEventInfo: !this.state.showEventInfo,
    });
  };

  forceHideEventIco = () => {
    this.setState({
      showEventInfo: false,
    });
  };

  toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };
  toggleMobileFilters = () => {
    this.setState({
      showMobileFilters: !this.state.showMobileFilters,
      mobileFirstTime: false,
    });
  };
  closePopUpSearcher = () => {
    this.setState({
      showSearcher: false,
    });
  };

  toggleSearcher = () => {
    this.setState({
      showSearcher: !this.state.showSearcher,
      searchinput: "",
      searchData: {
        searchinput: "",
        showtype: "grid",
        results: [],
        showresults: false,
        categories: [],
        showcategories: false,
        listcategories: true,
        selectedcategories: [],
        options: [],
        minprice: 0,
        maxprice: 0,
        minpricedef: 0,
        maxpricedef: 0,
        listprices: true,
      },
    });
  };

  openSearcher = () => {
    this.setState({
      showSearcher: true,
      searchinput: "",
      mobileFirstTime: true,
      showMobileFilters: false,

      searchData: {
        searchinput: "",
        showtype: "grid",
        results: [],
        showresults: false,
        categories: [],
        showcategories: false,
        listcategories: true,
        selectedcategories: [],
        options: [],
        minprice: 0,
        maxprice: 0,
        minpricedef: 0,
        maxpricedef: 0,
        listprices: true,
      },
    });
  };

  hideFilter = () => {
    this.setState({ showFilters: false });
  };
  hideCart = () => {
    if (this.state.windowWidth > 799) {
      this.setState({
        showCart: false,
        showUserInfo: false,
      });
      //CHECK!
      this.setState({ showEventInfo: false });
    }
    const pathN = window.location.pathname;
    if (pathN.indexOf("search") < 0) {
      this.setState({
        showFilters: true,
      });
    }
  };

  forceHideCart = () => {
    this.setState({
      showCart: false,
    });
  };

  toggleMenuTemplate = (event) => {
    var menuId = findObjName(event);
    var menuTemplate = this.state.menuTemplate;
    menuTemplate.map(function (key) {
      if (key.MENUID.toString() === menuId.toString()) {
        //console.log("k", key)
        key["OPEN"] = !key["OPEN"];
      }
    });
    this.setState({ menuTemplate: menuTemplate });
  };

  toggleClick = () => {
    this.setState({
      isToggled: !this.state.isToggled,
    });
  };
  toggleMainModal = () => {};

  hideMessage = (event) => {
    var messageName = findObjName(event);
    var pos = find(this.state.topMessages, messageName, "name");
    var topMessages = this.state.topMessages;
    topMessages[pos]["hide"] = true;
    this.setState({ topMessages: topMessages });
  };

  /*this.state.reloadPage?
		      	<Redirect
						  to={{  pathname:  "/" }}
						/>:""*/
  /*this.state.windowWidth+ "-----"+this.state.positiony*/
  /*SITEMAP
		    {
				'<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">'+
					"<url><loc>https://www.casaideas.com.bo/checkout/cart</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/account/login</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/account/create</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/account/forgotpassword</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/account</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/address</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/sales/order/history</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/tracking</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/getOrderDetails/</loc><lastmod>2022-07-01</lastmod></url>"+							
						"<url><loc>https://www.casaideas.com.bo/customer/event/manage</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/event/manage/</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/event/create-event</loc><lastmod>2022-07-01</lastmod></url>"+
						"<url><loc>https://www.casaideas.com.bo/customer/event/event-details</loc><lastmod>2022-07-01</lastmod></url>"+	   
						"<url><loc>https://www.casaideas.com.bo/wishlist/index/index</loc><lastmod>2022-07-01</lastmod></url>"

			}
		        {this.state.masterCategories.map(cat => (
						  	 <>
								{ 
								"	<url> \r\n"+
								 "   <loc>https://www.casaideas.com.bo/"+cat+"</loc> \r\n"+
								"   <lastmod>2023-04-01</lastmod> \r\n"+
								"  </url> \r\n"
								 }
								 </>
							))
				} 
				{this.state.menuRoutes2.map(cat => (
					<>
						{   
							"	<url> \r\n"+
							"   <loc>https://www.casaideas.com.bo"+cat+"</loc> \r\n"+
							"   <lastmod>2023-04-02</lastmod> \r\n"+
							"  </url> \r\n"
							}
							
					</>
					))
				}
				{this.state.prodRoutes.map(prod => (
					<>
						{   
							"	<url> \r\n"+
							"   <loc>https://www.casaideas.com.bo"+prod+"</loc> \r\n"+
							"   <lastmod>2023-04-03</lastmod> \r\n"+
							"  </url> \r\n"
							}
							
					</>
					))
				}

				
			{"</urlset>"}

			SITEMAP*/

  render() {
    setApiToken(getLoginInfo().keyToken);
    const { routes } = this.state;

    return (
      <>
        {this.state.redirectProductDetails && (
          <Redirect
            to={{
              pathname:
                (this.state.selectedCategory
                  ? "/" + this.state.selectedCategory + "/"
                  : "/productDetails/") +
                (this.state.selectedPath ? this.state.selectedPath : "show"),
            }}
          />
        )}

        <PageLoading isLoading={this.state.isLoading} />
        <PageLoading isLoading={this.state.isLoading2} />
        {this.state.selectStore && (
          <MDBModal isOpen={true} toggle={this.toggleMainModal}>
            <MDBModalHeader>
              <div className="logo-modal-store mb-3 mt-3">
                <img
                  src={getImagePath("/catalog/images/logo.webp")}
                  alt="logo"
                />
              </div>
            </MDBModalHeader>

            <MDBModalBody>
              <div className="row mt-3 mb-30">
                <div className="col col-12 flex items-center middle">
                  <span className="h4-responsive">
                    {" "}
                    {getDefValues().selectCatalogLabel}{" "}
                  </span>
                </div>
              </div>
              {this.state.storesInfo.stores.map((item, index) => (
                <div key={"div1" + index} className="row">
                  <div
                    key={"div2" + index}
                    className="col col-12 flex items-center middle modal-store-item"
                  >
                    <button
                      key={"button1" + index}
                      name={item.storeId}
                      value={item.storeId}
                      onClick={this.setStore}
                    >
                      {" "}
                      {item.storeName.toUpperCase()}{" "}
                    </button>
                  </div>
                </div>
              ))}
            </MDBModalBody>
          </MDBModal>
        )}

        <Router>
          <div className="appContainer">
            <Route
              render={(props) => (
                <Header
                  searchData={this.state.searchData}
                  searchinput={this.state.searchinput}
                  showUserInfo={this.state.showUserInfo}
                  toggleUserInfo={this.toggleUserInfo}
                  toggleEventInfo={this.toggleEventInfo}
                  forceHideEventIco={this.forceHideEventIco}
                  showEventInfo={this.state.showEventInfo}
                  mobileFirstTime={this.state.mobileFirstTime}
                  resultSearch={this.state.resultSearch}
                  manageSearch={this.manageSearch}
                  cardConfig={this.state.cardConfig}
                  cardData={this.state.cardData}
                  changeHandlerS={this.changeHandlerS}
                  selectProduct={this.selectProduct}
                  topMessages={this.state.topMessages}
                  showCart={this.state.showCart}
                  toggleMobileFilters={this.toggleMobileFilters}
                  showMobileFilters={this.state.showMobileFilters}
                  showFilters={this.state.showFilters}
                  toggleCart={this.toggleCart}
                  toggleSearcher={this.toggleSearcher}
                  closePopUpSearcher={this.closePopUpSearcher}
                  showSearcher={this.state.showSearcher}
                  openSearcher={this.openSearcher}
                  windowWidth={this.state.windowWidth}
                  scroll={this.state.scroll}
                  isToggled={this.state.isToggled}
                  toggleClick={this.toggleClick}
                  toggleMenuTemplate={this.toggleMenuTemplate}
                  menuTemplate={this.state.menuTemplate}
                  shopCart={this.state.shopCart}
                  updateCart={this.updateCart}
                  hideCart={this.hideCart}
                  forceHideCart={this.forceHideCart}
                  hideFilter={this.hideFilter}
                  storesInfo={this.state.storesInfo}
                  setStore={this.setStore}
                  loginInfo={this.state.loginInfo}
                  positionx={this.state.positionx}
                  positiony={this.state.positiony}
                  hideMessage={this.hideMessage}
                  setEventMode={this.setEventMode}
                  eventName={this.state.eventName}
                  eventId={this.state.eventId}
                  eventQty={this.state.eventQty}
                  action={this.state.action}
                  options={this.state.options}
                  {...props}
                />
              )}
            />
            <Switch>
              <>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <HomePage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                      addQtyEvent={this.addQtyEvent}
                    />
                  )}
                />
                <Route
                  path="/private/:page"
                  exact
                  render={(props) => (
                    <CustomPrivatePage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/pages/:page"
                  exact
                  render={(props) => (
                    <CustomPage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/pages/:folder1/:page"
                  exact
                  render={(props) => (
                    <CustomPage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/pages/:folder1/:folder2/:page"
                  exact
                  render={(props) => (
                    <CustomPage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/pages/:folder1/:folder2/:folder3/:page"
                  exact
                  render={(props) => (
                    <CustomPage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/pages/:folder1/:folder2/:folder3/:folder4/:page"
                  exact
                  render={(props) => (
                    <CustomPage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/checkout/cart"
                  exact
                  render={(props) => (
                    <ShopCart
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/checkout/recovery-cart/:token"
                  exact
                  render={(props) => (
                    <RecoveryCart
                      showSearcher={this.state.showSearcher}
                      updateUserInfo={this.updateUserInfo}
                      updateCart={this.updateCart}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/address"
                  exact
                  render={(props) => (
                    <AddressInfo
                      showSearcher={this.state.showSearcher}
                      toggleListener={this.toggleListener}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/account/logout"
                  exact
                  render={(props) => (
                    <Logout
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/account/login"
                  exact
                  render={(props) => (
                    <Login
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      windowWidth={this.state.windowWidth}
                      windowHeight={this.state.windowHeight}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/testingYVS/login"
                  exact
                  render={(props) => (
                    <LoginTest
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      windowWidth={this.state.windowWidth}
                      windowHeight={this.state.windowHeight}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/account/login/:token"
                  exact
                  render={(props) => (
                    <Login
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      windowWidth={this.state.windowWidth}
                      windowHeight={this.state.windowHeight}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/account/create"
                  exact
                  render={(props) => (
                    <Register
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      windowWidth={this.state.windowWidth}
                      windowHeight={this.state.windowHeight}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/account/forgotpassword"
                  exact
                  render={(props) => (
                    <ForgotPassword
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      windowWidth={this.state.windowWidth}
                      windowHeight={this.state.windowHeight}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/recovery/password/:tokenId"
                  exact
                  render={(props) => (
                    <RecoveryPassword
                      showSearcher={this.state.showSearcher}
                      setLoginInfo={this.setLoginInfo}
                      windowWidth={this.state.windowWidth}
                      windowHeight={this.state.windowHeight}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/account"
                  exact
                  render={(props) => (
                    <UserInfo
                      showSearcher={this.state.showSearcher}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/sales/order/history"
                  exact
                  render={(props) => (
                    <OrdersInfo
                      showSearcher={this.state.showSearcher}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/tracking"
                  exact
                  render={(props) => (
                    <CheckOrder
                      showSearcher={this.state.showSearcher}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/order/status/paid/:orderid"
                  exact
                  render={(props) => (
                    <SuccessfulPayment
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/order/status/paidOffline/"
                  exact
                  render={(props) => (
                    <SuccessfulPaymentOffline
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/event/manage"
                  exact
                  render={(props) => (
                    <EventManager
                      shopCart={this.state.shopCart}
                      setQtyEvent={this.setQtyEvent}
                      showSearcher={this.state.showSearcher}
                      setStoreOff={this.setStoreOff}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/event/manage/:key"
                  exact
                  render={(props) => (
                    <EventManager
                      shopCart={this.state.shopCart}
                      setQtyEvent={this.setQtyEvent}
                      showSearcher={this.state.showSearcher}
                      setStoreOff={this.setStoreOff}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/event/home"
                  exact
                  render={(props) => (
                    <EventHomePage
                      shopCart={this.state.shopCart}
                      setQtyEvent={this.setQtyEvent}
                      showSearcher={this.state.showSearcher}
                      setStoreOff={this.setStoreOff}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/event/create-event"
                  exact
                  render={(props) => (
                    <EventCreatePage
                      shopCart={this.state.shopCart}
                      showSearcher={this.state.showSearcher}
                      setStoreOff={this.setStoreOff}
                      clearEvent={this.clearEvent}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/event/create-event/:eventType"
                  exact
                  render={(props) => (
                    <EventCreatePage
                      showSearcher={this.state.showSearcher}
                      setStoreOff={this.setStoreOff}
                      clearEvent={this.clearEvent}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/customer/event/event-details"
                  exact
                  render={(props) => (
                    <EventShop
                      shopCart={this.state.shopCart}
                      setStore={this.setStore}
                      showSearcher={this.state.showSearcher}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      updateStoreToEvent={this.updateStoreToEvent}
                      updateStore={this.updateStore}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/customer/event/event-details/:token"
                  exact
                  render={(props) => (
                    <EventShop
                      shopCart={this.state.shopCart}
                      setStore={this.setStore}
                      showSearcher={this.state.showSearcher}
                      setEventMode={this.setEventMode}
                      toggleListener={this.toggleListener}
                      setLoginInfo={this.setLoginInfo}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      updateStoreToEvent={this.updateStoreToEvent}
                      updateStore={this.updateStore}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/getOrderDetails/:orderid/:email"
                  exact
                  render={(props) => (
                    <OrderDetails
                      showSearcher={this.state.showSearcher}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/wishlist/index/index"
                  exact
                  render={(props) => (
                    <Wishlist
                      showSearcher={this.state.showSearcher}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/wishlist/index/index/:token"
                  exact
                  render={(props) => (
                    <Wishlist
                      showSearcher={this.state.showSearcher}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/productDetails/:productname"
                  exact
                  render={(props) => (
                    <ProductDetails
                      addQtyEvent={this.addQtyEvent}
                      showSearcher={this.state.showSearcher}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      windowWidth={this.state.windowWidth}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />
                {this.state.masterCategories.map((cat, index) => (
                  <>
                    <Route
                      key={"routeCat1" + index}
                      path={"/" + cat + "/:productname"}
                      exact
                      render={(props) => (
                        <ProductDetails
                          addQtyEvent={this.addQtyEvent}
                          key={"routeCat1p" + index}
                          updateStore={this.updateStore}
                          eventId={this.state.eventId}
                          action={this.state.action}
                          showSearcher={this.state.showSearcher}
                          windowWidth={this.state.windowWidth}
                          updateCart={this.updateCart}
                          hideCart={this.hideCart}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      key={"routeCat2" + index}
                      path={"/" + cat + "/:categoryid"}
                      exact
                      render={(props) => (
                        <Categories
                          addQtyEvent={this.addQtyEvent}
                          key={"routeCat2p" + index}
                          updateStore={this.updateStore}
                          eventId={this.state.eventId}
                          action={this.state.action}
                          showSearcher={this.state.showSearcher}
                          windowWidth={this.state.windowWidth}
                          positionx={this.state.positionx}
                          positiony={this.state.positiony}
                          updateCart={this.updateCart}
                          hideCart={this.hideCart}
                          {...props}
                        />
                      )}
                    />
                  </>
                ))}
                <Route
                  path="/categories/:categoryid"
                  exact
                  render={(props) => (
                    <Categories
                      addQtyEvent={this.addQtyEvent}
                      updateStore={this.updateStore}
                      eventId={this.state.eventId}
                      action={this.state.action}
                      showSearcher={this.state.showSearcher}
                      windowWidth={this.state.windowWidth}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      {...props}
                    />
                  )}
                />

                {this.state.menuRoutes.map((cat, index) => (
                  <>
                    <Route
                      key={"route1" + index}
                      path={cat + "/:categoryid"}
                      exact
                      render={(props) => (
                        <Categories
                          addQtyEvent={this.addQtyEvent}
                          key={"route1p" + index}
                          updateStore={this.updateStore}
                          eventId={this.state.eventId}
                          action={this.state.action}
                          showSearcher={this.state.showSearcher}
                          positionx={this.state.positionx}
                          positiony={this.state.positiony}
                          windowWidth={this.state.windowWidth}
                          updateCart={this.updateCart}
                          hideCart={this.hideCart}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      key={"route2" + index}
                      path={cat + "/:productname"}
                      exact
                      render={(props) => (
                        <ProductDetails
                          addQtyEvent={this.addQtyEvent}
                          key={"route2p" + index}
                          showSearcher={this.state.showSearcher}
                          eventId={this.state.eventId}
                          action={this.state.action}
                          windowWidth={this.state.windowWidth}
                          updateCart={this.updateCart}
                          hideCart={this.hideCart}
                          {...props}
                        />
                      )}
                    />
                  </>
                ))}

                <Route
                  path="/:param"
                  exact
                  render={(props) => (
                    <Search
                      searchData={this.state.searchData}
                      searchinput={this.state.searchinput}
                      showUserInfo={this.state.showUserInfo}
                      toggleUserInfo={this.toggleUserInfo}
                      mobileFirstTime={this.state.mobileFirstTime}
                      resultSearch={this.state.resultSearch}
                      manageSearch={this.manageSearch}
                      isSearching={this.state.isSearching}
                      cardConfig={this.state.cardConfig}
                      cardData={this.state.cardData}
                      changeHandlerS={this.changeHandlerS}
                      selectProduct={this.selectProduct}
                      topMessages={this.state.topMessages}
                      showCart={this.state.showCart}
                      toggleMobileFilters={this.toggleMobileFilters}
                      showMobileFilters={this.state.showMobileFilters}
                      showFilters={this.state.showFilters}
                      toggleCart={this.toggleCart}
                      toggleSearcher={this.toggleSearcher}
                      showSearcher={this.state.showSearcher}
                      openSearcher={this.openSearcher}
                      windowWidth={this.state.windowWidth}
                      scroll={this.state.scroll}
                      isToggled={this.state.isToggled}
                      toggleClick={this.toggleClick}
                      toggleMenuTemplate={this.toggleMenuTemplate}
                      menuTemplate={this.state.menuTemplate}
                      shopCart={this.state.shopCart}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      forceHideCart={this.forceHideCart}
                      hideFilter={this.hideFilter}
                      storesInfo={this.state.storesInfo}
                      setStore={this.setStore}
                      loginInfo={this.state.loginInfo}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      hideMessage={this.hideMessage}
                      options={this.state.options}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/search/:param"
                  exact
                  render={(props) => (
                    <Search
                      searchData={this.state.searchData}
                      searchinput={this.state.searchinput}
                      showUserInfo={this.state.showUserInfo}
                      toggleUserInfo={this.toggleUserInfo}
                      mobileFirstTime={this.state.mobileFirstTime}
                      resultSearch={this.state.resultSearch}
                      isSearching={this.state.isSearching}
                      manageSearch={this.manageSearch}
                      cardConfig={this.state.cardConfig}
                      cardData={this.state.cardData}
                      changeHandlerS={this.changeHandlerS}
                      selectProduct={this.selectProduct}
                      topMessages={this.state.topMessages}
                      showCart={this.state.showCart}
                      toggleMobileFilters={this.toggleMobileFilters}
                      showMobileFilters={this.state.showMobileFilters}
                      showFilters={this.state.showFilters}
                      toggleCart={this.toggleCart}
                      toggleSearcher={this.toggleSearcher}
                      showSearcher={this.state.showSearcher}
                      openSearcher={this.openSearcher}
                      windowWidth={this.state.windowWidth}
                      scroll={this.state.scroll}
                      isToggled={this.state.isToggled}
                      toggleClick={this.toggleClick}
                      toggleMenuTemplate={this.toggleMenuTemplate}
                      menuTemplate={this.state.menuTemplate}
                      shopCart={this.state.shopCart}
                      updateCart={this.updateCart}
                      hideCart={this.hideCart}
                      forceHideCart={this.forceHideCart}
                      hideFilter={this.hideFilter}
                      storesInfo={this.state.storesInfo}
                      setStore={this.setStore}
                      loginInfo={this.state.loginInfo}
                      positionx={this.state.positionx}
                      positiony={this.state.positiony}
                      hideMessage={this.hideMessage}
                      options={this.state.options}
                      {...props}
                    />
                  )}
                />
              </>
            </Switch>
            <Route
              render={(props) => (
                <Footer
                  loginInfo={this.state.loginInfo}
                  footerconfig={this.state.footerconfig}
                  windowWidth={this.state.windowWidth}
                  {...props}
                />
              )}
            />
          </div>
        </Router>
      </>
    );
  }
}

export default Routes;
