/* eslint-disable jsx-a11y/alt-text */
/*****components***/
import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

/*custom components*/
import PageLoading from '../../components/PageLoading';

import Form from '../../components/Form';
import trackImg from "../../assets/images/img_track.png";

import {toast } from 'mdbreact'; 
import {  getRenderObj,getDefValues, getLanguage,  callApi, validateForm, } from '../../common/functions';

 
const foldername = process.env.REACT_APP_FOLDER_LAYOUT
class page extends Component{

	constructor(props) {
		
		super(props);
		this.state = {
			
			 trackFormData : {},
			 trackFormConfig :{},
  		 preRegisterFormData : {},
			 preRegisterFormConfig :{},
			 goOrder:false,
			 rememberme:true,
			 orderid:"",
			 orgName: process.env.REACT_APP_ORG_NAME,
			 MetaTags : {title: getDefValues().orderInfoLabel,
									names:[
										{  name: "description" , content: getDefValues().orderInfoLabel},
										{  name: "keywords" , content: process.env.REACT_APP_ORG_NAME },
										{  name: "robots"   , content: "INDEX,FOLLOW"}
									]},
		}
	}
	/*loading data*/
	componentDidMount = async() => {
		try{
			this.setState({ isLoading:true })
			 
				this.props.hideCart() 
				
				let trackForm= await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "trackingForm_"+getLanguage(), foldername: foldername+"private/" } )
				
				
				let rendegObj  = await getRenderObj ( trackForm.template[0], null,null, null,null, null, null, null) 
				
				this.setState({ trackFormData: rendegObj.curdata.data, 
												trackFormConfig:rendegObj.curdata.config ,
												isLoading:false })
												
				
	 	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
  
  changeHandler = async (event) => {
	
	  	let resp = await validateForm(this.state.trackFormData, toast,event);
	  	this.setState({trackFormData:resp.formData});
	
		
		if (event.key === 'Enter') {
      this.tracking(event)
      
    }
  }
	tracking= async (event) => {
		
		try{
			 
			var formData = this.state.trackFormData
			let validation = await validateForm (formData, toast )
			if (validation.isValid){
				this.setState({goOrder:true, orderid: formData.orderid.value , email : formData.email.value })
			}
			 
			 
		}catch(Excep){
			
			console.log(">>>>>>>",Excep )
		}
	}
	
	render(){
		return(this.props.showSearcher?"":
		<>
			{this.state.goOrder?
				<Redirect
						  to={{  pathname:  "/getOrderDetails/"+this.state.orderid+"/"+this.state.email }}
				/>
				:""
			}
			<PageLoading isLoading={this.state.isLoading} MetaTags={this.state.MetaTags}/>
			<div className="page-wrapper" onClick={this.props.hideCart}>
			
				
							
				<div className="content container-page custom-container container-fluid">
					<div className="categoryRoot mt-4 mb-2" style={{"margin-left": "-15px"}}>
						{this.state.orgName + " > " } <span>{getDefValues().orderInfoLabel}</span> 
					</div>
					<div className={"card  mt-30 " + (!this.state.isLoading? "card-user-info":"")}>
				
						<div className="row mt-30 mb-50 ">
							<div className={"mobile-col col-12 col-sm-12 col-md-4 col-lg-4"+ ( this.props.windowWidth>962?" border-right ml-5 mt-30" : " ml-1" ) }>
								<Form  customClass={"userForm"}
											formData={this.state.trackFormData}
										 	changeHandler={this.changeHandler}  
										 	formConfig={this.state.trackFormConfig}  
									 	/>
								{!this.state.isLoading?
									<div className="col-12 col-lg-12 col-md-12 col-sm-12">
										<button onClick={this.tracking} className="accout-btn btn btn-primary text-bold btn-block"> {getDefValues().startTrackingLabel.toUpperCase()} </button>
									</div> 
								:""}
							</div>  
							<div className={"mobile-col col-12 col-sm-12 col-md-5 col-lg-4" + (this.props.windowWidth<992? " mt-3 ":" ml-3 ")} >
								{!this.state.isLoading?
									<img style={{"width": "100%"}} src={trackImg} />
									:""}
							</div> 
						</div> 		
					</div> 
				</div>
			</div>
		</>				 
		)
	}
}
export default page; 