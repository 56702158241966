/*****components***/
import React, { Component } from "react";
import { toast } from "mdbreact";
import { Redirect } from "react-router-dom";
/*custom components*/
import PageLoading from "../../components/PageLoading";

import Modal from "../../components/Modal";
import Form from "../../components/Form";
import Card from "../../components/Card";
import Slider from "../../components/Slider";
import SliderImage from "../../components/SliderImage";
import Text from "../../components/Text";
import Image from "../../components/Image";
import CategoryBlog from "../../components/CategoryBlog";

/*functions*/
import {
  manageCart,
  manageButton,
  findObjName,
  changeHandlerManager,
  toggleModalManager,
  loadPageV2, 
  callApi, 
} from "../../common/functions";
 
var windowWidth = window.innerWidth;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      mainClass: "page-wrapper",
      redirectProductDetails: false,
      selectedProductID: 0,
      selectedPath: "",
      selectedCategory: "",
    };
  }

  /*loading data*/
  componentDidMount = async () => {
    try {
      this.props.hideCart();
      var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: this.props.match.params.page,
        foldername: foldername + "private/",
      });
      let _pageData = await loadPageV2(
        resp.template,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        windowWidth
      );
      console.log("RESPUESA FINAL:", this.props.match.params.page);
      console.log(_pageData);
      this.renderData(_pageData);

      //this.setState({pageData:_pageData, template: resp.template});
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderData = (_pageData) => {
    try {
      for (let r in _pageData) {
        console.log(_pageData[r]);
        this.setState({
          ["RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      console.log("-final-", this.state);
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  toggleModal = (event) => {
    let object = findObjName(event);
    console.log(object, this.state["RENDEROBJ" + object]);
    let newTemplate = toggleModalManager(this.state["RENDEROBJ" + object]);
    this.setState({ ["RENDEROBJ" + object]: newTemplate });
  };

  changeHandler = async (event) => {
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = changeHandlerManager(
      this.state["RENDEROBJ" + fieldName[0]],
      fieldName[1],
      event.target.value
    );
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
  };

  manageCart = async (event) => {
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }

    //console.log(object,container, this.state["RENDEROBJ"+container].data)
    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state["RENDEROBJ" + container].data,
        this.manageCart,
        toast
      );
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  manageButton = async (event) => {
    try {
      this.setState({ isLoading: true });
      var fieldName = event.target.name.split("__");
      var form;
      for (let key in this.state.pageData) {
        if (this.state.pageData[key].props.container === fieldName[0]) {
          form = this.state.pageData[key].props.formData;
        }
      }

      var resp = await manageButton(fieldName[1], form);
      if (resp.response) {
        toast.success(resp.descResponse, { closeButton: true });
      } else {
        toast.success(resp.descResponse, { closeButton: true });
      }
      let _pageData = await loadPageV2(
        this.state.template,
        this.changeHandler,
        this.toggleModal,
        this.manageButton,
        this.manageCart,
        windowWidth
      );
      this.setState({ pageData: _pageData });
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        <PageLoading isLoading={this.state.isLoading} />
        <div className="page-wrapper" onClick={this.props.hideCart}>
          {this.state.redirectProductDetails ? (
            <Redirect
              to={{
                pathname:
                  (this.state.selectedCategory
                    ? "/" + this.state.selectedCategory + "/"
                    : "/productDetails/") +
                  this.state.selectedProductID +
                  "/" +
                  (this.state.selectedPath ? this.state.selectedPath : "show"),
              }}
            />
          ) : (
            ""
          )}

          <div className="content container-page container-fluid custom-container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="card ">
                  {Object.keys(this.state).map((obj) => (
                    <>
                      {obj.startsWith("RENDEROBJ") ? (
                        <>
                          {this.state[obj].type === "TEXT" ? (
                            <Text
                              name={this.state[obj].ObjectName}
                              textData={this.state[obj].data}
                              textConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "IMAGE" ? (
                            <Image
                              name={this.state[obj].ObjectName}
                              imageData={this.state[obj].data}
                              imageConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CATEGORYBLOG" ? (
                            <CategoryBlog
                              name={this.state[obj].ObjectName}
                              categoryBlogData={this.state[obj].data}
                              categoryBlogContent={this.state[obj].content}
                              categoryBlogConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "FORM" ? (
                            <Form
                              name={this.state[obj].ObjectName}
                              container={this.state[obj].ObjectName}
                              formData={this.state[obj].data}
                              changeHandler={this.changeHandler}
                              manageButton={this.manageButton}
                              formConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDERPRODUCTS" ? (
                            <Slider
                              name={this.state[obj].ObjectName}
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CARDPRODUCTS" ? (
                            <Card
                              name={this.state[obj].ObjectName}
                              cardData={this.state[obj].data}
                              cardConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CARDCONTAINER" ? (
                            <Card
                              name={this.state[obj].ObjectName}
                              cardData={this.state[obj].data}
                              cardConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDERIMAGE" ? (
                            <SliderImage
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "MODAL" ? (
                            <Modal
                              name={this.state[obj].ObjectName}
                              modalData={this.state[obj].data}
                              modalConfig={this.state[obj].config}
                              toggle={this.toggleModal}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDER" ||
                          this.state[obj].type === "SLIDERCONTAINER" ? (
                            <Slider
                              name={this.state[obj].ObjectName}
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
