import React from "react";
import { useHistory } from "react-router-dom";

const Footer = (props) => {
  const history = useHistory();
  return (
    <div>
      {!props.footerconfig ? (
        ""
      ) : (
        <footer className="container  footer" style={props.footerconfig.style}>
          <div className="footer-top">
            <div className="container-fluid">
              <div className="row align-items-top">
                <div className="col-12">
                  <div className="row align-items-left">
                    {props.footerconfig.footeroptions.map((item, i) => (
                      <div
                        style={item.style}
                        className={
                          "py-3 pl-3 " +
                          (item.size ? item.size : "col-4") +
                          (item.hidemobile ? " hide-mobile" : " ") +
                          (item.hideweb ? " hide-web" : " ")
                        }
                      >
                        <div
                          onClick={() => {
                            if (item.title.link) {
                              history.push(item.title.link);
                            }
                          }}
                        >
                          {item.title.image ? (
                            <img
                              key={"fotitem1" + i}
                              style={item.title.style}
                              alt={item.title.label}
                              src={item.title.image}
                            />
                          ) : (
                            <span style={item.title.style}>
                              {item.title.label}
                            </span>
                          )}
                        </div>
                        {item.subtitle ? (
                          <div>
                            <span style={item.subtitle.style}>
                              {item.subtitle.label}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <ul className="footer-module-body">
                          {item.childs.map((subitem, i2) =>
                            (subitem.notlogged && props.loginInfo.userId) ||
                            (subitem.logged && !props.loginInfo.userId) ? (
                              ""
                            ) : (
                              <>
                                {subitem.type === "LINK" && (
                                  <li>
                                    <a
                                      className="link-text"
                                      href={subitem.link}
                                    >
                                      <span style={subitem.style}>
                                        {" "}
                                        {item.childs.length > 1 ? (
                                          <> •</>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {subitem.label}
                                      </span>
                                    </a>
                                  </li>
                                )}

                                {subitem.type === "TEXT" ? (
                                  subitem.link !== "" ? (
                                    <li>
                                      <a
                                        className="link-text"
                                        href={subitem.link}
                                      >
                                        <span style={subitem.style}>
                                          {" "}
                                          {item.childs.length > 1 ? (
                                            <> •</>
                                          ) : (
                                            ""
                                          )}{" "}
                                          {subitem.label}
                                        </span>
                                      </a>
                                    </li>
                                  ) : (
                                    <li>
                                      <span style={subitem.style}>
                                        {" "}
                                        {item.childs.length > 1 ? (
                                          <> •</>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {subitem.label}
                                      </span>
                                    </li>
                                  )
                                ) : (
                                  ""
                                )}
                                {subitem.type === "IMAGE" ? (
                                  <a
                                    className="link-image"
                                    tabIndex="1"
                                    href={subitem.link}
                                    target={
                                      subitem.target ? subitem.target : "_self"
                                    }
                                    rel="noopener"
                                  >
                                    <img
                                      src={subitem.image}
                                      alt={subitem.label}
                                      className="ml-1 "
                                      style={subitem.style}
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          )}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container-fluid">
              {/* Copyright */}
              <div className="copyright">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-1">
                    <div className="copyright-text">
                      <p className="mb-0">Copyright © 2024. Casaideas</p>
                      <small>Powered by Bolcore - 2023 </small>									  
                      <br></br>
                      <small>
                        Powered by{" "}
                        <a
                          rel="noreferrer"
                          href={"http://www.xpertcode.biz"}
                          target="_blank"
                        >
                          {" "}
                          {"XpertCode - 2024"}{" "}
                        </a>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Footer;
