/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";
import { Redirect } from "react-router-dom";

/*custom components*/
import PageLoading from "../../components/PageLoading";

import SuccesfulMessage from "../../components/SuccesfulMessage";

import shopcart from "../../assets/images/ico_checkout_cart.svg";
import shipping from "../../assets/images/ico_shippment_step.svg";
import payment from "../../assets/images/ico_credit_card.svg";

import { MDBAlert, MDBBtn } from "mdbreact";
import {
  finishCart,
  setGTMEvent,
  getCookie,
  getDefValues,
  setCookie,
  find,
  getStoresInfo,
  getLoginInfo,
  getLanguage,
  callApi,
} from "../../common/functions";

//import { v4 as uuid } from 'uuid';

/*functions*/
//var windowHeight = window.innerHeight
//const foldername = process.env.REACT_APP_FOLDER_LAYOUT

//const AnyReactComponent = ({ text }) => <div style={{"position":"absolute", "color":"red" }}>{text}</div>;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoading: true,
      orderPaid: false,
      paymentType: "",
      orderid: "",
      showErrorMessage: false,
      modal2: false,
      formDataPD: {},
      finalFormData: {},
      shippingCost: 0,
      subtotal: 0,
      discount: 0,
      shippingType: "",
      shippingLabel: "",
      paymentMethod: "",
      city: "",
      addressForm: {},
      tryout: 0,
      isPaid: false,
      quantity: 0,
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    try {
      this.props.hideCart();
      var intervalId = setInterval(this.checkorder, 7000);
      var eventid = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");

      if (eventid) {
        this.setState({ ...this.state, eventid: eventid });

        setCookie(process.env.REACT_APP_ORG_NAME + "_eventId", "", 0.00001);
        setCookie(process.env.REACT_APP_ORG_NAME + "_eventName", "", 0.00001);
        setCookie(process.env.REACT_APP_ORG_NAME + "_action", "", 0.00001);
        setCookie(process.env.REACT_APP_ORG_NAME + "_eventQty", 0, 0.00001);
      }
      this.setState({ ...this.state, isLoading: true, intervalId: intervalId });
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    }
  };

  componentWillUnmount = () => {
    //	console.log("out of timer" )
    clearInterval(this.state.intervalId);
  };

  checkorder = async (event) => {
    var login = getLoginInfo();
    var userId = 0;
    if (login.userId != null) {
      userId = login.userId;
    }
    var orderid = this.props.match.params.orderid;
    setCookie(process.env.REACT_APP_ORG_NAME + "_genericCoupons", null, 0.0001);
    var products = [];
    var items = [];
    if (!this.state.isPaid && this.state.tryout < 10) {
      this.setState({ tryout: this.state.tryout + 1 });
      //console.log("Checking", this.state.tryout)
      let resp = await callApi("frontend", "checkOrderPaid", {
        orderid: orderid,
        userId: userId,
        languageid: getLanguage(),
      });

      if (resp.success) {
        var quantity = 0;
        this.setState({ tryout: 10 });
        if (resp.order[0].PAYMENT_KEY === "NOTIFY") {
          this.setState({ reloadPage: true });
        } else {
          //var form = await getPersonalDataForm();
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_tempoUserCode",
            "0",
            0.01
          );

          resp.orderdetails.map(function (key) {
            products.push({
              id: key.PRODUCTID,
              productid: key.PRODUCTID,
              sku: key.SKU,
              productsku: key.SKU,
              name: key.PRODUCTNAME,
              productname: key.PRODUCTNAME,
              price:
                key.DISCOUNT == 0
                  ? key.PRICE.toFixed(2)
                  : key.DISCOUNT.toFixed(2),
              brand: "CasaIdeas",
              category: key.CATEGORY,
              variant: key.VARIANT,
              quantity: key.QUANTITY,
              image: key.DEFAULTIMAGE,
              skus: key.SKUS,
            });

            items.push({
              item_id: key.PRODUCTID,
              item_name: key.PRODUCTNAME,
              coupon:
                resp.order[0].HAVECOUPON !== "NO"
                  ? resp.order[0].HAVECOUPON
                  : "",
              discount:
                key.DISCOUNT == 0 ? 0 : (key.PRICE - key.DISCOUNT).toFixed(2),
              price: key.PRICE.toFixed(2),
              affiliation: "Google Store",
              item_brand: "CasaIdeas",
              item_category: key.CATEGORY,
              item_variant: key.VARIANT,
              quantity: key.QUANTITY,
              currency: "BOB",
            });

            quantity = quantity + parseInt(key.QUANTITY);
          });

          this.setState({
            modal2: true,
            orderPaid: true,
            products: products,
            orderid: orderid,
            /*	formDataPD:form.formData, */
            isPaid: true,
          });

          var _formData = JSON.parse(resp.order[0].ADDRESSINFO);
          //var formData={}
          var city = "";
          try {
            if (_formData.addressinfo.city) {
              city = _formData.addressinfo.city;
            }
          } catch (e) {
            if (_formData.city) {
              city = _formData.city;
            }
          }

          let storesInfo = await getStoresInfo();
          let pos = find(
            storesInfo.stores,
            storesInfo.selectedStoreId,
            "storeId"
          );
          var storeName = storesInfo.stores[pos].storeName;
          var objData = {
            current_view: "purchase",
            current_list: "purchase",
            current_currency: "BOB",
            userId: getLoginInfo().userId ? getLoginInfo().userId : "0",
            orderid: orderid,
            cart_products: null,
            string_searched: "",
            store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
            event: "gtm.load",
            payment_type: resp.order[0].PAYMENTMETHOD,
            delivery_type: resp.order[0].SHIPPINGMETHOD,
            //gtm: {uniqueEventId: 1157, start: 1649342789368},
            gdpr_marketing_status: "accepted",
            gdpr_statistics_status: "accepted",
            googleDynamicRemarketing: { ecomm_pagetype: "other" },
            criteoParams: { email: resp.order[0].EMAIL },

            orderSuccess: {
              id: orderid,
              total: resp.order[0].SUBTOTAL - resp.order[0].DISCOUNT,
              shipping: resp.order[0].SHIPPINGCOST,
              coupon: 0,
            },
            purchasedProducts: products,

            fb_pixel_track_name: "Purchase",
            fb_pixel_track_info: {
              value: (resp.order[0].SUBTOTAL - resp.order[0].DISCOUNT).toFixed(
                2
              ),
              currency: "BOB",
            },

            ecommerce: {
              currencyCode: "BOB",
              purchase: {
                actionField: {
                  id: orderid,
                  affiliation: process.env.REACT_APP_ORG_NAME + "-" + storeName,
                  revenue: resp.order[0].SUBTOTAL - resp.order[0].DISCOUNT,
                  tax: 0,
                  shipping: resp.order[0].SHIPPINGCOST,
                },
                products: products,
                items: items,
              },
            },
          };
          if (resp.order[0].HAVECOUPON !== "NO") {
            objData["ecommerce"]["purchase"]["actionField"]["coupon"] =
              resp.order[0].HAVECOUPON;
          }

          setGTMEvent("orderSuccess", objData);
          await callApi("frontend", "setOrderNotify", {
            orderid: orderid,
            userId: userId,
          });
          this.setState({
            /*finalFormData : formData, */
            shippingCost: resp.order[0].SHIPPINGCOST,
            paymentMethod: resp.order[0].PAYMENTMETHOD,
            shippingLabel: resp.order[0].SHIPPINGMETHOD,
            shippingType: resp.order[0].SHIPPINGTYPE,
            subtotal: resp.order[0].SUBTOTAL,
            discount: resp.order[0].DISCOUNT,
            city: city,
            addressForm: _formData,
            isLoading: false,
            quantity: quantity,
          });

          clearInterval(this.state.intervalId);

          await finishCart();
          //this.props.updateCart(data)
        }
      }
    } else {
      clearInterval(this.state.intervalId);
      await finishCart();
      this.setState({
        isLoading: false,
        showErrorMessage: true,
        orderid: orderid,
      });
    }
  };
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.reloadPage ? <Redirect to={{ pathname: "/" }} /> : ""}

        <PageLoading isLoading={this.state.isLoading} />
        {/*fullHeight*/}

        <div className="page-wrapper" onClick={this.props.hideCart}>
          <div className="content container-page custom-container container-fluid">
            <div className="row mt-2">
              <div className="col-12">
                <div className="steps-form mb-3 mt-3">
                  <div className="steps-row steps-row-img">
                    <div className="steps-step steps-step-center">
                      <img
                        src={shopcart}
                        alt="shop-cart"
                        className="opcacity05"
                      />
                    </div>
                    <div className="steps-step steps-step-center">
                      <img
                        src={shipping}
                        alt="shop-cart"
                        className="opcacity05"
                      />
                    </div>
                    <div className="steps-step steps-step-center">
                      <img src={payment} alt="shop-cart" />
                    </div>
                  </div>
                </div>
                <div className="steps-form">
                  <div className="steps-row" style={{ display: "flex" }}>
                    <div
                      className="steps-step"
                      style={{ display: "inline-block", width: "33.333%" }}
                    >
                      <a href="#formstep1">
                        <MDBBtn className="btn btn-light  cbtn-circle">
                          <div className="cbtn-circle-inactive"></div>
                        </MDBBtn>
                      </a>
                      <p>{getDefValues().myCartLabel.toUpperCase()}</p>
                    </div>

                    <div
                      className="steps-step"
                      style={{ display: "inline-block", width: "33.333%" }}
                    >
                      <a href="#formstep2">
                        <MDBBtn className="btn btn-light  cbtn-circle">
                          <div className="cbtn-circle-inactive"></div>
                        </MDBBtn>
                      </a>
                      <p>{getDefValues().ShippingLabel.toUpperCase()}</p>
                    </div>

                    <div
                      className="steps-step"
                      style={{ display: "inline-block", width: "33.333%" }}
                    >
                      <a href="#formstep3">
                        <MDBBtn className="btn btn-primary cbtn-circle">
                          <div className="cbtn-circle-active"></div>
                        </MDBBtn>
                      </a>
                      <p>{getDefValues().paymentLabel.toUpperCase()} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.showErrorMessage ? (
              <div className="row">
                <div className="col-12 flex items-center">
                  <MDBAlert color="danger" className="mt-2 mb-2">
                    <p className="mt-3 flex items-center">
                      <span
                        className="h2-responsive text-center"
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-exclamation-circle mr-1"></i>
                        {getDefValues().orderPaymentErrorLabel.replace(
                          "<orderid>",
                          this.state.orderid
                        )}
                      </span>
                    </p>
                    <p className="mt-1 flex items-center">
                      <a href="/customer/tracking">
                        <span className="h4-responsive dark-text text-underline text-center">
                          {" "}
                          {getDefValues().goCheckOrdersLabel}{" "}
                        </span>
                      </a>
                    </p>
                  </MDBAlert>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.orderPaid ? (
              <SuccesfulMessage
                orderid={this.state.orderid}
                toggle={this.toggle(2)}
                city={this.state.city}
                subtotal={this.state.subtotal}
                /*formDataPD={this.state.formDataPD}*/
                shippingLabel={this.state.shippingLabel}
                quantity={this.state.quantity}
                shippingCost={this.state.shippingCost}
                orderDetails={this.state.products}
                discount={this.state.discount}
                paymentMethod={this.state.paymentMethod}
                shippingType={this.state.shippingType}
                addressForm={this.state.addressForm}
                eventid={this.state.eventid}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}
export default page;
