/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from 'react'; 
import { Redirect } from "react-router-dom";
import decode from 'jwt-decode';
import {find, findValueById,callApi,setCookie,  getCookie} from '../../common/functions';
import PageLoading from '../../components/PageLoading'; 
 
class page extends Component{
	
	
	constructor(props) {
		
		
		super(props);
		this.state = {
			reridrect:false,
			goHome: false,
			isLoading:true
		}
	}
	
 
 	 
  
	/*loading data*/
	componentDidMount = async() => {
		 
		try{

			let token = this.props.match.params.token
		 console.log(token)
			let resp = await callApi ( "frontend","getUserInfoByCartID", { id : token  } )
		console.log("resp:::",resp)
			let storeid = resp.storeid
			 
			let respStores = await callApi ( "frontend","getStores" )
			let stores=[]			
			respStores.rows.map(function (key) {
				let coords = JSON.parse(key.CLOB_VALUE)
				let store ={storeId: key.DETAILDOMAINID, storeName:key.STRING_VALUE , catalogId: key.NUMBER_VALUE, lat:coords.lat, lng:coords.lng, add: coords.address }
				stores.push(store)
			}); 
			let catalogId =  findValueById(stores, storeid, "storeId", "catalogId")
 		
			console.log(catalogId, storeid , resp.storeid )
			if (resp.success && catalogId>0 &&  storeid.toString() ===  resp.storeid.toString() ){
				//console.log("res", resp)
				
				//let tempCart = localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_activeCartV2')
				//var curCart=JSON.parse(tempCart)
			 
				var dataC = []				
				try{
					dataC = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_cartSessionsV2'))
					if (dataC){
						let pos = find (dataC, storeid,"storeid")
						if (pos>0){
							console.log("actualizamos")
							dataC[pos] = { id :token, storeid:resp.storeid, userid: resp.userId }
						}else{
							console.log("llenamos")
							dataC.push({ id :token, storeid:resp.storeid, userid: resp.userId } )
						}
					}else{
						dataC= [{ id :token, storeid:resp.storeid, userid: resp.userId }]	
					}
				}catch(Ex){
					console.log("empty cart")
					dataC= [{ id :token, storeid:resp.storeid, userid: resp.userId }]
					
				}

				//console.log("dataC", dataC)


				localStorage.setItem(process.env.REACT_APP_ORG_NAME+'_activeCartV2',  JSON.stringify(resp.shopCart))
				setCookie(process.env.REACT_APP_ORG_NAME+'_cartSessionsV2',  JSON.stringify(dataC), 24 )

				var data = {
					userId      : resp.userId,
					userToken   : resp.token
				}

				var tData = decode(resp.token);

				var haveEvent = tData.haveenvent
				if (haveEvent>0){
					localStorage.setItem(process.env.REACT_APP_ORG_NAME+'_haveEvent',  haveEvent)
				}
				
				setCookie(process.env.REACT_APP_ORG_NAME+'_userloginV2', JSON.stringify(data), 24)			
				localStorage.removeItem(process.env.REACT_APP_ORG_NAME+'_userloginV2');

				data = {
					userId   : resp.userId,
					token    : tData,
					keyToken : resp.token,
				}

				await this.props.updateUserInfo(data)
				await this.props.updateCart(resp.shopCart)			 

				setTimeout( async function() {  
					this.setState({reridrect:true, isLoading: false})
				}.bind(this),1500)

			}else{
				this.setState({goHome:true, isLoading: false})
				console.log("invalid data")
			}
		}catch(Ex){
			
			this.setState({goHome: true, reridrect:false, isLoading: false })
		}
	}	
	 
	 
	render(){
		
		return(
			<div className="page-wrapper" onClick={this.props.hideCart}>
				<div className="content container-page custom-container container-fluid">
					<div className="row mt-20" style={{height:350, display:"flex", alignItems:"center"}}>
						<div className="col-12 col-12 text-center">
							<PageLoading isLoading={this.state.isLoading}/>
							
							{this.state.reridrect &&
								<Redirect   to={{  pathname:  "/checkout/cart" }} />
							}

							{this.state.goHome &&
								<Redirect to={{  pathname:  "/" }}/>
							}
						</div>
					</div>
				</div>
			</div>
		)
		 
	}
}

export default  page
