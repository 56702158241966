import React from 'react';
import AppContainer from './appcontainerV2.jsx';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "mdbreact";

import {  } from "react-router-dom";
 

const AppRouter = function (props) {
		return(
			<>
       	<Router> 
        	<Route render={(props)=> <AppContainer {...props}/>} />
				</Router>
        <ToastContainer
        		className={"custonNotif"}
	          closeButton={false}
	          autoClose={3000}
	          hideProgressBar={true}
	          />
    	</>
    );
}


export default AppRouter;